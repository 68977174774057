import { RequestHelper } from '../../../../helpers/requestHelper'
import { RequestHelperCountries } from '../../../../helpers/requestHelperCountries'
import { resolveMessages } from '../../../../helpers/resolveMessages'
import { SesionService } from '../../../../services/sesion.service'
import { TiposComprobantesSLV } from '../../../../store/types'
import { TNotaCreditoIngresar } from '../../pages/notasCredito/types/types'
import { TNotaDebitoIngresar } from '../../pages/sv/notasDebito/types/types'
import { TFacturaIngresar } from '../../pages/ventas/types/types'
import {
  TS_AnulacionSV,
  TS_Auth,
  TS_InvoiceConsumidorFinal,
  TS_ConstingencySV,
} from '../../types/facturacion/elSalvador/types'

export const SLV_VentasService = {
  registerDocConFinal,
  registerDocCredFiscal,
  registerAuth,
  sendMail,
  registerDocFactExportacion,
  anularDocumentoSV,
  registerDocRemision,
  registerDocSujetoExcluido,
  registerDocRetencion,
  registerDocPruebasConsumidorFinal,
  registerDocPruebasCCF,
  registerDocPruebasSujetoExcluido,
  registerDocPruebasExportacion,
  contingenciaDocumentoSV,
  registerDocPruebasNotaCredito,
  registerDocPruebasNotaDebito,
  registerDocPruebasNotaRemision,
  checkStateDte,
  onParseAutorizathionDte,
  ingresarVentaSV,
  updateCodeGeneration,
  ingresarNotaCredito,
  ingresarNotaDebito,
  ingresarDonacion,
}

async function registerDocConFinal(
  doc: TS_InvoiceConsumidorFinal,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'consumidor-final',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocPruebasConsumidorFinal(
  doc: any,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'consumidor-final',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocPruebasCCF(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'credito-fiscal',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocPruebasNotaCredito(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'nota-credito',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocPruebasNotaRemision(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'nota-remision',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}

async function registerDocPruebasNotaDebito(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'nota-debito',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocPruebasExportacion(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'exportacion',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocPruebasSujetoExcluido(
  doc: any,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'envio-pruebas',
      'sujeto-excluido',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocSujetoExcluido(
  doc: TS_InvoiceConsumidorFinal,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'sujeto-excluido',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocRemision(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'nota-remision',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocCredFiscal(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'credito-fiscal',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}
async function registerDocRetencion(doc: any): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'retencion',
      dte,
    )
    return creada
  } catch (error) {
    return error
  }
}
async function registerDocFactExportacion(
  doc: TS_InvoiceConsumidorFinal,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'factura-exportacion',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}

//anular factura
async function anularDocumentoSV(doc: TS_AnulacionSV): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'anulacion',
      dte,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function registerAuth(auth: TS_Auth): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>('ventas', 'registerAuth', {
      extras: auth,
      claveacceso: auth.claveacceso,
    })
    return creada
  } catch (error) {
    return error
  }
}

async function sendMail(claveacceso: string): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>('generales', 'sendDoc', {
      identificadosSV: {
        accessKey: claveacceso,
      },
    })
    return creada
  } catch (error) {
    return error
  }
}

async function contingenciaDocumentoSV(
  doc: TS_ConstingencySV,
): Promise<Promise<any>> {
  try {
    const dte: any = {
      ...getIdentificationClient(doc),
    }
    const creada = await RequestHelperCountries.postAll<any>(
      'facturacion-electronica',
      'contingencia',
      dte,
    )
    return creada
  } catch (err) {
    const detectError = resolveMessages.errorNetwork(err)
    if (detectError?.error === true) {
      return { error: detectError?.error, message: detectError?.message }
    }
    return err
  }
}

export function getIdentificationClient(doc: any) {
  const sesion = SesionService.getCurrentSesion()
  const cliente: any = {
    cliente: {
      ref: sesion.empresa.uuid,
    },
    ...doc,
  }
  return cliente
}

async function checkStateDte(
  fechaEmision: string,
  codigoGeneracion: string,
): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>('public', 'checkStateDte', {
      fechaEmision: fechaEmision,
      codigoGeneracion: codigoGeneracion,
    })
    return creada
  } catch (error) {
    return error
  }
}

function onParseAutorizathionDte(dteAuth): TS_Auth {
  return {
    ambiente: dteAuth?.ambiente ?? '',
    clasificaMsg: dteAuth?.clasificaMsg ?? '',
    codigoGeneracion: dteAuth?.codigoGeneracion ?? '',
    codigoMsg: dteAuth?.codigoMsg ?? '',
    descripcionMsg: dteAuth?.descripcionMsg ?? '',
    estado: dteAuth?.estado ?? '',
    fhProcesamiento: dteAuth?.fhProcesamiento ?? '',
    observaciones: dteAuth?.observaciones,
    selloRecibido: dteAuth?.selloRecibido ?? '',
    version: parseInt(dteAuth?.version) ?? 0,
    versionApp: parseInt(dteAuth?.versionApp) ?? 0,
  }
}

async function ingresarVentaSV(venta: TFacturaIngresar): Promise<Promise<any>> {
  try {
    const creada = await RequestHelper.postAll<any>(
      'ventas',
      'sv/ingresar',
      venta,
    )
    return creada
  } catch (error) {
    return error
  }
}

async function updateCodeGeneration(auth: {
  reference: number
  codeGeneration: string
  tipoDoc: TiposComprobantesSLV
}): Promise<Promise<any>> {
  try {
    const res = await RequestHelper.postAll<any>('ventas', 'codeGeneration', {
      ...auth,
    })
    return res
  } catch (error) {
    return error
  }
}

async function ingresarNotaCredito(
  notaCredito: TNotaCreditoIngresar,
): Promise<any> {
  const creada = await RequestHelper.post<any>(
    'ventas',
    'notacreditosv/ingresar',
    notaCredito,
  )
  return creada
}

async function ingresarNotaDebito(
  notaCredito: TNotaDebitoIngresar,
): Promise<any> {
  const creada = await RequestHelper.post<any>(
    'ventas',
    'notadebitosv/ingresar',
    notaCredito,
  )
  return creada
}

async function ingresarDonacion(documento: any): Promise<any> {
  const creada = await RequestHelper.postAll<any>(
    'ventas',
    'donaciones/sv/ingresar',
    documento,
  )
  return creada
}
