import React, { useCallback, useState } from 'react'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { MarcasDatosEdicion } from '../../../../../../inventario/pages/catalogos/marcas/types/types'
import FiltroBusqueda from './FiltroBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../../store/store'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { consoleService } from '../../../../../../../services/console.service'
import { MarcationsService } from '../../services/marcations.service'
import {
  changeFilter,
  setChangeLoader,
  setCollapsed,
  setResetSeleccion,
  setResults,
} from '../../store/searchReducer'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import {
  MarcationList,
  Marcations,
  MarcationsListDto,
  RegisterUserList,
  Registers,
  RegistersListDto,
} from '../../types/types'
import { ModalMarcacion } from '../../../../../../componentes/modalMarcaciones'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { isMobile } from 'react-device-detect'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { VistasBusqueda } from '../../../../../../../store/enums'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<MarcasDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup'
const Buscar: React.FunctionComponent<ISearchProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const tabs = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.tabs,
  )
  const filter = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.search.filtro,
  )
  const filterBackup = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.search.filtroBackup,
  )
  const resultados = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.search.resultados,
  )
  const seleccionado = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.search.seleccionado,
  )
  const loader = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.search.loader,
  )
  const currentTab = useSelector(
    (state: RootState) => state.nomina.registros.marcacion.tabs.current,
  )
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [showMarcacione, setShowMarcacion] = React.useState<any>(false)
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const [dataImprimir, setDataImprimir] = React.useState<RegisterUserList[]>([])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Marcación',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback(
    (showLoader: boolean, mensaje: string) => {
      dispatch(
        setChangeLoader({
          show: showLoader,
          mensaje: mensaje,
        }),
      )
    },
    [dispatch],
  )

  const loadArrears = useCallback(
    (data: Array<MarcationList>, codigo: number) => {
      let atrasos: number = 0
      let salidas: number = 0
      let hsalidas: number = 0
      let hatrasos: number = 0
      let hextra50: number = 0
      let hextra100: number = 0
      data.forEach((x) => {
        atrasos = x?.atrasos ?? 0
        salidas = x?.antes ?? 0
        hsalidas = x?.minutosAntes ?? 0
        hatrasos = x?.minutosAtraso ?? 0
        hextra50 = x?.minutosExtra50 ?? 0
        hextra100 = x?.minutosExtra100 ?? 0
      })
      dispatch(
        changeFilter({
          ...filter,
          htrCodigo: codigo,
          atrasos: atrasos,
          salidas: salidas,
          tiempoSalAnt: hsalidas,
          tiempoAtrasos: hatrasos,
          hoEx50:
            `${hextra50 / 60}:` + String(hextra50 % 60).substring(0, 2) + ':00',
          hoEx100:
            `${hextra100 / 60}:` +
            String(hextra100 % 60).substring(0, 2) +
            ':00',
        }),
      )
    },
    [dispatch, filter],
  )

  const onFind = useCallback(async () => {
    if (filter?.empleadoCodigo === 0) {
      setToast('Elija un empleado', ToastTypes.Warning)
      return
    }
    if (filter?.mes === null || filter?.mes?.value === '-1') {
      setToast('Elija un mes', ToastTypes.Warning)
      return
    }

    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      consoleService.log(filter)
      const data = await MarcationsService.loadMarctions(
        filter?.empleadoCodigo ?? 0,
        filter?.mes?.value ?? '',
        filter?.anio ?? 0,
      )
      const dataByUser = await MarcationsService.loadByUser(
        filter?.empleadoCodigo ?? 0,
        filter?.mes?.value ?? '',
        filter?.anio ?? 0,
      )
      consoleService.log(dataByUser, 'get byuser')
      if (dataByUser?.auto && dataByUser?.error === false) {
        dispatch(
          changeFilter({
            ...filter,
            htrCodigo: dataByUser?.auto,
          }),
        )
      } else {
        setToast(dataByUser?.message, ToastTypes.Danger)
      }
      consoleService.log(data, 'get marsk')
      if (data?.auto && data?.error === false) {
        if (data?.auto?.length > 5) {
          dispatch(setCollapsed(false))
        }
        dispatch(setResults(data?.auto))
        loadArrears(data?.auto, dataByUser?.auto ?? 0)
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [filter, setToast, onSetButtonAction, onLoader, dispatch, loadArrears])

  const onUndo = useCallback(() => {
    dispatch(
      changeFilter({
        ...filterBackup,
      }),
    )
    dispatch(setResetSeleccion())
  }, [filterBackup, dispatch])

  const onBroom = useCallback(() => {
    dispatch(setResults([]))
  }, [dispatch])

  const onAssociate = useCallback(async () => {
    onSetButtonAction(ButtonTypes.associate)
    onLoader(true, 'Asociando...')
    try {
      const data = await MarcationsService.associateMark()
      consoleService.log(data, 'associate')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [setToast, onLoader, onSetButtonAction])

  const onShowHours = useCallback(() => {
    if (seleccionado !== null) {
      setShowMarcacion(true)
    }
  }, [seleccionado])

  const onGetUser = useCallback(async () => {
    onLoader(true, 'Buscando usuario...')
    try {
      const data = await MarcationsService.getUserSimple(
        filter?.identificacion ?? '',
      )
      consoleService.log(data, 'data user')
      if (data?.auto && data?.error === false) {
        dispatch(
          changeFilter({
            ...filter,
            identificacion: data?.auto?.cedula ?? '',
            empleado: data?.auto?.nombre ?? '',
            empleadoCodigo: data?.auto?.codigo ?? 0,
          }),
        )
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
  }, [setToast, filter, onLoader, dispatch])

  const onGetDataPrint = useCallback(
    async (report: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.associate)
      onLoader(true, 'Asociando...')
      try {
        const data = await MarcationsService.getUserRegister(
          filter.empleadoCodigo,
          filter?.mes?.value,
          filter?.anio,
        )
        consoleService.log(data, 'get data print')
        if (data?.auto && data?.error === false) {
          setDataImprimir(data?.auto)
          setReporte(report)
        } else {
          setToast(data?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    },
    [setToast, onLoader, onSetButtonAction, filter],
  )

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) onFind()
          break
        case ButtonTypes.dialing:
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.associate:
          if (tabs.current === tabId) onAssociate()
          break
        case ButtonTypes.checkn:
          if (tabs.current === tabId) onShowHours()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) {
            if (filter.tipoImpresion?.value === 0) {
              setReporte('Viewer')
            } else {
              onGetDataPrint('Viewer')
            }
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) {
            if (filter.tipoImpresion?.value === 0) {
              setReporte('Designer')
            } else {
              onGetDataPrint('Designer')
            }
          }
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      tabs,
      onFind,
      onUndo,
      onBroom,
      onAssociate,
      onShowHours,
      filter,
      onGetDataPrint,
    ],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: false,
          Buscar: true,
          Imprimir: resultados.length > 0,
          Deshacer: true,
          Limpiar: resultados.length > 0,
          Marcacion: true,
          Asociar: true,
          Importar: false,
          Check: seleccionado !== null,
        },
      }),
    )
  }, [dispatch, resultados, seleccionado])

  const onParseData = useCallback(() => {
    const registros: Array<MarcationsListDto> = []
    const lista: Array<Marcations> = []
    if (resultados.length > 0) {
      resultados.forEach((x) => {
        lista.push({
          Date: x?.fecha ?? '',
          Day: x?.diaDescripcion ?? '',
          MinutesWorked: x?.minutosTrabajados ?? 0,
          Arrears: x?.atrasos ?? 0,
          MinutesLate: x?.minutosAtraso ?? 0,
          Before: x?.antes ?? 0,
          MinutesBefore: x?.minutosAntes ?? 0,
          Xtra50: x?.minutosExtra50 ?? 0,
          Xtra100: x?.minutosExtra100 ?? 0,
          Errors: x?.error ?? '',
        })
      })
    }
    const registro: MarcationsListDto = {
      Employee: filter?.empleado ?? '',
      Month: filter?.mes?.label ?? '',
      Year: filter?.anio ?? 0,
      Marcations: lista,
    }
    registros.push(registro)
    consoleService.log(registros)
    return registros
  }, [filter, resultados])

  const onParseDataReg = useCallback(() => {
    const registros: Array<RegistersListDto> = []
    const lista: Array<Registers> = []
    if (dataImprimir.length > 0) {
      dataImprimir.forEach((x) => {
        lista.push({
          Date: x?.fecha ?? '',
          Hour: x?.hora ?? '',
        })
      })
    }
    const registro: RegistersListDto = {
      Employee: filter?.empleado ?? '',
      Month: filter?.mes?.label ?? '',
      Year: filter?.anio ?? 0,
      Registers: lista,
    }
    registros.push(registro)
    consoleService.log(registros)
    return registros
  }, [filter, dataImprimir])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={
            filter.tipoImpresion?.value === 0 ? onParseData() : onParseDataReg()
          }
          fileName={
            filter.tipoImpresion?.value === 0
              ? 'StaticMarcations'
              : 'StaticRegisters'
          }
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template={
            filter.tipoImpresion?.value === 0
              ? 'StaticMarcations'
              : 'StaticRegisters'
          }
          key="reportDesigner"
        />
        {showMarcacione && (
          <ModalMarcacion
            onCancel={() => setShowMarcacion(false)}
            show={showMarcacione}
            data={{
              htrcodigo: filter?.htrCodigo ?? 0,
              fecha: seleccionado?.fecha ?? '',
              dia: seleccionado?.dia ?? 0,
              usuario: filter?.empleadoCodigo ?? 0,
            }}
          />
        )}
        <CCard>
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda onSearchEnter={onGetUser} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadosBusqueda />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={
          filter.tipoImpresion?.value === 0 ? onParseData() : onParseDataReg()
        }
        fileName={
          filter.tipoImpresion?.value === 0
            ? 'StaticMarcations'
            : 'StaticRegisters'
        }
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: '' }}
        template={
          filter.tipoImpresion?.value === 0
            ? 'StaticMarcations'
            : 'StaticRegisters'
        }
        key="reportDesigner"
      />
      {showMarcacione && (
        <ModalMarcacion
          onCancel={() => setShowMarcacion(false)}
          show={showMarcacione}
          data={{
            htrcodigo: filter?.htrCodigo ?? 0,
            fecha: seleccionado?.fecha ?? '',
            dia: seleccionado?.dia ?? 0,
            usuario: filter?.empleadoCodigo ?? 0,
          }}
        />
      )}
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={onGetUser} />
            <ResultadosBusqueda />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Buscar)
