import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomButtons, TabsState, TabState } from '../../../../types/generics'
import { TiposComprobantesSLV } from '../../../../../../store/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../types/enums'
import {
  existeTab,
  ventasCloseTab as donacionCloseTab,
} from '../../../../store/reducers'
import { DonacionesListado } from '../types/types'
import { InvoiceButtons } from '../../../../components/ventasSV/mantenimiento/donaciones/donacion'

const initialState: TabsState<DonacionesListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
      },
    },
  },
}

const tabsDonacionesSlice = createSlice({
  name: 'donacionTabsSV',
  initialState: initialState,
  reducers: {
    donacionCloseTab,
    openTab(
      state,
      action: PayloadAction<{ key: string; donacion?: DonacionesListado }>,
    ) {
      if (action.payload.donacion === undefined) {
        const tab: TabState<DonacionesListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            claveAcceso: '',
            numero: 0,
            tipoComprobante: TiposComprobantesSLV.Donacion,
          },
          buttons: InvoiceButtons,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = existeTab(
          state.tabs,
          action.payload.donacion.comprobante,
        )
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<DonacionesListado> = {
            tabKey: action.payload.key,
            id: action.payload.donacion.comprobante,
            tittle: `${action.payload.donacion.establecimiento}-${action.payload.donacion.puntoEmision}-${action.payload.donacion.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              claveAcceso: action.payload.donacion.claveAcceso,
              numero: action.payload.donacion.comprobante,
              tipoComprobante: TiposComprobantesSLV.Donacion,
              info: action.payload.donacion,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: InvoiceButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: DonacionesListado
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.comprobante,
          tipoComprobante: TiposComprobantesSLV.Donacion,
          info: info ?? null,
        }
        state.tabs[tabKey].id = info.comprobante
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
  },
})

export const {
  openTab,
  donacionCloseTab: closeTab,
  changeEditStatus,
  buttonClick,
  clearButtonClick,
  changeCurrentTab,
  setButtons,
  setCurrentExecutingAction,
} = tabsDonacionesSlice.actions
export const tabsReducer = tabsDonacionesSlice.reducer
