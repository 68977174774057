import { RequestHelper } from '../../../../../helpers/requestHelper'
import { TFiltroBusquedaTipoDonacionesState, TTipoDonacionListado } from '../store/types'
import { PaginationInfo } from '../../../../../store/types'

export const TipoDonacionService = {
  getCollection,
  getResource,
  postResource,
  deleteResource,
}

async function getCollection(
  filtro: TFiltroBusquedaTipoDonacionesState,
): Promise<any> {
  try {
    const query = buildQuery(filtro)
    const pagedResponse = await RequestHelper.getAll<any>(
      'admin/botones',
      'getAll',
      '',
      query,
    )
    pagedResponse.auto = pagedResponse.auto
      ? parseResponse(pagedResponse.auto)
      : []
    return pagedResponse
  } catch (error) {
    return error
  }
}

async function getResource(id: any): Promise<any> {
  try {
    // const query = {
    //   codigo: id,
    // };
    // return await RequestHelper.get<any>('province', 'getProvince', '', query);
    return new Promise((resolve, reject) => {})
  } catch (error) {
    return error
  }
}

async function postResource(raw: any): Promise<any> {
  try {
    const body = {
      boton: {
        ...raw,
      },
    }

    return await RequestHelper.postAll<any>('admin/botones', 'save', body)
  } catch (error) {
    return error
  }
}

async function deleteResource(codigo: any): Promise<any> {
  try {
    const query = {
      codigo: codigo,
    }

    return await RequestHelper.deleteRequestAll<any>(
      'admin/botones',
      'delete',
      '',
      query,
    )
  } catch (error) {
    return error
  }
}

// Private
function buildQuery(
  filtro: TFiltroBusquedaTipoDonacionesState,
  pagination?: PaginationInfo,
): any {
  const query: any = {
    ...(filtro.descripcion && { descripcion: filtro?.descripcion }),
  }

  if (pagination) {
    query.page = pagination.current
    query.limit = pagination.size
    query.isPaged = true
  }

  const filters = ['pais_codigo']

  filters.forEach((field) => {
    if (filtro[field] && filtro[field].length > 0) {
      query[field] = filtro[field]
    }
  })

  return query
}

function parseResponse(dataApi: any[]): Array<TTipoDonacionListado> {
  return dataApi.map((item) => ({
    codigo: item.codigo,
    descripcion: item.descripcion,
  }))
}
