import { create } from 'zustand'

interface Itab {
    mode: 'edit' | 'new';
}

interface TabState {
    tabs: {
        [key: string]: Itab;
    };
}

interface TabActions {
    setTab: (key: string, mode: 'edit' | 'new') => void;
    getTab: (key: string) => Itab;
}

type ITabStore = TabState & TabActions;

export const tabsStore = create<ITabStore>((set, get) => ({
    tabs: {},
    setTab: (key, mode) => set((state) => ({
        tabs: {
            ...state.tabs,
            [key]: {
                mode,
                firstTime: false
            }
        }
    })),
    getTab: (key) => {
        const tab = get().tabs[key];
        if (tab) {
            return tab;
        } else {
            // Handle the case where the tab does not exist, e.g., return a default value or throw an error
            return { mode: 'new', firstTime: true };
        }
    }
}));
