/* eslint-disable no-unused-vars */
/**
 * Estado de edicion
 */
export enum StatesEdition {
  new = 'new',
  edit = 'edit',
  save = 'save',
  doesNotApply = 'doesNotApply',
}

/**
 * Tipos de tabs definidos para el uso en un control de tabs de documentos
 */
export enum TabTypes {
  busqueda,
  documento,
  import,
  abonar,
  saldos,
  anticipos,
  cartera,
  carteraVencida,
  verAbonos,
  ingresosCaja,
  unificar,
  envios,
  recepcion,
  archivar,
  asignarUsuariosLocales,
  asignarUsuariosOrganigrama,
  permisos,
  asignarRolesOrganigrama,
  nomina,
  generar,
  transferencia,
  todos,
  sincronizados,
  asignarFormasPagoMinisterio,
}

export enum ERolUser {
  SADM = 'SADM',
  ADM = 'ADM',
}

export enum EEstadoElectronicoDoc {
  SINAUTORIZAR = 'SIN AUTORIZAR',
  AUTORIZADO = 'AUTORIZADO',
  ANULADO = 'ANULADO',
}

export enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Loading,
  Error,
  Resumen,
  ResultadosBusquedaCCF,
}
