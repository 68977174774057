import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'
import { CustomDictionary } from '../../../../../ventas/types/generics'
import {
  AutorizacionDocumentoVenta,
  Cliente,
  FormaPagoModuloVentas,
} from '../../../../types/types'
import { ventasClearDatosEdicion } from '../../../../store/reducers'
import {
  DonacionDatosEdicion,
  DonacionEdicionPayload,
  TTotalesDonacion,
} from '../types/types'
import { DetalleDonacionRedux } from '../../../ventas/types/detalleDonacion'
import { VendedorBusqueda } from '../../../../../shared/components/buscarVendedorLookMemoUp/type/types'

const initialState: CustomDictionary<DonacionDatosEdicion> = {}

const datosEdicionDonacionSlice = createSlice({
  name: 'donacionDatosEdicionSV',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<DonacionEdicionPayload<DonacionDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateTotales(
      state,
      action: PayloadAction<{
        key: number | string
        totales: TTotalesDonacion
      }>,
    ) {
      state[action.payload.key].totales = action.payload.totales
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string
        detalles: Array<DetalleDonacionRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    updateCliente(
      state,
      action: PayloadAction<{ key: number | string; cliente: Cliente | null }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<DonacionEdicionPayload<DonacionDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },

    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },

    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleDonacionRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string
        formaPago: FormaPagoModuloVentas
      }>,
    ) {
      state[action.payload.key].formaPago = action.payload.formaPago
        ? [action.payload.formaPago]
        : []
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    updateFechaEmision(
      state,
      action: PayloadAction<{ key: string | number; fecha: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fecha
    },
    updateSecuencial(
      state,
      action: PayloadAction<{
        key: number | string
        secuencial: {
          numerofactura: string
          establecimiento: string
          puntoEmision: string
          autorizacionVenta: AutorizacionDocumentoVenta
          periodo: number | null
        }
      }>,
    ) {
      const { key, secuencial } = action.payload
      state[key].establecimiento = secuencial.establecimiento
      state[key].puntoEmision = secuencial.puntoEmision
      state[key].numerofactura = secuencial.numerofactura
      state[key].autorizacionVenta = secuencial.autorizacionVenta
      state[key].periodo = secuencial.periodo
    },
    updateVendedor(
      state,
      action: PayloadAction<{
        key: number | string
        vendedor: VendedorBusqueda
      }>,
    ) {
      const { key, vendedor } = action.payload
      state[key].vendedor = vendedor
    },
  },
})

export const {
  updateFechaEmision,
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  updateCliente,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError,
  addDetalle,
  setFormaPago,
  setImprimir,
  updateSecuencial,
  updateVendedor,
} = datosEdicionDonacionSlice.actions
export const datosEdicionReducer = datosEdicionDonacionSlice.reducer
