import { RequestHelper } from '../helpers/requestHelper'
import {
  Empresa,
  LocalEmpresa,
  SesionIniciadaPayload,
  SesionUsuario,
  Usuario,
  RegistrarSesion,
} from '../store/types'
import { desencriptarJson } from '../helpers/Helper'
import { utilidades } from '../helpers/utilidades'
import { RequestHelperDotNet } from '../helpers/requestHelperDotNet'
import { loadAutorizacionModuloState } from '../store/storageRedux'

export const SesionService = {
  verificarUsuario,
  iniciarSesion,
  getCurrentSesion,
  getHaySesionActiva,
  solicitarVincularUsuario,
  registrarSesion,
  verificarSesion,
  closeSesion,
  closeOtherSesion,
  getIpServer,
  closeAllSesion,
  getCurrentModulosAutorizacion,
  verificarTerminosAceptados,
  generarAceptarTerminos,
}

async function parseIniciarSesionData(
  apiData: any,
): Promise<SesionIniciadaPayload> {
  //const urls = UrlHelper.getUrls();
  const _usuario = apiData.usuario
  const usuario: Usuario = {
    codigo: parseInt(_usuario.codigo),
    nombres: _usuario.nombres,
    apellidos: _usuario.apellidos,
    idsesion: _usuario.idsesion,
    email: _usuario.email,
    firma: _usuario.firma,
    rol: _usuario.rol,
    asistente: _usuario.asistente,
    cedula: _usuario.cedula,
    telefono: _usuario.telefono,
    direccion: _usuario.direccion,
    ciudad: _usuario.ciudad,
    codciudad: _usuario.codciudad,
    codprovincia: _usuario.codprovincia,
    provincia: _usuario.provincia,
    pais: _usuario.pais,
    privilegio: '',
  }

  const empresas: Array<Empresa> = []
  for await (const _empresa of apiData.empresas) {
    const empresa: Empresa = {
      codigo: parseInt(_empresa.codigo),
      logo: `${_empresa.logo}`,
      ruc: _empresa.ruc,
      nrc: _empresa.nrc,
      contribuyente: _empresa.contribuyente,
      nombre: _empresa.nombre,
      comercial: _empresa.comercial,
      direccion: _empresa.direccion,
      telefono: _empresa.telefono,
      email: _empresa.email,
      token: _empresa.token,
      descuento: parseFloat(_empresa.descuento),
      iproforma: _empresa.iproforma,
      transparencia: _empresa.transparencia,
      modulos: _empresa.modulos,
      precision: parseInt(_empresa.precision),
      uso_electronica: parseInt(_empresa.uso_electronica),
      uso_liquidacion: parseInt(_empresa.uso_liquidacion),
      uso_ncredito: parseInt(_empresa.uso_ncredito),
      creditos: parseInt(_empresa.creditos),
      uso_ndedito: parseInt(_empresa.uso_ndedito),
      uso_guia: parseInt(_empresa.uso_guia),
      uso_retencion: parseInt(_empresa.uso_retencion),
      certificado: _empresa.certificado,
      caduca: _empresa.caduca,
      imprimeAutomaticamenteAlfacturar: _empresa.pregImp === 1 ? false : true,
      pathArchivosPaginaWeb: _empresa.pathWeb,
      locales: [],
      cuotasVenta: parseInt(_empresa.cuotasv),
      cuotasVentaDias: parseInt(_empresa.diasCuota),
      formaPagoDefectoCompra: parseInt(_empresa.formaPagoCompra ?? 0),
      formaPagoDefectoVenta: parseInt(_empresa.formaPagoVenta ?? 0),
      configurado: _empresa.configurado ?? false,
      leyendaComprobantesElctronicos: _empresa.leyendaCe ?? '',
      codigoTipoNegocio: Number(_empresa?.tipo_negocio_id) ?? -1,
      editarFechaEmision: parseInt(_empresa?.editarFechaEmision) ?? 0,
      contrato: parseInt(_empresa.reuCodigo ?? 0),
      servicioS3: parseInt(_empresa.s3Service) === 1 ? true : false,
      uuid: _empresa.uuid ?? '',
      configuracion: {
        cantidadComprobantesCaducaContrato:
          parseInt(
            _empresa?.configuracion?.cantidadComprobantesCaducaContrato,
          ) ?? 0,
        cantidadDiasCaducaContrato:
          parseInt(_empresa?.configuracion?.cantidadDiasCaducaContrato) ?? 0,
        comisionPayphone:
          parseInt(_empresa?.configuracion?.comisionPayphone) ?? 0,
        urlFiles: _empresa?.configuracion?.urlFiles ?? '',
        cantidadDiasMoraPago:
          parseInt(_empresa?.configuracion?.cantidadDiasMoraPago) ?? 0,
        configApp: {
          middlewareSv:
            _empresa?.configuracion?.configApp?.API_MIDLEWARET_ELSALVADOR,
          backNet: _empresa?.configuracion?.configApp?.API_NET_URL,
          backNetDashboards:
            _empresa?.configuracion?.configApp?.API_DASHBOARDNET_URL,
          backNetReports:
            _empresa?.configuracion?.configApp?.API_REPORTSNET_URL,
          registroPersona:
            _empresa?.configuracion?.configApp?.ENLACE_REGISTRA_CLIENTE,
        },
      },
      uaf: _empresa.uaf ?? '',
      ipWeb: _empresa.ipWeb ?? '',
      wooUrl: _empresa.wooUrl ?? '',
      preciosIva: parseInt(_empresa.preciosIva) ?? 0,
      consumidorFinalValor: _empresa.consumidorFinalValor ?? 0,
      logs: parseInt(_empresa.logs) ?? 0,
      codigoTributarioActividad: _empresa.codigoTributarioActividad ?? '',
      descripcionActividad: _empresa.descripcionActividad ?? '',
      tipoAmbiente: parseInt(_empresa.tipoAmbiente) ?? 0,
      authHacienda: _empresa.authHacienda ?? '',
      expiraCertificado: _empresa.expiraCertificado ?? '',
      passCertificado: _empresa.passCertificado ?? '',
      plantillaBarras: parseInt(_empresa.iplantilla) ?? -1,
      inventarios: parseInt(_empresa.inventarios) ?? 0,
      distinguir: parseInt(_empresa.distinguir) ?? 0,
      codigoPais: parseInt(_empresa.paisCodigo) ?? 0,
      utilizarImpuestos: parseInt(_empresa.utilizarImpuestos) ?? 0,
      recintoFiscal: _empresa.recintoFiscal ?? '',
      regimen: _empresa.regimen ?? '',
      tipoItemExporta: parseInt(_empresa.tipoItemExporta) ?? 0,
      tipoLicencia: parseInt(_empresa?.tipoLicencia) ?? 0,
      numUsuarios: parseInt(_empresa?.numUsuarios) ?? 0,
      tipoAgente: parseInt(_empresa.tipoAgente) ?? -1,
      numeroRoles: parseInt(_empresa.numeroRoles) ?? 0,
      emitirCambio: parseInt(_empresa?.emitirCambio) ?? 0,
    }
    const locales: Array<LocalEmpresa> = []
    for await (const _local of _empresa.locales) {
      const local: LocalEmpresa = {
        codigo: parseInt(_local.codigo),
        nombre: _local.nombre,
        direccion: _local.direccion,
        telefono: _local.telefono,
        clase: _local.clase,
        descuento: parseFloat(_local.descuento),
        tipoRol: _local.tipoRol,
        controlaKardex: _local.kardex === '0',
        ciudadCodigo: parseInt(_local.ciucodigo),
        ciudadNombre: _local.ciudad,
        establecimiento: _local.establecimiento ?? '',
        siglas: _local.siglas ?? '',
        tipo: parseInt(_local.tipo) ?? 0,
        estado: parseInt(_local.estado) ?? 0,
        uaf: _local.uaf ?? '',
        provinciaCodigo: parseInt(_local.provinciaCodigo) ?? 0,
        provinciaNombre: _local.provinciaNombre ?? '',
        paisCodigo: parseInt(_local.paisCodigo) ?? 0,
        paisNombre: _local.paisNombre ?? '',
      }
      locales.push(local)
    }
    empresa.locales = locales
    empresas.push(empresa)
  }

  return { isOk: true, empresas: empresas, usuario: usuario }
}

async function iniciarSesion(token: string): Promise<SesionIniciadaPayload> {
  try {
    // if (isIOS13 === true || isMacOs === true) {
    //   sessionStorage.setItem("token", token);
    // } else {
    localStorage.setItem('token', token)
    // }
    if (utilidades.canUseNetApi()) {
      return iniciarSesionApiNet()
    } else {
      return iniciarSesionApiV4()
    }
    //RequestHelper.setToken(token);

    //return { isOk: true, empresas: apiData.empresas, usuario: apiData.usuario };
  } catch (error) {
    return { isOk: false, error: JSON.stringify(error) ?? '' }
  }
}

async function iniciarSesionApiNet() {
  const apiData = await RequestHelperDotNet.get<any>('Login', 'Login', '', null)
  const _usuario = apiData.user
  const usuario: Usuario = {
    codigo: _usuario.codigo,
    nombres: _usuario.nombres,
    apellidos: _usuario.apellidos,
    idsesion: _usuario.usuarioNombre,
    email: _usuario.email,
    firma: _usuario.firma,
    rol: _usuario.tipo,
    asistente: _usuario.asistente,
    cedula: _usuario.identificacion,
    telefono: _usuario.telefono,
    direccion: _usuario.direccion,
    ciudad: _usuario.ciudad,
    codciudad: _usuario.ciudadCodigo,
    codprovincia: _usuario.ciudadProvinciaCodigo,
    provincia: '',
    pais: '',
    privilegio: '',
  }

  const empresas: Array<Empresa> = []
  for await (const _empresa of apiData.companies) {
    const empresa: Empresa = {
      codigo: _empresa.codigo,
      logo: _empresa.pathLogoEmpresa,
      ruc: _empresa.ruc,
      nrc: _empresa.nrc,
      contribuyente: _empresa.contribuyente,
      nombre: _empresa.nombre,
      comercial: _empresa.nombreComercial,
      direccion: _empresa.direccion,
      telefono: _empresa.telefono,
      email: _empresa.email,
      token: _empresa.token,
      descuento: _empresa.descuento,
      iproforma: _empresa.ipProforma,
      transparencia: _empresa.transparencia,
      modulos: _empresa.modulos,
      precision: parseInt(_empresa.precisionDecimal),
      uso_electronica: parseInt(_empresa.modoFacturacion),
      uso_liquidacion: parseInt(_empresa.uso_liquidacion),
      uso_ncredito: parseInt(_empresa.uso_ncredito),
      creditos: parseInt(_empresa.creditos),
      uso_ndedito: parseInt(_empresa.uso_ndedito),
      uso_guia: parseInt(_empresa.uso_guia),
      uso_retencion: parseInt(_empresa.uso_retencion),
      certificado: _empresa.certificado,
      caduca: _empresa.certificadoCaduca,
      imprimeAutomaticamenteAlfacturar:
        _empresa.PreguntaAlImprimir === 1 ? false : true,
      pathArchivosPaginaWeb: _empresa.pathWeb,
      locales: [],
      cuotasVenta: parseInt(_empresa.cuotasVenta),
      cuotasVentaDias: parseInt(_empresa.cuotasVentaDias),
      formaPagoDefectoCompra: _empresa.formaPagoDefectoCompra,
      formaPagoDefectoVenta: _empresa.formaPagoDefectoVenta,
      configurado: _empresa.configurado ?? false,
      leyendaComprobantesElctronicos: _empresa.leyendaCe ?? '',
      codigoTipoNegocio: Number(_empresa?.tipo_negocio_id) ?? -1,
      editarFechaEmision: parseInt(_empresa?.editarFechaEmision) ?? 0,
      contrato: parseInt(_empresa.contrato ?? 0),
      servicioS3: _empresa.servicioS3,
      uuid: _empresa.uuid ?? '',
      configuracion: {
        cantidadComprobantesCaducaContrato:
          parseInt(
            _empresa?.configuracion?.cantidadComprobantesCaducaContrato,
          ) ?? 0,
        cantidadDiasCaducaContrato:
          parseInt(_empresa?.configuracion?.cantidadDiasCaducaContrato) ?? 0,
        comisionPayphone:
          parseInt(_empresa?.configuracion?.comisionPayphone) ?? 0,
        urlFiles: _empresa?.configuracion?.urlFiles ?? '',
        cantidadDiasMoraPago:
          parseInt(_empresa?.configuracion?.cantidadDiasMoraPago) ?? 0,
        configApp: {
          middlewareSv:
            _empresa?.configuracion?.configApp?.API_MIDLEWARET_ELSALVADOR,
          backNet: _empresa?.configuracion?.configApp?.API_NET_URL,
          backNetDashboards:
            _empresa?.configuracion?.configApp?.API_DASHBOARDNET_URL,
          backNetReports:
            _empresa?.configuracion?.configApp?.API_REPORTSNET_URL,
          registroPersona:
            _empresa?.configuracion?.configApp?.ENLACE_REGISTRA_CLIENTE,
        },
      },
      uaf: _empresa.uaf ?? '',
      ipWeb: _empresa.ipWeb ?? '',
      wooUrl: _empresa.wooUrl ?? '',
      preciosIva: parseInt(_empresa.preciosIva) ?? 0,
      consumidorFinalValor: _empresa.consumidorFinalValor ?? 0,
      logs: parseInt(_empresa.logs) ?? 0,
      codigoTributarioActividad: _empresa.codigoTributarioActividad ?? '',
      descripcionActividad: _empresa.descripcionActividad ?? '',
      tipoAmbiente: parseInt(_empresa.tipoAmbiente) ?? 0,
      authHacienda: _empresa.authHacienda ?? '',
      expiraCertificado: _empresa.expiraCertificado ?? '',
      passCertificado: _empresa.passCertificado ?? '',
      plantillaBarras: parseInt(_empresa.iplantilla) ?? -1,
      inventarios: parseInt(_empresa.inventarios) ?? 0,
      distinguir: parseInt(_empresa.distinguir) ?? 0,
      codigoPais: parseInt(_empresa.paisCodigo) ?? 0,
      utilizarImpuestos: parseInt(_empresa.utilizarImpuestos) ?? 0,
      recintoFiscal: _empresa.recintoFiscal ?? '',
      regimen: _empresa.utilizarImpuestos ?? '',
      tipoItemExporta: parseInt(_empresa.tipoItemExporta) ?? 0,
      tipoLicencia: parseInt(_empresa?.tipoLicencia) ?? 0,
      numUsuarios: parseInt(_empresa?.numUsuarios) ?? 0,
      tipoAgente: parseInt(_empresa.tipoAgente) ?? -1,
      numeroRoles: parseInt(_empresa.numeroRoles) ?? 0,
      emitirCambio: parseInt(_empresa?.emitirCambio) ?? 0,
    }
    const locales: Array<LocalEmpresa> = []
    for await (const _local of _empresa.locals) {
      const local: LocalEmpresa = {
        codigo: _local.codigo,
        nombre: _local.nombre,
        direccion: _local.direccion,
        telefono: _local.telefono,
        clase: _local.clase,
        descuento: parseFloat(_local.descuento),
        tipoRol: _local.tipoRol,
        controlaKardex: _local.kardex === '0',
        ciudadCodigo: parseInt(_local.ciudadCodigo),
        ciudadNombre: _local.ciudad,
        establecimiento: _local.establecimiento,
        siglas: _local.siglas ?? '',
        tipo: parseInt(_local.tipo) ?? 0,
        estado: parseInt(_local.estado) ?? 1,
        uaf: _local.uaf ?? '',
      }
      locales.push(local)
    }
    empresa.locales = locales
    empresas.push(empresa)
  }

  return { isOk: true, empresas: empresas, usuario: usuario }

  //return parsed;
}

async function iniciarSesionApiV4() {
  const apiData = await RequestHelper.post<any>('login', 'autenticar', null)
  const parsed = await parseIniciarSesionData(apiData)
  return parsed
}

async function verificarUsuario(
  token: string,
  setToken: boolean = true,
): Promise<boolean> {
  try {
    if (utilidades.canUseNetApi()) {
      return verificarUsuarioApiNet(token, setToken)
    } else {
      return verificarUsuarioApiV4(token, setToken)
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

async function verificarUsuarioApiNet(token: string, setToken: boolean = true) {
  if (setToken) {
    // if (isIOS13 === true || isMacOs === true) {
    //   sessionStorage.setItem("token", token);
    // } else {
    localStorage.setItem('token', token)
    // }
  }
  const apiData = setToken ? {} : { email: token }
  const existe = await RequestHelperDotNet.get<any>(
    'Login',
    'MailCheckout',
    '',
    apiData,
  )
  return existe
}

async function verificarUsuarioApiV4(token: string, setToken: boolean = true) {
  if (setToken) {
    // if (isIOS13 === true || isMacOs === true) {
    //   sessionStorage.setItem("token", token);
    // } else {
    localStorage.setItem('token', token)
    // }
  }

  const apiData = setToken ? {} : { email: token }
  const existe = await RequestHelper.get<boolean>(
    'login',
    'mailCheckout',
    '',
    apiData,
  )
  console.log('existe', existe)
  return existe
}

function getCurrentModulosAutorizacion(): any {
  // const serializedState = sessionStorage.getItem('reduxState') ?? '';
  // const state = JSON.parse(serializedState);
  const serializedState = loadAutorizacionModuloState()
  return serializedState as any
}

function getCurrentSesion(): SesionUsuario {
  // const serializedState = sessionStorage.getItem('reduxState') ?? '';
  // const state = JSON.parse(serializedState);
  const serializedState = loadState()
  return serializedState as SesionUsuario
}

function getHaySesionActiva() {
  try {
    const serializedState = getCurrentSesion() //sessionStorage.getItem('reduxState') ?? '';
    //JSON.parse(serializedState);
    return serializedState !== undefined
  } catch (error) {
    return false
  }
}

const loadState = () => {
  try {
    const encriptedState = sessionStorage.getItem('reduxState')
    if (encriptedState === null) {
      return undefined
    }
    const serializedState = desencriptarJson(encriptedState)
    const data = JSON.parse(serializedState)
    return data
  } catch (err) {
    return undefined
  }
}

async function solicitarVincularUsuario(objeto): Promise<boolean> {
  try {
    const existeVerificar = await RequestHelper.get<boolean>(
      'requestAccess',
      '',
      '',
      objeto,
    )
    return existeVerificar
  } catch (error) {
    console.error(error)
    return false
  }
}

async function registrarSesion(data: RegistrarSesion) {
  const registrar: any = {
    dispositivo: data.dispositivo,
    equipo: data.equipo,
    identificadorSesion: data.identificadorSesion,
    ip: data.ip,
    mac: data.mac,
    navegador: data.navegador,
    sistemaOperativo: data.sistemaOperativo,
    tokenSesion: data.tokenSesion,
  }
  const dataRegistro = {
    infoRegistro: {
      ...registrar,
    },
  }
  const apiData = await RequestHelper.post<any>(
    'sessions',
    'store',
    dataRegistro,
  )
  return apiData
}

async function verificarSesion(dataVerificar): Promise<any> {
  try {
    const apiData = await RequestHelper.getAll<any>(
      'sessions',
      'getSession',
      '',
      dataVerificar,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function closeSesion(dataVerificar): Promise<any> {
  try {
    const dataClose = {
      dispositivo: dataVerificar['dispositivo'],
      identificadorSesion: dataVerificar['identificadorSesion'],
    }
    const apiData = await RequestHelper.deleteRequestAll<any>(
      'sessions',
      'deactivate',
      '',
      dataClose,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function closeOtherSesion(dataSesion): Promise<any> {
  try {
    const dataClose = {
      identificadorSesion: dataSesion['identificadorSesion'],
    }
    const apiData = await RequestHelper.deleteRequestAll<any>(
      'sessions',
      'deactivate',
      '',
      dataClose,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function closeAllSesion(dataSesion): Promise<any> {
  try {
    const dataClose = {
      dispositivo: dataSesion['dispositivo'],
    }
    const apiData = await RequestHelper.deleteRequestAll<any>(
      'sessions',
      'deactivateAll',
      '',
      dataClose,
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function getIpServer(): Promise<any> {
  try {
    const apiData = await RequestHelper.getAll<any>(
      'generales',
      'getIP',
      '',
      '',
    )
    return apiData
  } catch (error) {
    return error
  }
}

async function verificarTerminosAceptados(): Promise<boolean> {
  try {
    const apiData = await RequestHelper.get<boolean>(
      'terminos',
      'varificate',
      '',
      '',
    )
    return apiData
  } catch (error) {
    return false
  }
}

async function generarAceptarTerminos(dataRegistro) {
  const apiData = await RequestHelper.post<any>(
    'terminos',
    'accept',
    dataRegistro,
  )
  return apiData
}
