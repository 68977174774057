import React, { useCallback, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { TabState } from '../../../../../../store/genericTabTypes'
import {
  ContractsListDto,
  ContratosDatosEdicion,
  ContratosList,
  SaveGirdUser,
  SaveListContracts,
} from '../../types/types'
import FiltroBusqueda from './FiltroBusqueda'
import ResultadoBusqueda from './ResultadoBusqueda'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  changeFilter,
  fetchContractList,
  setChangeLoader,
  setImport,
  setResetSeleccion,
  setSearch,
} from '../../store/searchReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { ContratsServices } from '../../services/contratos.services'
import DataSource from 'devextreme/data/data_source'
import {
  ConfigPopupMsg,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { consoleService } from '../../../../../../services/console.service'
import ConfirmarAccionMsj from '../../../../../componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../../../store/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { PersonalServices } from '../../../personal/services/personal.service'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaulContratosData } from '../nuevo/index'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { VistasBusqueda } from '../../../../../../store/enums'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<ContratosDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup'

const Busqueda: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props
  const dispatch = useDispatch()

  const filterInitial = useSelector(
    (state: RootState) => state.nomina.contratos.search.filtro,
  )
  const filtroBackup = useSelector(
    (state: RootState) => state.nomina.contratos.search.filtroBackup,
  )
  const tabs = useSelector((state: RootState) => state.nomina.contratos.tabs)
  const loader = useSelector(
    (state: RootState) => state.nomina.contratos.search.loader,
  )
  const seleccionado = useSelector(
    (state: RootState) => state.nomina.contratos.search.seleccionado,
  )
  const searchContratos = useSelector(
    (state: RootState) => state.nomina.contratos.search.searchContratos,
  )
  const importar = useSelector(
    (state: RootState) => state.nomina.contratos.search.import,
  )
  const currentTab = useSelector((state: RootState) => {
    return state.nomina.contratos.tabs.current
  })
  const estadoBusqueda = useSelector(
    (state: RootState) => state.nomina.contratos.search.status,
  )
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const [resultadoImprimir, setResultadoImprimir] = useState<
    Array<ContratosList> | []
  >([])
  const { getContratsDatasource } = ContratsServices.useSetDataSources()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const DataSourceContrats = useMemo(
    () => getContratsDatasource(filterInitial),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterInitial],
  )
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [dataSourceContrats, setDataSourceContrats] = useState<DataSource>(null)
  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Contratos',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = useCallback(
    (showLoader: boolean, mensaje: string) => {
      dispatch(
        setChangeLoader({
          show: showLoader,
          mensaje: mensaje,
        }),
      )
    },
    [dispatch],
  )

  const openTabToEdit = React.useCallback(
    (contract: any) => {
      const id = uuidv4()
      consoleService.log(contract, 'on edit')
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaulContratosData,
            loading: true,
          },
        }),
      )
      const data: any = {
        codigo: contract?.codigo ?? 0,
        contratos: {
          ...contract,
          empleado: `${contract?.usuarioNombres ?? ''}(${
            contract?.usuarioCedula ?? ''
          })`,
        },
      }
      dispatch(openTab({ key: id, store: data }))
    },
    [dispatch],
  )

  const onSaveGrid = React.useCallback(async () => {
    const saveArray: Array<ContratosList> = []
    const saveArrayUsuarios: Array<SaveGirdUser> = []
    const newArray = DataSourceContrats.items()
    newArray.forEach((x) => {
      if (x?.codigo) {
        let fechaS = ''
        if (
          x?.fechaSalida !== null &&
          x?.fechaSalida !== undefined &&
          x?.fechaSalida !== ''
        ) {
          fechaS = DateUtils.format(x?.fechaSalida, 'dd/MM/yyyy', 'yyyy/MM/dd')
        }
        saveArray.push({
          ...x,
          fechaSalida: fechaS,
        })
      }
    })
    consoleService.log(saveArray, '___saveArray')
    const list_save: SaveListContracts = {
      infoRegistro: {
        fecha: DateUtils.getCurrentDateAsString('dd/MM/yyyy'),
        detalle: saveArray,
      },
    }

    if (saveArray.length > 0) {
      saveArray.forEach((u) => {
        saveArrayUsuarios.push({
          codigo: u?.usuarioCodigo ?? 0,
          cedula: u?.usuarioCedula ?? '',
          nombres: u?.usuarioNombres ?? '',
          apellidos: u?.usuarioApellidos ?? '',
          direccion: '',
          email: '',
          telefono: '',
          numero: '',
          nivelCodigo: u?.nivelEstudiosCodigo ?? 0,
          ciuCodigo: 0,
        })
      })
    }
    consoleService.log(list_save, '___list_save')
    consoleService.log(saveArrayUsuarios, '___list_save user')
    onSetButtonAction(ButtonTypes.save)
    onLoader(true, 'Guardando lista...')
    try {
      const dataSave = await ContratsServices.saveColumn(list_save)
      const data = await PersonalServices.saveGrid(saveArray)
      consoleService.log(dataSave, 'result save column')
      consoleService.log(data, 'return save grid')
      if (dataSave['error'] && dataSave['error'] === true) {
        setToast(dataSave['message'], ToastTypes.Danger)
      }
      if (data['error'] && data['error'] === true) {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [DataSourceContrats, setToast, onLoader, onSetButtonAction])

  const onFind = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceContrats.reload()
      setDataSourceContrats(DataSourceContrats)
      dispatch(setSearch(true))
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      setToast(
        `${DataSourceContrats.totalCount()} Registro(s) Encontrado(s)`,
        ToastTypes.Success,
      )
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [DataSourceContrats, dispatch, onLoader, onSetButtonAction, setToast])

  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])

  const onHandleDelete = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminando...')
    try {
      const data = await ContratsServices.deleteContrat(
        seleccionado?.codigo ?? 0,
      )
      consoleService.log(data, 'delete contrat')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        dispatch(setResetSeleccion())
        DataSourceContrats.reload()
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [
    seleccionado,
    setToast,
    onLoader,
    onSetButtonAction,
    DataSourceContrats,
    dispatch,
  ])

  const onBroom = React.useCallback(() => {
    setDataSourceContrats(null)
  }, [])

  const onUndo = React.useCallback(async () => {
    const data = { ...filtroBackup }
    dispatch(
      changeFilter({
        ...data,
      }),
    )
    dispatch(setResetSeleccion())
  }, [dispatch, filtroBackup])

  const onPrintData = React.useCallback(
    async (reporte: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.print)
      onLoader(true, 'Genrando reporte...')
      setResultadoImprimir([])
      try {
        const toAny: any = fetchContractList(filterInitial)
        const res = await dispatch(toAny)
        consoleService.log(res, 'res imprimir')
        if (res?.payload && res?.payload?.error === false) {
          setResultadoImprimir(res?.payload?.auto)
          setReporte(reporte)
        } else {
          setToast(res?.payload?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    },
    [onLoader, onSetButtonAction, setToast, filterInitial, dispatch],
  )

  const onImport = React.useCallback(() => {
    dispatch(setImport(!importar))
  }, [dispatch, importar])

  const onConfirmEdit = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea EDITAR el registro ${seleccionado?.usuarioNombre}?`,
        type: 'confirm',
        currentAction: ButtonTypes.edit,
      })
    }
  }, [seleccionado])

  const onConfirmDelete = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea ELIMINAR el registro ${seleccionado?.usuarioNombre}?`,
        type: 'confirm',
        currentAction: ButtonTypes.delete,
      })
    }
  }, [seleccionado])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) {
            onResetPag()
            onFind()
          }
          break
        case ButtonTypes.save:
          if (tabs.current === tabId) onSaveGrid()
          break
        case ButtonTypes.delete:
          if (tabs.current === tabId) {
            onConfirmDelete()
          }
          break
        case ButtonTypes.edit:
          if (tabs.current === tabId) {
            onConfirmEdit()
          }
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.import:
          if (tabs.current === tabId) onImport()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) onPrintData('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) onPrintData('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      tabs,
      onFind,
      onResetPag,
      onSaveGrid,
      onBroom,
      onUndo,
      onImport,
      onConfirmEdit,
      onConfirmDelete,
      onPrintData,
    ],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  React.useEffect(() => {
    if (searchContratos) setDataSourceContrats(DataSourceContrats)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit)
        openTabToEdit(seleccionado)
      else if (popupMsgConfig.currentAction === ButtonTypes.delete)
        onHandleDelete()
    }
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onHandleDelete, openTabToEdit, popupMsgConfig, seleccionado])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: dataSourceContrats !== null,
          Editar: seleccionado !== null && seleccionado !== undefined,
          Eliminar: seleccionado !== null && seleccionado !== undefined,
          Buscar: true,
          Imprimir: dataSourceContrats !== null,
          Deshacer: true,
          Limpiar: dataSourceContrats !== null,
          Importar: true,
        },
      }),
    )
  }, [dispatch, seleccionado, dataSourceContrats])

  const onParseData = React.useCallback((data: ContratosList[]) => {
    let registros: Array<ContractsListDto> = []
    let contrato: ContractsListDto
    if (data.length > 0) {
      registros = data.map((x) => {
        contrato = {
          Identification: x?.usuarioCedula ?? '',
          Names: x?.usuarioNombre ?? '',
          DescriptionRole: x?.rolDescripcion ?? '',
          Title: x?.tituloDescripcion ?? '',
          Studies: x?.nivelEstudiosDescripcion ?? '',
          Qualify: x?.calificadoDescripcion ?? '',
          WorkingDay: x?.jornadaNombre ?? '',
          BeginDate: x?.fechaIngreso ?? '',
          EndDate: x?.fechaSalida ?? '',
          Observations: x?.observacionesSalida ?? '',
          Salary: x?.nominal ?? 0,
        }
        return contrato
      })
    }

    consoleService.log(registros)
    return registros
  }, [])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros)
        break
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading)
        break
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
        break
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error)
        break
      default:
        break
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(resultadoImprimir ?? [])}
          fileName="StaticContractsList"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template="StaticContractsList"
          key="reportDesigner"
        />
        <ConfirmarAccionMsj
          title={popupMsgConfig.title}
          isVisible={popupMsgConfig.show}
          handleConfirm={onHandleConfirm}
          handleCancel={onHandleCancel}
          message={popupMsgConfig.message}
          typeMessage={popupMsgConfig.type}
          typeInputInfo={null}
        />
        <CCard>
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda tab={tab} tabId={tabId} onEnter={onFind} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadoBusqueda
                onDBClick={onConfirmEdit}
                data={dataSourceContrats}
                handleOptionChange={handleOptionChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onDelete={onConfirmDelete}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultadoImprimir ?? [])}
        fileName="StaticContractsList"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: '' }}
        template="StaticContractsList"
        key="reportDesigner"
      />
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda tab={tab} tabId={tabId} onEnter={onFind} />
            <ResultadoBusqueda
              onDBClick={onConfirmEdit}
              data={dataSourceContrats}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={onConfirmDelete}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Busqueda)
