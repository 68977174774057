import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RootState } from '../../../../../../store/store'
import {
  FETCH_STATUS,
  TiposComprobantesSLV,
} from '../../../../../../store/types'
import { Cliente } from '../../../../types/types'
import { utilidades } from '../../../../../../helpers/utilidades'
import {
  DonacionesListado,
  DonacionFiltroBusquedaState,
  SearchDonateState,
} from '../types/types'
import { donacionesService } from '../services/donacion.services'

const initialState: SearchDonateState<DonacionesListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null,
    tipoDoc: TiposComprobantesSLV.Donacion,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  verJson: false,
  verJsonTab: '',
}

export const fetchDonaciones = createAsyncThunk<
  Array<DonacionesListado>,
  DonacionFiltroBusquedaState
>('ventas/searchDonacionSV', async (filtro) => {
  try {
    const notasDonacion = await donacionesService.getDonaciones(
      0,
      DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? '',
      DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? '',
      filtro.puntoVenta,
      filtro.documento ?? undefined,
      filtro.estado ? filtro.estado.codigo.toString() : undefined,
      filtro.cliente?.identificacion,
      filtro.pendientesAbutorizar === undefined
        ? undefined
        : !filtro.pendientesAbutorizar,
      filtro.claveAcceso ?? '',
      filtro.formaPago ? filtro.formaPago.codigo.toString() : undefined,
      filtro.establecimiento
        ? filtro.establecimiento.establecimiento
        : undefined,
      filtro.puntoEmision ? filtro.puntoEmision.puntoEmision : undefined,
      filtro.tipoDoc,
    )
    let data = notasDonacion
    if (notasDonacion.length > 0) {
      data = utilidades.sortJSON(notasDonacion, 'comprobante', 'desc')
    }
    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchDonacionesSlice = createSlice({
  name: 'searchNDonacionesSV',
  initialState: initialState,
  reducers: {
    onResetState(state) {
      state.filter = {
        ...initialState.filter,
        fechaInicio: DateUtils.getCurrentDateAsString(),
        fechaFin: DateUtils.getCurrentDateAsString(),
      }
    },
    changeFilter(state, action: PayloadAction<DonacionFiltroBusquedaState>) {
      state.filter = action.payload
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload
    },
    setSalesPoint(state, action: PayloadAction<string>) {
      state.filter.puntoVenta = action.payload
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload
    },
    setInvoiceClaveAcceso(state, action: PayloadAction<string>) {
      state.filter.claveAcceso = action.payload
    },
    setInvoiceNumber(state, action: PayloadAction<string>) {
      state.filter.documento = action.payload
    },
    setPendientesAutorizar(state, action: PayloadAction<boolean>) {
      state.filter.pendientesAbutorizar = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultadosNDebito(
      state,
      action: PayloadAction<Array<DonacionesListado> | []>,
    ) {
      state.resultados = action.payload
    },
    toggleVerJsonDonacion(state, action: PayloadAction<boolean>) {
      state.verJson = action.payload
    },
    setVerJsonTabDonacion(state, action: PayloadAction<string>) {
      state.verJsonTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDonaciones.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchDonaciones.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchDonaciones.rejected, (state, { payload }) => {
      state.resultados = []
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaDonaciones = (state: RootState) => {
  return state.ventas.ventasSV.donaciones.search.filter
}
export const selectResultadosBusquedaDonaciones = (state: RootState) => {
  return state.ventas.ventasSV.donaciones.search.resultados
}
export const selectBusquedaDonacionesEstado = (state: RootState) => {
  return state.ventas.ventasSV.donaciones.search.status
}
export const selectBusquedaVerJsonDonacion = (state: RootState) => {
  return state.ventas.ventasSV.donaciones.search.verJson
}
export const selectBusquedaVerJsonTabDonacion = (state: RootState) => {
  return state.ventas.ventasSV.donaciones.search.verJsonTab
}
export const {
  setResultadosNDebito,
  changeFilter,
  setCustomer,
  setDateEnd,
  setDateStart,
  setInvoiceNumber,
  setPendientesAutorizar,
  setSalesPoint,
  onResetState,
  setCleanResult,
  setInvoiceClaveAcceso,
  toggleVerJsonDonacion,
  setVerJsonTabDonacion,
} = searchDonacionesSlice.actions
export const searchDonacionesReducer = searchDonacionesSlice.reducer
