import React, { useState, useEffect, useCallback } from 'react'
import './InvoiceChange.scss'
import {
  CButton,
  CCardBody,
  CModal,
  CModalBody,
  CSpinner,
  CAlert,
} from '@coreui/react-pro'
import { TextBox } from 'devextreme-react'
import RowContainer from '../rowContainer/rowContainer'
import CustomCol from '../colContainer'
import { utilidades } from '../../../helpers/utilidades'

interface IInvoiceChangeProps extends React.PropsWithChildren {
  show: boolean
  setShow: (newValue: boolean) => void
  total: number
}

const InvoiceChange: React.FunctionComponent<IInvoiceChangeProps> = (props) => {
  const { show, setShow, total } = props

  const [loading, setLoading] = useState(true)
  const [receive, setReceive] = useState(0)
  const [change, setChange] = useState(0)
  const [showAlert, setShowAlert] = useState(false)

  const isVisible = show && total > 0

  const receiveRef = React.useRef<any>(null)
  const changeRef = React.useRef<any>(null)

  const onClose = React.useCallback(() => {
    setShowAlert(false)
    setReceive(0)
    setShow(false)
  }, [setShow])

  const onChangeReceive = useCallback(
    (data) => {
      const value = data.trim() === '' ? 0 : parseFloat(data)
      const tot = parseFloat(total.toFixed(2))

      if (isNaN(Number(value))) {
        setReceive(0)
        return
      }
      if (value < tot) {
        setReceive(value)
        setShowAlert(true)
        const result = Number(value - tot)
        setChange(parseFloat(result.toFixed(2)))

        const inputElement = receiveRef.current?.instance
          ?.element()
          .querySelector('input')
        if (inputElement) {
          inputElement.focus()
          inputElement.select()
        }

        return
      }

      setShowAlert(false)
      setReceive(value)
      const result = Number(value - tot)
      setChange(parseFloat(result.toFixed(2)))
    },
    [total],
  )

  const handleEnterKey = useCallback(
    (e) => {
      if (!showAlert && receive >= total) {
        changeRef.current.instance.focus()
      }
    },
    [showAlert, receive, total],
  )

  const handleOnKeyDiferencia = React.useCallback((e) => {
    if (e?.event.key === 'd' || e?.event.keyCode === 68) {
      e.event?.preventDefault()
    }
  }, [])

  const handleEnterKeyClose = useCallback(
    (e) => {
      if (e?.event?.originalEvent && e?.event?.originalEvent?.keyCode === 13) {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    if (!total) {
      setLoading(true)
      return
    }
    setLoading(false)
  }, [total])

  useEffect(() => {
    if (isVisible && !loading) {
      const timeout = setTimeout(() => {
        const inputElement = receiveRef.current?.instance
          ?.element()
          .querySelector('input')
        if (inputElement) {
          inputElement.focus()
          inputElement.select()
        }
      }, 0)
      return () => clearTimeout(timeout)
    }
  }, [isVisible, loading])

  return (
    <CModal
      visible={isVisible}
      onClose={!loading ? onClose : undefined}
      size="sm"
      alignment="center"
      backdrop={loading ? 'static' : true}
      className="modal-invoice-change"
      portal={true}
    >
      {loading ? (
        <CModalBody className="d-flex justify-content-center align-items-center p-4">
          <div className="text-center">
            <CSpinner color="primary" className="mb-3" />
            <p className="m-0 text-muted">{'Espere, por favor...'}</p>
          </div>
        </CModalBody>
      ) : (
        <>
          <div className="modal-header bg-primary text-white p-2">
            <div className="d-flex justify-content-between align-items-center w-100">
              <span></span>
              <button
                className="btn-close btn-close-white bg-white text-white"
                onClick={onClose}
              ></button>
            </div>
          </div>
          <CCardBody className="p-3">
            {showAlert && (
              <CAlert
                color="danger"
                className="mb-3"
                dismissible
                onClose={() => {
                  setShowAlert(!showAlert)
                }}
                variant="solid"
              >
                {
                  'El valor introducido es inferior al valor que se desea cancelar.'
                }
              </CAlert>
            )}
            <div className="mb-4">
              <RowContainer className="mb-3 align-items-center">
                <CustomCol xs={6}>
                  <label className="text-primary fs-4 fw-bold">
                    {'TOTAL:'}
                  </label>
                </CustomCol>
                <CustomCol xs={6}>
                  <TextBox
                    value={total.toFixed(2)}
                    readOnly={true}
                    height={40}
                    stylingMode="outlined"
                    className="textbox-total"
                  />
                </CustomCol>
              </RowContainer>
              <RowContainer className="mb-3 align-items-center">
                <CustomCol xs={6}>
                  <label className="text-primary fs-4 fw-bold">
                    {'RECIBE:'}
                  </label>
                </CustomCol>
                <CustomCol xs={6}>
                  <TextBox
                    ref={receiveRef}
                    value={receive.toFixed(2)}
                    onValueChange={onChangeReceive}
                    onEnterKey={(e) => handleEnterKey(e)}
                    onKeyDown={utilidades.filtraTeclasNoNumericasAndKeyD}
                    onKeyUp={(e) => {
                      handleOnKeyDiferencia(e)
                    }}
                    height={40}
                    stylingMode="outlined"
                    className="textbox-recibe"
                  />
                </CustomCol>
              </RowContainer>
              <RowContainer className="mb-3 align-items-center">
                <CustomCol xs={6}>
                  <label
                    className={`${
                      change < 0 ? 'label-faltante' : 'label-ok'
                    } text-primary fs-4 fw-bold `}
                  >
                    {change < 0 ? 'FALTANTE:' : 'CAMBIO:'}
                  </label>
                </CustomCol>
                <CustomCol xs={6}>
                  <TextBox
                    ref={changeRef}
                    readOnly
                    value={change.toFixed(2)}
                    height={40}
                    stylingMode="outlined"
                    className={
                      change < 0 ? 'textbox-faltante' : 'textbox-cambio'
                    }
                    onKeyUp={(e) => {
                      handleEnterKeyClose(e)
                    }}
                  />
                </CustomCol>
              </RowContainer>
            </div>
            <div className="d-flex justify-content-center">
              <CButton color="light" onClick={onClose} className="px-4">
                <p className="fw-bold m-0">{'Aceptar'}</p>
              </CButton>
            </div>
          </CCardBody>
        </>
      )}
    </CModal>
  )
}

export default React.memo(InvoiceChange)
