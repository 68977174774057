import { CustomButtons } from '../../../../../store/types'

export const EFormaPagoMinisterioButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}
