import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TButton, TFlattendMenu } from './types'
import { TConfigSearchState } from './types'
import { RootState } from '../../../../store/store'

const initialState: TConfigSearchState = {
  filter: {},
  mensaje: '',
  error: false,
  allModulesMenus: [],
  selectedModuleMenu: null,
  allUnAssignedButtons: [],
  selectedUnAssignedButton: null,
  allAssignedButtons: [],
  selectedAssignedButton: null,
}

const searchConfSlice = createSlice({
  name: 'MenuBotonesSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    cleanAllModulesMenu(state) {
      state.allModulesMenus = []
    },
    setAllModulesMenu(state, action: PayloadAction<TFlattendMenu[] | []>) {
      state.allModulesMenus = action.payload
    },
    cleanSelectedModuleMenu(state) {
      state.selectedModuleMenu = null
    },
    setSelectedModuleMenu(state, action: PayloadAction<TFlattendMenu>) {
      state.selectedModuleMenu = action.payload
    },
    cleanAllUnAssignedButtons(state) {
      state.allUnAssignedButtons = []
    },
    setAllUnAssignedButtons(state, action: PayloadAction<TButton[] | []>) {
      state.allUnAssignedButtons = action.payload
    },
    cleanSelectedUnAssignedButton(state) {
      state.selectedUnAssignedButton = null
    },
    setSelectedUnAssignedButton(state, action: PayloadAction<TButton>) {
      state.selectedUnAssignedButton = action.payload
    },
    cleanAllAssignedButtons(state) {
      state.allAssignedButtons = []
    },
    setAllAssignedButtons(state, action: PayloadAction<TButton[] | []>) {
      state.allAssignedButtons = action.payload
    },
    cleanSelectedAssignedButton(state) {
      state.selectedAssignedButton = null
    },
    setSelectedAssignedButton(state, action: PayloadAction<TButton>) {
      state.selectedAssignedButton = action.payload
    },
  },
})

export const selectAllModulesMenu = (state: RootState) => {
  return state.administracion.menuBotones.search.allModulesMenus
}

export const selectSelectedModuleMenu = (state: RootState) => {
  return state.administracion.menuBotones.search.selectedModuleMenu
}

export const selectAllUnAssignedButtons = (state: RootState) => {
  return state.administracion.menuBotones.search.allUnAssignedButtons
}

export const selectAllAssignedButtons = (state: RootState) => {
  return state.administracion.menuBotones.search.allAssignedButtons
}

export const selectSelectedUnAssignedButton = (state: RootState) => {
  return state.administracion.menuBotones.search.selectedUnAssignedButton
}

export const selectSelectedAssignedButton = (state: RootState) => {
  return state.administracion.menuBotones.search.selectedAssignedButton
}

export const {
  resetState,
  cleanAllModulesMenu,
  setAllModulesMenu,
  cleanSelectedModuleMenu,
  setSelectedModuleMenu,
  cleanAllUnAssignedButtons,
  setAllUnAssignedButtons,
  cleanSelectedUnAssignedButton,
  setSelectedUnAssignedButton,
  cleanAllAssignedButtons,
  setAllAssignedButtons,
  cleanSelectedAssignedButton,
  setSelectedAssignedButton,
} = searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
