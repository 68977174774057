import React from 'react'
import { CAlert, CBadge } from '@coreui/react-pro'
export interface IAlertProps extends React.PropsWithChildren {
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | string
  id?: string
  children?: React.ReactNode
}
export function AlertCUI(props: IAlertProps) {
  const { color, id, children } = props
  return (
    <CAlert color={color} id={`alert-${id}`}>
      {children}
    </CAlert>
  )
}

export interface IBadgeProps extends React.PropsWithChildren {
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'primary-gradient'
    | 'secondary-gradient'
    | 'success-gradient'
    | 'danger-gradient'
    | 'warning-gradient'
    | 'info-gradient'
    | 'dark-gradient'
    | 'light-gradient'
    | string
  id?: string
  children?: React.ReactNode
}
export function BadgeCUI(props: IBadgeProps) {
  const { color, id, children } = props
  return (
    <CBadge color={color} id={`badge-${id}`}>
      {children}
    </CBadge>
  )
}
