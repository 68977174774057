import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'
import { CustomDictionary } from '../../../../types/generics'
import { Cliente } from '../../../../types/types'
import {
  DocumentoOrigenFacturacion,
  FacturacionEdicionPayload,
} from '../../../ventas/types/types'
import { ventasClearDatosEdicion } from '../../../../store/reducers'
import { ProveedorBusqueda } from '../../../../../proveedores/store/types'
import { NotaRemisionDatosEdicion } from '../types/types'
import { DetalleNotaRemisionRedux } from '../../../ventas/types/detalleVentaSVNotaRemision'
export const guiasEditDataInitialState: CustomDictionary<NotaRemisionDatosEdicion> =
  {}

const editDataSlice = createSlice({
  name: 'notaRemisionDataEdition',
  initialState: guiasEditDataInitialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<
        FacturacionEdicionPayload<NotaRemisionDatosEdicion>
      >,
    ) {
      state[action.payload.key] = action.payload.data
    },
    updateDetails(
      state,
      action: PayloadAction<{
        key: string | number
        detalles: Array<DetalleNotaRemisionRedux>
      }>,
    ) {
      state[action.payload.key].detalles = action.payload.detalles
    },
    updateClient(
      state,
      action: PayloadAction<{ key: string | number; cliente: Cliente | null }>,
    ) {
      state[action.payload.key].cliente = action.payload.cliente
      if (action.payload.cliente != null) {
        state[
          action.payload.key
        ].puntoLlegada = `${action.payload.cliente.ciudad}, ${action.payload.cliente.direccion}`
      }
    },
    updateProvider(
      state,
      action: PayloadAction<{
        key: string | number
        proveedor: ProveedorBusqueda | null
      }>,
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor
      if (action.payload.proveedor == null) {
        state[action.payload.key].placa = ''
      }
    },
    updateObservations(
      state,
      action: PayloadAction<{ key: string | number; observaciones: string }>,
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones
    },
    updateMotive(
      state,
      action: PayloadAction<{ key: string | number; motivo: string }>,
    ) {
      state[action.payload.key].motivo = action.payload.motivo
    },
    updateDateGuide(
      state,
      action: PayloadAction<{ key: string | number; fechaGuia: string }>,
    ) {
      state[action.payload.key].fecha = action.payload.fechaGuia
    },
    updateDateParted(
      state,
      action: PayloadAction<{ key: string | number; fechaPartida: string }>,
    ) {
      state[action.payload.key].fechaDesde = action.payload.fechaPartida
    },
    updateDateArrival(
      state,
      action: PayloadAction<{ key: string | number; fechaLlegada: string }>,
    ) {
      state[action.payload.key].fechaHasta = action.payload.fechaLlegada
    },
    updateDeclarationAduanera(
      state,
      action: PayloadAction<{
        key: string | number
        declaracionAduanera: string
      }>,
    ) {
      state[action.payload.key].declaracionAduanera =
        action.payload.declaracionAduanera
    },
    updateEntryOrder(
      state,
      action: PayloadAction<{ key: string | number; ordenIngreso: string }>,
    ) {
      state[action.payload.key].ordenIngreso = action.payload.ordenIngreso
    },
    updatePuntoPartida(
      state,
      action: PayloadAction<{ key: string | number; puntoPartida: string }>,
    ) {
      state[action.payload.key].puntoPartida = action.payload.puntoPartida
    },
    updatePuntoLlegada(
      state,
      action: PayloadAction<{ key: string | number; puntoLlegada: string }>,
    ) {
      state[action.payload.key].puntoLlegada = action.payload.puntoLlegada
    },
    updatePlaca(
      state,
      action: PayloadAction<{ key: string | number; placa: string }>,
    ) {
      state[action.payload.key].placa = action.payload.placa
    },
    //Establecer datos edicion para un tab nuevo
    initEditData(
      state,
      action: PayloadAction<
        FacturacionEdicionPayload<NotaRemisionDatosEdicion>
      >,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: string | number; info: LoaderInfo }>,
    ) {
      state[action.payload.key].loader = action.payload.info
    },
    updateDocumentOrigen(
      state,
      action: PayloadAction<{
        key: string | number
        documento: DocumentoOrigenFacturacion
        detalles: Array<DetalleNotaRemisionRedux>
        cliente: Cliente
      }>,
    ) {
      state[action.payload.key].documentoOrigen = action.payload.documento
      state[action.payload.key].cliente = action.payload.cliente
      state[action.payload.key].detalles = action.payload.detalles
      state[
        action.payload.key
      ].puntoLlegada = `${action.payload.cliente.ciudad}, ${action.payload.cliente.direccion}`
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: string | number
        tieneError: boolean
        mensajeError: string
      }>,
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError
      state[action.payload.key].mensajeError = action.payload.mensajeError
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>,
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string
        detalle: DetalleNotaRemisionRedux
      }>,
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle)
    },
    setClaveAccesoGuardada(
      state,
      action: PayloadAction<{
        key: string | number
        claveAcceso: string
        imprimir: boolean
      }>,
    ) {
      state[action.payload.key].claveAcceso = action.payload.claveAcceso
      state[action.payload.key].imprimir = action.payload.imprimir
    },
    updateNumeroReferencia(
      state,
      action: PayloadAction<{
        key: string | number
        numeroReferencia: number
      }>,
    ) {
      state[action.payload.key].numeroReferencia =
        action.payload.numeroReferencia
    },
    updateValorReferencia(
      state,
      action: PayloadAction<{
        key: string | number
        valorReferencia: number
      }>,
    ) {
      state[action.payload.key].valorReferencia = action.payload.valorReferencia
    },
  },
})

export const {
  initEditData,
  setDatosEdicion,
  updateDetails,
  updateClient,
  updateObservations,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  updateDateParted,
  updateDateArrival,
  updateEntryOrder,
  updateDeclarationAduanera,
  updatePuntoPartida,
  updatePuntoLlegada,
  updateProvider,
  updatePlaca,
  updateDocumentOrigen,
  setMuestraError,
  updateMotive,
  setImprimir,
  addDetalle,
  setClaveAccesoGuardada,
  updateDateGuide,
  updateNumeroReferencia,
  updateValorReferencia,
} = editDataSlice.actions

export const editDataReducer = editDataSlice.reducer
