import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutorizacionDocumentoVenta } from '../../../../types/types'
import { ConfiguracionesDonacion } from '../types/types'

const initialState: ConfiguracionesDonacion = {
  autorizacionDocumento: null,
}

const configuracionesDonacionlice = createSlice({
  name: 'configuracionesDonaciones',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta>) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloDonacion(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesDonacionlice.reducer
export const { setAutorizacionModuloDonacion } =
  configuracionesDonacionlice.actions
