import { combineReducers } from '@reduxjs/toolkit'
import { datosEdicionReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { searchDonacionesReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesDonacionReducer'
import { DonacionState } from '../types/types'

export const donacionesReducer = combineReducers<DonacionState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchDonacionesReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
