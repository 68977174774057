import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { TiposComprobantesSLV } from '../../../../../../store/types'
import { DonacionesListado } from '../types/types'

export const donacionesService = { getDonaciones }

async function getDonaciones(
  codigo: number,
  fechaInicio: string,
  fechaFin: string,
  puntoVenta: string,
  numero: string | undefined,
  estadoFactura: string | undefined,
  identificacion: string | undefined,
  autorizado: boolean | undefined,
  clave_acceso: string | undefined,
  formaPago: string | undefined,
  establecimiento: string | undefined,
  puntoEmision: string | undefined,
  tipoDoc: TiposComprobantesSLV,
): Promise<Array<DonacionesListado>> {
  const inicio = fechaInicio //fechaInicio.length > 0 ? moment(fechaInicio, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const fin = fechaFin //fechaInicio.length > 0 ? moment(fechaFin, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const data: any = {
    clave_acceso: clave_acceso ?? '',
    tipodoc: tipoDoc,
    desde: inicio,
    hasta: fin,
    numero: numero ?? '',
    nombre: '',
    identificacion: identificacion ?? '',
    autorizado: autorizado ?? '',
    pventa: puntoVenta,
    codigo: codigo,
  }

  if (estadoFactura) {
    data.estadoFactura = estadoFactura
  }
  if (formaPago) {
    data.formaPago = formaPago
  }
  if (establecimiento) {
    data.establecimiento = establecimiento
  }
  if (puntoEmision) {
    data.ptoemision = puntoEmision
  }
  if (clave_acceso) {
    data.claveAcceso = clave_acceso
  }

  const ventas = await RequestHelper.get<Array<DonacionesListado>>(
    'ventas',
    'listar',
    '',
    data,
  )
  return ventas
}
