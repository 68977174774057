import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CAlert, CCard, CCardBody } from '@coreui/react-pro'
import { ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'

import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../store/types'
import { RootState } from '../../../../store/store'
import {
  TPlanesDatosEdicion,
  TPlanesData,
  IPlanForm,
  IDetallePlan,
  IItemPlan,
} from '../store/types'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { PopupContent } from '../../../../views/componentes/popupContent'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabs.reducer'
import { StatesEdition } from '../../../../store/enums'
import { setDatosEdicion } from '../store/edit.reducer'
import InputTextDE, {
  InputCheckDE,
  InputNumberDE,
} from '../../../../views/componentes/inputText-DE/inputText-DE'
import { PlanesService } from '../services/planes.services'
import PaisesLookUp from '../../../componentes/paisesLookUp'
import DetallesPlanes from './detallesPlanes'
import PlanesCategoriasLookup from '../../../componentes/planesCategoriaLookup'
import { defaultDatosEdicionPlanes } from '../store/dataEdition'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../views/componentes/colContainer'
import { useToast } from '../../../../hooks/useToast'

interface IMantenimientoProps extends React.PropsWithChildren {
  info: DocumentInfo<TPlanesData>
  tabId: string
  tab: TabState<TPlanesData>
}

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MantenimientoPlanes: React.FunctionComponent<IMantenimientoProps> = (
  props,
) => {
  const { info, tabId, tab } = props

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>(null)
  const validationGroup2Ref = React.useRef<any>(null)

  const tabs = useSelector(
    (state: RootState) => state.administracion.planes.tabs,
  )
  const dataEdicion = useSelector(
    (state: RootState) => state.administracion.planes.editData[tabId],
  )
  const loading = useSelector(
    (state: RootState) => state.administracion.planes.editData[tabId].loading,
  )
  const empresaPais = useSelector(
    (state: RootState) => state.global.session.empresa.codigoPais,
  )

  const dispatch = useDispatch()

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loader, setLoader] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const { setToast } = useToast()

  const parseLocalPaisData = React.useCallback(
    async (localState: TPlanesData): Promise<TPlanesDatosEdicion> => {
      return {
        id: localState?.id ?? 0,
        nombre: localState?.plan ?? '',
        precio: localState?.precio ?? 0,
        popular: localState?.pupular ?? 0,
        estado: Number(localState?.estado) ?? 0,
        mobilesPermitidos: localState?.movil ?? 0,
        pcPermitidos: localState?.pc ?? 0,
        tabletsPermitidos: localState?.tablet ?? 0,
        codigoBase: localState?.cod_base ?? 0,
        nombreWeb: localState?.name_web ?? '',
        usuariosBase: localState?.usuarios_base ?? 0,
        usuariosExtra: localState?.usuarios_extra ?? 0,
        codigoItem: localState?.cod_item ?? '',
        codigoUsuario: localState?.codItemUsuarios ?? '',
        codigoComprobantes: localState?.codItemComprobantes ?? '',
        usuariosMaximo: localState?.maxUsuarios ?? 0,
        numeroEmpresas: 0,
        numeroLocales: 0,
        multisesion: Boolean(localState?.controlaSesiones) ?? false,
        masIva: Boolean(localState?.iva) ?? false,
        comprobantesMaximo: localState?.maxComprobantes ?? 0,
        tituloAlternativo: localState?.tituloAlternativo ?? '',
        ordenPlan: localState?.planOrden ?? 0,
        tituloDetalle: localState?.tituloDetalle ?? '',
        visibleWeb: Boolean(localState?.visibleWeb) ?? false,
        categoria: {
          codigo: localState?.categoriaCodigo ?? 1,
          descripcion: localState?.categoriaDescripcion ?? '',
          estado: Boolean(localState?.categoriaEstado ?? 1),
        },
        asistencias: 0,
        periodoCodigo: localState?.codPeriodo ?? 0,
        pais: null,
        detalle: localState?.detalle ?? [],
        edition: false,
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: '',
      }
    },
    [],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultDatosEdicionPlanes }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, loading, tabId],
  )

  const modoEdicion = React.useCallback(
    async (reload: boolean = false, dataEdicion: TPlanesData) => {
      setLoader({ show: true, message: 'Espere, por favor...' })

      const dataCopyEdition = dataEdicion

      setDatosEdicion2(dataCopyEdition)

      try {
        let dataApi: any
        let infoParse: TPlanesDatosEdicion = {
          ...defaultDatosEdicionPlanes,
        }

        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseLocalPaisData(dataEdicion)

        if (reload) {
          dataApi = await PlanesService.getResource(dataCopyEdition?.id ?? 0)

          infoParse = await parseLocalPaisData(dataApi)
        }

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )

        setLoader({ show: false, message: '' })
      } catch (error) {
        setLoader({ show: false, message: JSON.stringify(error) })
      }
    },
    [dispatch, parseLocalPaisData, tabId],
  )

  const playLoader = React.useCallback(async () => {
    setLoader({ show: true, message: 'Espere, por favor...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoader({ show: false, message: '' })
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return
    }

    setShowErrorPopup(false)
    setShowErrorMessages([])
    onSetButtonAction(ButtonTypes.save)

    const parseDetalles = (data: IDetallePlan[]): IItemPlan[] => {
      const detalles: IItemPlan[] = []

      if (!data || data.length <= 0) {
        return detalles
      }

      data.forEach((item: IDetallePlan) => {
        detalles.push({
          codigo: item.isNew ? 0 : item.codigo,
          descripcion: item.descripcion,
          estado: String(item.estado),
          codigoPlan: item.planId,
        })
      })

      return detalles
    }

    const bodyRequestPlan: IPlanForm = {
      id: dataEdicion?.id ?? 0,
      nombre: dataEdicion?.nombre ?? '',
      precio: String(dataEdicion?.precio) ?? '0',
      popular: String(dataEdicion?.popular) ?? '0',
      estado: String(dataEdicion?.estado) ?? '0',
      mobilesPermitidos: dataEdicion?.mobilesPermitidos ?? 0,
      pcPermitidos: dataEdicion?.pcPermitidos ?? 0,
      tabletsPermitidos: dataEdicion?.tabletsPermitidos ?? 0,
      codigoBase: dataEdicion?.codigoBase ?? 0,
      nombreWeb: dataEdicion?.nombreWeb ?? '',
      usuariosBase: dataEdicion?.usuariosBase ?? 0,
      usuariosExtra: dataEdicion?.usuariosExtra ?? 0,
      codigoItem: dataEdicion?.codigoItem ?? '',
      codigoUsuario: dataEdicion?.codigoUsuario ?? '',
      codigoComprobantes: dataEdicion?.codigoComprobantes ?? '',
      usuariosMaximo: dataEdicion?.usuariosMaximo ?? 0,
      numeroEmpresas: dataEdicion?.numeroEmpresas ?? 0,
      numeroLocales: dataEdicion?.numeroLocales ?? 0,
      multisesion: dataEdicion?.multisesion ?? false,
      masIva: dataEdicion?.masIva ?? false,
      comprobantesMaximo: dataEdicion?.comprobantesMaximo ?? 0,
      tituloAlternativo: dataEdicion?.tituloAlternativo ?? '',
      ordenPlan: dataEdicion?.ordenPlan ?? 0,
      tituloDetalle: dataEdicion?.tituloDetalle ?? '',
      visibleWeb: dataEdicion?.visibleWeb ?? false,
      categoria: dataEdicion?.categoria?.codigo ?? 0,
      asistencias: dataEdicion?.asistencias ?? 0,
      periodoCodigo: dataEdicion?.periodoCodigo ?? 0,
      paisCodigo: dataEdicion?.pais?.codigo ?? 0,
    }

    const bodyRequestDetalles: IItemPlan[] = parseDetalles(dataEdicion.detalle)

    try {
      const data = await PlanesService.postResource(
        bodyRequestPlan,
        bodyRequestDetalles,
      )

      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning)
        stopLoader()
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EPlanesButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        return
      }

      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        stopLoader()
        onSetButtonAction(undefined)
      }

      setDatosEdicion2(bodyRequestPlan)
      if (bodyRequestPlan.id === 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EPlanesButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      if (bodyRequestPlan.id !== 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EPlanesButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      dispatch(
        setNameTab({
          key: tabId,
          nombre: bodyRequestPlan?.nombre ?? '',
          codigo: data?.auto ?? 0,
        }),
      )
    } catch (error) {
      onSetButtonAction(undefined)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...EPlanesButtons,
            Guardar: true,
            Imprimir: true,
          },
        }),
      )
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [
    playLoader,
    onSetButtonAction,
    dataEdicion,
    setToast,
    stopLoader,
    dispatch,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      //let ciudaddselect=null;
      switch (buttonAction) {
        case ButtonTypes.undo:
          // eslint-disable-next-line no-case-declarations
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2 },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) handleSubmit()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, datosEdicion2, tabs, handleSubmit],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(false, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map((item, id) => (
                  <li key={id}>{item['message']}</li>
                ))}
              </ul>
            </CAlert>
          </PopupContent>
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loader?.show ?? false}
            message={loader?.message ?? ''}
          >
            <fieldset disabled={loader.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Nombre(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.nombre ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'nombre')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Nombre: Este campo es requerido" />
                                  <StringLengthRule
                                    min="3"
                                    message="- Nombre: Este campo debe tener al menos 3 caracteres"
                                  />
                                  <StringLengthRule
                                    max="45"
                                    message="- Nombre: Este campo no puede tener mas de 45 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Precio(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.precio ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'precio')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Precio: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Popular(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.popular ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'popular')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Popular: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Número de teléfonos permitidos(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.mobilesPermitidos ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'mobilesPermitidos')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Teléfonos permitidos: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Nombre web(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.nombreWeb ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'nombreWeb')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Nombre web: Este campo es requerido" />
                                  <StringLengthRule
                                    min="5"
                                    message="- Nombre web: Este campo debe tener al menos 5 caracteres"
                                  />
                                  <StringLengthRule
                                    max="250"
                                    message="- Nombre web: Este campo no puede tener mas de 250 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Número de computadoras permitidas(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.pcPermitidos ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'pcPermitidos')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Computadoras permitidas: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Número de tablets permitidas(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.tabletsPermitidos ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'tabletsPermitidos')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Tablets permitidas: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código base(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.codigoBase ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'codigoBase')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código base: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Item(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoItem ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'codigoItem')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Item: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código Item: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="250"
                                    message="- Código Item: Este campo no puede tener mas de 250 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Usuario(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoUsuario ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'codigoUsuario')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Usuario: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código Usuario: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="250"
                                    message="- Código Usuario: Este campo no puede tener mas de 250 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Usuario Base(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.usuariosBase ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'usuariosBase')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Usuario Base: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Usuario Extra(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.usuariosExtra ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'usuariosExtra')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Usuario Extra: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Máximo de usuarios(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.usuariosMaximo ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'usuariosMaximo')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Máximo de usuarios: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Número de empresas(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.numeroEmpresas ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'numeroEmpresas')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Número de empresas: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Número de locales(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.numeroLocales ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'numeroLocales')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Número de locales: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Multisesiones(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputCheckDE
                                value={dataEdicion?.multisesion}
                                onValueChange={(e) =>
                                  onChangeValue(e, 'multisesion')
                                }
                                text={dataEdicion?.multisesion ? 'Sí' : 'No'}
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Comprobante(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoComprobantes ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'codigoComprobantes')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Comprobante: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código Comprobante: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="250"
                                    message="- Código Comprobante: Este campo no puede tener mas de 250 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Máximo de comprobantes(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.comprobantesMaximo ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'comprobantesMaximo')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Máximo de comprobantes: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Título alternativo(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.tituloAlternativo ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'tituloAlternativo')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Título alternativo: Este campo es requerido" />
                                  <StringLengthRule
                                    min="5"
                                    message="- Título alternativo: Este campo debe tener al menos 5 caracteres"
                                  />
                                  <StringLengthRule
                                    max="250"
                                    message="- Título alternativo: Este campo no puede tener mas de 250 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Plan de orden(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.ordenPlan ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'ordenPlan')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Plan de orden: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código de periodo(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.periodoCodigo ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'periodoCodigo')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código de periodo: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Título detalle(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.tituloDetalle ?? ''}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'tituloDetalle')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Título detalle: Este campo es requerido" />
                                  <StringLengthRule
                                    min="5"
                                    message="- Título detalle: Este campo debe tener al menos 5 caracteres"
                                  />
                                  <StringLengthRule
                                    max="250"
                                    message="- Título detalle: Este campo no puede tener mas de 250 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Categoría(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <PlanesCategoriasLookup
                                selected={dataEdicion?.categoria}
                                provider={[]}
                                onChange={(e) => {
                                  onChangeValue(e, 'categoria')
                                }}
                              >
                                <Validator>
                                  <RequiredRule message="- Categoría: Este campo es requerido" />
                                </Validator>
                              </PlanesCategoriasLookup>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Asistencias(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.asistencias ?? 0}
                                placeholder=""
                                onValueChange={(e) =>
                                  onChangeValue(e, 'asistencias')
                                }
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Asistencias: Este campo es requerido" />
                                </Validator>
                              </InputNumberDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Mas IVA(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputCheckDE
                                value={dataEdicion?.masIva}
                                onValueChange={(e) =>
                                  onChangeValue(e, 'masIva')
                                }
                                text={dataEdicion?.masIva ? 'Sí' : 'No'}
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Web visible(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputCheckDE
                                value={dataEdicion?.visibleWeb}
                                onValueChange={(e) =>
                                  onChangeValue(e, 'visibleWeb')
                                }
                                text={dataEdicion?.visibleWeb ? 'Sí' : 'No'}
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Estado:'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputCheckDE
                                value={dataEdicion?.estado === 1}
                                onValueChange={(e) => {
                                  const newValue = e ? 1 : 0
                                  onChangeValue(newValue, 'estado')
                                }}
                                text={
                                  dataEdicion?.estado === 1
                                    ? 'Activo'
                                    : 'Inactivo'
                                }
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>

                      <CustomCol className="d-flex" md="3">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'País:'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <PaisesLookUp
                                onChanged={(value) =>
                                  onChangeValue(value, 'pais')
                                }
                                onChangedOptions={() => {}}
                                selected={
                                  dataEdicion?.pais ?? { codigo: empresaPais }
                                }
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>
                  </CCardBody>
                </CCard>
              </ValidationGroup>

              <ValidationGroup ref={validationGroup2Ref} className="">
                <CCard style={{ border: 1 }} className="m-0 mt-3">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />
                    <DetallesPlanes
                      planId={dataEdicion?.id ?? 0}
                      validationGroupRef={validationGroup2Ref}
                      items={dataEdicion?.detalle ?? []}
                      onChange={(data: any) => {
                        onChangeValue(data, 'detalle')
                      }}
                    />
                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MantenimientoPlanes)

export const EPlanesButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}
