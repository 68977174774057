import React from 'react'
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
  HeaderFilter,
  Button as DatagridButton,
  IColumnProps,
} from 'devextreme-react/data-grid'
import { ButtonConfig } from './type'

interface IDataGridComponentProps extends React.PropsWithChildren {
  refDataGrid?: any
  typeEntidad?: string
  resultados: any
  selectedLocal: any
  onRowDblClick?: any
  onSelectionChanged?: any
  commands?: ButtonConfig[]
  keyExpr?: string
  enabledColumnChooser?: boolean
  visibleHeaderFilter?: boolean
  columns: IColumnProps[]
  columnAutoWidth?: boolean
  width?: string
  height?: string
}

const pageSizes = [10, 25, 50, 100, 200]

const DataGridComponent = (props: IDataGridComponentProps) => {
  const {
    resultados,
    refDataGrid,
    selectedLocal,
    onRowDblClick,
    onSelectionChanged,
    commands,
    keyExpr = 'codigo',
    enabledColumnChooser,
    visibleHeaderFilter,
    columns,
    columnAutoWidth,
    width,
    height,
  } = props

  return (
    <DataGrid
      ref={refDataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      focusedRowKey={selectedLocal}
      keyExpr={keyExpr}
      width={width ?? '100%'}
      height={height ?? 'auto'}
      dataSource={resultados}
      showBorders={true}
      onRowDblClick={onRowDblClick}
      onSelectionChanged={onSelectionChanged}
      columnAutoWidth={columnAutoWidth ?? false}
    >
      <ColumnChooser enabled={enabledColumnChooser ?? true} mode="select" />
      <HeaderFilter visible={visibleHeaderFilter ?? true} />
      {commands && commands.length > 0 && (
        <Column type="buttons">
          {commands.map((button, index) => (
            <DatagridButton
              key={index}
              icon={button.icon}
              hint={button.hint}
              disabled={button.disabled}
              onClick={button.onClick}
            />
          ))}
        </Column>
      )}
      {columns.map((col) => (
        <Column
          key={col.dataField}
          dataField={col.dataField}
          caption={col.caption}
          width={col.width}
          minWidth={col?.minWidth ?? undefined}
          allowEditing={false}
          allowSearch={false}
          allowFiltering={col?.allowFiltering ?? false}
          calculateDisplayValue={col?.calculateDisplayValue ?? undefined}
          alignment={col?.alignment ?? undefined}
          allowSorting={col?.allowSorting ?? false}
          cellRender={col?.cellRender ?? undefined}
          format={col?.format ?? undefined}
          visible={col.visible ?? true}
          fixed={col?.fixed ?? false}
          fixedPosition={col?.fixedPosition ?? undefined}
        />
      ))}
      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />
    </DataGrid>
  )
}

export default DataGridComponent
