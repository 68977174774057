import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDictionary } from '../../../../ventas/types/generics'
import { ActividadesEconomicasDatosEdicion } from './types'
import { TConfEdicionPayload } from '../../store/types'
import { ventasClearDatosEdicion } from '../../../../ventas/store/reducers'

const initialState: CustomDictionary<ActividadesEconomicasDatosEdicion> = {}
const datosEdicionConfLocalSlice = createSlice({
  name: 'ActividadesEconomicasDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<
        TConfEdicionPayload<ActividadesEconomicasDatosEdicion>
      >,
    ) {
      state[action.payload.key] = action.payload.data
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<
        TConfEdicionPayload<ActividadesEconomicasDatosEdicion>
      >,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
  },
})

export const {
  ventasClearDatosEdicion: clearDatosEdicion,
  initDatosEdicion,
  setDatosEdicion,
} = datosEdicionConfLocalSlice.actions
export const dataEditionReducer = datosEdicionConfLocalSlice.reducer
