import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import FiltroBusqueda, { optionsActivos } from './FiltroBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import { HorarioDatosEdicion } from '../../types/types'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeFilter,
  fetchHourList,
  setChangeLoader,
  setResetSeleccion,
  setSearch,
} from '../../store/searchReducer'
import { HoursServices } from '../../services/horarios.services'
import DataSource from 'devextreme/data/data_source'
import { isMobile } from 'react-device-detect'
import {
  ConfigPopupMsg,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import ConfirmarAccionMsj from '../../../../../../componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../../../../store/types'
import { WorkingHour } from '../../../../../../componentes/modalHorariosTrabajo/types/type'
import { consoleService } from '../../../../../../../services/console.service'
import { initDatosEdicion } from '../../store/editDataReducer'
import { defaultHoursData } from '../nuevo/index'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { GroupReport } from '../../../../../../inventario/pages/catalogos/grupos/types/types'
import { VistasBusqueda } from '../../../../../../../store/enums'
interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<HorarioDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup'
const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const filter = useSelector(
    (state: RootState) => state.nomina.registros.horarios.search.filtro,
  )
  const loader = useSelector(
    (state: RootState) => state.nomina.registros.horarios.search.loader,
  )
  const searchHorarios = useSelector(
    (state: RootState) => state.nomina.registros.horarios.search.searchHorarios,
  )
  const seleccionado = useSelector(
    (state: RootState) => state.nomina.registros.horarios.search.seleccionado,
  )
  const currentTab = useSelector(
    (state: RootState) => state.nomina.registros.horarios.tabs.current,
  )
  const estadoBusqueda = useSelector(
    (state: RootState) => state.nomina.registros.horarios.search.status,
  )
  const tabs = useSelector(
    (state: RootState) => state.nomina.registros.horarios.tabs,
  )
  const { getHoursDatasource } = HoursServices.useSetDataSources()
  const DataSourceHours = useMemo(
    () => getHoursDatasource(filter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  )
  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )
  const [resultadoImprimir, setResultadoImprimir] = useState<
    Array<WorkingHour> | []
  >([])
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [dataSourceHours, setDataSourceHours] = useState<DataSource>(null)
  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Horarios',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])

  const onLoader = useCallback(
    (showLoader: boolean, mensaje: string) => {
      dispatch(
        setChangeLoader({
          show: showLoader,
          mensaje: mensaje,
        }),
      )
    },
    [dispatch],
  )
  const initSearch = useCallback(() => {
    const data = { ...filter }
    if (data?.initial) {
      data.descripcion = ''
      data.estado = optionsActivos[0]
      data.initial = false
      dispatch(
        changeFilter({
          ...data,
        }),
      )
    }
  }, [dispatch, filter])

  const onFind = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceHours.reload()
      setDataSourceHours(DataSourceHours)
      dispatch(setSearch(true))
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      setToast(
        `${DataSourceHours.totalCount()} Registro(s) Encontrado(s)`,
        ToastTypes.Success,
      )
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [DataSourceHours, dispatch, onLoader, onSetButtonAction, setToast])

  const onUndo = useCallback(() => {
    const data = { ...filter }
    data.descripcion = ''
    data.estado = optionsActivos[0]
    dispatch(
      changeFilter({
        ...data,
      }),
    )
    dispatch(setResetSeleccion())
  }, [dispatch, filter])

  const onBroom = useCallback(() => {
    setDataSourceHours(null)
  }, [])

  const openTabToEdit = useCallback(
    (selected: WorkingHour) => {
      const id = uuidv4()
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaultHoursData,
            loading: true,
          },
        }),
      )
      const data: any = { ...selected }
      dispatch(openTab({ key: id, store: data }))
    },
    [dispatch],
  )

  const onHandleDelete = useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminando...')
    try {
      const data = await HoursServices.deleteHour(seleccionado?.codigo ?? 0)
      consoleService.log(data, 'delete data')
      if (data['auto'] && data['error'] === false) {
        DataSourceHours.reload()
        setToast(data['message'], ToastTypes.Success)
        dispatch(setResetSeleccion())
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [
    seleccionado,
    setToast,
    onSetButtonAction,
    onLoader,
    DataSourceHours,
    dispatch,
  ])

  const onConfirmDelete = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea ELIMINAR el registro ${seleccionado?.descripcion}?`,
        type: 'confirm',
        currentAction: ButtonTypes.delete,
      })
    }
  }, [seleccionado])

  const onConfirmUpdate = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.codigo) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea EDITAR el registro ${seleccionado?.descripcion}?`,
        type: 'confirm',
        currentAction: ButtonTypes.edit,
      })
    }
  }, [seleccionado])

  const onPrintData = useCallback(
    async (reporte: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.print)
      onLoader(true, 'Genrando reporte...')
      setResultadoImprimir([])
      try {
        const toAny: any = fetchHourList(filter)
        const res = await dispatch(toAny)
        consoleService.log(res, 'print data')
        if (res?.payload && res?.payload?.error === false) {
          setResultadoImprimir(res?.payload?.auto)
          setReporte(reporte)
        } else {
          setToast(res?.payload?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    },
    [onLoader, onSetButtonAction, setToast, dispatch, filter],
  )

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) {
            onResetPag()
            onFind()
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.delete:
          if (tabs.current === tabId) onConfirmDelete()
          break
        case ButtonTypes.edit:
          if (tabs.current === tabId) onConfirmUpdate()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) onPrintData('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) onPrintData('Designer')
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      onResetPag,
      onFind,
      onBroom,
      onUndo,
      onConfirmDelete,
      onConfirmUpdate,
      onPrintData,
      tabs,
    ],
  )

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: false,
          Editar: seleccionado !== null && seleccionado !== undefined,
          Eliminar: seleccionado !== null && seleccionado !== undefined,
          Buscar: true,
          Imprimir: dataSourceHours !== null,
          Deshacer: true,
          Limpiar: dataSourceHours !== null,
        },
      }),
    )
  }, [dispatch, seleccionado, dataSourceHours])

  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit)
        openTabToEdit(seleccionado)
      else if (popupMsgConfig.currentAction === ButtonTypes.delete)
        onHandleDelete()
    }
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onHandleDelete, openTabToEdit, popupMsgConfig, seleccionado])

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    initSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  useEffect(() => {
    if (searchHorarios) setDataSourceHours(DataSourceHours)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros)
        break
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading)
        break
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
        break
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error)
        break
      default:
        break
    }
  }, [estadoBusqueda])

  const onParseData = useCallback((data: WorkingHour[]) => {
    let registros: Array<GroupReport> = []

    if (data.length > 0) {
      registros = data.map((x) => {
        const registro: GroupReport = {
          Code: x?.codigo ?? 0,
          Description: x?.descripcion ?? '',
          State: x?.estado ?? 0,
          Category: x?.estado == 1 ? 'Activo' : 'Inactivo',
        }
        return registro
      })
    }
    consoleService.log(registros)
    return registros
  }, [])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(resultadoImprimir ?? [])}
          fileName="StaticHorariosListado"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template="StaticHorariosListado"
          key="reportDesigner"
        />
        <ConfirmarAccionMsj
          title={popupMsgConfig.title}
          isVisible={popupMsgConfig.show}
          handleConfirm={onHandleConfirm}
          handleCancel={onHandleCancel}
          message={popupMsgConfig.message}
          typeMessage={popupMsgConfig.type}
          typeInputInfo={null}
        />
        <CCard>
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda onSearchEnter={onFind} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadosBusqueda
                data={dataSourceHours}
                onDBClick={onConfirmUpdate}
                handleOptionChange={handleOptionChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onDelete={onConfirmDelete}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultadoImprimir ?? [])}
        fileName="StaticHorariosListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: '' }}
        template="StaticHorariosListado"
        key="reportDesigner"
      />
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      <div id="PersonalContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={onFind} />
            <ResultadosBusqueda
              data={dataSourceHours}
              onDBClick={onConfirmUpdate}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={onConfirmDelete}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default Search
