import React from 'react'
import { useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Paging,
  Pager,
  ColumnChooser,
  HeaderFilter,
  Item,
  Export,
} from 'devextreme-react/data-grid'
import { DatosVenta } from '../../../../types/types'
import { RootState } from '../../../../../../../../../../../store/store'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { getAllowedPageSizes } from '../../../../../../../../../../../helpers/Helper'
interface ITablaCCFProps extends React.PropsWithChildren {
  data: Array<DatosVenta> | []
  // eslint-disable-next-line no-unused-vars
  onExport: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200]

const TablaCCF: React.FunctionComponent<ITablaCCFProps> = (props) => {
  const { data, onExport } = props

  const filtros = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.search.filter
  })
  const dataGrid = React.useRef<any>()
  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')

  const onExportExcell = React.useCallback(
    (e) => {
      const rango = `${filtros?.fechaInicio} - ${filtros?.fechaFinal}`
      const fileName = `anexo_contribuyentes-${rango}`
      if (e.format === 'xlsx') {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('Contribuyentes')
        e.component.exportDataFieldHeaders = false
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              fileName + '.xlsx',
            )
          })
        })
      } else if (e.format === 'pdf') {
        // const doc = new jsPDF();
        // exportDataGridToPdf({
        //   jsPDFDocument: doc,
        //   component: e.component
        // }).then(() => {
        //   doc.save('anexo_consumidor_final.pdf');
        // })
      } else if (e.format === 'csv') {
        onExport({
          title: 'Contribuyentes',
          format: e.format,
          component: e.component,
          filename: fileName,
        })
      }
    },
    [filtros, onExport],
  )

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr="comprobante"
      focusedRowKey="comprobante"
      dataSource={data}
      showBorders={true}
      onExporting={onExportExcell}
      width={'100%'}
    >
      <Pager
        visible={data.length > 0 ? true : false}
        allowedPageSizes={getAllowedPageSizes(data)}
        displayMode={displayMode}
        showPageSizeSelector={showPageSizeSelector}
        showInfo={showInfo}
        showNavigationButtons={showNavButtons}
      />
      <Export
        enabled={true}
        allowExportSelectedData={false}
        formats={['csv', 'xlsx']}
      />

      <Item name="columnChooserButton" />
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Column
        dataField="fechaNegociacion"
        caption="FECHA DE EMISIÓN" // A
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="claseDocumento"
        caption="CLASE DE DOCUMENTO" // B
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="tipoDocumentoCodigo"
        caption="TIPO DE DOCUMENTO" // C
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="numeroResolucion"
        caption="NÚMERO DE RESOLUCIÓN" // D
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="serieDocumento"
        caption="SERIE DE DOCUMENTO" // E
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="numDocumento"
        caption="NÚMERO DE DOCUMENTO" // F
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="numControlInterno"
        caption="NÚMERO DE CONTROL INTERNO" // G
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="clienteIdentificacion"
        caption="NIT O NRC DEL CLIENTE" // H
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="clienteNombre"
        caption="NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN" // I
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="subtotal0" //hay que calcularlo
        caption="VENTAS EXENTAS" // J
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasNoSujetas"
        caption="VENTAS NO SUJETAS" // K
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="subtotalIva"
        caption="VENTAS GRAVADAS LOCALES" // L
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="debitoFiscal" //hay que calcularlo
        caption="DEBITO FISCAL" // M
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasProporcionalidad"
        caption="VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS" // N
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasZonasFrancasDPA"
        caption="DEBITO FISCAL POR VENTAS A CUENTA DE TERCEROS " // O
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="total"
        caption="TOTAL DE VENTAS" // P
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="duiCliente"
        caption="DUI DEL CLIENTE " // Q
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      />

      <Column
        dataField="tipoOperacion"
        caption="TIPO DE OPERACION" // R
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>

      <Column
        dataField="tipoIngreso"
        caption="TIPO DE INGRESO" // S
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>
      <Column
        dataField="numeroAnexo"
        caption="NÚMERO DEL ANEXO" // T
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>

      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />
    </DataGrid>
  )
}
export default TablaCCF
