/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
import React from 'react'
import ReactDom from 'react-dom'
import { CustomButtons } from '../../../../../../types/generics'
import {
  ITotalesProforma,
  OrdenProformaDatosEdicion,
  TabStateOrdenProforma,
} from '../../../../type/types'
import {
  DataProformaExportar,
  ItemProforma,
  ProformaInfo,
  TOrdenDetalleIngresar,
  TOrdenProformaIngresar,
} from '../../type/types'
import {
  DateUtils,
  formatoFechasDatePickers,
} from '../../../../../../../../helpers/dateUtils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import VisualizaError from '../../../../../shared/visualizaError/visualizaError'
import {
  addDetalle,
  setDatosEdicion,
  setEditLoader,
  setMostrarBusquedaItems,
  setMuestraError,
  updateCliente,
  updateClienteOnly,
  updateDetalles,
  updateObservaciones,
  updateTotales,
  updateVendedor,
  updateProyecto,
  updateLocal,
} from '../../store/editDataReducer'
import LoadingIndicator from '../../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { CAlert, CButton, CCol, CFormInput, CRow } from '@coreui/react-pro'
import ValidationGroup from 'devextreme-react/validation-group'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import ValidationSummary from 'devextreme-react/validation-summary'
import { isMobile, isMobileOnly } from 'react-device-detect'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import { StatesEdition } from '../../../../../../types/enums'
import config from '../../../../../../../../config/config'
import { ClientesService } from '../../../../../../services/clientes.service'
import { Cliente } from '../../../../../../types/types'
import { addToast } from '../../../../../../../../store/toasterReducer'
import {
  ConfigPopupMsg,
  TiposComprobantesSLV,
  TiposComprobantesSri,
  ToastTypes,
} from '../../../../../../../../store/types'
import BuscarClienteLookUp from '../../../../../../components/busquedaCliente/busquedaCliente'
import {
  changeEditStatus,
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types'
import BuscarVendedorLookMemoUp from '../../../../../../../shared/components/buscarVendedorLookMemoUp/buscarVendedorLookMemoUp'
import TextArea from 'devextreme-react/text-area'
import { CustomSheet } from '../../../../../../../../views/componentes/customSheet/customSheet'
import { CustomSheetTextEditor } from '../../../../../../../../views/componentes/customSheet/editors/textEditor'
import { DataSheetColumn } from '../../../../../../../../views/componentes/customSheet/dataSheetColumn'
import { CustomSheetNumberEditor } from '../../../../../../../../views/componentes/customSheet/editors/numberEditor'
import CustomSheetNumberViewer from '../../../../../../../../views/componentes/customSheet/viewers/sheetNumberViewer'
import { SheetTextEditor } from '../../../../../../components/ventas/sheetEditors/sheetTextEditor'
import { RowCommand } from '../../../../../../../../views/componentes/customSheet/genericRow'
import { CellChangedArgs } from '../../../../../../../../views/componentes/customSheet/cellChangedArgs'
import { TextBox } from 'devextreme-react/text-box'
import { SheetPedidosDescripcionItemSelectEditor } from '../../../../components/shared/sheetEditors/sheetPedidosDescripcionItemSelectEditor'
import { DetallePedido, DetalleProformaRedux } from '../../type/detallePedido'
import { PedidoHelper } from '../../type/pedidosHelper'
import ScrollView from 'devextreme-react/scroll-view'
import ModificaItem from '../../../../../../components/items/modificarItem'
import { ReportWithLocalDataModal } from '../../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { RequestHelperDotNet } from '../../../../../../../../../src/helpers/requestHelperDotNet'
import AgregarDetallePedidoMobile from '../busquedaProformas/agregarDetalleVentaMobile'
import Button from 'devextreme-react/button'
import MainModalItems from '../../../../../../components/items/busquedaItemsModal/MainModalItems'
import BlockUi from '../../../../../../../../views/componentes/librerias/block-ui'
import Dialog from '../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { ProformaService } from '../../services/proforma.services'
import { toogleUsaDescuentoGlobal } from '../../store/configuracionesOrdenProformaReducer'
import { VentasService } from '../../../../../../services/ventas.service'
import ProjectsLookUp from '../../../../../../../../containers/component/sharedProviders/projets/projectsLookUp/projectsLookUp'
import LocsLookUp from '../../../../../../../../containers/component/sharedProviders/projets/locsLookUp/locsLookUp'
import './proforma.scss'
import { consoleService } from '../../../../../../../../services/console.service'
import { EModulosAcatha } from '../../../../../../../../store/enumsAplication'
import { SinPuntoDeVenta } from '../../../../../../components/sinPuntoVenta/sinPuntoVenta'
import {
  MessagesKeys,
  lh,
} from '../../../../../../../../helpers/localizationHelper'
import { setAutorizacionModulo } from '../../../../../ventas/store/configuracionesVentaReducer'
import MUIDataTable from 'mui-datatables'
import {
  OptionsTablaDetalleProformas,
  getColumnnasTablaDetalleProformas,
} from '../busquedaProformas/helper'
import { ECountry } from '../../../../../../../../store/enum/countries'
import { autorizacionModuloDtestore } from '../../../../../../../../store/zustand/autorizacionModuloDte.store'
import ConfirmarAccionMsj from '../../../../../../../componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../../../../../../nomina/store/types'
import { VentaHelperSV } from '../../../../../ventas/helpers/ventaHelperSV'
import Modalform from '../../../../../../../../views/componentes/modalform'
import { Local } from '../../../../../../../shared/types/types'
import { projetsService } from '../../../../../../../../containers/component/sharedProviders/projets/projets.services'

interface ICPedidoProps extends React.PropsWithChildren {
  info: any
  tabId: string
  tab: TabStateOrdenProforma<ProformaInfo>
}

const optionConfirm: ConfigPopupMsg = {
  ...defaultPopupMsgConfig,
  type: 'confirmOptions',
  optionsConfirm: [
    { type: TiposComprobantesSLV.CreditoFiscal, title: 'Crédito Fiscal' },
    { type: TiposComprobantesSLV.Factura, title: 'Con. Final' },
  ],
}

export enum TypeFormat {
  standard = 'standard',
  ticketera = 'Ticketera',
}

const Proforma: React.FunctionComponent<ICPedidoProps> = (props) => {
  const { info, tabId, tab } = props

  const dispatch = useDispatch()

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const localSession = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })

  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId]
  })
  const loader = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].loader
  })
  const cliente = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].cliente
  })
  const detalles = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].detalles
  })
  const desdePlantilla = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].desdePlantilla
  })
  const fechaSolicitud = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].fechaSolicitud
  })
  const loading = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].loading
  })
  const iva = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].iva
  })
  const numero = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].numero
  })
  const vendedor = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].vendedor
  })
  const proyecto = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].proyecto
  })
  const local = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].local
  })
  const observaciones = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].observaciones
  })
  const codigo = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].codigo
  })
  const mostrarBusquedaItems = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId]
      .mostrarBusquedaItems
  })
  const descuentoGlobal = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId].descuentoGlobal
  })
  const usaDescuentoGlobal = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.configuraciones
      .usaDescuentoGlobal
  })
  const autorizacionVenta = useSelector((state: RootState) => {
    return state.ventas.proformas.ordenProforma.editData[tabId]
      .autorizacionVenta
  })

  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [filaEliminar, setFilaEliminar] = React.useState(-1)
  const [filaClonar, setFilaClonar] = React.useState(-1)
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false)
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('')
  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{
    codigo: number
    design: boolean
  }>()
  const [infoReporte, setInfoReporte] = React.useState<any>(null)
  const [dataHeaders, setDataHeaders] = React.useState<any>(null)
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false)
  const [tipoImpresion, setTipoImpresion] = React.useState<TypeFormat | null>(
    null,
  )
  const [confirmImprimir, setConfirmImprimir] = React.useState<boolean>(false)
  const [configurarPV, setConfigurarPv] = React.useState(false)
  const [configurarAutorizacion, setConfigurarAutorizacion] =
    React.useState(false)
  const [popupMsgConfig, setPopupMsgConfig] =
    React.useState<ConfigPopupMsg>(optionConfirm)

  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleProformaRedux>> = []
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: 'minus',
      onExecute: (rowIndex) => {
        setFilaEliminar(rowIndex)
      },
    })
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: 'edit',
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true)
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras)
      },
    })
    // commads.push({
    //   id: 'Duplicar',
    //   text: 'Duplicar',
    //   icon: faCopy,
    //   onExecute: (rowIndex) => {
    //     setFilaClonar(rowIndex)
    //   },
    // });
    return commads
  }, [detalles])

  const getSessionData = React.useCallback(async () => {
    const conf = await RequestHelperDotNet.getConfigAsync('POST')
    const head = {
      authorization: conf.headers['x-authorization'],
      client_id: conf.headers['client-id'],
      secret_key: conf.headers['secret-key'],
      'Session-ID': conf.headers['Session-ID'],
      'x-csrf-token': String(conf.headers['authorization']).replaceAll(
        'Bearer',
        '',
      ),
    }
    setDataHeaders(head)
    return head
  }, [])

  const showEditLoader = React.useCallback(
    (mensaje: string) => {
      dispatch(
        setEditLoader({
          key: tabId,
          info: {
            mensaje: mensaje,
            show: true,
          },
        }),
      )
    },
    [tabId, dispatch],
  )

  const hideEditLoader = React.useCallback(() => {
    dispatch(
      setEditLoader({
        key: tabId,
        info: {
          mensaje: '',
          show: false,
        },
      }),
    )
  }, [tabId, dispatch])

  const sheetOnCellsChanged = React.useCallback(
    async (arrayOfChanges: Array<CellChangedArgs<DetalleProformaRedux>>) => {
      const _detalles = detalles.slice(0)
      for (const change of arrayOfChanges) {
        const detalle = new DetallePedido() //clase que realiza los calculos para un detalle
        detalle.setDetalle(change.rowData, empresa?.precision ?? 4)
        consoleService.log('detalle', detalle)

        let fecha = ''
        if (detalle.fecha !== '') {
          fecha = DateUtils.pickersDateToApiDate(detalle.fecha)
        } else {
          fecha = DateUtils.getCurrentDateAsString()
        }
        consoleService.log('fecha', fecha)

        switch (change.col.name) {
          case 'codigoBarras':
            showEditLoader('Buscando item...')
            const item = await ProformaService.getItemPorCodigoBarrasPedido(
              detalle.codigoBarras,
              datosEdicion.cliente?.identificacion ?? config.rucConsumidorFinal,
              fecha,
            )

            consoleService.log('item', item)

            if (item && item.length > 0) {
              detalle.setItem(item[0], empresa?.precision ?? 4)
              hideEditLoader()
            } else {
              dispatch(
                addToast({
                  content:
                    'No se encuentra el item con código ' + change.newValue,
                  title: 'Agregar item',
                  type: ToastTypes.Warning,
                  autoHide: 2000,
                }),
              )
              continue
            }
            break
          case 'cantidad':
            detalle.cantidad = change.newValue
            detalle.calcular()
            break
          case 'descripcion':
            if (typeof change.newValue === 'string') {
              if (detalle.codigo === undefined) {
                dispatch(
                  addToast({
                    content:
                      'No se puede pegar un item, debe usar la búsqueda o la columna código.',
                    title: 'Agregar item',
                    type: ToastTypes.Warning,
                    autoHide: 2000,
                  }),
                )
                continue
              }
              detalle.descripcion = change.newValue
            }
            break
          case '_cantidad':
            detalle.cantidad = parseFloat(change.newValue)
            break
          case '_porcentajeDescuento':
            detalle.porcentajeDescuento = parseFloat(change.newValue)
            break
          case '_precioUnitario':
            detalle.precio = parseFloat(change.newValue)
            break
          case '_precioIva':
            detalle.precioIva = parseFloat(change.newValue)
            break
          default:
            break
        }
        if (change.rowIndex === -1) {
          //nueva fila
          _detalles.push(detalle.getDetalle())
        } else {
          _detalles[change.rowIndex] = detalle.getDetalle()
        }
      }
      dispatch(
        updateDetalles({
          detalles: _detalles,
          key: tabId,
        }),
      )
      hideEditLoader()
    },
    [
      empresa,
      datosEdicion,
      detalles,
      dispatch,
      hideEditLoader,
      tabId,
      showEditLoader,
    ],
  )

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return (
        <CAlert color="danger">
          <ul className="errorList">
            {item.map(function (item, id) {
              return <li key={id}>{item['message']}</li>
            })}
          </ul>
        </CAlert>
      )
    } else {
      return <></>
    }
  }, [])

  const cambiarCliente = React.useCallback(
    async (cliente: Cliente) => {
      dispatch(
        updateCliente({
          cliente: cliente,
          key: tabId,
        }),
      )
    },
    [tabId, dispatch],
  )

  const cambiarClienteConfirm = React.useCallback(
    (clienteNuevo: Cliente, detalles: Array<any>, fecha: string) => {
      if (cliente?.codigo === clienteNuevo.codigo) {
        dispatch(
          updateClienteOnly({
            cliente: clienteNuevo,
            key: tabId,
          }),
        )
        return
      }

      const clienteLocal = clienteNuevo
      const fnActualizaOnOk = async (clienteNuevo) => {
        showEditLoader('Actualizando precios...')
        for (let index = 0; index < detalles.length; index++) {
          const detalle = detalles[index]
          const _detalle = new DetallePedido()
          const item = await ProformaService.getItemPorCodigoBarrasPedido(
            detalle.codigoBarras,
            clienteNuevo.identificacion,
            DateUtils.pickersDateToApiDate(fecha),
          )
          if (item && item.length > 0) {
            _detalle.setDetalle(detalle, empresa?.precision ?? 4)
            _detalle.setItem(item[0], empresa?.precision ?? 4)
            detalles[index] = _detalle.getDetalle()
          } else {
            addToast({
              content: `No se pudo recuperar el item ${detalle.descripcion}, el item no existe`,
              title: 'Cambiar precios',
              type: ToastTypes.Danger,
            })
          }
        }

        dispatch(
          updateCliente({
            cliente: clienteNuevo,
            detalles: detalles,
            key: tabId,
          }),
        )
        addToast({
          content: `Precios actualizados`,
          title: 'Cambiar precios',
          type: ToastTypes.Success,
        })
        hideEditLoader()
      }
      dialogRef.current.show({
        title: 'Cambio de cliente',
        body: `¿Desea actualizar el cliente?, esto afectará a los precios de los productos seleccionados actualmente`,
        actions: [
          Dialog.Action(
            <span>
              <u>{'A'}</u>
              {'ceptar'}
            </span>,
            () => fnActualizaOnOk(clienteLocal),
            'btn-success',
            'a',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              if (cliente !== null) {
                const tmpCliente = { ...cliente }
                cambiarCliente(tmpCliente)
              }
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          // dialog.hide()
        },
      })
      return
    },
    [
      tabId,
      cliente,
      cambiarCliente,
      hideEditLoader,
      showEditLoader,
      dispatch,
      empresa,
    ],
  )

  const cambiarClienteSeleccionado = React.useCallback(
    async (clienteORuc: string | Cliente | null) => {
      clienteORuc = clienteORuc ?? config.rucConsumidorFinal
      if (tab.editStatus === StatesEdition.save) {
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc
        dispatch(
          updateCliente({
            cliente: cliente,
            key: tabId,
          }),
        )
        return
      }
      //setMostrarEdicionClientes(false);
      if (detalles.length > 0) {
        if (typeof clienteORuc === 'string') {
          showEditLoader('Recuperando cliente...')
        }
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc
        if (cliente == null) {
          dispatch(
            addToast({
              content: 'No se puede recuperar el cliente ' + clienteORuc,
              type: ToastTypes.Danger,
            }),
          )
          return
        }
        if (detalles.length > 0 && !desdePlantilla) {
          cambiarClienteConfirm(cliente, detalles.slice(0), fechaSolicitud)
        } else {
          cambiarCliente(cliente)
        }
      } else {
        // if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
        // }
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc
        if (cliente) {
          dispatch(
            updateCliente({
              cliente: cliente,
              key: tabId,
            }),
          )
        }
        //setFormasPago(formasPago);
        // if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
        //   //setLocalLoader({ show: false, mensaje: 'Recuperando cliente...' })
        // }
      }
    },
    [
      detalles,
      fechaSolicitud,
      desdePlantilla,
      cambiarClienteConfirm,
      cambiarCliente,
      tab,
      tabId,
      showEditLoader,
      dispatch,
    ],
  )

  const cambiarVendedorSeleccionado = React.useCallback(
    (data) => {
      if (data !== null) {
        dispatch(updateVendedor({ key: tabId, vendedor: data }))
      }
    },
    [tabId, dispatch],
  )

  const verificaFechaEntrega = React.useCallback((dateApi: string) => {
    let dateEntrega: string = DateUtils.dateToString(
      new Date(),
      'dd/MM/yyyy HH:mm',
    )
    if (dateApi) {
      const fechaHora = dateApi.split(' ')
      if (fechaHora.length > 0) {
        const fecha = fechaHora[0]
        const hora = fechaHora[1]
        const horaSplit = hora.split(':')
        dateEntrega =
          DateUtils.format(fecha, 'dd/MM/yyyy', 'yyyy-MM-dd') +
          ' ' +
          horaSplit[0] +
          ':' +
          horaSplit[1]
      }
    }
    return dateEntrega
  }, [])

  const onParseData = React.useCallback(
    async (infoPedidoExportar: any) => {
      consoleService.log('onParseData : ', infoPedidoExportar)
      const pedido = await ProformaService.getOrden(infoPedidoExportar?.codigo)

      const detalles = await ProformaService.getOrdenDetalle(
        infoPedidoExportar?.codigo,
      )
      const cliente = await ClientesService.getCliente(
        pedido?.clienteIdentificacion,
      )

      let vendedor = {
        codigo: 0,
        usuarioEmail: '',
        usuarioIdentificacion: '',
        completo: '',
      }
      if (pedido?.vendedorCodigo !== 0) {
        const vendedorApi = await ProformaService.getVendedorByCode(
          pedido?.vendedorCodigo,
        )
        if (vendedorApi) {
          vendedor = {
            codigo: vendedorApi?.vendedorCodigo ?? 0,
            usuarioEmail: vendedorApi?.usuarioEmail ?? '',
            usuarioIdentificacion: vendedorApi?.usuarioIdentificacion ?? '',
            completo: vendedorApi?.completo ?? '',
          }
        }
      }

      const itemDetalle: any = []
      detalles.map((det) => {
        let UnitPriceIva: number =
          parseFloat(
            Number(det.detallePrecio).toFixed(empresa?.precision ?? 2),
          ) ?? 0
        if (autorizacionVenta && autorizacionVenta.codigoTIC != null) {
          if (
            autorizacionVenta.codigoTIC === TiposComprobantesSLV.CreditoFiscal
          ) {
            UnitPriceIva =
              parseFloat(
                Number(det.detallePrecioIva).toFixed(empresa?.precision ?? 2),
              ) ?? 0
          } else if (
            autorizacionVenta.codigoTIC === TiposComprobantesSLV.Factura
          ) {
            UnitPriceIva =
              parseFloat(
                Number(det.detallePrecio).toFixed(empresa?.precision ?? 2),
              ) ?? 0
          }
        }
        itemDetalle.push({
          ItemCode: Number(det.codigo) ?? 0,
          Barcode: String(det.codigoBarras) ?? 0,
          ItemDescription: String(det.descripcion) ?? 0,
          Quantity: Number(det.detalleCantidad) ?? 0,
          porcentajeDescuento: Number(det.detalleDescuento) ?? 0,
          UnitPriceIva: UnitPriceIva ?? 0,
          Subtotal: 0,
          Cost: Number(det.detalleValor) ?? 0,
        })
      })

      let dataExport: DataProformaExportar
      dataExport = {
        CountryCode: empresa?.codigoPais,
        TitleDocument:
          empresa?.codigoPais === ECountry.Ecuador ? 'PROFORMA' : 'COTIZACION',
        Code: pedido?.codigo ?? 0,
        Number: pedido?.numero ?? '',
        Observation: pedido?.observaciones ?? '',
        Total: parseFloat(pedido?.total.toFixed(empresa?.precision ?? 2)) ?? 0,
        Local: {
          Cod: pedido?.local?.codigo ?? 0,
          Name: pedido?.local?.nombre ?? '',
        },
        DateDelivery: pedido?.fechaHoraEntrega ?? '',
        DateOrder: pedido?.fecha ?? '',
        Customer: {
          Code: cliente?.codigo ?? 0,
          Address: cliente?.direccion ?? '',
          Email: cliente?.email ?? '',
          Identification: cliente?.identificacion ?? '',
          Phone: cliente?.telefono ?? '',
          Ciudad: cliente?.ciudad ?? '',
          Names: cliente?.nombres ?? '',
          LabelTabIdentification:
            empresa?.codigoPais === ECountry.Ecuador ? 'RUC:' : 'NIT:',
        },
        Seller: {
          Code: vendedor?.codigo ?? 0,
          Email: vendedor?.usuarioEmail ?? '',
          LabelTabIdentification:
            empresa?.codigoPais === ECountry.Ecuador ? 'RUC / CI:' : 'NIT:',
          Identificacion: vendedor?.usuarioIdentificacion ?? '',
          Names: vendedor?.completo ?? '',
        },
        Detall: itemDetalle,
        ImageReference: null,
        ImageDecoration: null,
        Portion: 0,
        Guarantee: 0,
        Balance: 0,
        Payment: 0,
      }
      consoleService.log('dataExport: ', dataExport)

      setInfoReporte(dataExport)
    },
    [empresa, autorizacionVenta],
  )

  const obtenerAutorizacion = React.useCallback(async () => {
    if (empresa.codigoPais === ECountry.Ecuador) {
      sessionStorage.removeItem(
        `autorizacionModulo-${empresa?.codigo}-${TiposComprobantesSri.Factura}`,
      )
      const autorizacion = await VentasService.getAutorizacion(
        puntoVenta,
        TiposComprobantesSri.Factura,
      )
      autorizacionModuloDtestore.getState().reset()
      return autorizacion
    } else if (empresa.codigoPais === ECountry.ElSalvador) {
      sessionStorage.removeItem(
        `autorizacionModulo-${empresa?.codigo}-${TiposComprobantesSLV.Factura}`,
      )
      const autorizacion = await VentasService.getAutorizacion(
        puntoVenta,
        TiposComprobantesSLV.Factura,
      )
      autorizacionModuloDtestore.getState().reset()
      return autorizacion
    }
  }, [empresa, puntoVenta])

  const estableceAutorizacion = React.useCallback(
    (data) => {
      if (empresa.codigoPais === ECountry.Ecuador) {
        sessionStorage.setItem(
          `autorizacionModulo-${empresa?.codigo}-${TiposComprobantesSri.Factura}`,
          JSON.stringify(data),
        )
        autorizacionModuloDtestore
          .getState()
          .setAutorizacion(
            `autorizacionModulo-${empresa?.codigo}-${TiposComprobantesSri.Factura}`,
          )
      } else if (empresa.codigoPais === ECountry.ElSalvador) {
        sessionStorage.setItem(
          `autorizacionModulo-${empresa?.codigo}-${TiposComprobantesSLV.Factura}`,
          JSON.stringify(data),
        )
        autorizacionModuloDtestore
          .getState()
          .setAutorizacion(
            `autorizacionModulo-${empresa?.codigo}-${TiposComprobantesSLV.Factura}`,
          )
      }
    },
    [empresa],
  )

  const cargarProforma = React.useCallback(
    async (codigo: number, setInitData: boolean) => {
      consoleService.log('cargarProforma')

      if (datosEdicion.imprimir) {
        setVerFacturaPdf({ codigo: datosEdicion.codigo, design: false })
        setReporte('Viewer')
        setConfirmImprimir(true)
      }
      if (datosEdicion.loading === false) {
        return
      }
      const data = { ...defaulDatosEdicionOrdenPedido } //utilidades.unfreeze(defaultDatosEdicionFactura);
      consoleService.log('puntoVenta ', puntoVenta)

      if (puntoVenta.length === 0) {
        setConfigurarPv(true)
        return false
      }

      const autorizacion = await obtenerAutorizacion()

      data.autorizacionVenta = autorizacion ?? null

      consoleService.log('autorizacion:', autorizacion)

      dispatch(setAutorizacionModulo(autorizacion))
      sessionStorage.setItem('autorizacionModulo', JSON.stringify(autorizacion))
      estableceAutorizacion(autorizacion)

      if (autorizacion === null || autorizacion === undefined) {
        setConfigurarAutorizacion(true)
        return false
      }

      try {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.find,
          }),
        )

        const pedido = await ProformaService.getOrden(codigo)
        if (pedido) {
          const cliente = await ClientesService.getCliente(
            pedido?.clienteIdentificacion,
          )

          const detalles = await ProformaService.getOrdenDetalle(codigo)
          consoleService.log('pedido', pedido)
          consoleService.log('pedido.fecha', pedido.fecha)
          const iva = await VentasService.getIva(pedido.fecha, autorizacion)

          if (pedido?.vendedorCodigo !== 0) {
            let findSeller = {
              cod: Number(pedido?.vendedorCodigo),
            }
            const vendedorApi = await ProformaService.getVendedorByCode(
              findSeller,
            )

            if (vendedorApi) {
              data.vendedor = {
                vendedorCodigo: parseInt(vendedorApi.vendedorCodigo) ?? 0,
                usuarioCodigo: parseInt(vendedorApi.usuarioCodigo) ?? 0,
                comision: parseInt(vendedorApi.comision) ?? 0,
                vendedorZona: String(vendedorApi.vendedorZona) ?? '',
                usuarioNombres: String(vendedorApi.usuarioNombres) ?? '',
                usuarioApellidos: String(vendedorApi.usuarioApellidos) ?? '',
                completo: String(vendedorApi.completo) ?? '',
                siglas: String(vendedorApi.siglas) ?? '',
                estado: String(vendedorApi.estado) ?? '',
                localCodigo: String(vendedorApi.localCodigo) ?? '',
                usuarioEmail: String(vendedorApi.usuarioEmail) ?? '',
                usuarioIdentificacion:
                  String(vendedorApi.usuarioIdentificacion) ?? '',
                usuarioTelefono: String(vendedorApi.usuarioTelefono) ?? '',
              }
            }
          }

          data.codigo = pedido.codigo
          data.cliente = cliente
          data.observaciones = pedido.observaciones
          data.numero = pedido.numero
          data.total = Number(pedido.total)
          data.iva = iva ?? 0
          data.descuentoGlobal = pedido?.descuentoGlobal ?? 0

          const tmpDet2 = PedidoHelper.detallesToDetallesPedido(
            detalles,
            empresa?.precision ?? 4,
          )
          data.detalles = tmpDet2

          let locales: Local[] = []
          const apilocales = await projetsService.getLocs(empresa.codigo, '')
          if (apilocales.auto && apilocales.auto.length > 0) {
            locales = apilocales.auto
          }

          const localFiltrado = locales.find(
            (x) => x.codigo === pedido.localCodigo,
          )
          if (localFiltrado) {
            data.local = localFiltrado
          }

          data.loader = {
            mensaje: '',
            show: false,
          }
          data.loading = false

          dispatch(
            setDatosEdicion({
              data: data,
              key: tabId,
            }),
          )

          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...OrdenProformaButtons,
                Guardar: true,
                Deshacer: false,
                Descuento: true,
              },
            }),
          )
        }
      } catch (error) {
        const dataError: any = error
        data.tieneError = true
        data.mensajeError = dataError
        data.loading = false
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
      }
    },
    [
      datosEdicion,
      puntoVenta,
      dispatch,
      tabId,
      obtenerAutorizacion,
      estableceAutorizacion,
      empresa,
      // autorizacionVenta,
    ],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }

      sessionStorage.removeItem('autorizacionModulo')

      const data = { ...defaulDatosEdicionOrdenPedido }

      consoleService.log('puntoVenta ', puntoVenta)

      if (puntoVenta.length === 0) {
        setConfigurarPv(true)
        return false
      }

      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...OrdenProformaButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
            Descuento: true,
          },
        }),
      )
      try {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.new,
          }),
        )
        const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers)
        sessionStorage.setItem('fechaFacturar', JSON.stringify(fecha))

        let autorizacion
        if (!autorizacionVenta) {
          autorizacion = await obtenerAutorizacion()
        } else {
          autorizacion = autorizacionVenta
        }
        data.autorizacionVenta = autorizacion ?? null

        consoleService.log('autorizacion:', autorizacion)

        dispatch(setAutorizacionModulo(autorizacion))

        estableceAutorizacion(autorizacion)
        if (autorizacion === null || autorizacion === undefined) {
          setConfigurarAutorizacion(true)
          return false
        }

        const iva = await VentasService.getIva(
          DateUtils.pickersDateToApiDate(fecha),
          autorizacion,
        )

        if (template > 0) {
          const proforma = await ProformaService.getOrden(template)

          if (proforma) {
            if (proforma?.vendedorCodigo !== 0) {
              let findSeller = {
                cod: Number(proforma?.vendedorCodigo),
              }
              const vendedorApi = await ProformaService.getVendedorByCode(
                findSeller,
              )

              if (vendedorApi) {
                data.vendedor = {
                  vendedorCodigo: parseInt(vendedorApi.vendedorCodigo) ?? 0,
                  usuarioCodigo: parseInt(vendedorApi.usuarioCodigo) ?? 0,
                  comision: parseInt(vendedorApi.comision) ?? 0,
                  vendedorZona: String(vendedorApi.vendedorZona) ?? '',
                  usuarioNombres: String(vendedorApi.usuarioNombres) ?? '',
                  usuarioApellidos: String(vendedorApi.usuarioApellidos) ?? '',
                  completo: String(vendedorApi.completo) ?? '',
                  siglas: String(vendedorApi.siglas) ?? '',
                  estado: String(vendedorApi.estado) ?? '',
                  localCodigo: String(vendedorApi.localCodigo) ?? '',
                  usuarioEmail: String(vendedorApi.usuarioEmail) ?? '',
                  usuarioIdentificacion:
                    String(vendedorApi.usuarioIdentificacion) ?? '',
                  usuarioTelefono: String(vendedorApi.usuarioTelefono) ?? '',
                }
              }
            }

            const secuencial = await ProformaService.getSecuencial('PROD')

            const detalles = await ProformaService.getOrdenDetalle(template)
            const cliente = await ClientesService.getCliente(
              proforma?.clienteIdentificacion,
            )
            data.desdePlantilla = true

            data.codigo = 0
            data.numero = secuencial
            data.total = Number(proforma.total)

            if (proforma.fechaHoraEntrega) {
              data.fechaEntrega = verificaFechaEntrega(
                proforma.fechaHoraEntrega,
              )
            }

            const tmpDet2 = PedidoHelper.detallesToDetallesPedido(
              detalles,
              empresa.precision ?? 4,
            )
            data.detalles = tmpDet2

            data.cliente = cliente

            data.observaciones = proforma.observaciones
            data.fechaSolicitud = DateUtils.getCurrentDateAsString()
          }
        } else {
          const secuencial = await ProformaService.getSecuencial('PRO')
          const cliente = await ClientesService.getCliente(
            config.rucConsumidorFinal,
          )
          data.cliente = cliente
          data.detalles = []
          data.observaciones = ''
          data.desdePlantilla = false
          data.numero = secuencial
          data.fechaSolicitud = fechaSolicitud
          data.vendedor = defaultSeller

          if (vendedor !== null) {
            data.vendedor = vendedor
          }

          if (usuario.cedula) {
            let findSeller = {
              id: usuario.cedula,
            }
            const vendedorApi = await ProformaService.getVendedorByCode(
              findSeller,
            )
            consoleService.log('vendedorApi: ', vendedorApi)
            if (
              vendedorApi !== null &&
              vendedorApi !== undefined &&
              vendedorApi?.vendedorCodigo
            ) {
              data.vendedor = vendedorApi
            }
          }
        }
        data.iva = iva

        let locales: Local[] = []
        const apilocales = await projetsService.getLocs(empresa.codigo, '')
        if (apilocales.auto && apilocales.auto.length > 0) {
          locales = apilocales.auto
        }

        const localFiltrado = locales.find(
          (x) => x.codigo === localSession?.codigo,
        )
        if (localFiltrado) {
          data.local = localFiltrado
        }

        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
        dispatch(
          addToast({
            content: data.mensajeError,
            type: ToastTypes.Danger,
          }),
        )
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    },
    [
      verificaFechaEntrega,
      loading,
      tabId,
      empresa,
      usuario,
      dispatch,
      fechaSolicitud,
      puntoVenta,
      vendedor,
      obtenerAutorizacion,
      estableceAutorizacion,
      autorizacionVenta,
      localSession,
    ],
  )

  const setToast = React.useCallback(
    (texto, type: ToastTypes, tittle: string = 'Acatha') => {
      dispatch(
        addToast({
          id: '',
          autoHide: 5000,
          title: tittle,
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const validarFormulario = React.useCallback(async () => {
    const smsErrors: Array<any> = []
    if (datosEdicion?.cliente?.codigo === 0) {
      const sms: string = 'Seleccione el Cliente por favor.'
      smsErrors.push({ sms })
    }
    if (
      datosEdicion?.vendedor === null ||
      datosEdicion?.vendedor?.vendedorCodigo === 0
    ) {
      const sms: string = 'Seleccione el Vendedor por favor.'
      smsErrors.push({ sms })
    }
    if (datosEdicion.detalles.length === 0) {
      const sms: string = 'Ingrese al menos un item por favor.'
      smsErrors.push({ sms })
    }
    if (datosEdicion?.numero && Number(datosEdicion?.numero) === 0) {
      const sms: string = 'El número de orden no es el correcto.'
      smsErrors.push({ sms })
    }
    if (datosEdicion?.totales?.total < 0) {
      const sms: string = 'El Total no es correcto.'
      smsErrors.push({ sms })
    }
    return smsErrors
  }, [datosEdicion])

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px' }}>{tittle}</strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item?.sms ?? ''}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const guardar = React.useCallback(async () => {
    consoleService.log('guardar')
    consoleService.log('datosEdicion', datosEdicion)

    if (
      usuario == null ||
      empresa == null ||
      localSession == null ||
      puntoVenta == null
    ) {
      return
    }

    if (local === null) {
      dispatch(
        addToast({
          id: '',
          autoHide: 5000,
          content: 'Por favor seleccione un local',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )

      return
    }

    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save,
      }),
    )

    const resValidarFormulario = await validarFormulario()
    if (resValidarFormulario.length > 0) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      onErrorConfirm(
        resValidarFormulario,
        'Antes de continuar revise lo siguiente:',
      )
      return false
    }

    try {
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: true,
              mensaje: 'Guardando Orden...',
            },
          },
          key: tabId,
        }),
      )

      consoleService.log('datosEdicion.vendedor :', datosEdicion?.vendedor)

      let vendedor = null
      if (
        datosEdicion?.vendedor !== null &&
        datosEdicion?.vendedor?.vendedorCodigo !== null
      ) {
        vendedor = datosEdicion?.vendedor?.vendedorCodigo ?? 0
      }

      let detallesProforma = []
      if (datosEdicion?.detalles.length > 0) {
        detallesProforma = datosEdicion.detalles.map((det) => {
          consoleService.log('det', det)
          const detalle: TOrdenDetalleIngresar = {
            itemCodigo: det?.codigo,
            itemBarras: det?.codigoBarras?.toString(),
            detalleCantidad: det?._cantidad,
            itemDescripcion: det?.descripcion,
            detalleImpuesto: det?.codigoImpuesto ?? 0,
            itemUnidadCompraDescripcion: '',
            detallePorcentaje: 0,
            detalleDescuento: det?._descuento,
            detallePrecio: det?._precioUnitario,
            detalleValor: det?._total,
          }
          return detalle
        })
      }
      const ordenRegistrar: TOrdenProformaIngresar = {
        codigo: datosEdicion.codigo ?? 0,
        numero: datosEdicion.numero === '' ? '0000000' : datosEdicion.numero,
        fecha: DateUtils.pickersDateToApiDate(datosEdicion.fechaSolicitud),
        fechaEntrega: datosEdicion.fechaEntrega,
        clienteCodigo: datosEdicion?.cliente?.codigo ?? 0,
        detalleTrabajo: '',
        detalleRecepcion: '',
        detalleObservaciones: datosEdicion?.observaciones ?? '',
        detalleTipo: 'PRO',
        detalleOrigen: 0,
        detalleDescuento: datosEdicion?.descuentoGlobal ?? 0,
        localCodigo: local.codigo ?? localSession?.codigo,
        pventa: puntoVenta,
        total: datosEdicion?.totales?.total ?? 0,
        vendedorCodigo: vendedor,
        valorDetalle: detallesProforma,
        documentoGenerar: '',
      }

      if (autorizacionVenta && autorizacionVenta?.codigoTIC !== null) {
        ordenRegistrar.documentoGenerar = autorizacionVenta?.codigoTIC
      }

      consoleService.log('ordenRegistrar :', ordenRegistrar)

      const resultado = await ProformaService.ingresarOrden(ordenRegistrar)
      consoleService.log('resultado :', resultado)

      let sms = ''
      sms = resultado?.message

      if (resultado?.error !== undefined && resultado?.error === true) {
        setToast(sms, ToastTypes.Warning, 'Guardar')
        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              loader: {
                show: false,
                mensaje: '',
              },
              // imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
            },
            key: tabId,
          }),
        )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        return false
      } else {
        setToast(sms, ToastTypes.Success, 'Guardar')
      }

      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            total: datosEdicion?.totales?.total ?? 0,
            codigo: parseInt(resultado['auto'].codigo),
            numero: resultado?.auto?.numero.toString() ?? 0,
            // fechaSolicitud: DateUtils.getCurrentDateAsString(DateUtils.apiDateToPickersDate(resultado['auto'].fecha.toString())),
            loader: {
              show: false,
              mensaje: '',
            },
            imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false,
          },
          key: tabId,
        }),
      )

      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          info: resultado['auto'],
          buttons: {
            ...OrdenProformaButtons,
            Guardar: false,
            Editar: false,
            Deshacer: false,
            Descuento: false,
            Imprimir: true,
          },
        }),
      )
      consoleService.log('empresa', empresa)
      if (empresa.imprimeAutomaticamenteAlfacturar) {
        consoleService.log('genera objeto impresion')
        await onParseData(resultado['auto'])
        setReporte('Viewer')
        setConfirmImprimir(true)
      }

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    } catch (error) {
      consoleService.error('Error al guardar orden:', error)

      let message = 'Error al guardar la orden'

      if (error instanceof Error) {
        message = `${message}: ${error.message}`
      } else if (typeof error === 'object' && error !== null) {
        if ('error' in error && 'message' in error) {
          message = error.message
        } else {
          const errorStr = JSON.stringify(error, null, 2)
          message = errorStr !== '{}' ? `${message}: ${errorStr}` : message
        }
      } else if (typeof error === 'string') {
        message = `${message}: ${error}`
      }

      dispatch(
        addToast({
          id: '',
          autoHide: 5000,
          content: message,
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      return
    }
  }, [
    autorizacionVenta,
    puntoVenta,
    onErrorConfirm,
    validarFormulario,
    localSession,
    onParseData,
    datosEdicion,
    tabId,
    dispatch,
    empresa,
    setToast,
    usuario,
  ])

  const handlePrint = React.useCallback(
    (design: boolean) => {
      setVerFacturaPdf({ codigo: codigo, design: design })
    },
    [codigo],
  )

  const modoEdicion = React.useCallback(() => {
    consoleService.log('modoEdicion')
  }, [])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.save: {
          guardar()
          break
        }
        case ButtonTypes.print:
          setReporte('Viewer')
          setConfirmImprimir(true)
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          setReporte('Designer')
          setConfirmImprimir(true)
          handlePrint(true)
          break
        case ButtonTypes.broom:
          modoNuevo(0, true)
          break
        case ButtonTypes.edit:
          modoEdicion()
          break
        case ButtonTypes.discount:
          dispatch(toogleUsaDescuentoGlobal())
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, modoNuevo, guardar, tabId, handlePrint, modoEdicion],
  )

  const calcularTotales = React.useCallback(
    (detalles: Array<DetalleProformaRedux>, descuentoGlobal: number) => {
      consoleService.log('calcularTotales')
      consoleService.log('descuentoGlobal', descuentoGlobal)
      const totalCero: ITotalesProforma = {
        descuento: 0,
        descuentoGloabal: descuentoGlobal,
        ice: 0,
        iva: 0,
        subtotal: 0,
        subtotal0: 0,
        subtotalIva: 0,
        total: 0,
      }
      const totales = detalles.reduce((sum, next) => {
        sum.subtotal += next._subtotal
        sum.subtotal0 += next._subtotal0
        sum.subtotalIva += next._precioIva * next._cantidad
        sum.iva += next._iva
        sum.descuento += next._descuento
        sum.total += next._total - next._descuento
        return sum
      }, totalCero)

      if (descuentoGlobal > totales.total) {
        dispatch(
          addToast({
            title: 'Calcular totales',
            content: 'El descuento no puede ser mayor al total',
            type: ToastTypes.Warning,
            autoHide: 2500,
          }),
        )
      }

      if (descuentoGlobal > 0) {
        if (totales.total === 0) {
          return
        }
        const proporciaonalIva =
          ((100 / totales.subtotal) * totales.subtotalIva) / 100
        const proporcionalBaseCero = 1 - proporciaonalIva
        totales.subtotalIva =
          totales.subtotalIva - descuentoGlobal * proporciaonalIva
        totales.iva = totales.subtotalIva * (iva / 100)
        totales.subtotal0 =
          totales.subtotal0 - descuentoGlobal * proporcionalBaseCero
        totales.total = totales.subtotalIva + totales.iva + totales.subtotal0
      } else if (descuentoGlobal < 0) {
        return
      }

      dispatch(
        updateTotales({
          totales: totales,
          key: tabId,
        }),
      )

      return totales
    },
    [tabId, dispatch, iva],
  )

  const establecerDescuentoGlobal = React.useCallback(
    (valor) => {
      consoleService.log('establecerDescuentoGlobal')
      consoleService.log('detalles', detalles)
      // calcularTotales(detalles, valor);
      //setdescuentoGlobal(valor);

      const _detalles = detalles.slice(0)
      for (let index = 0; index < _detalles.length; index++) {
        const _detalle = _detalles[index]
        const _detalleTmp: DetallePedido = new DetallePedido()
        _detalleTmp.setDetalle(_detalle, empresa?.precision ?? 2)
        _detalleTmp.porcentajeDescuento = valor
        _detalles[index] = _detalleTmp.getDetalle()
      }
      dispatch(
        updateDetalles({
          key: props.tabId,
          detalles: _detalles,
        }),
      )
    },
    [detalles, dispatch, props, empresa],
  )

  const onModalItemsItemSelected = React.useCallback(
    (item: ItemProforma) => {
      const detalle: DetallePedido = new DetallePedido()
      detalle.setItem(item, empresa?.precision ?? 4)
      detalle.cantidad = 1
      dispatch(
        addDetalle({
          key: tabId,
          detalle: detalle.getDetalle(),
        }),
      )
      dispatch(
        setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }),
      )
    },
    [tabId, empresa, dispatch],
  )

  const onCloneItemSelected = React.useCallback((index: number) => {}, [])

  const onParseInfoReporte = React.useCallback(async () => {
    let dataExport: DataProformaExportar

    let cliente: any = null
    if (datosEdicion?.cliente !== null) {
      cliente = datosEdicion?.cliente
    }

    let vendedor: any = null
    if (datosEdicion?.vendedor !== null) {
      vendedor = datosEdicion?.vendedor
    }

    let itemDetalle: any = []
    datosEdicion?.detalles.map((det) => {
      let UnitPriceIva: number =
        parseFloat(
          Number(det._precioUnitario).toFixed(empresa?.precision ?? 2),
        ) ?? 0
      if (autorizacionVenta && autorizacionVenta.codigoTIC != null) {
        if (
          autorizacionVenta.codigoTIC === TiposComprobantesSLV.CreditoFiscal
        ) {
          UnitPriceIva =
            parseFloat(
              Number(det._precioIva).toFixed(empresa?.precision ?? 2),
            ) ?? 0
        } else if (
          autorizacionVenta.codigoTIC === TiposComprobantesSLV.Factura
        ) {
          UnitPriceIva =
            parseFloat(
              Number(det._precioUnitario).toFixed(empresa?.precision ?? 2),
            ) ?? 0
        }
      }
      itemDetalle.push({
        ItemCode: Number(det.codigo) ?? 0,
        Barcode: String(det.codigoBarras) ?? 0,
        ItemDescription: String(det.descripcion) ?? 0,
        Quantity: Number(det._cantidad) ?? 0,
        porcentajeDescuento: parseFloat(Number(det._descuento).toFixed(2)) ?? 0,
        UnitPriceIva: UnitPriceIva,
        Subtotal: parseFloat(Number(det._subtotal).toFixed(2)) ?? 0,
        Cost: parseFloat(Number(det._total).toFixed(2)) ?? 0,
      })
    })

    dataExport = {
      CountryCode: empresa?.codigoPais,
      TitleDocument:
        empresa?.codigoPais === ECountry.Ecuador ? 'PROFORMA' : 'COTIZACIÓN',
      Code: datosEdicion?.codigo ?? 0,
      Number: datosEdicion?.numero ?? '',
      ImageReference: [],
      ImageDecoration: [],
      Portion: 0,
      Observation: datosEdicion?.observaciones ?? ' ',
      Guarantee: 0,
      Balance: 0,
      Total:
        parseFloat(datosEdicion?.total.toFixed(empresa?.precision ?? 2)) ?? 0,
      Payment: 0,
      DateDelivery: '',
      DateOrder: datosEdicion?.fechaSolicitud ?? '',
      Customer:
        cliente !== null
          ? {
              Code: cliente?.codigo ?? 0,
              Address: cliente?.direccion ?? '',
              Email: cliente?.email ?? '',
              Identification: cliente?.identificacion ?? '',
              Phone: cliente?.telefono ?? '',
              Ciudad: cliente?.ciudad ?? '',
              Names: cliente?.nombres ?? '',
              LabelTabIdentification:
                empresa?.codigoPais === ECountry.Ecuador ? 'RUC:' : 'NIT:',
            }
          : null,
      Seller:
        vendedor !== null
          ? {
              Code: vendedor?.usuarioCodigo ?? 0,
              Email: vendedor?.usuarioEmail ?? '',
              LabelTabIdentification:
                empresa?.codigoPais === ECountry.Ecuador ? 'RUC / CI:' : 'NIT:',
              Identificacion: vendedor?.usuarioIdentificacion ?? '',
              Names: vendedor?.completo ?? '',
            }
          : null,
      Local: {
        Cod: 0,
        Name: '',
      },
      Detall: itemDetalle,
    }

    consoleService.log('dataExport:', dataExport)
    setInfoReporte(dataExport)
  }, [datosEdicion, empresa, autorizacionVenta])

  const onButtonDiseñarClick = React.useCallback(
    async (printType) => {
      await onParseInfoReporte()
    },
    [onParseInfoReporte],
  )

  const onButtonExportarPdfClick = React.useCallback(
    async (printType) => {
      await onParseInfoReporte()
    },
    [onParseInfoReporte],
  )

  const muiTableCellsChanged = React.useCallback(
    (row, col, newValue) => {
      const _detalles = detalles.slice(0)
      //const detalle = _detalles[row];
      const _detalle = new DetallePedido()
      _detalle.setDetalle(_detalles[row], empresa?.precision ?? 4)

      //const dt = datosEdicion.detallesMuiTable;
      switch (col) {
        case 2: //TABLECOL_DESCRIPCION
          _detalle.descripcion = newValue
          //dt[row].descripcion = newValue;
          break
        case 3: //TABLECOL_CANTIDAD
          _detalle.cantidad = parseFloat(newValue)
          //dt[row].cantidad = newValue ? parseFloat(newValue) : 0;
          break
        case 4: //TABLECOL_PRECIO
          _detalle.precio = parseFloat(newValue)
          //dt[row].precio = newValue ? parseFloat(newValue) : 0;
          break
        default:
          break
      }
      _detalles[row] = _detalle.getDetalle()
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
    },
    [detalles, tabId, empresa, dispatch],
  )

  const isDeleteRow = React.useCallback(
    (indice) => {
      const det = detalles.slice()
      det.splice(indice, 1)
      dispatch(
        updateDetalles({
          detalles: det,
          key: tabId,
        }),
      )
      setFilaEliminar(-1)
    },
    [detalles, dispatch, tabId],
  )

  const onHandleConfirm = React.useCallback(
    (e) => {
      console.log('onHandleConfirm', e)
      if (popupMsgConfig.type === 'confirm') {
        if (popupMsgConfig.currentAction === ButtonTypes.edit)
          consoleService.log('on edit')
        else if (popupMsgConfig.currentAction === ButtonTypes.delete)
          consoleService.log('on delete')
        //onHandleDelete()
      } else consoleService.log('on ok')
      setPopupMsgConfig(defaultPopupMsgConfig)
    },
    [popupMsgConfig],
  )

  const onHandleCancel = React.useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  React.useEffect(() => {
    getSessionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      cargarProforma(info.codigo, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles, descuentoGlobal)
    }
  }, [loading, detalles, descuentoGlobal, calcularTotales])

  React.useEffect(() => {
    if (filaEliminar >= 0) {
      isDeleteRow(filaEliminar)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filaEliminar])

  React.useEffect(() => {
    if (filaClonar >= 0 && detalles.length > 0) {
      onCloneItemSelected(filaClonar)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filaClonar, detalles])

  const agregarDetallePedidoMobile = React.useCallback(
    (detalle: DetallePedido) => {
      const _detalles = detalles.slice(0)
      _detalles.push(detalle.getDetalle())
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
      setAgregarDetalleMovil(false)
    },
    [detalles, tabId, dispatch],
  )

  //#region productos

  const onCrearProductoButtonClick = React.useCallback(() => {
    setCodigoBarrasItemEditar('')
    setMostrarEdicionItems(true)
  }, [])

  const onMasOpcionesButtonClick = React.useCallback(() => {
    dispatch(
      setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }),
    )
  }, [tabId, dispatch])

  //#endregion

  const ocultarBusquedaItems = React.useCallback(() => {
    dispatch(
      setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }),
    )
  }, [tabId, dispatch])

  const confirmActionImprimir = () => {
    return (
      <Modalform
        name="confirmar"
        headerTitle={'ácatha'}
        childrenBody={<>{'¿Que impresión desea realizar?'}</>}
        childrenFooter={
          <CRow>
            <CCol lg="12" sm="12" md="12">
              <CButton
                size="sm"
                color="secondary"
                className="me-2"
                onClick={() => {
                  setTipoImpresion(TypeFormat.standard)

                  if (reporte === 'Viewer') {
                    onButtonDiseñarClick(TypeFormat.standard)
                  } else {
                    onButtonExportarPdfClick(TypeFormat.standard)
                  }
                  setConfirmImprimir(false)
                }}
              >
                {TypeFormat.standard.charAt(0).toUpperCase() +
                  TypeFormat.standard.slice(1)}
              </CButton>
              <CButton
                size="sm"
                color="secondary"
                className="me-2"
                onClick={() => {
                  setTipoImpresion(TypeFormat.ticketera)

                  if (reporte === 'Viewer') {
                    onButtonDiseñarClick(TypeFormat.ticketera)
                  } else {
                    onButtonExportarPdfClick(TypeFormat.ticketera)
                  }
                  setConfirmImprimir(false)
                }}
              >
                {TypeFormat.ticketera.charAt(0).toUpperCase() +
                  TypeFormat.ticketera.slice(1)}
              </CButton>
              <CButton
                size="sm"
                color="secondary"
                className="me-2"
                onClick={() => {
                  setTipoImpresion(null)
                  setConfirmImprimir(false)
                }}
              >
                {'Cancelar'}
              </CButton>
            </CCol>
          </CRow>
        }
        closeOnBackdrop={false}
        show={confirmImprimir}
        onClose={() => setConfirmImprimir(!confirmImprimir)}
        centered={true}
        size="sm"
      />
    )
  }

  const getTipoComprobante = () => {
    if (!autorizacionVenta) {
      return ''
    } else {
      return (
        'COTIZAR - ' +
        VentaHelperSV.getTipoComprobantesSV(
          autorizacionVenta?.codigoTIC as TiposComprobantesSLV,
        )
      )
    }
  }

  return (
    <>
      {popupMsgConfig.show && (
        <ConfirmarAccionMsj
          title={popupMsgConfig.title}
          isVisible={popupMsgConfig.show}
          handleConfirm={onHandleConfirm}
          handleCancel={onHandleCancel}
          message={popupMsgConfig.message}
          typeMessage={popupMsgConfig.type}
          optionsConfirm={popupMsgConfig.optionsConfirm}
          typeInputInfo={null}
        />
      )}
      {confirmImprimir === true &&
        ReactDom.createPortal(confirmActionImprimir(), document.body)}

      <div
        style={{ padding: '10px', overflowX: 'hidden', paddingBottom: '150px' }}
        id="mantenimientoPedido"
      >
        <Dialog ref={dialogRef} />

        {datosEdicion.tieneError && (
          <VisualizaError
            titulo="Error en Proforma"
            mensaje={datosEdicion.mensajeError}
            content={generaDetalleErrores(showErrorMessages)}
            onOk={() => {
              dispatch(
                setMuestraError({
                  key: props.tabId,
                  tieneError: false,
                  mensajeError: '',
                }),
              )
            }}
          />
        )}

        {configurarPV && (
          <VisualizaError
            mensaje=""
            titulo=""
            content={
              <SinPuntoDeVenta
                mensaje={lh.getMessage(MessagesKeys.SelectSalesPoint)}
                onClick={() => setConfigurarPv(false)}
              />
            }
            onOk={() => {
              setConfigurarPv(false)
            }}
          />
        )}

        {configurarAutorizacion && (
          <VisualizaError
            mensaje=""
            titulo=""
            content={
              <SinPuntoDeVenta
                mensaje={lh.getMessage(MessagesKeys.ConfigurarAutorizacionSRI)}
                onClick={() => setConfigurarAutorizacion(false)}
              />
            }
            onOk={() => {
              setConfigurarAutorizacion(false)
            }}
          />
        )}

        {agregarDetalleMovil && (
          <AgregarDetallePedidoMobile
            key="agregarDetalleVentaMovil"
            onOk={agregarDetallePedidoMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            cliente={datosEdicion.cliente}
            fecha={DateUtils.pickersDateToApiDate(datosEdicion.fechaSolicitud)}
          />
        )}
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion="ventas"
            action="none"
            show={mostrarBusquedaItems}
            cliente={
              datosEdicion.cliente !== null
                ? datosEdicion.cliente
                : config.rucConsumidorFinal
            }
            onCancel={ocultarBusquedaItems}
            returnItem={onModalItemsItemSelected}
            modulo={EModulosAcatha.Proformas}
          />
        )}
        {/* {tab.editStatus === StatesEdition.save && verFacturaPdf && */}
        {reporte !== null && infoReporte !== null && tipoImpresion !== null && (
          <ReportWithLocalDataModal
            show={reporte !== null}
            onClose={() => {
              setReporte(null)
              setTipoImpresion(null)
            }}
            data={[infoReporte]}
            fileName={
              tipoImpresion === TypeFormat.standard
                ? 'StaticStandardProform'
                : 'StaticTicketProform'
            }
            mode={reporte ?? 'Viewer'}
            parameters={{ Local: localSession?.nombre ?? '' }}
            template={
              tipoImpresion === TypeFormat.standard
                ? 'StaticStandardProform'
                : 'StaticTicketProform'
            }
            key="reportDesigner"
          />
        )}

        {mostrarEdicionItems && (
          <ModificaItem
            transaccion={'ventas'}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false)
              setCodigoBarrasItemEditar('')
              if (codigoBarrasItemEditar === '') {
                //nuevo
                const items =
                  await ProformaService.getItemPorCodigoBarrasPedido(
                    codigoBarras,
                    cliente?.identificacion ?? config.rucConsumidorFinal,
                    DateUtils.pickersDateToApiDate(fechaSolicitud),
                  )
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0])
                }
              }
            }}
          />
        )}

        <BlockUi
          loader={LoadingIndicator}
          tag="div"
          blocking={loader.show}
          message={loader.mensaje}
        >
          <fieldset>
            <ValidationGroup
              id={`valGroupOrden${props.tabId}`}
              ref={validationGroupRef}
            >
              {!isMobileOnly && (
                <RowContainer>
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              )}

              <div>
                {empresa?.codigoPais === ECountry.ElSalvador && (
                  <RowContainer>
                    <CustomCol xs="12" className="d-flex justify-content-end">
                      <strong> {getTipoComprobante()} </strong>
                    </CustomCol>
                  </RowContainer>
                )}
                <RowContainer>
                  <CustomCol md="6" lg="6">
                    <RowContainer>
                      <div>
                        <RowContainer>
                          <CustomCol xs="12">
                            <Labeled label="Cliente">
                              <BuscarClienteLookUp
                                disabled={false}
                                selected={cliente}
                                onChanged={cambiarClienteSeleccionado}
                                allowAdd
                                allowClear
                                allowEdit
                              />
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                        {cliente && (
                          <RowContainer>
                            <CustomCol xs="12">
                              <Labeled label="Dirección">
                                <TextBox
                                  value={cliente.direccion}
                                  readOnly
                                  className="inputPedidos"
                                />
                              </Labeled>
                            </CustomCol>
                            <CustomCol xs="4">
                              <Labeled label="Teléfono">
                                <TextBox
                                  readOnly
                                  value={cliente.telefono ?? ''}
                                  className="inputPedidos"
                                />
                              </Labeled>
                            </CustomCol>
                            <CustomCol xs="8">
                              <Labeled label="Correo eléctronico">
                                <TextBox
                                  readOnly
                                  value={cliente.email ?? ''}
                                  className="inputPedidos"
                                />
                              </Labeled>
                            </CustomCol>
                          </RowContainer>
                        )}
                      </div>
                    </RowContainer>
                  </CustomCol>
                  <CustomCol md="6" lg="6">
                    <RowContainer>
                      <CustomCol xs="5" md="3" lg="3">
                        <Labeled label="Nro. Proforma #">
                          <TextBox
                            disabled={datosEdicion.numero !== ''}
                            placeholder="######"
                            value={numero}
                            className="inputPedidos"
                          />
                        </Labeled>
                      </CustomCol>

                      <CustomCol xs="5" md="3" lg="3">
                        <Labeled label="Nro. Orden">
                          <TextBox
                            disabled={String(datosEdicion.codigo) !== ''}
                            placeholder="######"
                            value={String(codigo)}
                            className="inputPedidos"
                          />
                        </Labeled>
                      </CustomCol>

                      <CustomCol xs="12" md="6" lg="6">
                        <Labeled label="Vendedor">
                          <BuscarVendedorLookMemoUp
                            vendedor={vendedor}
                            tab={tab}
                            cambiarVendedorSeleccionado={
                              cambiarVendedorSeleccionado
                            }
                            disabled={false}
                            allowAdd
                            allowClear
                            allowEdit
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol xs="12" md="12" lg="6">
                        <Labeled label="Proyectos">
                          <ProjectsLookUp
                            onChanged={(data) => {
                              consoleService.log('data ', data)
                              dispatch(
                                updateProyecto({
                                  proyecto: data,
                                  key: tabId,
                                }),
                              )
                            }}
                            onChangedOptions={(data) => {
                              consoleService.log('data ', data)
                            }}
                            selected={proyecto}
                          />
                        </Labeled>
                      </CustomCol>

                      <CustomCol xs="12" md="12" lg="6">
                        <Labeled label="Local:">
                          <LocsLookUp
                            onChanged={(data) => {
                              consoleService.log('data ', data)
                              dispatch(
                                updateLocal({
                                  local: data,
                                  key: tabId,
                                }),
                              )
                            }}
                            onChangedOptions={(opt) => {
                              consoleService.log('localidad ', opt)
                            }}
                            selected={local}
                            provider={proyecto?.locales ?? []}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  </CustomCol>
                </RowContainer>

                <RowContainer>
                  <CCol xs="12" sm="12" md="12" className="mt-2 mb-2 ">
                    <ScrollView width="100%" height="100%">
                      <RowContainer>
                        <CCol>
                          <div className="buttonsContainer mb-1 mt-1">
                            {isMobileOnly && (
                              <Button
                                text="Agregar Detalle"
                                icon="add"
                                stylingMode="contained"
                                type="default"
                                onClick={() => {
                                  setAgregarDetalleMovil(true)
                                }}
                                className="me-1"
                              />
                            )}
                            <Button
                              style={buttonsProductoStyle}
                              text="Crear Producto / Servicio"
                              icon="file"
                              stylingMode="contained"
                              type="default"
                              onClick={onCrearProductoButtonClick}
                              elementAttr={{
                                id: 'btnCreateItem',
                              }}
                              className="me-1"
                            />
                            <Button
                              style={buttonsProductoStyle}
                              hint="Más opciones"
                              icon="more"
                              stylingMode="contained"
                              type="default"
                              onClick={onMasOpcionesButtonClick}
                              elementAttr={{ id: 'btnOptionsItems' }}
                              className="me-1"
                            />
                          </div>
                        </CCol>
                      </RowContainer>

                      {isMobileOnly && ( //Mobiles
                        <RowContainer>
                          <div style={{ paddingTop: '5px' }}>
                            <MUIDataTable
                              data={detalles}
                              columns={getColumnnasTablaDetalleProformas(
                                muiTableCellsChanged,
                                (row) => setFilaEliminar(row),
                              )}
                              options={OptionsTablaDetalleProformas}
                            />
                          </div>
                        </RowContainer>
                      )}

                      {isMobile &&
                        !isMobileOnly && ( //Tablets
                          <RowContainer>
                            <CCol xs="12">
                              <div
                                className="detallesTableContainer"
                                style={{
                                  maxHeight: `${window.innerHeight / 2}px`,
                                }}
                              >
                                <CustomSheet
                                  id="sheetDetalle"
                                  striped
                                  blankRows={2}
                                  columns={sheetColumns}
                                  data={detalles}
                                  editable={
                                    tab.editStatus !== StatesEdition.save
                                  }
                                  initializeNewRow={(col, value) => {
                                    const detalle: DetallePedido =
                                      new DetallePedido()
                                    if (col.name === 'descripcion') {
                                      const item = value as ItemProforma
                                      detalle.setItem(
                                        item,
                                        empresa?.precision ?? 4,
                                      )
                                    } else {
                                      detalle[col.name] = value
                                    }

                                    return detalle.getDetalle()
                                  }}
                                  onCellChanged={sheetOnCellsChanged}
                                  showRowNumber
                                  rowCommands={sheetCommands}
                                />
                              </div>
                            </CCol>
                          </RowContainer>
                        )}

                      {!isMobile && ( //Escritorio
                        <RowContainer>
                          <CCol xs="12">
                            <div
                              className="detallesTableContainer overflow-auto"
                              style={{
                                width:
                                  window.innerWidth > 900
                                    ? '100%'
                                    : `${window.innerWidth - 20}px`,
                                maxHeight: `${window.innerHeight / 2}px`,
                              }}
                            >
                              <CustomSheet
                                id="sheetDetalle"
                                blankRows={2}
                                striped
                                columns={sheetColumns}
                                data={detalles}
                                editable={
                                  empresa.codigoPais === ECountry.Ecuador
                                    ? tab.editStatus !== StatesEdition.save
                                    : true
                                }
                                initializeNewRow={(col, value) => {
                                  const detalle: DetallePedido =
                                    new DetallePedido()
                                  if (col.name === 'descripcion') {
                                    const item = value as ItemProforma
                                    detalle.setItem(
                                      item,
                                      empresa?.precision ?? 2,
                                    )
                                  } else {
                                    detalle[col.name] = value
                                  }
                                  return detalle.getDetalle()
                                }}
                                onCellChanged={sheetOnCellsChanged}
                                showRowNumber
                                rowCommands={sheetCommands}
                              />
                            </div>
                          </CCol>
                        </RowContainer>
                      )}
                    </ScrollView>
                  </CCol>
                </RowContainer>

                <RowContainer>
                  <CustomCol xs="12" md={usaDescuentoGlobal ? '4' : '8'}>
                    <Labeled label="Observaciones">
                      <TextArea
                        value={observaciones}
                        onValueChanged={(data) => {
                          if (data.event !== undefined) {
                            dispatch(
                              updateObservaciones({
                                observaciones: data.value,
                                key: tabId,
                              }),
                            )
                          }
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                  {usaDescuentoGlobal && (
                    <>
                      <CustomCol xs={3} md={2} lg={2}>
                        <Labeled label="Desc ($)">
                          <CFormInput
                            name="inputDescuentoXItemValor"
                            type="number"
                            defaultValue={datosEdicion?.descuentoGlobal ?? 0}
                            onBlur={(ev) => {
                              if (ev.currentTarget.value) {
                                establecerDescuentoGlobal(
                                  parseFloat(ev.currentTarget.value),
                                )
                              } else {
                                establecerDescuentoGlobal(
                                  datosEdicion.descuentoGlobal,
                                )
                              }
                            }}
                          />
                        </Labeled>
                      </CustomCol>
                    </>
                  )}
                </RowContainer>

                {!isMobile && (
                  <RowContainer>
                    <div className="totalesContainer">
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>Subtotal</span>
                        </div>
                        <div className="totalValue">
                          <span>
                            {' '}
                            {(
                              datosEdicion.totales.subtotal -
                              datosEdicion.totales.descuentoGloabal
                            ).toFixed(2)}{' '}
                          </span>
                        </div>
                      </div>
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>Subtotal 0%</span>
                        </div>
                        <div className="totalValue">
                          <span>
                            {datosEdicion.totales.subtotal0.toFixed(2)}{' '}
                          </span>
                        </div>
                      </div>
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>I.C.E</span>
                        </div>
                        <div className="totalValue">
                          <span>{datosEdicion.totales.ice.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>Subtotal IVA</span>
                        </div>
                        <div className="totalValue">
                          <span>
                            {' '}
                            {datosEdicion.totales.subtotalIva.toFixed(2)}{' '}
                          </span>
                        </div>
                      </div>
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>IVA {iva}%</span>
                        </div>
                        <div className="totalValue">
                          <span> {datosEdicion.totales.iva.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>Desc. Items</span>
                        </div>
                        <div className="totalValue">
                          <span>
                            {' '}
                            {datosEdicion.totales.descuento.toFixed(2)}{' '}
                          </span>
                        </div>
                      </div>
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>Total</span>
                        </div>
                        <div className="totalValue">
                          <span> {datosEdicion.totales.total.toFixed(2)} </span>
                        </div>
                      </div>
                    </div>
                  </RowContainer>
                )}
                {isMobile && (
                  <>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>Subtotal</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>
                          {datosEdicion.totales.subtotal.toFixed(2)}
                        </small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>Subtotal 0%</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>
                          {datosEdicion.totales.subtotal0.toFixed(2)}
                        </small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>ICE</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>{datosEdicion.totales.ice.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>Subtotal IVA</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>
                          {datosEdicion.totales.subtotalIva.toFixed(2)}
                        </small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>Impuestos</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>{datosEdicion.totales.iva.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>Descuento Items</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>
                          {datosEdicion.totales.descuento.toFixed(2)}
                        </small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="8">
                        <small>Total</small>
                      </CustomCol>
                      <CustomCol xs="4">
                        <small>{datosEdicion.totales.total.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                  </>
                )}
              </div>

              {isMobileOnly && (
                <RowContainer>
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              )}
            </ValidationGroup>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
}

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null
    },
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false
    },
    getDataEditor: (data) => {
      return data == null
        ? SheetPedidosDescripcionItemSelectEditor
        : SheetTextEditor
    },
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleProformaRedux) =>
      `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`,
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleProformaRedux) =>
      `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(
        2,
      )})`,
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    //getDisplay: (value, data: DetalleVentaRedux) => data._precioIva.toFixed(4)
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleProformaRedux) =>
      data._subtotal.toFixed(2),
  },
]

const defaultSeller = {
  comision: 0,
  completo: 'Elejir un vendedor',
  estado: '',
  localCodigo: '',
  siglas: '',
  usuarioApellidos: '',
  usuarioCodigo: 0,
  usuarioEmail: '',
  usuarioIdentificacion: '',
  usuarioNombres: '',
  usuarioTelefono: '',
  vendedorCodigo: 0,
  vendedorZona: '',
}

export const defaulDatosEdicionOrdenPedido: OrdenProformaDatosEdicion = {
  codigo: 0,
  numero: '',
  cliente: null,
  detalles: [],
  dpvendedores: [],
  vendedor: null,
  fechaSolicitud: DateUtils.getCurrentDateAsString(),
  fechaEntrega: DateUtils.dateToString(new Date(), 'dd/MM/yyyy HH:mm'),
  observaciones: '',
  total: 0,
  loader: {
    mensaje: 'Cargando...',
    show: true,
  },
  loading: true,
  imprimir: false,
  tieneError: false,
  mensajeError: '',
  mostrarBusquedaItems: false,
  mostrarEdicionItems: false,
  desdePlantilla: false,
  totales: {
    descuento: 0,
    descuentoGloabal: 0,
    ice: 0,
    iva: 0,
    subtotal: 0,
    subtotal0: 0,
    subtotalIva: 0,
    total: 0,
  },
  descuentoGlobal: 0,
  iva: 0,
  proyecto: null,
  local: null,
}

export const OrdenProformaButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: true,
  Credito: true,
  Extraer: false,
  Descuento: true,
}

const buttonsProductoStyle = { marginLeft: '2px' }

export default React.memo(Proforma)
