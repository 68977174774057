import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DocumentoOrigenFacturacion,
  FacturaDatosEdicion,
  TTotalesFactura,
} from '../../../pages/ventas/types/types'
import { changeLoader, setLoadSummaryDocs } from '../../../../../store/reducers'
import { RootState } from '../../../../../store/store'
import {
  AutorizacionDocumentoVenta,
  InfoDocumentoVentaAfectar,
  ItemVenta,
  TipoIngresoDocumentos,
  VentaListado,
} from '../../../types/types'
import { VentasService } from '../../../services/ventas.service'
import { ClientesService } from '../../../services/clientes.service'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { VentaHelper } from '../../../pages/ventas/ventaHelper'
import { StatesEdition } from '../../../types/enums'
import config from '../../../../../config/config'
import { addToast } from '../../../../../store/toasterReducer'
import {
  TiposComprobantesSLV,
  TiposComprobantesSri,
  ToastTypes,
} from '../../../../../store/types'
import {
  DetalleVenta,
  DetalleVentaRedux,
} from '../../../pages/ventas/types/detalleVenta'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { VerRIDE } from '../../verRide/verRide'
import AgregarDetalleVentaMobile from '.././../ventas/venta/agregarDetalleVentaMobile'
import ModificaItem from '../../items/modificarItem'
import MainModalItems from '../../items/busquedaItemsModal/MainModalItems'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { CAlert, CBadge, CButton, CCol, CRow } from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import MUIDataTable from 'mui-datatables'
import {
  getColumnnasTablaDetalleVentas,
  OptionsTablaDetalleVentas,
} from './helper'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn'
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer'
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs'
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow'
import { SheetDescripcionItemSelectEditor } from '../../ventas/sheetEditors/sheetDescripcionItemSelectEditor'
import { SheetTextEditor } from '../../ventas/sheetEditors/sheetTextEditor'
import { defaultDatosEdicionFactura } from '../../ventas/venta/venta'
import {
  changeEditStatus,
  clearButtonClick,
  closeTab,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,
} from '../../../pages/notasCredito/store/tabsReducer'
import {
  clearDatosEdicion,
  setDatosEdicion,
  setEditLoader,
  updateDetalles,
  updateObservaciones,
  updateTotales,
  updateDocumentoOrigen,
  setMuestraError,
  setClaveAccesoGuardada,
  setImprimir,
  addDetalle,
  setFormaPago,
  updateFechaNegociacionOrigen,
  updateFechaEmision,
} from '../../../pages/notasCredito/store/editDataReducer'
import DocumentoVentaAfectar from '../../busquedaDocumentoVentaAfectar/documentoVentaAfectar'
import VisualizaError from '../../../pages/shared/visualizaError/visualizaError'
import { TNotaCreditoIngresar } from '../../../pages/notasCredito/types/types'
import { InfoNotaDebitoDetalleIngresar } from '../../../pages/notasDebito/types/types'
import { CustomButtons, DocumentInfo, TabState } from '../../../types/generics'
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor'
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor'
import { v4 as uuidv4 } from 'uuid'
import {
  DateUtils,
  formatoFechasApi,
  formatoFechasDatePickers,
} from '../../../../../helpers/dateUtils'
import Barcode from '../../../../../views/componentes/barcode/barcode'
import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet'
import BuscarClienteLookUp from '../../busquedaCliente/busquedaCliente'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import Button from 'devextreme-react/button'
import PopupReenvioMail from '../../ventas/busquedaVentas/popupReenvioMail/index'
import { AnularDocumentoVenta } from '../../../pages/shared/anularDocumentoVenta/anularDocumentoVenta'
import TextBox from 'devextreme-react/text-box'
import DateBox from 'devextreme-react/date-box'
import TextArea from 'devextreme-react/text-area'
import { ComprobantesService } from '../../../../../containers/component/infoEmergente/comprobantes.service'
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import { Validator, RequiredRule, AsyncRule } from 'devextreme-react/validator'
import { NumberBox } from 'devextreme-react'
import { useNavigate } from 'react-router-dom'
import { XMLDisplay } from '../../../../../views/componentes/XMLDisplay/XMLDisplay'
import { RequestHelper } from '../../../../../helpers/requestHelper'

interface INotaCreditoProps extends React.PropsWithChildren {
  info: DocumentInfo<VentaListado>
  tabId: string
  tab: TabState<VentaListado>
}

const NotaCredito: React.FunctionComponent<INotaCreditoProps> = (props) => {
  const { tabId, info, tab } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const local = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  //const currentTab = useSelector((state: RootState) => { return state.ventas.notasCredito.tabs.tabs[props.tabIndex] });
  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId]
  })
  const claveAcceso = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].claveAcceso
  })
  const imprimir = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].imprimir
  })
  const detalles = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].detalles
  })
  const loading = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].loading
  })
  const cliente = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].cliente
  })
  const fecha = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].fecha
  })
  const documentoOrigen = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].documentoOrigen
  })
  const autorizacionVenta = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].autorizacionVenta
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const formasPago = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].formasPago
  })
  const formaPago = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].formaPago
  })
  const tieneError = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].tieneError
  })
  const mensajeError = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].mensajeError
  })
  const observaciones = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].observaciones
  })
  const iva = useSelector((state: RootState) => {
    return state.ventas.notasCredito.editData[props.tabId].iva
  })
  const cuotasVencidas = useSelector(
    (state: RootState) => state.suscripcionUpgrate.cuotasVencidas,
  )
  const currentButtons = useSelector((state: RootState) => {
    return state.ventas?.notasCredito?.tabs?.tabs[props.tabId]?.buttons
  })

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const [filaEliminar, setFilaEliminar] = React.useState(-1)
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false)
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false)
  const [mostrarBusquedaItems, setMostrarBusquedaItems] = React.useState(false)
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('')
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{
    claveAcceso: string
    design: boolean
  }>()
  const [verSeleccionDocumentos, setVerSeleccionDocumentos] =
    React.useState(false)
  const [modalXML, setModalXML] = React.useState<{
    show: boolean
    urlBlobXml: Blob | null
    urlXml: Blob | null
    fileName: string
  }>({
    show: false,
    urlBlobXml: null,
    urlXml: null,
    fileName: '',
  })

  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false)
  const [mostrarAnular, setMostrarAnular] = React.useState(false)
  const [enableEdition, setEnableEdition] = React.useState<boolean>(false)
  const [tipoIngresoDocumento, setTipoIngresoDocumento] =
    React.useState<TipoIngresoDocumentos>(TipoIngresoDocumentos.seleccion)
  const [controlErrores, setControlErrores] = React.useState<{
    tieneErrores: boolean
    errores: Array<string> | []
  }>({ tieneErrores: false, errores: [] })

  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleVentaRedux>> = []
    //fas fa-file-invoice-dollar
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: 'minus',
      color: 'danger',
      onExecute: (rowIndex) => {
        setFilaEliminar(rowIndex)
      },
    })
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: 'edit',
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true)
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras)
      },
    })
    return commads
  }, [detalles])

  const XMLModalMemo = React.useMemo(() => {
    if (modalXML === undefined || modalXML === null) {
      return
    }

    if (!modalXML?.show) {
      return
    }

    return (
      <XMLDisplay
        show={modalXML?.show}
        urlBlobPdf={modalXML?.urlBlobXml}
        urlXml={modalXML?.urlXml}
        fileName={modalXML?.fileName}
        onClose={() => {
          setModalXML({
            ...modalXML,
            show: false,
          })
        }}
      />
    )
  }, [modalXML])

  const showEditLoader = React.useCallback(
    (mensaje: string) => {
      dispatch(
        setEditLoader({
          key: tabId,
          info: {
            mensaje: mensaje,
            show: true,
          },
        }),
      )
    },
    [dispatch, tabId],
  )
  const hideEditLoader = React.useCallback(() => {
    dispatch(
      setEditLoader({
        key: tabId,
        info: {
          mensaje: '',
          show: false,
        },
      }),
    )
  }, [dispatch, tabId])

  const cambiarDocumentoManualFacturacion = React.useCallback(
    async (documento: DocumentoOrigenFacturacion) => {
      showEditLoader('Cargando documento...')
      const cliente = await ClientesService.getCliente(
        config.rucConsumidorFinal,
      )
      const formasPago = await VentasService.getFormasPago(
        cliente.identificacion,
      )
      const formaPago = formasPago.length > 0 ? formasPago[0] : undefined

      setTipoIngresoDocumento(TipoIngresoDocumentos.manual)
      dispatch(
        updateDocumentoOrigen({
          key: tabId,
          documento: documento,
          cliente: cliente,
          detalles: [],
          formaPago: formaPago,
          formasPago,
        }),
      )

      setEnableEdition(true)

      hideEditLoader()
    },
    [dispatch, tabId, hideEditLoader, showEditLoader],
  )

  const cambiarDocumentoOrigenFacturacion = React.useCallback(
    async (documento: DocumentoOrigenFacturacion) => {
      showEditLoader('Cargando documento...')
      //const numero = documento.numero.split('-')[2];
      // const _ventas = await VentasService.getVentas(documento.comprobante, "", "", "", numero,
      //   undefined, undefined, undefined, undefined, undefined, undefined, undefined, TiposComprobantesSri.Factura);
      setTipoIngresoDocumento(TipoIngresoDocumentos.seleccion)

      const _venta = await VentasService.getVenta(documento.comprobante) //_ventas.find(x => x.comprobante == documento.comprobante);
      console.log('_venta', _venta)

      if (_venta) {
        const cliente = await ClientesService.getCliente(
          _venta?.clienteIdentificacion,
        )
        const formasPago = await VentasService.getFormasPago(
          cliente.identificacion,
        )
        const formaPago = formasPago.find(
          (fp) => fp.codigo === _venta.formaPago,
        )
        const _detallesApi = await VentasService.getDetalleVenta(
          _venta.codigo,
          TiposComprobantesSri.Factura,
        )

        console.log('_detallesApi', _detallesApi)

        const _detalles = VentaHelper.detallesToDetallesVenta(
          _detallesApi,
          empresa?.precision ?? 4,
        )

        console.log('_detalles', _detalles)

        let mostrarAlertaDescuento = false

        console.log('_detalles 1', _detalles)

        for (let index = 0; index < _detalles.length; index++) {
          const _detalle = _detalles[index]
          if (_detalle._descuento > 0) {
            mostrarAlertaDescuento = true
          }
          const tmpDetallle = new DetalleVenta()
          tmpDetallle.setDetalle(_detalle, empresa?.precision ?? 4)
          tmpDetallle.porcentajeDescuento = 0
          _detalles[index] = tmpDetallle.getDetalle()
        }

        console.log('_detalles 2', _detalles)

        if (mostrarAlertaDescuento) {
          dispatch(
            addToast({
              title: 'Acatha',
              content: 'Tipo de documento no admite descuentos',
              type: ToastTypes.Danger,
            }),
          )
        }

        dispatch(
          updateDocumentoOrigen({
            key: tabId,
            documento: documento,
            cliente: cliente,
            detalles: _detalles,
            formaPago: formaPago,
            formasPago,
          }),
        )
      }
      hideEditLoader()
    },
    [dispatch, tabId, showEditLoader, hideEditLoader, empresa],
  )

  const cargarVenta = React.useCallback(
    async (numero: number, setInitData: boolean) => {
      if (loading === false) {
        return
      }
      const data = { ...defaultDatosEdicionFactura } //utilidades.unfreeze(defaultDatosEdicionFactura);
      try {
        if (setInitData)
          dispatch(
            setDatosEdicion({
              data: { ...data },
              key: tabId,
            }),
          )
        //const ventas = await VentasService.getVentas(0, "", "", "", "", "", "", undefined, clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.NotaCredito);
        const venta = await VentasService.getVenta(numero)
        if (venta) {
          const detalles = await VentasService.getDetalleVenta(
            numero,
            TiposComprobantesSri.NotaCredito,
          )
          const cliente = await ClientesService.getCliente(
            venta.clienteIdentificacion,
          )
          const formasPago = await VentasService.getFormasPago(
            cliente.identificacion,
          )
          const iva = await VentasService.getIva(venta.fecha, null)
          data.iva = iva
          data.establecimiento = venta.establecimiento
          data.puntoEmision = venta.puntoEmision
          data.numerofactura = venta.numero
          data.observaciones = venta.observaciones

          const formaPago = formasPago.find((x) => x.codigo === venta.formaPago)
          data.formasPago = formasPago
          if (formaPago) {
            data.formaPago = [formaPago]
          }
          data.cliente = cliente
          const tmpDet2 = VentaHelper.detallesToDetallesVenta(
            detalles,
            empresa?.precision ?? 4,
          )
          data.detalles = tmpDet2
          const fechaAutorizacion = venta.claveAccesoFecha ?? ''
          data.fechaAutorizacion = fechaAutorizacion
          data.autorizacion = venta.claveAcceso ?? ''
          data.autorizacionVenta = await VentasService.getAutorizacion(
            puntoVenta ?? '',
            TiposComprobantesSri.NotaCredito,
          )
          data.claveAcceso = venta.claveAcceso ?? '' //ventas[0].claveAcceso;
          data.fecha = DateUtils.apiDateToPickersDate(venta.fecha) //ventas[0].fecha;
          data.descuentoGlobal = venta.descuentoGlobal // ventas[0].descuento_global;
          data.loader = {
            mensaje: '',
            show: false,
          }
          data.loading = false
          if (venta.ventaAfectaNumero) {
            data.documentoOrigen = {
              comprobante: venta.codigo,
              tipoDoc: '01',
              fechaEmision: '',
              fechaNegociacion: '',
              numero: `${venta.ventaAfectaEstablecimiento}-${venta.ventaAfectaPtoEmision}-${venta.ventaAfectaNumero}`,
              totalDocumento: venta.ventaAfectaTotal ?? 0,
              formaPagoCodigo: venta.ventaAfectaFormaPagoCodigo ?? 0,
            }
          }
          dispatch(
            setDatosEdicion({
              data: data,
              key: tabId,
            }),
          )

          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...CreditNoteButtons,
                Guardar: false,
                Autorizar: venta.claveAccesoFecha === '',
                Enviar: venta.claveAccesoFecha !== '',
                Deshacer: false,
                Descuento: false,
              },
            }),
          )
        }
      } catch (error) {
        data.loading = false

        data.tieneError = true
        data.mensajeError = error
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
      }
    },
    [dispatch, tabId, puntoVenta, loading, empresa],
  )

  const modoNuevo = React.useCallback(
    async (puntoVenta: string, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      sessionStorage.removeItem('autorizacionModulo')

      const data = { ...defaultDatosEdicionFactura }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...CreditNoteButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
          },
        }),
      )
      try {
        //setLoading(true);
        const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers) //  moment().format('yyyy-MM-DD');
        const formasPago = await VentasService.getFormasPago('9')

        let autorizacion: AutorizacionDocumentoVenta
        if (!autorizacionVenta) {
          autorizacion = await VentasService.getAutorizacion(
            puntoVenta,
            TiposComprobantesSri.NotaCredito,
          )
        } else {
          autorizacion = autorizacionVenta
        }

        sessionStorage.setItem(
          'autorizacionModulo',
          JSON.stringify(autorizacion),
        )

        const iva = await VentasService.getIva(
          DateUtils.pickersDateToApiDate(fecha),
          autorizacion,
        )
        //const cliente = await ClientesService.getCliente(config.rucConsumidorFinal);
        //data.cliente = cliente;
        data.autorizacion = ''
        data.autorizacionVenta = autorizacion
        data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)'
        data.detalles = []
        data.iva = iva
        data.fecha = fecha
        data.establecimiento = autorizacion.establecimiento
        data.puntoEmision = autorizacion.ptoemision
        data.numerofactura = autorizacion.numActual.toString()
        data.identificadorTransaccion = uuidv4()
        data.observaciones = ''
        data.formasPago = formasPago
        const fp = formasPago.find(
          (fp) => fp.descripcion.toUpperCase() === 'EFECTIVO',
        )
        if (fp) {
          data.formaPago = [fp]
          //setFormaPago([fp]);
        }
        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
        // dispatch(setDatosEdicion({
        //   data: data,
        //   key: props.tabId
        // }));
        setVerSeleccionDocumentos(true)
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )
    },
    [dispatch, tabId, loading, autorizacionVenta],
  )

  const modoEdicion = React.useCallback(() => {
    if (datosEdicion == null || datosEdicion.cliente == null) {
      return
    }
    if (
      datosEdicion.claveAcceso.length === 37 ||
      datosEdicion.claveAcceso.length === 49
    ) {
      dispatch(
        addToast({
          autoHide: true,
          content: 'Una Nota Crédito eléctronica no puede ser editada.',
          fade: true,
          id: '',
          title: 'Editar Nota Crédito',
          type: ToastTypes.Info,
        }),
      )
    }
  }, [dispatch, datosEdicion])

  const onVerXML = React.useCallback(
    async (item: FacturaDatosEdicion) => {
      try {
        dispatch(
          changeLoader({
            mensaje: 'Cargando...',
            show: true,
          }),
        )

        const responseXML = await RequestHelper.getAll<any>(
          'ventas',
          'obtenerJson',
          '',
          {
            selloRecibido: item.claveAcceso,
            ext: '.xml',
          },
        )

        const blobForPreview = new Blob([responseXML], {
          type: 'application/xml',
        })
        const blobForDownload = new Blob([responseXML], {
          type: 'application/xml',
        })

        setModalXML({
          show: true,
          urlBlobXml: blobForPreview,
          urlXml: blobForDownload,
          fileName: `${item.claveAcceso}.xml`,
        })

        dispatch(
          changeLoader({
            mensaje: '',
            show: false,
          }),
        )
      } catch (error) {
        dispatch(
          changeLoader({
            mensaje: '',
            show: false,
          }),
        )
        dispatch(
          addToast({
            content: 'Lo siento, algo salió mal al recuperar el archivo XML.',
            type: ToastTypes.Warning,
          }),
        )
        setModalXML({
          show: false,
          urlBlobXml: null,
          urlXml: null,
          fileName: '',
        })
      }
    },
    [dispatch],
  )

  const isDetall = React.useCallback(async (params) => {
    if (params.value && Number(params.value) > 0) {
      return Promise.resolve()
    } else {
      return Promise.reject('La Nota de Crédito no tiene detalles')
    }
  }, [])

  const guardar = React.useCallback(async () => {
    if (
      usuario == null ||
      empresa == null ||
      local == null ||
      puntoVenta == null
    ) {
      return
    }

    let mensajes: Array<string> = []

    const validationResult = validationGroupRef.current.instance.validate()

    if (!validationResult.isValid) {
      if (validationResult.brokenRules) {
        mensajes = validationResult.brokenRules.map((x) => {
          return x.message
        })
      }
    }

    if (datosEdicion.detalles.length === 0) {
      mensajes.push('La Nota de Crédito no tiene detalles')

      dispatch(
        addToast({
          autoHide: true,
          content: 'La Nota de Crédito no tiene detalles',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (datosEdicion.totales.total === 0) {
      mensajes.push('La Nota de Crédito no puede ser por un valor igual 0')
      dispatch(
        addToast({
          autoHide: true,
          content: 'La Nota de Crédito no puede ser por un valor igual 0',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (!datosEdicion.cliente) {
      mensajes.push('Debe de seleccionar el cliente')

      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Debe de seleccionar el cliente',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (
      datosEdicion.formaPago === null ||
      datosEdicion.formaPago.length === 0 ||
      datosEdicion.formaPago[0] == null
    ) {
      mensajes.push('Debe de seleccionar la forma de pago')

      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Debe de seleccionar la forma de pago',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (tipoIngresoDocumento === TipoIngresoDocumentos.seleccion) {
      if (
        parseFloat(datosEdicion.totales.total.toFixed(2)) >
        (datosEdicion.documentoOrigen?.totalDocumento ?? 0)
      ) {
        mensajes.push(
          `El total de la nota de credito (${datosEdicion.totales.total.toFixed(
            2,
          )}) es mayor que el de la factura (${
            datosEdicion.documentoOrigen?.totalDocumento
          }).`,
        )

        dispatch(
          addToast({
            content: `El total de la nota de credito (${datosEdicion.totales.total.toFixed(
              2,
            )}) es mayor que el de la factura (${
              datosEdicion.documentoOrigen?.totalDocumento
            }).`,
            type: ToastTypes.Warning,
            autoHide: 3000,
          }),
        )
        return
      }
    }

    if (mensajes.length > 0) {
      dispatch(
        addToast({
          content: `Tiene errores en la nota de credito, por favor reviarlo y vuelva a intentar.`,
          type: ToastTypes.Warning,
          autoHide: 3000,
        }),
      )
      setControlErrores({ tieneErrores: true, errores: mensajes ?? [] })
      return
    }

    try {
      let fechaNeg: string = ''
      if (
        datosEdicion.documentoOrigen &&
        datosEdicion.documentoOrigen?.fechaNegociacion !== ''
      ) {
        fechaNeg = DateUtils.pickersDateToApiDate(
          datosEdicion.documentoOrigen?.fechaNegociacion,
        )
      }
      showEditLoader('Guardando Nota de Crédito...')

      // verifica deudas
      // cuotasVencidas

      let valorSaldoVencidas: number = 0
      if (cuotasVencidas.length > 0) {
        cuotasVencidas.forEach((key: any) => {
          valorSaldoVencidas += key.saldo
        })
        if (valorSaldoVencidas > 0) {
          valorSaldoVencidas =
            Number(parseFloat(valorSaldoVencidas.toFixed(2))) + 0
        }
      }
      if (valorSaldoVencidas > 0) {
        hideEditLoader()
        dispatch(
          addToast({
            autoHide: true,
            content: lh.getMessage(MessagesKeys.GenericPasDue, ''),
            fade: true,
            title: 'Deudas vencidas.',
            type: ToastTypes.Warning,
          }),
        )
        navigate('/past_due_payments')
        return false
      }

      // verificar plan activo
      const contrato = await ComprobantesService.getRecurrente(empresa.uuid)
      if (contrato.codigo && contrato.planActivo === false) {
        dispatch(setLoadSummaryDocs(true))
        hideEditLoader()
        dispatch(
          addToast({
            autoHide: true,
            content: lh.getMessage(MessagesKeys.GenericSuscriptionEnd, ''),
            fade: true,
            title: 'Renovar Suscripción.',
            type: ToastTypes.Warning,
          }),
        )
        return false
      }

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.save,
        }),
      )

      const totalCero = {
        iva5: 0,
      }

      const totales = datosEdicion.detalles.reduce((sum, next) => {
        if (next.codigoImpuesto === 5) {
          sum.iva5 += next._iva
        }
        return sum
      }, totalCero)

      const notaCredito: TNotaCreditoIngresar = {
        notaCredito: {
          pventa: puntoVenta,
          infoNotaCredito: {
            codDocModificado: datosEdicion.documentoOrigen?.tipoDoc ?? '',
            numDocModificado: datosEdicion.documentoOrigen?.numero ?? '',
            fechaEmision: DateUtils.pickersDateToApiDate(datosEdicion.fecha),
            fechaNegociacion: fechaNeg ?? '',
            fechaEmisionDocSustento: '',
            valorModificacion: parseFloat(
              datosEdicion.totales.total.toFixed(2),
            ),
            totalSinImpuestos: parseFloat(
              datosEdicion.totales.subtotal.toFixed(2),
            ),
            totalConImpuestos: {
              totalImpuesto: [
                {
                  codigo: '2',
                  codigoPorcentaje: '0',
                  baseImponible: parseFloat(
                    datosEdicion.totales.subtotal0.toFixed(2),
                  ),
                  valor: 0,
                },
                {
                  codigo: '2',
                  codigoPorcentaje: '2',
                  baseImponible: parseFloat(
                    datosEdicion.totales.subtotalIva.toFixed(2),
                  ),
                  valor: parseFloat(datosEdicion.totales.iva.toFixed(2)),
                },
                {
                  codigo: '2',
                  codigoPorcentaje: '5',
                  baseImponible: parseFloat(
                    datosEdicion.totales.subtotal5.toFixed(2),
                  ),
                  valor: parseFloat(totales.iva5.toFixed(2)),
                },
              ],
            },
            observaciones: observaciones,
            motivo: 'Devolución y/o descuento',
          },
          detalles: {
            detalle: datosEdicion.detalles.map((detalle) => {
              const _detalle: InfoNotaDebitoDetalleIngresar = {
                codigoInterno: detalle.codigoInterno,
                codigoAdicional: detalle.codigoBarras,
                cantidad: detalle._cantidad,
                descripcion: detalle.descripcion,
                precioTotalSinImpuesto: detalle._subtotal,
                precioUnitario: detalle._precioUnitario,
                impuestos: {
                  impuesto: [
                    {
                      codigo: detalle.tieneIva ? 3 : 2,
                      codigoPorcentaje: detalle.tieneIva ? 2 : 0,
                      baseImponible: detalle._subtotal0,
                      tarifa: parseFloat(
                        (detalle.porcentajeiva * 100).toFixed(0),
                      ),
                      valor: detalle._iva,
                    },
                  ],
                },
              }
              return _detalle
            }),
          },
          pagos: [
            {
              medio: datosEdicion.formaPago[0].descripcion,
              total: parseFloat(datosEdicion.totales.total.toFixed(2)),
            },
          ],
          tipoIngreso: tipoIngresoDocumento,
          cliente: cliente ?? null,
          local: local ?? null,
        },
      }
      const resultado = await VentasService.ingresarNotaCredito(notaCredito)
      // const ingresada = await VentasService.getVenta(resultado.comprobante);//VentasService.getVentas(0, "", "", "", "", "", "", undefined, resultado.clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.NotaCredito);
      // const listado = await VentasService.getVentas(ingresada?.codigo ?? 0, "", "", "", ingresada?.numero, undefined, undefined, undefined, undefined, undefined, ingresada?.establecimiento, ingresada?.puntoEmision, TiposComprobantesSri.NotaCredito);

      dispatch(
        setClaveAccesoGuardada({
          key: tabId,
          claveAcceso: resultado.claveAcceso,
          imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false,
          fechaAutorizacion: resultado.fechaAutorizacion,
        }),
      )

      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          info: resultado,
          buttons: {
            ...CreditNoteButtons,
            Guardar: false,
            Editar: true,
            Deshacer: false,
            Autorizar: resultado.estadoElectronico !== 'AUTORIZADO',
            Enviar: resultado.estadoElectronico === 'AUTORIZADO',
            Anular: resultado.estadoElectronico === 'AUTORIZADO',
          },
        }),
      )

      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Guardado',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Success,
        }),
      )
      //await cargarVenta(resultado.clave_acceso, false);
    } catch (error) {
      //console.log(error);
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al guardar nota de credito ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
    }
    hideEditLoader()
  }, [
    dispatch,
    hideEditLoader,
    showEditLoader,
    observaciones,
    tabId,
    datosEdicion,
    empresa,
    local,
    puntoVenta,
    usuario,
    tipoIngresoDocumento,
    cliente,
    navigate,
    cuotasVencidas,
  ])

  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      const tId: any = tabId

      dispatch(closeTab(tId))
      dispatch(clearDatosEdicion(tId))
    } else {
      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          buttons: {
            ...CreditNoteButtons,
            Guardar: false,
            Editar: true,
            Imprimir: true,
          },
        }),
      )
      cargarVenta(datosEdicion.numero, false)
    }
  }, [dispatch, tabId, info, cargarVenta, datosEdicion.numero])

  const sheetOnCellsChanged = React.useCallback(
    async (arrayOfChanges: Array<CellChangedArgs<DetalleVentaRedux>>) => {
      const _detalles = detalles.slice(0)
      for (const change of arrayOfChanges) {
        const detalle = new DetalleVenta() //clase que realiza los calculos para un detalle
        detalle.setDetalle(change.rowData, empresa?.precision ?? 4)

        let fecha = ''
        if (detalle.fecha !== '') {
          fecha = DateUtils.pickersDateToApiDate(detalle.fecha)
        } else {
          fecha = DateUtils.getCurrentDateAsString()
        }

        switch (change.col.name) {
          case 'codigoBarras':
            showEditLoader('Buscando item...')
            // eslint-disable-next-line no-case-declarations
            const item = await VentasService.getItemPorCodigoBarras(
              detalle.codigoBarras,
              datosEdicion.cliente?.identificacion ?? config.rucConsumidorFinal,
              fecha,
            )

            if (item) {
              detalle.setItem(item, empresa?.precision ?? 4)
              hideEditLoader()
            } else {
              dispatch(
                addToast({
                  content:
                    'No se encuentra el item con código ' + change.newValue,
                  title: 'Agregar item',
                  type: ToastTypes.Warning,
                  autoHide: 2000,
                }),
              )
              continue
            }
            break
          case 'cantidad':
            detalle.cantidad = change.newValue
            detalle.calcular()
            break
          case 'descripcion':
            if (typeof change.newValue === 'string') {
              detalle.descripcion = change.newValue
            }
            break
          case '_cantidad':
            detalle.cantidad = parseFloat(change.newValue)
            //detalle.calcular();
            break
          case '_porcentajeDescuento':
            detalle.porcentajeDescuento = parseFloat(change.newValue)
            //detalle.calcular();
            break
          case '_precioUnitario':
            detalle.precio = parseFloat(change.newValue)
            //detalle.calcular();
            break
          case '_precioIva':
            detalle.precioIva = parseFloat(change.newValue)
            //detalle.calcular();
            break
          default:
            break
        }
        if (change.rowIndex === -1) {
          //nueva fila
          _detalles.push(detalle.getDetalle())
        } else {
          _detalles[change.rowIndex] = detalle.getDetalle()
        }
      }
      dispatch(
        updateDetalles({
          detalles: _detalles,
          key: tabId,
        }),
      )
      hideEditLoader()
    },
    [
      dispatch,
      hideEditLoader,
      showEditLoader,
      tabId,
      detalles,
      datosEdicion.cliente,
      empresa,
    ],
  )

  const calcularTotales = React.useCallback(
    (detalles: Array<DetalleVentaRedux>) => {
      const totalCero: TTotalesFactura = {
        descuento: 0,
        descuentoGloabal: 0,
        ice: 0,
        iva: 0,
        subtotal: 0,
        subtotal0: 0,
        subtotalIva: 0,
        total: 0,
        subtotal5: 0,
      }
      const totales = detalles.reduce((sum, next) => {
        sum.subtotal += next._subtotal
        sum.subtotal0 += next._subtotal0
        sum.subtotal5 += next._subtotal5
        sum.subtotalIva += next._subtotalIva
        sum.iva += next._iva
        sum.descuento += next._descuento
        sum.total += next._total

        return sum
      }, totalCero)

      dispatch(
        updateTotales({
          totales: totales,
          key: tabId,
        }),
      )
      //setTotales(totales);
    },
    [dispatch, tabId],
  )

  const agregarDetalleVentaMobile = React.useCallback(
    (detalle: DetalleVenta) => {
      const _detalles = detalles.slice(0)
      _detalles.push(detalle.getDetalle())
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
      setAgregarDetalleMovil(false)
    },
    [dispatch, tabId, detalles],
  )

  const buscarClienteOnChanged = React.useCallback(
    async (value) => {
      console.error(
        'No se puede cambiar el cliente de una nota de crédito',
        value,
      )

      if (value.codigo) {
        const formasPago = await VentasService.getFormasPago(
          value.identificacion,
        )

        const data: any = { ...datosEdicion }
        if (value) {
          data.cliente = { ...value }
        }
        if (formasPago.length > 0) {
          data.formasPago = formasPago ?? []
          formasPago[0] !== undefined ? [formasPago[0]] : undefined
        }
        data.observaciones = `${'NOTA DE CRéDITO que afecta a FACTURA '} ${
          datosEdicion?.documentoOrigen?.numero ?? ''
        }`
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
      }
    },
    [dispatch, tabId, datosEdicion],
  )

  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...')
      await VentasService.reintentarAutorizacion(
        claveAcceso,
        TiposComprobantesSri.NotaCredito,
      )
      dispatch(
        addToast({
          content: 'El comprobante fue enviado al sri para su autorización.',
          type: ToastTypes.Info,
        }),
      )
    } catch (error) {
      if (typeof error == 'string') {
        dispatch(
          addToast({
            content: error,
            type: ToastTypes.Danger,
          }),
        )
      }

      //dispatch( )
    }
    hideEditLoader()
  }, [claveAcceso, showEditLoader, hideEditLoader, dispatch])

  const handlePrint = React.useCallback(
    (design: boolean) => {
      setVerFacturaPdf({ claveAcceso: claveAcceso, design: design })
    },
    [claveAcceso],
  )

  const muiTableCellsChanged = React.useCallback(
    (row, col, newValue) => {
      const _detalles = detalles.slice(0)
      //const detalle = _detalles[row];
      const _detalle = new DetalleVenta()
      _detalle.setDetalle(_detalles[row], empresa?.precision ?? 4)

      //const dt = datosEdicion.detallesMuiTable;
      switch (col) {
        case 2: //TABLECOL_DESCRIPCION
          _detalle.descripcion = newValue
          //dt[row].descripcion = newValue;
          break
        case 3: //TABLECOL_CANTIDAD
          _detalle.cantidad = parseFloat(newValue)
          //dt[row].cantidad = newValue ? parseFloat(newValue) : 0;
          break
        case 4: //TABLECOL_PRECIO
          _detalle.precio = parseFloat(newValue)
          //dt[row].precio = newValue ? parseFloat(newValue) : 0;
          break
        default:
          break
      }
      _detalles[row] = _detalle.getDetalle()
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
    },
    [dispatch, tabId, detalles, empresa],
  )

  const onModalItemsItemSelected = React.useCallback(
    (item: ItemVenta) => {
      const detalle: DetalleVenta = new DetalleVenta()
      detalle.setItem(item, empresa?.precision ?? 4)
      detalle.cantidad = 1
      dispatch(
        addDetalle({
          key: tabId,
          detalle: detalle.getDetalle(),
        }),
      )
      setMostrarBusquedaItems(false)
    },
    [tabId, empresa, dispatch],
  )

  //#region  anular
  const onAnular = React.useCallback(async () => {
    if (info.info == null || info === undefined) {
      dispatch(
        addToast({
          content:
            'No se encuentra la información del documento, comunicar a los desarrolladores.',
          type: ToastTypes.Danger,
        }),
      )
      return
    }
    try {
      setMostrarAnular(false)
      showEditLoader('Anulando Comprobante...')
      await VentasService.anularDocumento(info.info)
      dispatch(
        addToast({
          content: 'El documento fue anulado correctamente.',
          type: ToastTypes.Success,
        }),
      )
      dispatch(setTabInfoAsInactive(tabId))
    } catch (error) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al anular ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
    } finally {
      hideEditLoader()
    }
  }, [info, tabId, showEditLoader, hideEditLoader, dispatch])

  const onCancelAnular = React.useCallback(() => {
    setMostrarAnular(false)
  }, [])
  //#endregion

  //#region handle Buttons
  const buttonClickAnular = React.useCallback(() => {
    if (info && info.info?.estadoElectronico !== 'AUTORIZADO') {
      dispatch(
        addToast({
          content:
            'La Nota de Crédito no está autorizada, debe autorizarce antes de proceder a la anulación.',
          type: ToastTypes.Warning,
        }),
      )
    }
    setMostrarAnular(true)
  }, [info, dispatch])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          modoEdicion()
          break
        case ButtonTypes.save: {
          guardar()
          break
        }
        case ButtonTypes.undo:
          deshacer()
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        case ButtonTypes.sendMail:
          setShowReenviarMail(true)
          break
        case ButtonTypes.authorizations:
          reenviarAutorizacion()
          break
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? '', true)
          break
        case ButtonTypes.disable:
          buttonClickAnular()
          break
        case ButtonTypes.xml:
          onVerXML(datosEdicion)
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      deshacer,
      dispatch,
      guardar,
      buttonClickAnular,
      handlePrint,
      modoEdicion,
      modoNuevo,
      tabId,
      puntoVenta,
      reenviarAutorizacion,
      onVerXML,
      datosEdicion,
    ],
  )

  //#endregion

  const onObservacionesChanged = React.useCallback(
    ({ value }: any) => {
      dispatch(
        updateObservaciones({
          key: tabId,
          observaciones: value ?? '',
        }),
      )
    },
    [tabId, dispatch],
  )

  const onFormaPagoChanged = React.useCallback(
    (newValue) => {
      dispatch(
        setFormaPago({
          formaPago: newValue,
          key: tabId,
        }),
      )
    },
    [tabId, dispatch],
  )

  const onFechaNegociacionChanged = React.useCallback(
    ({ value }: any) => {
      dispatch(
        updateFechaNegociacionOrigen({
          key: tabId,
          documentoOrigen: documentoOrigen,
          fechaNegociacion: value ?? '',
        }),
      )
    },
    [tabId, dispatch, documentoOrigen],
  )

  const onFechaEmisionChanged = React.useCallback(
    (data) => {
      if (data?.value !== null) {
        dispatch(
          updateFechaEmision({
            key: tabId,
            fechaEmision: data?.value ?? '',
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const isReadonlyDate = React.useCallback(() => {
    if (
      empresa?.editarFechaEmision &&
      Number(empresa?.editarFechaEmision) > 0
    ) {
      return false
    }
    return true
  }, [empresa])

  const enableJsonButton = React.useCallback(() => {
    const enable =
      datosEdicion?.claveAcceso?.length > 0 &&
      datosEdicion?.fechaAutorizacion?.length > 0

    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...currentButtons,
          [ButtonTypes.xml]: enable,
        },
      }),
    )
  }, [currentButtons, datosEdicion, dispatch, tabId])

  //#region efectos
  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? '')
    } else {
      cargarVenta(info.numero, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
      //dispatch(clearButtonClick(tabIndex))
    }
  }, [handleButtonClick, tab, dispatch])

  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles)
    }
  }, [loading, detalles, calcularTotales])

  React.useEffect(() => {
    if (!datosEdicion?.fechaAutorizacion?.includes('NUEVO')) {
      enableJsonButton()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEdicion?.fechaAutorizacion])

  React.useEffect(() => {
    const eliminarDetalle = (
      indice: number,
      detalles: Array<DetalleVentaRedux>,
    ) => {
      detalles.splice(indice, 1)
      dispatch(
        updateDetalles({
          detalles: detalles,
          key: tabId,
        }),
      )
      setFilaEliminar(-1)
    }

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice]
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcion}?`,
        actions: [
          Dialog.Action(
            <span>
              <u>E</u>liminar
            </span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0))
            },
            'btn-success',
            'e',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              setFilaEliminar(-1)
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
          setFilaEliminar(-1)
        },
      })
      return
    }
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar)
    }
  }, [dispatch, tabId, filaEliminar, detalles])

  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false)
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  //#endregion

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={datosEdicion.loader.show}
        message={datosEdicion.loader.mensaje}
      >
        {info.info !== null && mostrarAnular && (
          <AnularDocumentoVenta
            onOk={onAnular}
            onCancel={onCancelAnular}
            info={info.info}
          />
        )}
        {showReenviarMail && cliente != null && (
          <PopupReenvioMail
            showConfirm={showReenviarMail}
            itemReenviarMail={{
              claveAcceso: claveAcceso,
              cliente: cliente,
            }}
            closeConfirm={() => setShowReenviarMail(false)}
          />
        )}
        {tieneError && (
          <VisualizaError
            titulo="Error en Nota de crédito"
            mensaje={mensajeError}
            onOk={() => {
              dispatch(
                setMuestraError({
                  key: props.tabId,
                  tieneError: false,
                  mensajeError: '',
                }),
              )
              if (tab.editStatus === StatesEdition.new) {
                deshacer()
              }
            }}
          />
        )}
        {tab.editStatus === StatesEdition.save &&
          verFacturaPdf &&
          verFacturaPdf && (
            <VerRIDE
              modo="Legacy"
              claveAcceso={verFacturaPdf.claveAcceso}
              vista={verFacturaPdf.design ? 'Designer' : 'Viewer'}
              onClose={() => {
                setVerFacturaPdf(undefined)
                dispatch(
                  setImprimir({
                    imprimir: false,
                    key: tabId,
                  }),
                )
              }}
            />
          )}
        {modalXML?.show && XMLModalMemo}
        {verSeleccionDocumentos && (
          <DocumentoVentaAfectar
            onCancel={() => {
              setVerSeleccionDocumentos(false)
            }}
            onOk={(documento: InfoDocumentoVentaAfectar) => {
              console.log(documento)
              if (documento.tipoIngreso === TipoIngresoDocumentos.manual) {
                if (
                  documento.tipoIngreso &&
                  documento.tipoIngreso === TipoIngresoDocumentos.manual
                ) {
                  cambiarDocumentoManualFacturacion({
                    comprobante: documento.codigo,
                    fechaEmision: DateUtils.format(
                      documento.fecha,
                      formatoFechasApi,
                    ),
                    fechaNegociacion: DateUtils.getCurrentDateAsString(
                      formatoFechasDatePickers,
                    ),
                    formaPagoCodigo: documento.formaPagoCodigo,
                    numero: documento.numero,
                    tipoDoc: documento.tipoComprobante,
                    totalDocumento: documento.total,
                  })
                }
              } else {
                console.log('else')
                cambiarDocumentoOrigenFacturacion({
                  comprobante: documento.codigo,
                  fechaEmision: DateUtils.format(
                    documento.fecha,
                    formatoFechasApi,
                  ),
                  fechaNegociacion: '',
                  formaPagoCodigo: documento.formaPagoCodigo,
                  numero: documento.numero,
                  tipoDoc: documento.tipoComprobante,
                  totalDocumento: documento.total,
                })
              }

              setVerSeleccionDocumentos(false)
            }}
            tipo={TiposComprobantesSri.NotaCredito}
          />
        )}

        {agregarDetalleMovil && (
          <AgregarDetalleVentaMobile
            key="agregarDetalleVentaMovil"
            onOk={agregarDetalleVentaMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            cliente={datosEdicion.cliente}
            fecha={datosEdicion.fecha}
          />
        )}
        <Dialog ref={dialogRef} />
        {mostrarEdicionItems && (
          <ModificaItem
            transaccion={'ventas'}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false)
              setCodigoBarrasItemEditar('')
              if (codigoBarrasItemEditar === '') {
                //nuevo
                const items = await VentasService.buscarItemCodigoBarras(
                  codigoBarras,
                  cliente?.identificacion ?? config.rucConsumidorFinal,
                  DateUtils.pickersDateToApiDate(fecha),
                )
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0])
                }
              }
            }}
          />
        )}
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion="ventas"
            action="none"
            show={mostrarBusquedaItems}
            cliente={
              datosEdicion.cliente !== null
                ? datosEdicion.cliente
                : config.rucConsumidorFinal
            }
            onCancel={() => setMostrarBusquedaItems(false)}
            returnItem={onModalItemsItemSelected}
          />
        )}
        <fieldset
          data-tut="sectionTutComprobante"
          disabled={tab.editStatus === StatesEdition.save}
        >
          <div>
            <ValidationGroup
              id={`valGroupNotas${props.tabId}`}
              ref={validationGroupRef}
            >
              {!isMobileOnly && (
                <RowContainer className="mt-2 mb-2">
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              )}
              <RowContainer>
                <CustomCol xs="12" md="7">
                  <div>
                    <RowContainer>
                      <CustomCol xs="12">
                        <Labeled label="Nombre / Razón Social del cliente">
                          <BuscarClienteLookUp
                            // disabled={tab.editStatus === StatesEdition.save}
                            disabled={!enableEdition}
                            selected={cliente}
                            onChanged={buscarClienteOnChanged}
                            allowEdit
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {cliente && (
                      <RowContainer>
                        <CustomCol xs="12">
                          <Labeled label="Dirección">
                            <TextBox value={cliente.direccion} readOnly />
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="4">
                          <Labeled label="Teléfono">
                            <TextBox readOnly value={cliente.telefono ?? ''} />
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="8">
                          <Labeled label="Correo eléctronico">
                            <TextBox readOnly value={cliente.email ?? ''} />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    )}
                  </div>
                </CustomCol>
                <CustomCol xs="12" md="5">
                  <div>
                    <RowContainer>
                      <CustomCol xs="12" md="5">
                        <Labeled label="Fecha">
                          <DateBox
                            value={fecha}
                            readOnly={isReadonlyDate()}
                            onValueChanged={(d) => {
                              if (d?.event !== undefined) {
                                onFechaEmisionChanged(d)
                              }
                            }}
                            max={DateUtils.getCurrentDateAsString(
                              formatoFechasDatePickers,
                            )}
                          >
                            <Validator>
                              <RequiredRule
                                message={lh.getMessage(
                                  MessagesKeys.GlobalCampoRequerido,
                                  'Fecha',
                                )}
                              />
                            </Validator>
                          </DateBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" md="7">
                        <Labeled label="Número">
                          <CRow>
                            <CCol xs="3">
                              <TextBox
                                readOnly
                                placeholder="001"
                                value={datosEdicion.establecimiento}
                              />
                            </CCol>
                            <CCol xs="3">
                              <TextBox
                                readOnly
                                placeholder="001"
                                value={datosEdicion.puntoEmision}
                              />
                            </CCol>
                            <CCol xs="6">
                              <TextBox
                                readOnly
                                placeholder="0000000000"
                                value={
                                  '0'.repeat(
                                    10 -
                                      datosEdicion.numerofactura.toString()
                                        .length,
                                  ) + datosEdicion.numerofactura.toString()
                                }
                              />
                            </CCol>
                          </CRow>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="6" md="3">
                        <Labeled label="# Detalles">
                          <TextBox
                            readOnly
                            value={datosEdicion.detalles.length.toString()}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="6" md="3">
                        <Labeled label="# Articulos">
                          <TextBox
                            readOnly
                            value={detalles
                              .reduce((prev, next) => prev + next._cantidad, 0)
                              .toFixed(2)}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" md="6">
                        <Labeled label="Punto Venta">
                          <TextBox readOnly text={puntoVenta ?? ''} />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {tab.editStatus !== StatesEdition.save && ( //Electronica
                      <RowContainer>
                        <CustomCol>
                          <Labeled label="Comprobante al que afecta">
                            {datosEdicion.documentoOrigen && (
                              <span>{`FACTURA ${datosEdicion.documentoOrigen?.numero}`}</span>
                            )}
                            {!datosEdicion.documentoOrigen && (
                              <Button
                                icon="find"
                                text="Buscar"
                                type="default"
                                stylingMode="outlined"
                                onClick={() => {
                                  setVerSeleccionDocumentos(true)
                                }}
                              />
                            )}
                          </Labeled>
                        </CustomCol>
                        {datosEdicion.documentoOrigen &&
                          documentoOrigen?.fechaNegociacion &&
                          documentoOrigen.fechaNegociacion !== '' && (
                            <CustomCol>
                              <Labeled label="Negociación">
                                <DateBox
                                  value={documentoOrigen.fechaNegociacion}
                                  onValueChanged={onFechaNegociacionChanged}
                                />
                              </Labeled>
                            </CustomCol>
                          )}
                      </RowContainer>
                    )}

                    {tab.editStatus === StatesEdition.save && ( //Electronica
                      <>
                        <RowContainer>
                          <CustomCol xs="12" lg="6">
                            {!isMobileOnly && (
                              <Barcode text={datosEdicion.autorizacion} />
                            )}
                            {isMobileOnly && (
                              <Labeled label="Autorización">
                                <small>{datosEdicion.autorizacion}</small>
                              </Labeled>
                            )}
                          </CustomCol>
                        </RowContainer>
                        <RowContainer>
                          <CustomCol>
                            <Labeled label="Fecha Autorización">
                              <CBadge
                                color={
                                  datosEdicion.fechaAutorizacion
                                    ? 'info'
                                    : 'danger'
                                }
                              >
                                {datosEdicion.fechaAutorizacion
                                  ? datosEdicion.fechaAutorizacion
                                  : 'No Autorizado'}
                              </CBadge>
                            </Labeled>
                          </CustomCol>
                          <CustomCol>
                            <Labeled label="Comprobante al que afecta">
                              {datosEdicion.documentoOrigen && (
                                <span>{`FACTURA ${datosEdicion.documentoOrigen?.numero}`}</span>
                              )}
                              {!datosEdicion.documentoOrigen && (
                                <CButton
                                  onClick={() => {
                                    setVerSeleccionDocumentos(true)
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                  Buscar
                                </CButton>
                              )}
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                      </>
                    )}
                  </div>
                </CustomCol>
              </RowContainer>
              {tab.editStatus !== StatesEdition.save && (
                <RowContainer>
                  <CCol>
                    <div className="buttonsContainer">
                      {isMobileOnly && (
                        <Button
                          text="Agregar Detalle"
                          icon="add"
                          stylingMode="contained"
                          type="default"
                          onClick={() => {
                            setAgregarDetalleMovil(true)
                          }}
                        />
                      )}
                      <Button
                        className="mb-1"
                        style={{ marginLeft: '2px' }}
                        text="Crear Producto"
                        icon="file"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setCodigoBarrasItemEditar('')
                          setMostrarEdicionItems(true)
                        }}
                      />
                      <Button
                        className="mb-1"
                        style={{ marginLeft: '2px' }}
                        hint="Más opciones"
                        icon="more"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setMostrarBusquedaItems(true)
                        }}
                      ></Button>
                    </div>
                  </CCol>
                </RowContainer>
              )}
              {isMobileOnly && ( //Mobiles
                <RowContainer>
                  <div style={{ paddingTop: '5px' }}>
                    <MUIDataTable
                      data={detalles}
                      columns={getColumnnasTablaDetalleVentas(
                        muiTableCellsChanged,
                        (row) => setFilaEliminar(row),
                      )}
                      options={OptionsTablaDetalleVentas}
                    />
                    <NumberBox
                      inputAttr={{
                        hidden: true,
                      }}
                      value={datosEdicion.detalles.length}
                    >
                      <Validator>
                        <RequiredRule
                          message={'La Nota de Crédito no tiene detalles'}
                        />
                        <AsyncRule
                          message={'La Nota de Crédito no tiene detalles'}
                          reevaluate={false}
                          validationCallback={(params) => isDetall(params)}
                        />
                      </Validator>
                    </NumberBox>
                  </div>
                </RowContainer>
              )}
              {isMobile &&
                !isMobileOnly && ( //Tablets
                  <RowContainer>
                    <CCol xs="12">
                      <div
                        className="detallesTableContainer"
                        style={{ maxHeight: `${window.innerHeight / 2}px` }}
                      >
                        <CustomSheet
                          striped
                          id="sheetDetalle"
                          blankRows={2}
                          columns={sheetColumns}
                          data={detalles}
                          editable={tab.editStatus !== StatesEdition.save}
                          initializeNewRow={(col, value) => {
                            const detalle: DetalleVenta = new DetalleVenta()
                            if (col.name === 'descripcion') {
                              const item = value as ItemVenta
                              detalle.setItem(item, empresa?.precision ?? 4)
                            } else {
                              detalle[col.name] = value
                            }

                            return detalle.getDetalle()
                          }}
                          onCellChanged={sheetOnCellsChanged}
                          showRowNumber
                          rowCommands={sheetCommands}
                        />
                        <NumberBox
                          inputAttr={{
                            hidden: true,
                          }}
                          value={datosEdicion.detalles.length}
                        >
                          <Validator>
                            <RequiredRule
                              message={'La Nota de Crédito no tiene detalles'}
                            />
                            <AsyncRule
                              message={'La Nota de Crédito no tiene detalles'}
                              reevaluate={false}
                              validationCallback={(params) => isDetall(params)}
                            />
                          </Validator>
                        </NumberBox>
                      </div>
                    </CCol>
                  </RowContainer>
                )}

              {!isMobile && ( //Escritorio
                <RowContainer>
                  <CCol xs="12">
                    <div
                      className="detallesTableContainer overflow-auto"
                      onKeyDown={(e) => {}}
                      style={{
                        width:
                          window.innerWidth > 900
                            ? '100%'
                            : `${window.innerWidth - 20}px`,
                        maxHeight: `${window.innerHeight / 2}px`,
                      }}
                    >
                      <CustomSheet
                        striped
                        id="sheetDetalle"
                        blankRows={2}
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: DetalleVenta = new DetalleVenta()
                          if (col.name === 'descripcion') {
                            const item = value as ItemVenta
                            detalle.setItem(item, empresa?.precision ?? 4)
                          } else {
                            detalle[col.name] = value
                          }

                          return detalle.getDetalle()
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}
                      />
                      <NumberBox
                        inputAttr={{
                          hidden: true,
                        }}
                        value={datosEdicion.detalles.length}
                      >
                        <Validator>
                          <RequiredRule
                            message={'La Nota de Crédito no tiene detalles'}
                          />
                          <AsyncRule
                            message={'La Nota de Crédito no tiene detalles'}
                            reevaluate={false}
                            validationCallback={(params) => isDetall(params)}
                          />
                        </Validator>
                      </NumberBox>
                    </div>
                  </CCol>
                </RowContainer>
              )}

              <RowContainer>
                <CustomCol xs="12" md={8}>
                  <Labeled label="Observaciones">
                    <TextArea
                      value={observaciones}
                      onValueChanged={onObservacionesChanged}
                    />
                  </Labeled>
                </CustomCol>

                <CustomCol xs="12" md="3">
                  <Labeled label="Forma Pago">
                    <SelectBox
                      id="selectFormaPago"
                      displayExpr="descripcion"
                      keyExpr="codigo"
                      options={formasPago}
                      selected={
                        formaPago && formaPago.length > 0 ? formaPago[0] : null
                      }
                      placeholder="Forma de pago"
                      multiple={false}
                      onChange={onFormaPagoChanged}
                      disabled={tab.editStatus === StatesEdition.save}
                      clearButton={true}
                      invalid={formaPago.length === 0}
                    >
                      <Validator>
                        <RequiredRule
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Forma Pago',
                          )}
                        />
                      </Validator>
                    </SelectBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {!isMobile && (
                <RowContainer>
                  <div className="totalesContainer">
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal 0%</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {datosEdicion.totales.subtotal0.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal IVA</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {' '}
                          {datosEdicion.totales.subtotalIva.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Descuento</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {' '}
                          {datosEdicion.totales.descuento.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>

                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {' '}
                          {datosEdicion.totales.subtotal.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>IVA {iva}%</span>
                      </div>
                      <div className="totalValue">
                        <span> {datosEdicion.totales.iva.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Total</span>
                      </div>
                      <div className="totalValue">
                        <span> {datosEdicion.totales.total.toFixed(2)} </span>
                      </div>
                    </div>
                  </div>
                </RowContainer>
              )}
              {isMobile && (
                <>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.subtotal.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal 0%</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>ICE</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.ice.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal IVA</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>
                        {datosEdicion.totales.subtotalIva.toFixed(2)}
                      </small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Impuestos</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.iva.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Total</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.total.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                </>
              )}

              {isMobileOnly && (
                <>
                  <RowContainer className="ms-2">
                    <CustomCol>
                      <ValidationSummary />
                    </CustomCol>
                  </RowContainer>
                  {controlErrores.tieneErrores === true &&
                    controlErrores.errores.length > 0 && (
                      <PopupContent
                        show={controlErrores.errores.length > 0}
                        title={'Acatha'}
                        subTitle={
                          'Por favor revise las siguientes advertencias:'
                        }
                        onClose={() => {
                          setControlErrores({
                            tieneErrores: false,
                            errores: [],
                          })
                        }}
                        size={'sm'}
                        canClose={true}
                      >
                        <CAlert color="danger">
                          <ul>
                            {controlErrores.errores.map(function (item, id) {
                              return <li key={id}>{item}</li>
                            })}
                          </ul>
                        </CAlert>
                      </PopupContent>
                    )}
                </>
              )}
            </ValidationGroup>
          </div>
        </fieldset>
      </BlockUi>
    </div>
  )
}

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null
    },
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    },
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleVentaRedux) =>
      `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`,
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return true
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) =>
      `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(
        2,
      )})`,
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    //getDisplay: (value, data: DetalleVentaRedux) => data._precioIva.toFixed(4)
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._subtotal.toFixed(4),
  },
]

export const CreditNoteButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Descuento: true,
  Enviar: true,
  Autorizar: true,
}

export default React.memo(NotaCredito)
