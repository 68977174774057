import { combineReducers } from '@reduxjs/toolkit'
import { TActividadesEconomicas } from './types'
import { menuReducer } from './menuReducer'
import { tabsReducer } from './tabsReducer'
import { dataEditionReducer } from './editDataReducer'
import { searchReducer } from './searchReducer'

export const CtlgActividadesEconomicasReducer =
  combineReducers<TActividadesEconomicas>({
    menu: menuReducer,
    tabs: tabsReducer,
    editData: dataEditionReducer,
    search: searchReducer,
  })
