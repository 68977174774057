import {
  AccionMenu,
  LoaderInfo,
  TiposComprobantesSLV,
  TiposComprobantesSri,
} from '../../../store/types'
import { WindowLiquidacionState, WindowVentasState } from './generics'
import {
  ConfiguracionesVentas,
  DocumentoOrigenFacturacion,
  FacturaDatosEdicion,
  TAutorizacionHaciendaSV,
} from '../pages/ventas/types/types'
import {
  RetencionDatosEdicion,
  RetencionventaListado,
} from '../pages/retenciones/types/types'
import { ProveedorBusqueda } from '../../proveedores/store/types'
import { CuadresState } from '../pages/cuadres/types/types'
import { OrdenesState } from '../pages/ordenes/type/types'
import { ProformaState } from '../pages/proformas/type/types'
import { NotasCreditoState } from '../pages/notasCredito/types/types'
import { NotasDebitoState } from '../pages/notasDebito/types/types'
import { GuiasState } from '../pages/guiasRemision/types/types'
import { TModuloFacturacionSV } from '../pages/ventas/facturacionSV/types/types'
import { TS_Auth } from './facturacion/elSalvador/types'
import { ParametrosState } from '../pages/parametros/types/types'

/**
 * Estado del módulo de ventas en redux
 */
export type ModuloVentasState = {
  ventas: VentasState
  ventasSV: TModuloFacturacionSV
  notasCredito: NotasCreditoState
  notasDebito: NotasDebitoState
  guiasRemision: GuiasState
  retenciones: WindowVentasState<RetencionventaListado, RetencionDatosEdicion>
  liquidaciones: WindowLiquidacionState
  cuadres: CuadresState
  ordenes: OrdenesState
  proformas: ProformaState
  // liquidacionesDeCompra: {}
  parametros: ParametrosState
}

export type VentasState = WindowVentasState<
  VentaListado,
  FacturaDatosEdicion
> & {
  configuraciones: ConfiguracionesVentas
}

export type MenuState = {
  loading: boolean
  accions: Array<AccionMenu>
}
export type DocRelacionado = {
  codigo: number
  codigoGeneracion: string
  numeroDocumento: string
}
export type FiltroBusquedaState = {
  fechaInicio: string
  fechaFin: string
  cliente: Cliente | null
  puntoVenta: string
  documento: string
  pendientesAbutorizar?: boolean
  formaPago: FormaPagoModuloVentas | null
  estado: EstadoComprobante | null
  establecimiento: EstablecimientoSri | null
  puntoEmision: PuntoEmisionSri | null
  activas: boolean
  institucionesFinancieras?: boolean
  proveedor?: ProveedorBusqueda
  claveAcceso?: string
  nombreAlterno?: string
  tipoDoc: TiposComprobantesSri | TiposComprobantesSLV | null
}

export type SaveSucursales = {
  codigo: number
  codigoCliente: number
  codigoTelefono: number
  codigoCiudad: number
  codigoZona: number
  direccion: string
  email: string
  referencia: string
}

export type Cliente = {
  codigo: number
  identificacion: string
  nombres: string
  nombreComercial: string | null
  direccion: string
  telefono: string | null
  email: string | null
  descuento: number
  ciudad: string
  ciudadCodigo: number
  tipoCliente: number
  tipoIdentificacion: string
  nrc?: string //  exclusivo, EL Salvador
  dui?: string //  exclusivo, EL Salvador
  tipoNegocio?: number | null
  sucursales?: Array<SaveSucursales> | []
}

export type VentaDetalleInfo = {
  codigo: number
  codigoInterno: string
  codigoBarras: string
  descripcion: string
  numeroSerie: number
  lotes: number
  cantidad: number
  descuento: number // des %
  descuentoValor: number // desc el $
  pvd: number
  pvp: number
  valor: number
  impuesto: number
  porcentajeIva: number
  pvpiva: number
  local: number
  ice: number
  imagenes: Array<string>
}

export type VentaInfo = {
  codigo: number
  tipoDocumento: string
  tipoDocDescripcion: string
  numero: string
  autorizacion: string
  establecimiento: string
  puntoEmision: string
  fecha: string
  fechaHoraEmision?: string
  guia: string
  subtotal12: number
  subtotal0: number
  descuento: number
  subtotal: number
  iva: number
  total: number
  formaPago: number
  formaPagoDescripcion: string
  impreso: boolean
  observaciones: string
  estado: number
  estadoDescripcion: string
  subtotalSinIva: number
  ice: number
  servicios: number
  costeoNotasCredito: number
  descuentoGlobal: number
  fechaN: number
  destinoTipo: number
  destinoCiudadCodigo: number
  destinoCiudadNombre: string | null
  fechaInicioRenta: string | null
  fechaFinRenta: string | null
  origenViene: number
  origenNumero: string | null
  pasajerosVenta: string | null
  multaVenta: number | null
  multaObservaciones: string | null
  origen: string
  numeroAsiento: number
  clienteCodigo: number
  clienteIdentificacion: string
  clienteNombre: string
  clienteRazonComercial: string
  clienteDireccion: string
  clienteTelefono: string
  clienteTipoIdentificacion: string
  clienteEmail: string
  clienteImpresion: string
  clienteRemitente: number
  clienteRemitenteIdentificacion: string | null
  clienteNombreRemitente: string | null
  clienteDireccionRemitente: string | null
  clienteTelefonoRemitente: string | null
  clienteDestinatario: number
  clienteDestinatarioIdentificacion: string | null
  clienteNombreDestinatario: string | null
  clienteDireccionDestinatario: string | null
  clienteTelefonoDestinatario: string | null
  medidorCodigo: number
  comunidadDescripcion: string | null
  sectoresDescripcion: string | null
  usuarioCodigo: number
  empresaCodigo: number
  localCodigo: number
  localNombre: string | null
  ptoVenCodigo: number
  ptoVenHost: string
  vendedorCodigo: number
  vendedorNombre: string | null
  retencionTotal: number
  retencionRenta: number
  retencionIva: number
  autorizacionCodigo: number
  otros: string | null
  sucursalCodigo: number
  saldo: number
  costo: number
  utilidadBruta: number
  claveAcceso: string | null
  claveAccesoFecha: string | null
  claveAccesoEstado: number
  contratoCodigo: number
  vehiculoCodigo: number
  vehiculoDisco: number
  vehiculoPlaca: string | null
  proveedorChofer: string | null
  proveedorSocio: string | null
  diasRenta: number
  nuevoInicioRenta: string | null
  nuevoDiasRenta: string | null
  colorFila: string | null
  movientoInventarioCodigo: number
  electronicoFacturaEstado: string
  proyectoDetalleCodigo: number
  comisionVentaCodigo: number
  puntoVentaEspecial: string
  ventaAfectaCodigo?: number
  ventaAfectaNumero?: string
  ventaAfectaEstablecimiento?: string
  ventaAfectaPtoEmision?: string
  ventaAfectaTotal?: number
  identificadorTransaccion?: string
  codigoGeneracion?: string
  ventaAfectaFormaPagoCodigo?: number
  ventaAfectaCodTipoDoc?: string
  ventaAfectaFecha?: string
  ventaAfectaClaveAcceso?: string
  autorizacionHacienda?: TAutorizacionHaciendaSV
  flete?: number | null
  seguro?: number | null
  valorRetenidoIva?: number | null
  valorRetenidoRenta?: number | null
  relacionado?: DocRelacionado | null
  docFechaAfecta?: string | null
  docModoAfectar?: number | null
  docNumeroAfectar?: string | null
  docTipoDocAfecta?: string | null
  documentoAfectar?: any
  fechaNegociacion?: any
  documentoAfectarAutorizacionHacienda?: {
    ambiente?: number
    clasificacionMensaje?: string
    claveAcceso?: null
    codigoGeneracion?: string
    codigoMensaje?: string
    descripcionMensaje?: string
    estado?: string
    fechaProcesamiento?: string
    observaciones?: any
    selloRecibido?: string
    tipoComprobante?: string
    version?: string
    versionApp?: string
  }
}

export type Contingencia = {
  tipoContingencia: 1 | 2 | 3 | 4 | 5
  motivo: string | null
}

export type TContingenciaRegistrada = {
  estado: string
  fechaHora: string
  mensaje: string
  observaciones: string[]
  selloRecibido: string
}

export type VentaListado = {
  comprobante: number
  identificador?: string
  tipoDocumentoDescripcion: string
  formaPagoCodigo?: number
  tipoDocumentoCodigo: TiposComprobantesSri | TiposComprobantesSLV
  guia?: string
  fechaNegociacion: string
  establecimiento: string
  servicios: number
  puntoEmision: string
  numero: string
  autorizacion: string
  fecha: string
  fechaHoraEmision?: string
  subtotalIva: number
  subtotal5: number
  subtotal0: number
  subtotalNoIva: number
  subtotal: number
  retTotal: number
  descuentos: number
  descuentoItems: number
  descuentoGlobal: number
  ice: number
  iva: number
  total: number
  clienteIdentificacion: string
  clienteNombre: string
  clienteRazonComercial: string
  asesorComercial?: string
  clienteDireccion: string
  clienteTelefono: string
  clienteEmail: string
  estado: string
  claveAcceso: string
  fechaAutorizacion: string
  saldo?: number
  asociados: number
  formaPago: string
  local: string
  puntoVenta: string
  observaciones: string
  pdf: string
  xml: string
  url: string
  items: number
  estadoElectronico: string
  autorizacionHacienda?: TAutorizacionDocumentoSV
  relacionado?: string
  docRelacionado?: DocRelacionado | null
  codigoRelacionado: number | null
  relacionadoCodigoDocumento?: number
  contingencia?: {
    fechaInicio: Date | string
    fechaFinal: Date | string
    observaciones: string
  }
  valorRetenidoIva?: number
  valorRetenidoRenta?: number
  tiposPagos?: any
  docFechaAfecta?: string
  docModoAfectar?: string
  docNumeroAfectar?: string
  docTipoDocAfecta?: string
  sucursalCodigo?: number
}
export type VentaListadoConFechaTransaccion = VentaListado & {
  fechaTransaccion: Date
}

export type ItemVenta = {
  codigo: number
  codigoInterno: string
  codigoBarras: string
  descripcion: string
  existencia: number
  pvdiva: number
  pvpiva: number
  pvd: number
  pvp: number
  codigoImpuesto: number
  tieneIce: boolean
  porcentajeIva: number
  costo: number
  tipoCodigo: number
  numeroSerie: number
  plantilla: number
  freeitem: number
  gradoAcoholico: number
  capacidad: number
  factor: number
  jerarquia: string | null
  descuentos: number
  titadicional: string
  valadicional: string
  grupo: string
  codigoGrupo: number
  codigoUnidadVenta: number
  tipoDescripcion: string
  reservas: number
  stockMin: number
  stockMax: number
}

export type TipoClasificacion = {
  codigo: number
  descripcion: string
}

export type TipoProveedor = {
  codigo: string
  descripcion: string
}

export type TipoCliente = {
  codigo: number
  descripcion: string
  estado: number
  estadoDescripcion: string
  porcentajeDescuento: number
}

export type FormaPagoModuloVentas = {
  codigo: number
  descripcion: string
}

export type TSucursalVentas = {
  codigo: number
  codigoCliente: number
  direccion: string
  referencia: string
  email: string
  telefonoCodigo: number
  codigoCiudad: number
  codigoZona: number
  descripcionZona: string
  estado: number
  ciudadNombre: string
  telefonoNumero: string
}

export type AutorizacionDocumentoVenta = {
  asociado?: string
  asociadoID?: number
  asociadoDes?: string
  aut: string | null
  codigoAutorizacion: number
  caducidad?: string
  codTributarioTipoDeNegocio: number
  codigoImpresora?: number
  compartida?: boolean
  copiasImp?: number
  descTipoDeNegocio: string
  desde?: string
  establecimiento: string
  estado?: boolean
  hasta?: string
  idTipoDeNegocio: number
  imprenta?: string
  numActual: number
  pertenece?: number
  perteneceDes?: string
  ptoemision: string
  codigoTIC: string
  comprobandeTIC: string
  especial: string
  restaurante: boolean
  rubro?: number
}

export type TAutorizacionModulo = AutorizacionDocumentoVenta

export type DocumentoRelacionado = {
  tipoDocumentoCod: string
  tipoDocumentoDes: string
  tipoGeneracion: number
  numeroDocumento: string
  fechaEmision: string
}

export type UnidadVenta = {
  codigo: number
  nombre: string
  siglas?: string
  grupoCodigo?: number
  grupoNombre?: string
  estadoCodigo?: number
  estadoNombre?: string
  fraccion?: number
}

export type TipoIva = {
  codigo: number
  descripcion: string
  valor: number
}

export type InfoDocumentosAutorizados = {
  comprobante: string
  totalEmitidos: number
  autorizados: number
  sinAutorizar: number
}

export type ItemventaDatosModificar = {
  codigo: number
  descripcion: string
  codigoBarras: string
  codigoUsu?: string
  pvp: number
  pvd: number
  codigoImpuesto: number
  tipoCodigo: number
  codigoUnidadVenta: number
  codigoLinea: number
  codigoGrupo: number
  codigoMarca: number
  iceTipoIce: number
  iceCodigoGradoAlcoholico: string
  iceGradoAlcoholico: number
  iceCapacidaCm3: number
  iceCodigoImpuesto: string
  iceCodigoClasificacion: string
  iceCodigoMarca: string
  iceEstado: string
  iceCodigoPresentacion: string
  iceCodigoUnidad: string
  iceGramosAzucar: number
  ice?: boolean
}

export type GuiaInfo = {
  relacionado: string
  codigoRelacionado: number
  codigo: number
  ventaCodigo: number
  clienteCodigo: number
  proveedorTransportistaCodigo: number
  numero: string
  autorizacion: string
  establecimiento: string
  puntoEmision: string
  fechaInicioTraslado: string
  fechaFinTraslado: string
  ordenIngreso: string
  fechaEmision: string
  fechaHoraEmision?: string
  nroDeclaracionAduanera: string
  motivoMovilizacion: string
  puntoPartida: string
  puntoLlegada: string
  proveedorPlacaTransportista: string
  observaciones: string
  puntoVentaCodigo: number
  estadoCodigo: number
  estadoDescripcion: string
  clienteIdentificacion: string
  clienteNombre: string
  clienteDireccion: string
  clienteTelefono: string
  clienteEmail: string
  proveedorTransportistaIdentificacion: string
  proveedorTransportistaNombre: string
  proveedorTransportistaDireccion: string
  proveedorTransportistaTelefono: string
  ventaNumero: string
  ventaAutorizacion: string
  ventaEstablecimiento: string
  ventaPuntoEmision: string
  claveAcceso: string
  fechaAutorizacion: string
  xml: string
  pdf: string
  url: string
  estadoElectronico: string
  tipoDocumentoCodigo?: TiposComprobantesSri.GuiaRemision
  numeroReferencia: number
  valorReferencia: number
  autorizacionHacienda?: TAutorizacionHaciendaSV
  puntoVenta: string
  identificador?: string
}

export type GuiaInfo2 = GuiaInfo & {
  fechaTransaccion: Date
}

export type GuiaDatosEdicionDetalle = {
  idItem: number
  codigoBarras: string
  descripcionItem: string
  cantidadItem: number
  valor?: number
  pvp?: number
  pvpiva?: number
  descuento?: number
  porcentajeIva?: number
  codigoImpuesto?: number
}

export type GuiaDatosEdicion = {
  identificadorTransaccion: string
  numeroControl?: string
  autorizacionHacienda?: TAutorizacionHaciendaSV
  claveAcceso: string
  codigoGeneracion?: string
  idGuia: number
  idVenta: number
  cliente: Cliente | null
  proveedor: ProveedorBusqueda | null
  numero: string
  guiaEstablecimiento: string
  guiaEmision: string
  fecha: string
  hora?: string
  fechaDesde: string
  fechaHasta: string
  motivo: string
  puntoPartida: string
  puntoLlegada: string
  placa: string
  observaciones: string
  despacho: number
  venta: number
  estadoSri: number
  detalles: Array<GuiaDatosEdicionDetalle>
  loader: LoaderInfo
  tieneError: boolean
  mensajeError: string | string[]
  autorizacion?: AutorizacionDocumentoVenta
  fechaAutorizacion: string
  establecimiento: string
  puntoEmision: string
  loading: boolean
  documentoOrigen: DocumentoOrigenFacturacion | null
  declaracionAduanera: string
  ordenIngreso: string
  imprimir: boolean
  tieneErroresValidar: boolean
  numeroReferencia: number | null
  valorReferencia: number | null
  relacionado?: string | null
  estadoElectronico?: string
}

export type TiposEstados = {
  codigo: string
  tipo: string
}

export type TiposOperadoras = {
  codigo: string
  tipo: string
}

export type TipoIdentificacion = {
  codigo: string
  tipo: string
  codTributario?: string
  descripcion?: string
}

export enum TipoIngresoDocumentos {
  // eslint-disable-next-line no-unused-vars
  manual = 'manual',
  // eslint-disable-next-line no-unused-vars
  seleccion = 'seleccion',
}

export type InfoDocumentoVentaAfectar = {
  codigo: number
  tipoComprobante: TiposComprobantesSri | TiposComprobantesSLV
  tipoComprobanteNombre: string
  numero: string
  clienteCodigo: number
  clienteIdentificacion: string
  clienteNombre: string
  clienteDireccion: string
  clienteTelefono: string
  asociado: string
  clienteEmail: string
  subtotal0: number
  subtotal12: number
  subtotalNoIva: number
  descuento: number
  descuentoGlobal: number
  iva: number
  total: number
  fecha: Date
  formaPagoCodigo: number
  formaPagoDescripcion: string
  puntoVenta: string
  tipoIngreso?: TipoIngresoDocumentos
  codigoGeneracion?: string
  autorizacionExtras?: TS_Auth | null
  retencionIva?: number
  retencionRenta?: number
}

export type EstadoComprobante = {
  codigo: number
  numero: number
  descripcion: string
}

export type EstablecimientoSri = {
  establecimiento: string
  puntosEmision: Array<PuntoEmisionSri>
}

export type PuntoEmisionSri = {
  puntoEmision: string
  establecimiento: EstablecimientoSri | null
}

export type CuotaCreditoVenta = {
  codigo?: string
  codigoPVE?: string
  numero: number
  letra: string
  cuota: number
  vencimiento: string
  saldo: number
  saldoCap?: number
  interes?: number
  estado: string
}

export type InfoInsertarRetencionVentaDetalle = {
  eFiscal: number
  base: number
  porcentaje: number
  valor: number
  conCodigo: string
  conImpuesto: string
}

export type InfoInsertarRetencion = {
  infoRegistro: InsertarRetencionInformacionRegistro
  infoEmpresa: { ruc: string }
}

export type InsertarRetencionInformacionRegistro = {
  codigo: number
  autorizacion: string
  establecimiento: string
  puntoEmision: string
  numero: number
  fecha: string
  total: number
  codigoVenta: number
  codigoUsuario: number
  formaPago: number
  codigoEmpresa: number
  codigoLocal: number
  rev_lotetar: string
  codigoCliente: number
  infoDetalle: Array<InfoInsertarRetencionVentaDetalle>
  infoUsuario: { usuario: string }
}

export type FiltroBusquedaLiquidacionCompraState = {
  fechaInicio: string
  fechaFin: string
  proveedor: ProveedorBusqueda | null
  puntoVenta: string
  documento: string
  pendientesAbutorizar: boolean | null
  formaPago: FormaPagoModuloVentas | null
  estado: EstadoComprobante | null
  establecimiento: EstablecimientoSri | null
  puntoEmision: PuntoEmisionSri | null
  activas: boolean
  claveAcceso?: string
  tipoDoc: TiposComprobantesSri | TiposComprobantesSLV | null
}

export type InfoDocumentoAnular = {
  codigo: number
  fecha: string
  tipodoc: TiposComprobantesSri
  cliente: number
  fechan: string
}

export type TAutorizacionDocumentoSV = {
  estado: string
  version: string
  ambiente: string
  codigoMensaje: string
  versionApp: string
  clasificacionMensaje: string
  selloRecibido: string
  observaciones?: string[]
  descripcionMensaje: string
  fechaProcesamiento: string
  tipoComprobante: string
  codigoGeneracion: string
  claveAcceso: string
  codigo: string
}

export type TAutorizacionBloqueResponse = {
  error: boolean
  auto: {
    rechazados: {
      descripcionMsg: string
      estado: string
      codigoGeneracion: string
      numeroFactura: string
      observaciones: string[]
      message?: string
      error?: string
    }[]
    aprobados: {
      descripcionMsg: string
      estado: string
      codigoGeneracion: string
    }[]
  }
}

export type FormaPagoMinisterioRelacionada = {
  formaPagoCodigo: number
  formaPagoDescripcion: string
  formaPagoSRICodigo: string
}

export type FormaPagoMinisterio = {
  codigo: string
  descripcion: string
  estado: number
  formaPagoCodigo: number
  relacionadas: FormaPagoMinisterioRelacionada[]
}

export type TAnularDocumentos = {
  codigo: number
  fecha: string
  tipodoc: string
  fechan: string
  relacionado?: number
}
