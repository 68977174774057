import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store/store'
import { Autorizacion } from '../../../../../../store/types'
import { AutorizacionDocumentoVenta } from '../../../../types/types'
import {
  ConfiguracionesVentas,
  FacturacionEdicionPayload,
  FacturaDatosEdicion,
} from '../../types/types'
import { ventasClearDatosEdicion } from '../../../../store/reducers'

const initialState: ConfiguracionesVentas = {
  usaDescuentoGlobal: false,
  autorizacion: null,
  autorizacionDocumento: null,
  autorizacionDocumentoCredFiscal: null,
  autorizacionDocumentoFactExportacion: null,
  documentoSeleccionado: null,
  mostrarIngresaCaja: {},
}

const configuracionesVentaSlice = createSlice({
  name: 'configuracionesCreditoFiscal',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    initialize(state, acion: PayloadAction<Autorizacion>) {
      state.autorizacion = acion.payload
    },
    toogleUsaDescuentoGlobal(state, acion: PayloadAction) {
      state.usaDescuentoGlobal = !state.usaDescuentoGlobal
    },
    setAutorizacionModulo(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumento = acion.payload
    },
    setAutorizacionModuloCreditoFiscal(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumentoCredFiscal = acion.payload
    },
    setAutorizacionModuloFactExportacion(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta>,
    ) {
      state.autorizacionDocumentoFactExportacion = acion.payload
    },
    setDatosEdicionBackup(
      state,
      action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    setMostrarIngresoCaja(
      state,
      action: PayloadAction<{ key: string; mostrar: boolean }>,
    ) {
      state.mostrarIngresaCaja[action.payload.key] = action.payload.mostrar
    },
    removeMostrarIngresoCaja(state, action: PayloadAction<string>) {
      delete state.mostrarIngresaCaja[action.payload]
    },
  },
})

export const selectUsaDescuentoGlobal = (state: RootState) => {
  return state.ventas.ventasSV.creditoFiscal.configuraciones.usaDescuentoGlobal
}
export const configuracionesReducer = configuracionesVentaSlice.reducer
export const {
  setMostrarIngresoCaja,
  removeMostrarIngresoCaja,
  setAutorizacionModuloFactExportacion,
  setAutorizacionModuloCreditoFiscal,
  setAutorizacionModulo,
  toogleUsaDescuentoGlobal,
  setDatosEdicionBackup,
  ventasClearDatosEdicion: clearDatosEdicionBackup,
} = configuracionesVentaSlice.actions
