import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccionMenu } from '../../../../../../store/types'
import { MenuState } from '../../../../types/types'

const initialState: MenuState = {
  loading: true,
  accions: [],
}

const menuGuideSlice = createSlice({
  name: 'guideRemitionMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload
      state.loading = false
    },
  },
})

export const { setMenuButtons } = menuGuideSlice.actions
export const menuReducer = menuGuideSlice.reducer
