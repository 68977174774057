import React from 'react'
import { TextBox, NumberBox, CheckBox } from 'devextreme-react'
import DateBox from 'devextreme-react/date-box'
import RadioGroup from 'devextreme-react/radio-group'

export interface IInputBaseProps extends React.PropsWithChildren {
  onKeyDown?: any
  onValueChanged?: (data: any) => void
  onValueChange?: (data: any) => void
  placeholder?: string
  width?: string
  disabled?: boolean
  useMaskedValue?: boolean
  showClearButton?: boolean
  children?: any
  onEnterKey?: () => void
  inputAttr?: any
  name?: string
  id?: string
  ref?: any
  readOnly?: boolean
  className?: string
  style?: any
}

export interface IInputTextProps extends IInputBaseProps {
  value?: string | null // Solo string o null en el TextBox
  mode?: 'password' | 'text'
}

export interface IInputNumberProps extends IInputBaseProps {
  value?: number | null // Solo number o null en el NumberBox
  min?: number | null
  max?: number | null
  showSpinButtons?: boolean
  showClearButton?: boolean
  defaultValue?: number | null // Solo number
  format?: string
}
// textbox
export default function InputTextDE(props: IInputTextProps) {
  const {
    children,
    value,
    placeholder,
    onValueChanged,
    onValueChange,
    width,
    showClearButton,
    onEnterKey,
    inputAttr,
    name,
    id,
    mode,
    onKeyDown,
    ref,
    readOnly,
    disabled,
    style,
    className,
  } = props
  return (
    <TextBox
      {...(readOnly && { readOnly })}
      {...(ref && { ref })}
      {...(mode && { mode })}
      {...(name && { name })}
      {...(id && { id })}
      {...(placeholder && { placeholder })}
      {...(onValueChanged && { onValueChanged })}
      value={value}
      onValueChange={onValueChange}
      {...(width && { width })}
      {...(showClearButton && { showClearButton })}
      {...(onEnterKey && { onEnterKey })}
      {...(inputAttr && { inputAttr })}
      {...(onKeyDown && { onKeyDown })}
      {...(disabled && { disabled })}
      {...(style && { style })}
      {...(className && { className })}
    >
      {children}
    </TextBox>
  )
}
// number
export function InputNumberDE(props: IInputNumberProps) {
  const {
    children,
    value,
    placeholder,
    onValueChanged,
    onValueChange,
    width,
    showClearButton,
    onEnterKey,
    format,
    style,
    className,
  } = props
  return (
    <NumberBox
      value={value}
      placeholder={placeholder}
      onValueChanged={onValueChanged}
      onValueChange={onValueChange}
      width={width}
      showClearButton={showClearButton}
      onEnterKey={onEnterKey}
      {...(format && { format })}
      {...(style && { style })}
      {...(className && { className })}
    >
      {children}
    </NumberBox>
  )
}
// chebox
export interface IInputCheckProps extends React.PropsWithChildren {
  onValueChanged?: (data: any) => void
  onValueChange?: (data: any) => void
  disabled?: boolean
  children?: any
  value?: any
  text?: string
  className?: string
}
export function InputCheckDE(props: IInputCheckProps) {
  const { className, children, value, onValueChanged, onValueChange, text } =
    props
  return (
    <CheckBox
      value={value}
      text={text}
      onValueChanged={onValueChanged}
      onValueChange={onValueChange}
      {...(className && { className })}
    >
      {children}
    </CheckBox>
  )
}

// date
export interface IInputDateProps extends React.PropsWithChildren {
  onValueChanged?: (data: any) => void
  onValueChange?: (data: any) => void
  placeholder?: string
  width?: string
  disabled?: boolean
  showClearButton?: boolean
  children?: any
  onEnterKey?: () => void
  disabledDates?: any
  value: any
}
export function InputDateDE(props: IInputDateProps) {
  const {
    children,
    value,
    placeholder,
    onValueChanged,
    onValueChange,
    width,
    showClearButton,
    onEnterKey,
    disabledDates,
  } = props
  return (
    <DateBox
      value={value}
      placeholder={placeholder}
      onValueChanged={onValueChanged}
      onValueChange={onValueChange}
      width={width}
      showClearButton={showClearButton}
      onEnterKey={onEnterKey}
      disabledDates={disabledDates}
    >
      {children}
    </DateBox>
  )
}

// radio
export interface IRadioOptionProps extends React.PropsWithChildren {
  onValueChanged?: (data: any) => void
  onValueChange?: (data: any) => void
  disabled?: boolean
  children?: any
  value: any
  name?: any
  items?: any
  layout?: 'horizontal' | 'vertical'
  displayExpr?: any
  dataSource?: any
}
export function RadioOptionDE(props: IRadioOptionProps) {
  const {
    children,
    value,
    onValueChanged,
    onValueChange,
    disabled,
    name,
    items,
    layout,
    displayExpr,
    dataSource,
  } = props
  return (
    <RadioGroup
      {...(name && { name })}
      {...(disabled !== undefined && { disabled })}
      {...(layout && { layout })}
      {...(items && { items })}
      {...(dataSource && { dataSource })}
      value={value}
      onValueChange={onValueChange}
      {...(displayExpr && { displayExpr })}
      {...(onValueChanged && { onValueChanged })}
    >
      {children}
    </RadioGroup>
  )
}
