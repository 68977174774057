import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutorizacionesModuloState } from '../types/types'
import { TAutorizacionModulo } from '../../ventas/types/types'

const initialState: AutorizacionesModuloState = {
  autorizaciones: [],
}

const providersSlice = createSlice({
  name: 'providers',
  initialState: initialState,
  reducers: {
    setAutorizacionesModulos(
      state,
      action: PayloadAction<Array<TAutorizacionModulo>>,
    ) {
      state.autorizaciones = action.payload
    },
  },
})

export const { setAutorizacionesModulos } = providersSlice.actions
export const autorizacionesReducers = providersSlice.reducer
