import { combineReducers } from '@reduxjs/toolkit'
import { editDataReducer } from './editDataReduce'
import { menuReducer } from './menuReducer'
import { searchGuideReducer } from './searchReducer'
import { tabsReducer } from './tabsReducer'
import { configurationsReducer } from './configurationsGuideReducer'
import { NotaRemisionState } from '../types/types'

export const guideRemitionSVReducer = combineReducers<NotaRemisionState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchGuideReducer,
  editData: editDataReducer,
  configurations: configurationsReducer,
})
