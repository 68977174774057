import { FormaPagoMinisterio } from "../../../types/types";


export const GeneralVentaHelper = {
  obtenerFormaPagoSRICodigo
}

function obtenerFormaPagoSRICodigo(formaPagoCodigo: number, objetos: FormaPagoMinisterio[]): FormaPagoMinisterio | null {
  for (const objeto of objetos) {
    const relacionadas = objeto.relacionadas;
    if (relacionadas && relacionadas.length > 0) {
      for (const relacionada of relacionadas) {
        if (relacionada.formaPagoCodigo === formaPagoCodigo) {
          return objeto;
        }
      }
    }
  }
  return null; // Devolver null si no se encuentra ninguna correspondencia
}

// function TFacturaIngresarToVentaListado(venta:TFacturaIngresar):VentaListado {
//   return {
//     numero: venta.numeroComprobante ??'',
//     afecta_ven_estableciomiento:'',
//     afecta_ven_numero:'',
//     afecta_ven_ptoemision:'',
//     asesorComercial:'',
//     asociados:0,
//     autorizacion: venta.cl
//   }
// }