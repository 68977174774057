import { combineReducers } from '@reduxjs/toolkit'
import { AnexoTransaccionalState } from '../types/types'
import { datosEdicionReducer } from './editDataReducer'
import { menuReducer } from './menuReducer'
import { searchAnexoReducer } from './searchReducer'
import { tabsReducer } from './tabsReduxcers'
import { configuracionesReducer } from './configuracionesReducer'

export const anexoTransaccionalReducer =
  combineReducers<AnexoTransaccionalState>({
    menu: menuReducer,
    tabs: tabsReducer,
    search: searchAnexoReducer,
    editData: datosEdicionReducer,
    configuracion: configuracionesReducer,
  })
