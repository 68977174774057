import React, { useEffect } from 'react'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import {
  AplicacionInventario,
  AplicacionesDatosEdicion,
  AplicationsReport,
  FiltrosaplicacionesState,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, openTab } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderAplications,
  setCurrentFunction,
} from '../../store/generalReducer'
import { TabState } from '../../../../../../../store/genericTabTypes'
import Arbol from '../../../../../../contabilidad/pages/planCuentas/components/arbol'
import {
  changeFilter,
  fetchUnidades,
  setCleanDatEdit,
  setCleanResult,
  setCollapsed,
  setDatEdit,
} from '../../store/serachReducer'
import { getIndexProvider } from '../../../../../../shared/helpers/funciones'
import { aplicationsModalServices } from '../../../../items/components/modalAplicaciones/services/modalAplicaciones.services'
import { FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { addToast } from '../../../../../../../store/toasterReducer'
import { initDatosEdicion } from '../../store/editDataReducer'
import { v4 as uuidv4 } from 'uuid'
import { ReportWithLocalDataModal } from '../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { CCard } from '@coreui/react-pro'
import FiltroBusqueda, { stateOptions } from './FiltroBusqueda'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { aplicationsServices } from '../../../../../../componentes/aplicacionesLookUp/sevices/aplicaciones.services'
import { defaultDataAplications } from '../nuevo/index'
import DraggableActions from '../../../../../../componentes/draggableActions/draggableActions'
import { VistasBusqueda } from '../../../../../../../store/enums'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<AplicacionesDatosEdicion>
  tabId: string
}
const draggingGroupName = 'appointmentsGroup'

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId, tab } = props

  const dispatch = useDispatch()
  const currentTab = useSelector(
    (state: RootState) => state.inventarios.catalogos.aplicaciones.tabs.current,
  )
  const searchResults = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.aplicaciones.search.resultados,
  )
  const searchFilter = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.aplicaciones.search.filter,
  )
  const estadoBusqueda = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.aplicaciones.search.status,
  )
  const dataEdit = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.aplicaciones.search.dataEdit,
  )
  const modulo = useSelector(
    (state: RootState) =>
      state.inventarios.catalogos.aplicaciones.config.modulo,
  )
  const aplicacionesLoader = useSelector((state: RootState) => {
    return state.inventarios.catalogos.aplicaciones.general
  })
  const dialogRef = React.useRef<any>(null)
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )

  const [resultados, setResultados] = React.useState([])
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false)

  const setToast = React.useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Inventario - Aplicaciones',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const getFilterString = React.useCallback(
    (filter: FiltrosaplicacionesState) => {
      const filterText = ''
      return filterText
    },
    [],
  )

  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderAplications({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderAplications({ show: false, mensaje: '' }))
  }, [dispatch])

  const onGetPertenece = React.useCallback(async (codigo: number) => {
    try {
      const data = await aplicationsServices.getAplications()
      if (data !== null && data !== undefined && data['error'] === false) {
        const est = data['auto'].find((item) => item.codigo === codigo)
        return est
      }
      return null
    } catch (error) {
      return null
    }
  }, [])

  const initFilter = React.useCallback(async () => {
    const data = { ...searchFilter }
    if (data.initial) {
      const pertenece = await onGetPertenece(0)
      data.pertence = pertenece
      data.descripcion = ''
      data.estado = stateOptions[0]
      data.initial = false
      dispatch(
        changeFilter({
          ...data,
        }),
      )
    }
  }, [dispatch, searchFilter, onGetPertenece])

  const onHandleEdit = React.useCallback(
    async (appData: any) => {
      const id = uuidv4()
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaultDataAplications,
            loading: true,
            codigo: appData?.codigo,
          },
        }),
      )
      dispatch(openTab({ key: id, aplicacion: appData }))
    },
    [dispatch],
  )

  const onHandleRemove = React.useCallback(async () => {
    try {
      const id = dataEdit?.codigo ?? 0
      playLoader()
      const data = await aplicationsModalServices.deleteAplication(id)
      stopLoader()
      if (data !== null && data !== undefined && data['error'] === false) {
        const provider = resultados.slice(0)
        const index = await getIndexProvider(provider, 'codigo', id)
        if (index > -1) {
          provider.splice(index, 1)
          setResultados(provider)
          dispatch(setCleanDatEdit())
        }
        setToast(data['message'], ToastTypes.Success)
      } else {
        setToast(data['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dataEdit, playLoader, stopLoader, setToast, dispatch, resultados])

  const onGetDataEdit = React.useCallback(
    async (data) => {
      if (resultados.length > 0) {
        const provider = resultados.slice(0)
        const index = await getIndexProvider(provider, 'codigo', data?.id)
        if (index > -1) {
          const dataToEdit = provider[index]
          dispatch(setDatEdit(dataToEdit))
        }
      }
    },
    [resultados, dispatch],
  )

  const handleSearch = React.useCallback(async () => {
    try {
      const toAny: any = fetchUnidades(searchFilter)
      const res = await dispatch(toAny)
      if (res !== null && res !== undefined && res.payload['error'] === false) {
        setToast(res.payload['message'], ToastTypes.Success)
      } else {
        setToast(res.payload['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
  }, [dispatch, searchFilter, setToast])

  const onUndoFilter = React.useCallback(async () => {
    const data = { ...searchFilter }
    const pertenece = await onGetPertenece(0)
    data.pertence = pertenece
    data.descripcion = ''
    data.estado = stateOptions[0]
    dispatch(
      changeFilter({
        ...data,
      }),
    )
    dispatch(setCleanResult())
    dispatch(setCleanDatEdit())
  }, [dispatch, onGetPertenece, searchFilter])

  const onBroomResult = React.useCallback(async () => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onConfirmEdit = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro: ${dataEdit?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleEdit(dataEdit)
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmEdit(false)
    return
  }, [dataEdit, onHandleEdit])

  const onConfirmDelete = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro: ${dataEdit?.descripcion ?? '-'}?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            onHandleRemove()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmDelete(false)
    return
  }, [dataEdit, onHandleRemove])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          setConfirmDelete(true)
          break
        case ButtonTypes.find:
          handleSearch()
          break
        case ButtonTypes.broom:
          onBroomResult()
          break
        case ButtonTypes.undo:
          onUndoFilter()
          break
        case ButtonTypes.edit:
          setConfirmEdit(true)
          break
        case ButtonTypes.print:
          setReporte('Viewer')
          break
        case ButtonTypes.print_design:
          setReporte('Designer')
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, handleSearch, onUndoFilter, onBroomResult],
  )

  const onGetData = React.useCallback(() => {
    let newAray = []
    if (
      searchResults !== null &&
      searchResults !== undefined &&
      searchResults['error'] === false
    ) {
      newAray = searchResults['auto'].map((item) => {
        return {
          codigo: item?.codigo ?? 0,
          numero: '',
          descripcion: item?.descripcion ?? '',
          padre: item?.pertenece ?? 0,
          estado: item?.estado ?? 0,
          observaciones: item?.observaciones ?? '',
        }
      })
      if (newAray.length > 5) {
        dispatch(setCollapsed(false))
      }
      setResultados(newAray)
    } else {
      setResultados([])
      dispatch(setCollapsed(true))
    }
  }, [searchResults, dispatch])

  const onParseData = React.useCallback(
    (aplicacion: Array<AplicacionInventario> = []) => {
      let registros: Array<AplicationsReport> = []
      if (aplicacion.length > 0) {
        registros = aplicacion.map((x) => {
          return {
            CodeGroup: x?.codigo ?? 0,
            DescriptionGroup: x?.descripcion ?? '',
            State: x?.estado ?? 0,
            SubGroup: [],
          }
        })
      }
      return registros
    },
    [],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (confirmDelete) {
      onConfirmDelete()
    }
  }, [confirmDelete, onConfirmDelete])

  React.useEffect(() => {
    if (confirmEdit) {
      onConfirmEdit()
    }
  }, [confirmEdit, onConfirmEdit])

  useEffect(() => {
    initFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros)
        break
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading)
        break
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
        break
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error)
        break
      default:
        break
    }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(searchResults['auto'])}
          fileName="StaticAplicacionesListado"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: getFilterString(searchFilter) }}
          template="StaticAplicacionesListado"
          key="reportDesigner"
        />
        <CCard>
          <Dialog ref={dialogRef} />
          {vistaActual === VistasBusqueda.Filtros && (
            <FiltroBusqueda onSearchEnter={handleSearch} />
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <div className="p-2">
              <Arbol
                name="ArbolAplicacion"
                data={resultados}
                jerarquiaName="Aplicacion"
                activedense={true}
                sendData={(data) => onGetDataEdit(data)}
                modulo={modulo}
              />
            </div>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && <TableLoader />}
          {currentTab === tabId && (
            <DraggableActions
              vistaActual={vistaActual}
              estadoBusqueda={estadoBusqueda}
              resultados={resultados}
              setVistaActual={setVistaActual}
              draggingGroupName={draggingGroupName}
            />
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(searchResults['auto'])}
        fileName="StaticAplicacionesListado"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: getFilterString(searchFilter) }}
        template="StaticAplicacionesListado"
        key="reportDesigner"
      />
      <Dialog ref={dialogRef} />
      <div id="unidadesContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={aplicacionesLoader.loader.show}
          message={aplicacionesLoader.loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={handleSearch} />
            <div className="p-2">
              <Arbol
                name="ArbolAplicacion"
                data={resultados}
                jerarquiaName="Aplicacion"
                activedense={true}
                sendData={(data) => onGetDataEdit(data)}
                modulo={modulo}
              />
            </div>
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
