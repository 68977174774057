import { CBadge, CLink } from '@coreui/react-pro'
import React from 'react'
import { IPopoverOptions, Popover } from 'devextreme-react/popover'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import QrCode from '../../../../../../views/componentes/qrcode/qrcode'
import { TAutorizacionHaciendaSV } from '../../../../pages/ventas/types/types'
import { utilidades } from '../../../../../../helpers/utilidades'

interface QrDocumentoProps {
  autorizacionDocumento: TAutorizacionHaciendaSV
  label?: string
  showEnlace?: boolean
}

const animationConfig: IPopoverOptions['animation'] = {
  show: {
    type: 'pop',
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
  },
}
const QrDocumento: React.FC<QrDocumentoProps> = (props) => {
  const { autorizacionDocumento, label, showEnlace } = props
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const [enlace, setEnlace] = React.useState<string>('')
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  function reemplazarValores(cadena, valores) {
    for (const valor in valores) {
      cadena = cadena.replace(valor, valores[valor])
    }
    return cadena
  }
  function convertDateFormat(dateString) {
    const [day, month, year] = dateString.split(' ')[0].split('/')
    return `${year}-${month}-${day}`
  }

  const configQrDocumento = React.useCallback(
    (datosAuthDte) => {
      if (
        datosAuthDte &&
        datosAuthDte.selloRecibido ===
          'No hay Sello de Recepción asociado a este código de generación.'
      ) {
        return false
      }
      if (datosAuthDte && datosAuthDte.fechaProcesamiento) {
        let urlQR = ''
        const ambiente = utilidades.zeroFill(datosAuthDte?.ambiente, 2, 'left')
        try {
          console.log('configQrDocumento')
          console.log('datosAuthDte', datosAuthDte)

          const valores = {
            '%AMBIENT%': ambiente ?? '00',
            '%CODE_GENERATION%': datosAuthDte?.codigoGeneracion ?? '',
            '%DATE_EMITION%': convertDateFormat(
              datosAuthDte?.fechaProcesamiento,
            ),
          }
          const urlFile = empresa?.configuracion?.urlFiles ?? ''

          urlQR = reemplazarValores(urlFile, valores)
          console.log('urlQR', urlQR)
          setEnlace(urlQR)
        } catch (error) {
          console.error(error)
        }
      }
    },
    [empresa],
  )

  React.useEffect(() => {
    configQrDocumento(autorizacionDocumento)
  }, [autorizacionDocumento, configQrDocumento])

  return (
    <>
      <CBadge
        id={'target-' + autorizacionDocumento?.selloRecibido}
        style={{ cursor: 'pointer' }}
        className="ms-2 me-2"
        color="secondary"
        onClick={() => {
          setShowPopup(true)
        }}
      >
        {label ?? 'QR'}
      </CBadge>
      {showPopup && (
        <Popover
          target={'#target-' + autorizacionDocumento?.selloRecibido}
          showEvent="click"
          position="top"
          animation={animationConfig}
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.5)"
        >
          {enlace !== '' && <QrCode text={enlace} />}
          {showEnlace && (
            <CLink href={enlace} target="_blank">
              {'enlace'}
            </CLink>
          )}
        </Popover>
      )}
    </>
  )
}

export default QrDocumento
