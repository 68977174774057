import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  OrganicListDto,
  OrganicoDatosEdicion,
  OrganicoList,
} from '../../types/types'
import { TabState } from '../../../../../../store/genericTabTypes'
import FiltroBusqueda from './FiltroBusqueda'
import ResultadosBusqueda from './ResultadosBusqueda'
import { CCard, CCardFooter } from '@coreui/react-pro'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { ConfigPopupMsg, ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  changeFilter,
  fetchOrganictList,
  setChangeLoader,
  setResetSeleccion,
  setSearch,
} from '../../store/searchReducer'
import { isMobile } from 'react-device-detect'
import { OrganicServices } from '../../services/organico.services'
import DataSource from 'devextreme/data/data_source'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import ConfirmarAccionMsj from '../../../../../componentes/confirmarAccionMsj'
import { defaultPopupMsgConfig } from '../../../../store/types'
import { consoleService } from '../../../../../../services/console.service'
import { defaultOrganicEdition } from '../nuevo/index'
import { initDatosEdicion } from '../../store/editDataReducer'
import TableLoader from '../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import { Draggable, SpeedDialAction } from 'devextreme-react'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { VistasBusqueda } from '../../../../../../store/enums'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<OrganicoDatosEdicion>
  tabId: string
}

const draggingGroupName = 'appointmentsGroup'

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const filter = useSelector(
    (state: RootState) => state.nomina.organico.search.filtro,
  )
  const searchOrganico = useSelector(
    (state: RootState) => state.nomina.organico.search.searchOrganico,
  )
  const seleccionado = useSelector(
    (state: RootState) => state.nomina.organico.search.seleccionado,
  )
  const loader = useSelector(
    (state: RootState) => state.nomina.organico.search.loader,
  )
  const tabs = useSelector((state: RootState) => state.nomina.organico.tabs)
  const currentTab = useSelector(
    (state: RootState) => state.nomina.organico.tabs.current,
  )
  const { getOrganicDatasource } = OrganicServices.useSetDataSources()
  const DataSourceOrganic = useMemo(
    () => getOrganicDatasource(filter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  )

  const [reporte, setReporte] = React.useState<null | 'Viewer' | 'Designer'>(
    null,
  )
  const [resultadoImprimir, setResultadoImprimir] = useState<
    Array<OrganicoList> | []
  >([])

  const [vistaActual, setVistaActual] = useState<VistasBusqueda>(
    VistasBusqueda.Filtros,
  )
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [dataSourceOrganic, setDataSourceOrganic] = useState<DataSource>(null)
  const [popupMsgConfig, setPopupMsgConfig] = useState<ConfigPopupMsg>(
    defaultPopupMsgConfig,
  )

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: tipo,
        }),
      )
    },
    [dispatch],
  )

  const setToast = useCallback(
    (mensaje: string, tipo: ToastTypes) => {
      dispatch(
        addToast({
          title: 'Nómina - Organigrama',
          content: mensaje,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const onResetPag = useCallback(async () => {
    setPageIndex(0)
    setPageSize(10)
  }, [])

  const onLoader = useCallback(
    (showLoader: boolean, mensaje: string) => {
      dispatch(
        setChangeLoader({
          show: showLoader,
          mensaje: mensaje,
        }),
      )
    },
    [dispatch],
  )

  const initSearch = useCallback(() => {
    const data = { ...filter }
    if (data?.initital) {
      data.descripcion = ''
      data.initital = false
      dispatch(
        changeFilter({
          ...data,
        }),
      )
    }
  }, [dispatch, filter])

  const onFind = React.useCallback(async () => {
    onSetButtonAction(ButtonTypes.find)
    if (!isMobile) {
      onLoader(true, 'Buscando...')
    }
    try {
      if (isMobile) {
        setVistaActual(VistasBusqueda.Loading)
      }
      await DataSourceOrganic.reload()
      setDataSourceOrganic(DataSourceOrganic)
      dispatch(setSearch(true))
      if (isMobile) {
        setVistaActual(VistasBusqueda.ResultadosBusqueda)
      }
      setToast(
        `${DataSourceOrganic.totalCount()} Registro(s) Encontrado(s)`,
        ToastTypes.Success,
      )
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
      if (isMobile) {
        setVistaActual(VistasBusqueda.Error)
      }
    }
    if (!isMobile) {
      onLoader(false, '')
    }
    onSetButtonAction(undefined)
  }, [DataSourceOrganic, dispatch, onLoader, onSetButtonAction, setToast])

  const onConfirmDelete = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.organico) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea ELIMINAR el registro ${
          seleccionado?.descripcion ?? '-'
        }?`,
        type: 'confirm',
        currentAction: ButtonTypes.delete,
      })
    }
  }, [seleccionado])
  const onConfirmEdit = React.useCallback(() => {
    if (seleccionado !== null && seleccionado?.organico) {
      setPopupMsgConfig({
        show: true,
        title: 'Ácatha',
        message: `¿Desea EDITAR el registro ${
          seleccionado?.descripcion ?? '-'
        }?`,
        type: 'confirm',
        currentAction: ButtonTypes.edit,
      })
    }
  }, [seleccionado])

  const onBroom = useCallback(() => {
    setDataSourceOrganic(null)
  }, [])

  const onUndo = useCallback(() => {
    const data = { ...filter }
    data.descripcion = ''
    dispatch(
      changeFilter({
        ...data,
      }),
    )
    dispatch(setResetSeleccion())
  }, [dispatch, filter])

  const onPrintData = React.useCallback(
    async (reporte: null | 'Viewer' | 'Designer') => {
      onSetButtonAction(ButtonTypes.print)
      onLoader(true, 'Genrando reporte...')
      setResultadoImprimir([])
      try {
        const toAny: any = fetchOrganictList(filter)
        const res = await dispatch(toAny)
        consoleService.log(res, 'res imprimir')
        if (res?.payload && res?.payload?.error === false) {
          setResultadoImprimir(res?.payload?.auto)
          setReporte(reporte)
        } else {
          setToast(res?.payload?.message, ToastTypes.Danger)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
      onLoader(false, '')
      onSetButtonAction(undefined)
    },
    [onLoader, onSetButtonAction, setToast, filter, dispatch],
  )

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) {
            onResetPag()
            onFind()
          }
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onBroom()
          break
        case ButtonTypes.delete:
          if (tabs.current === tabId) onConfirmDelete()
          break
        case ButtonTypes.edit:
          if (tabs.current === tabId) onConfirmEdit()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) onPrintData('Viewer')
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) onPrintData('Designer')
          break
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      dispatch,
      tabId,
      onResetPag,
      onFind,
      tabs,
      onConfirmDelete,
      onBroom,
      onUndo,
      onConfirmEdit,
      onPrintData,
    ],
  )

  useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  useEffect(() => {
    initSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOptionChange = useCallback(async (e) => {
    if (e.fullName === 'paging.pageSize') {
      setPageSize(e.value)
    }
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }, [])

  const openTabToEdit = useCallback(
    (organicEdit) => {
      consoleService.log(organicEdit)
      const id = uuidv4()
      dispatch(
        initDatosEdicion({
          key: id,
          data: {
            ...defaultOrganicEdition,
            loading: true,
          },
        }),
      )
      const data: any = {
        ...organicEdit,
        codigo: organicEdit?.organico,
      }
      dispatch(openTab({ key: id, store: data }))
    },
    [dispatch],
  )

  const onHandleDelete = useCallback(async () => {
    onSetButtonAction(ButtonTypes.delete)
    onLoader(true, 'Eliminando registro...')
    try {
      const data = await OrganicServices.deleteOrganic(
        seleccionado?.organico ?? 0,
      )
      consoleService.log(data, 'delete data')
      if (data?.auto && data?.error === false) {
        setToast(data?.message, ToastTypes.Success)
        dispatch(setResetSeleccion())
        DataSourceOrganic.reload()
        setDataSourceOrganic(DataSourceOrganic)
      } else {
        setToast(data?.message, ToastTypes.Danger)
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    onLoader(false, '')
    onSetButtonAction(undefined)
  }, [
    seleccionado,
    setToast,
    onLoader,
    onSetButtonAction,
    DataSourceOrganic,
    dispatch,
  ])

  const onHandleConfirm = useCallback(() => {
    if (popupMsgConfig.type === 'confirm') {
      if (popupMsgConfig.currentAction === ButtonTypes.edit)
        openTabToEdit(seleccionado)
      else if (popupMsgConfig.currentAction === ButtonTypes.delete)
        onHandleDelete()
    }
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [onHandleDelete, openTabToEdit, popupMsgConfig, seleccionado])

  const onHandleCancel = useCallback(() => {
    setPopupMsgConfig(defaultPopupMsgConfig)
  }, [])

  React.useEffect(() => {
    dispatch(
      setButtons({
        tabKey: 'BUSQUEDA',
        buttons: {
          Nuevo: true,
          Guardar: false,
          Editar: seleccionado !== null && seleccionado !== undefined,
          Eliminar: seleccionado !== null && seleccionado !== undefined,
          Buscar: true,
          Imprimir: dataSourceOrganic !== null,
          Deshacer: true,
          Limpiar: dataSourceOrganic !== null,
          Asignar: false,
        },
      }),
    )
  }, [dispatch, seleccionado, dataSourceOrganic])

  useEffect(() => {
    if (searchOrganico) setDataSourceOrganic(DataSourceOrganic)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onParseData = useCallback((data: OrganicoList[]) => {
    const registros: Array<OrganicListDto> = []
    if (data.length > 0) {
      data.forEach((x) => {
        registros.push({
          Description: x?.descripcion ?? '',
          Acronym: x?.siglas ?? '',
          BelongingTo: x?.padreNombre ?? '',
        })
      })
    }
    consoleService.log(registros)
    return registros
  }, [])

  if (isMobile) {
    return (
      <>
        <ReportWithLocalDataModal
          show={reporte !== null}
          onClose={() => setReporte(null)}
          data={onParseData(resultadoImprimir ?? [])}
          fileName="StaticOrganicList"
          mode={reporte ?? 'Viewer'}
          parameters={{ Reporte_Filtro: '' }}
          template="StaticOrganicList"
          key="reportDesigner"
        />
        <ConfirmarAccionMsj
          title={popupMsgConfig.title}
          isVisible={popupMsgConfig.show}
          handleConfirm={onHandleConfirm}
          handleCancel={onHandleCancel}
          message={popupMsgConfig.message}
          typeMessage={popupMsgConfig.type}
          typeInputInfo={null}
        />
        <CCard>
          {vistaActual === VistasBusqueda.Filtros && (
            <>
              <FiltroBusqueda onSearchEnter={onFind} />
            </>
          )}
          {vistaActual === VistasBusqueda.ResultadosBusqueda && (
            <>
              <ResultadosBusqueda
                data={dataSourceOrganic}
                onDBClick={onConfirmEdit}
                handleOptionChange={handleOptionChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onDelete={onConfirmDelete}
              />
            </>
          )}
          {vistaActual === VistasBusqueda.Error && <></>}
          {vistaActual === VistasBusqueda.Loading && (
            <CCardFooter>
              <TableLoader />
            </CCardFooter>
          )}
          {currentTab === tabId && (
            <Draggable id="list" data="dropArea" group={draggingGroupName}>
              <SpeedDialAction
                icon="filter"
                label="Filtros"
                visible={true}
                index={1}
                onClick={() => {
                  setVistaActual(VistasBusqueda.Filtros)
                }}
              />
              <SpeedDialAction
                icon="search"
                label="Busqueda"
                visible={true}
                index={2}
                onClick={() => {
                  setVistaActual(VistasBusqueda.ResultadosBusqueda)
                }}
              />
            </Draggable>
          )}
        </CCard>
      </>
    )
  }

  return (
    <>
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={onParseData(resultadoImprimir ?? [])}
        fileName="StaticOrganicList"
        mode={reporte ?? 'Viewer'}
        parameters={{ Reporte_Filtro: '' }}
        template="StaticOrganicList"
        key="reportDesigner"
      />
      <ConfirmarAccionMsj
        title={popupMsgConfig.title}
        isVisible={popupMsgConfig.show}
        handleConfirm={onHandleConfirm}
        handleCancel={onHandleCancel}
        message={popupMsgConfig.message}
        typeMessage={popupMsgConfig.type}
        typeInputInfo={null}
      />
      <div id="OrganicContent">
        <BlockUi
          tag="div"
          loader={LoadingIndicator}
          blocking={loader.show}
          message={loader.mensaje}
        >
          <CCard>
            <FiltroBusqueda onSearchEnter={onFind} />
            <ResultadosBusqueda
              data={dataSourceOrganic}
              onDBClick={onConfirmEdit}
              handleOptionChange={handleOptionChange}
              pageIndex={pageIndex}
              pageSize={pageSize}
              onDelete={onConfirmDelete}
            />
          </CCard>
        </BlockUi>
      </div>
    </>
  )
}

export default React.memo(Search)
