import React, { useEffect } from 'react'
import { GlobalMenu } from '../../../../views/componentes/globalMenu/globalMenu'
import {
  AccionMenu,
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../store/types'
import CardContent from '../../../bancos/components/cardContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/store'
import { NotificationList, typeNotification } from './types/types'
import {
  changeLoaderNotifications,
  setCurrentFunction,
  setTypeNotification,
} from './store/generalReducer'
import { addToast } from '../../../../store/toasterReducer'
import TabControl from './components/shared'
import { Aplicacion } from '../../store/types'
import { utilidades } from '../../../../helpers/utilidades'
import {
  changeCurrentTab,
  notificationCloseTab,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from './store/tabsReducer'
import { ButtonTypes } from '../../../../views/componentes/globalMenu/types'
import { deleteEditData, initDatosEdicion } from './store/editDataReduce'
import { fetchNotifications, setCleanResult } from './store/serachReducer'
import Dialog from '../../../../views/componentes/librerias/bootstrap-dialog'
import { DateUtils } from '../../../../helpers/dateUtils'
import { notificationsServices } from './services/notifications.services'
import {
  useButtonDisabled,
  useMenuOptions,
} from '../../../../hooks/useMenuButtonControls'

const pathVerificador = '/notificaciones'

interface NotificacionesProps extends React.PropsWithChildren {}

const Notificaciones: React.FunctionComponent<NotificacionesProps> = () => {
  const dispatch = useDispatch()
  const menuState = useSelector((state: RootState) => state.global.menu)
  const tabs = useSelector((state: RootState) => {
    return state.administracion.notificaciones.tabs
  })
  const loader = useSelector((state: RootState) => {
    return state.administracion.notificaciones.general.loader
  })
  const filter = useSelector((state: RootState) => {
    return state.administracion.notificaciones.search.filter
  })
  const estadoBusqueda = useSelector((state: RootState) => {
    return state.administracion.notificaciones.search.status
  })
  const resultadosBusqueda = useSelector((state: RootState) => {
    return state.administracion.notificaciones.search.resultados
  })
  const notificationType = useSelector((state: RootState) => {
    return state.administracion.notificaciones.general.typesNotification
  })

  const [confirmarBorrar, setConfirmarBorrar] = React.useState<boolean>(false)
  const [confirmarEditar, setConfirmarEditar] = React.useState<boolean>(false)
  const dialogRef = React.useRef<any>(null)
  const getButtonDisabled = useButtonDisabled(tabs)
  const { getMenuOptions, options } = useMenuOptions()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderNotifications({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderNotifications({ show: false, mensaje: '' }))
  }, [dispatch])

  const onTabClosing = React.useCallback(
    async (index) => {
      const closedTab = tabs.tabs[index]
      dispatch(notificationCloseTab(closedTab.tabKey ?? index))
      dispatch(deleteEditData(closedTab.tabKey ?? index))
      await utilidades.sleep(50)
    },
    [dispatch, tabs.tabs],
  )

  const onTabChanged = React.useCallback(
    (tabKey: string) => {
      dispatch(changeCurrentTab(tabKey))
    },
    [dispatch],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onConfirmarEditar = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Editar el registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.edit))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarEditar(false)
    return
  }, [dispatch])

  const onConfirmarBorrar = React.useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea Eliminar el Registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>A</u>ceptar
          </span>,
          (dialog) => {
            dialog.hide()
            dispatch(setCurrentFunction(ButtonTypes.delete))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setConfirmarBorrar(false)
    return
  }, [dispatch])

  React.useEffect(() => {
    if (confirmarEditar) {
      onConfirmarEditar()
    }
  }, [confirmarEditar, onConfirmarEditar])

  React.useEffect(() => {
    if (confirmarBorrar) {
      onConfirmarBorrar()
    }
  }, [confirmarBorrar, onConfirmarBorrar])

  const onMenuButtonClick = React.useCallback(
    async (action: AccionMenu) => {
      const id = utilidades.getUUID()
      switch (action.actionType) {
        case ButtonTypes.find:
          if (tabs.current === 'BUSQUEDA') {
            onCleanResultFilter()
            const toAny: any = fetchNotifications(filter)
            dispatch(toAny)
          } else {
            dispatch(changeCurrentTab('BUSQUEDA'))
          }
          break
        case ButtonTypes.new:
          if (tabs.current === 'BUSQUEDA') {
            const dataInitialDefault = {
              ...defaultDataNotification,
              tipoId: notificationType[0],
            }
            dispatch(
              initDatosEdicion({
                key: id,
                data: dataInitialDefault,
              }),
            )
            dispatch(openTab({ key: id }))
          } else {
            dispatch(setCurrentFunction(ButtonTypes.new))
          }
          break
        case ButtonTypes.save:
          if (tabs.current !== 'BUSQUEDA')
            dispatch(setCurrentFunction(ButtonTypes.save))
          break
        case ButtonTypes.edit:
          if (tabs.current === 'BUSQUEDA') setConfirmarEditar(true)
          break
        case ButtonTypes.delete:
          if (tabs.current === 'BUSQUEDA') setConfirmarBorrar(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === 'BUSQUEDA') onCleanResultFilter()
          else dispatch(setCurrentFunction(ButtonTypes.undo))
          break
        default:
          break
      }
    },
    [dispatch, filter, notificationType, onCleanResultFilter, tabs],
  )

  useEffect(() => {
    if (menuState.length > 0 && options.length === 0) {
      getMenuOptions(menuState, 'to', pathVerificador)
    }
  }, [menuState, getMenuOptions, options])

  React.useEffect(() => {
    if (estadoBusqueda === FETCH_STATUS.FAILED) {
      stopLoader()
      dispatch(
        addToast({
          title: 'Buscar clientes',
          content: 'Error al realizar la búsqueda',
          type: ToastTypes.Danger,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: undefined,
        }),
      )
    }
    if (estadoBusqueda === FETCH_STATUS.LOADING) {
      playLoader()
      dispatch(
        setCurrentExecutingAction({
          tabKey: 'BUSQUEDA',
          buttonType: ButtonTypes.find,
        }),
      )
    }
    if (estadoBusqueda === FETCH_STATUS.SUCCESS) {
      stopLoader()
      dispatch(
        setButtons({
          tabKey: 'BUSQUEDA',
          buttons: {
            Nuevo: true,
            Guardar: false,
            Editar: false,
            Eliminar: false,
            Buscar: true,
            Imprimir: false,
            Deshacer: resultadosBusqueda.length > 0,
          },
        }),
      )
    }
  }, [dispatch, estadoBusqueda, playLoader, stopLoader, resultadosBusqueda])

  const getTypesNotification = React.useCallback(async () => {
    try {
      playLoader('Cargando . . .')
      const typeNotifications =
        await notificationsServices.getTypeNotification()
      if (!typeNotifications.error) {
        const types: Array<typeNotification> = []
        typeNotifications.auto.map((type: any) => {
          types.push({
            value: Number(type.id),
            label: type.descripcion,
          })
        })
        dispatch(setTypeNotification(types))
        stopLoader()
      } else {
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content:
              typeNotifications.message ??
              'Error al cargar Tipos de Notificacion.',
            fade: true,
            title: 'Cargar',
            type: ToastTypes.Danger,
          }),
        )
      }
    } catch (error) {
      stopLoader()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al guardar ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [dispatch, playLoader, stopLoader])

  useEffect(() => {
    if (notificationType === null) getTypesNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <GlobalMenu
          printButtonAsDropdown={false}
          showExportDesingOptions
          acciones={options}
          onClick={onMenuButtonClick}
          getButtonDisabled={getButtonDisabled}
          currentWorking={tabs.tabs[tabs.current].currentExecutingAction}
        />
      )
    } else {
      return <> </>
    }
  }

  const bodyForm = () => {
    return (
      <TabControl
        tabsState={tabs}
        onTabChanged={onTabChanged}
        ontabClosing={onTabClosing}
        tipoAplicacion={Aplicacion.notificaciones}
      />
    )
  }

  return (
    <>
      <div id={'performanceCustomers'}>
        <Dialog ref={dialogRef} />
        <CardContent
          childrenOptions={optionsForm()}
          childrenBody={bodyForm()}
          headerTitle={'Mensajes'}
          aplicacion={'Mensajes'}
          loader={loader}
        />
      </div>
    </>
  )
}

export default Notificaciones

export const ModuleButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Editar: false,
  Eliminar: false,
  Buscar: true,
  Imprimir: false,
  Deshacer: true,
}

export const defaultDataNotification: NotificationList = {
  codigo: 0,
  descripcion: '',
  fechaInicio: DateUtils.getCurrentDateAsString(),
  fechaFin: DateUtils.getCurrentDateAsString(),
  titulo: '',
  imagen: '',
  permanente: 0,
  tipoId: null,
  mostrarPopup: 1,
  imagenCodificada: '',
  defaultContent: '',
}
