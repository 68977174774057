/* eslint-disable no-unused-vars */

import { ECountry } from './countries'

interface PhoneLengthConfig {
  min: number
  max: number
}

const DEFAULT_PHONE_CONFIG: PhoneLengthConfig = {
  min: 6,
  max: 10,
}

export const EPhoneLength: { [key: number]: PhoneLengthConfig } = {
  [ECountry.ElSalvador]: {
    min: 8,
    max: 10,
  },
  [ECountry.Ecuador]: {
    min: 6,
    max: 15,
  },
  0: DEFAULT_PHONE_CONFIG,
}

export const getPhoneConfig = (country?: ECountry): PhoneLengthConfig => {
  return country !== undefined ? EPhoneLength[country] : EPhoneLength[0]
}
