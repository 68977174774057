import { combineReducers } from '@reduxjs/toolkit'
import { TModuloFacturacionSV } from '../types/types'
import { creditoFiscalReducer } from '../../creditoFiscal/store/creditoFiscalReducer'
import { consumidorFinalReducer } from '../../facturacion/store/consumidorFinalReducer'
import { facturacionExportacionReducer } from '../../facturaExportacion/store/consumidorFinalReducer'
import { guideRemitionSVReducer } from '../../../sv/guiasRemision/store/guiasReducer'
import { notasCreditoReducer } from '../../../sv/notasCredito/store/notasCreditoReducer'
import { notasDebitoReducer } from '../../../sv/notasDebito/store/notasDebitoReducer'
import { retencionesSVReducer } from '../../../../../compras/sv/pages/retenciones/store/retencionesReducer'
import { donacionesReducer } from '../../../sv/donacion/store/donacionReducer'

export const moduloVentasSVReducer = combineReducers<TModuloFacturacionSV>({
  creditoFiscal: creditoFiscalReducer,
  consumidorFinal: consumidorFinalReducer,
  exportacion: facturacionExportacionReducer,
  notasCredito: notasCreditoReducer,
  notasDebito: notasDebitoReducer,
  guideRemition: guideRemitionSVReducer,
  retenciones: retencionesSVReducer,
  donaciones: donacionesReducer,
})
