import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutorizacionDocumentoVenta } from '../../../../../../types/types'
import { ConfiguracionesAnexos } from '../types/types'

const initialState: ConfiguracionesAnexos = {
  autorizacionesModulo: [],
}

const configuracionesAnexosSlice = createSlice({
  name: 'configuracionesAnexos',
  initialState: initialState,
  reducers: {
    initialize(state, acion: PayloadAction<AutorizacionDocumentoVenta[]>) {
      state.autorizacionesModulo = acion.payload
    },
    setAutorizacionModulo(
      state,
      acion: PayloadAction<AutorizacionDocumentoVenta[]>,
    ) {
      state.autorizacionesModulo = acion.payload
    },
  },
})

export const configuracionesReducer = configuracionesAnexosSlice.reducer
export const { setAutorizacionModulo } = configuracionesAnexosSlice.actions
