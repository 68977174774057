//#region imports
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FacturaDatosEdicion,
  TFacturaIngresar,
  TFacturaIngresarDetalle,
  TFacturaIngresarFormaPago2,
  TFacturaIngresarImpuesto,
  TTotalesFactura,
} from '../../../pages/ventas/types/types'
import { RootState } from '../../../../../store/store'
import { useNavigate } from 'react-router-dom'
import {
  Cliente,
  ItemVenta,
  VentaDetalleInfo,
  VentaListado,
} from '../../../types/types'
import { VentasService } from '../../../services/ventas.service'
import { ClientesService } from '../../../services/clientes.service'
import { VentaHelper } from '../../../pages/ventas/ventaHelper'
import {
  ETiposNegociosPermitidosMaxCFinal,
  StatesEdition,
} from '../../../types/enums'
import {
  DateUtils,
  formatoFechasApi,
  formatoFechasDatePickers,
} from '../../../../../helpers/dateUtils'
import config from '../../../../../config/config'
import {
  addDetalle,
  clearDatosEdicion,
  setCuotasCredito,
  setDatosEdicion,
  setEditLoader,
  setFormaPago,
  setImprimir,
  setIva,
  setMetodosAgregados,
  setMostrarBusquedaItems,
  setMuestraError,
  updateCliente,
  updateClienteOnly,
  updateDetalles,
  updateFechaEmision,
  updateObservaciones,
  updateTotales,
} from '../../../pages/ventas/store/editDataReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { TiposComprobantesSri, ToastTypes } from '../../../../../store/types'
import {
  buttonClick,
  changeEditStatus,
  clearButtonClick,
  closeTab,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,
} from '../../../pages/ventas/store/tabsReducer'
import {
  DetalleVenta,
  DetalleVentaRedux,
} from '../../../pages/ventas/types/detalleVenta'
import { SheetTextEditor } from '../sheetEditors/sheetTextEditor'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { VerRIDE } from '../../verRide/verRide'
import AgregarDetalleVentaMobile from './agregarDetalleVentaMobile'
import ModificaItem from '../../items/modificarItem'
import MainModalItems from '../../items/busquedaItemsModal/MainModalItems'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { CBadge, CCol, CFormInput, CRow } from '@coreui/react-pro'
import Barcode from '../../../../../views/componentes/barcode/barcode'
import MUIDataTable from 'mui-datatables'
import {
  getColumnnasTablaDetalleVentas,
  OptionsTablaDetalleVentas,
} from './helper'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import { CustomButtons, DocumentInfo, TabState } from '../../../types/generics'

import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet'
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn'
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor'
import { SheetDescripcionItemSelectEditor } from '../sheetEditors/sheetDescripcionItemSelectEditor'
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor'
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer'
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs'
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow'
import VisualizaError from '../../../pages/shared/visualizaError/visualizaError'
import {
  removeMostrarIngresoCaja,
  setAutorizacionModulo,
  setMostrarIngresoCaja,
  toogleUsaDescuentoGlobal,
} from '../../../pages/ventas/store/configuracionesVentaReducer'
import { CreditosVenta } from '../creditosVenta/creditosVenta'
import VariasFormasPago from '../variasFormasPago/variasFormasPago'
import BuscarClienteLookUp from '../../busquedaCliente/busquedaCliente'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import Button from 'devextreme-react/button'
import PopupReenvioMail from '../busquedaVentas/popupReenvioMail/index'
import { AnularDocumentoVenta } from '../../../pages/shared/anularDocumentoVenta/anularDocumentoVenta'
import TextArea from 'devextreme-react/text-area'
import TextBox from 'devextreme-react/text-box'
import DateBox from 'devextreme-react/date-box'
import { ComprobantesService } from '../../../../../containers/component/infoEmergente/comprobantes.service'
import { changeLoader, setLoadSummaryDocs } from '../../../../../store/reducers'
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper'
import { UrlHelper } from '../../../../../helpers/urlHelper'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import { consoleService } from '../../../../../services/console.service'
import useValidarCuotasVencidas from '../../../../../hooks/useControlPay'
import { ECountry } from '../../../../../store/enum/countries'
import { getAmbiente } from '../../../../../helpers/sv/utilitiesSv'
import { EAmbiente } from '../../../../../services/itemEnum/enumSV'
import { licenceHelper } from '../../../../../helpers/licenceHelper'
import { EFormasPago } from '../../../../../store/enum/formasPago'
import { OrdenProformaListado } from '../../../../componentes/modalProformas/store/types'
import ModalProformas from '../../../../componentes/modalProformas'
import { Local } from '../../../../shared/types/types'
import { LocalService } from '../../../../shared/components/buscarLocalesLookMemoUp/service/local.service'
import { localidadesService } from '../../../../../services/localidades.service'
import InvoiceChange from '../../../../../views/componentes/InvoiceChange/InvoiceChange'
import { XMLDisplay } from '../../../../../views/componentes/XMLDisplay/XMLDisplay'
import { RequestHelper } from '../../../../../helpers/requestHelper'
import DeviceHelper from '../../../../../helpers/DeviceHelper'
import { utilidades } from '../../../../../helpers/utilidades'
//#endregion imports

//#region types'

//#endregion

interface IVentaProps {
  info: DocumentInfo<VentaListado>
  tabId: string
  tab: TabState<VentaListado>
  templateMode?: boolean
}

const Venta: React.FunctionComponent<IVentaProps> = (props) => {
  const { tabId, info, tab } = props
  const urls = UrlHelper.getUrls()

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const maxAllowDiasVencidos = useSelector(
    (state: RootState) =>
      state.global.session.empresa.configuracion.cantidadDiasMoraPago,
  )
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const local = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  //const currentTab = useSelector((state: RootState) => { return state.ventas.ventas.tabs.tabs[props.tabIndex] });
  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.ventas.ventas.tabs
  })
  const detalles = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].detalles
  })
  //const templateMode = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].templateMode });
  const imprimir = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].imprimir
  })
  const cliente = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].cliente
  })
  const observaciones = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].observaciones
  })
  const claveAcceso = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].claveAcceso
  })
  const fecha = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].fecha
  })
  const loading = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].loading
  })
  const descuentoGlobal = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].descuentoGlobal
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const usaDescuentoGlobal = useSelector((state: RootState) => {
    return state.ventas.ventas.configuraciones.usaDescuentoGlobal
  })
  const formasPago = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].formasPago
  })
  const formaPago = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].formaPago
  })
  const tiposPagos = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].tiposPagos
  })
  const iva = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].iva
  })
  const cuotas = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].cuotas
  })
  const totales = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].totales
  })
  const mostrarBusquedaItems = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].mostrarBusquedaItems
  })
  const desdePlantilla = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].desdePlantilla
  })
  const autorizacionVenta = useSelector((state: RootState) => {
    return state.ventas.ventas.editData[props.tabId].autorizacionVenta
  })
  const cuotasVencidas = useSelector(
    (state: RootState) => state.suscripcionUpgrate.cuotasVencidas,
  )
  const autorizacionModulo = useSelector(
    (state: RootState) =>
      state.ventas.ventas.configuraciones.autorizacionDocumento,
  )
  const precision = useSelector((state: RootState) => {
    return state.global.session?.empresa?.precision
  })
  const mostrarIngresoCaja = useSelector((state: RootState) => {
    return state.ventas?.ventas?.configuraciones?.mostrarIngresaCaja
  })
  const currentButtons = useSelector((state: RootState) => {
    return state.ventas?.ventas?.tabs?.tabs[props.tabId]?.buttons
  })

  const { inmediato } = useValidarCuotasVencidas(cuotasVencidas)

  const dialogRef = React.useRef<any>(null)

  const [filaEliminar, setFilaEliminar] = React.useState(-1)
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false)
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false)
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('')
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{
    claveAcceso: string
    design: boolean
  }>()
  const [mostrarIngresoCreditos, setMostrarIngresoCreditos] =
    React.useState(false)
  const [variasFormasPago, setVariasFormasPago] = React.useState(false)
  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false)
  const [mostrarAnular, setMostrarAnular] = React.useState(false)
  const [habilitarDisenador, setHabilitarDisenador] =
    React.useState<boolean>(false)
  const [modalOrdenProforma, setModalOrdenProforma] = React.useState<{
    show: boolean
    orden: OrdenProformaListado | null
  }>({
    show: false,
    orden: null,
  })
  const [modalXML, setModalXML] = React.useState<{
    show: boolean
    urlBlobXml: Blob | null
    urlXml: Blob | null
    fileName: string
  }>({
    show: false,
    urlBlobXml: null,
    urlXml: null,
    fileName: '',
  })

  const handleShowModalInvoiceChange = React.useCallback(
    (key: string, value: boolean) => {
      if (value) {
        dispatch(
          setMostrarIngresoCaja({
            key: key,
            mostrar: value,
          }),
        )
      } else {
        dispatch(removeMostrarIngresoCaja(key))
      }
    },
    [dispatch],
  )

  const getVerificaPath = React.useCallback(async () => {
    if (urls.origin.includes('acatha.io')) {
      setHabilitarDisenador(false)
    } else {
      setHabilitarDisenador(false)
    }
  }, [urls])

  const handleChangeModalOrdenProforma = React.useCallback((value) => {
    setModalOrdenProforma({
      ...value,
    })
  }, [])

  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleVentaRedux>> = []
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: 'minus',
      onExecute: (rowIndex) => {
        setFilaEliminar(rowIndex)
      },
    })
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: 'edit',
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true)
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras)
      },
    })
    return commads
  }, [detalles])

  const rideMemo = React.useMemo(() => {
    consoleService.log('rideMemo')
    consoleService.log('verFacturaPdf', verFacturaPdf)
    consoleService.log('habilitarDisenador', habilitarDisenador)
    if (verFacturaPdf === undefined) {
      return
    }

    if (tabs ? tab.tabKey !== tabs.current : false) {
      return
    }

    return (
      <VerRIDE
        modo={habilitarDisenador ? 'DevExpressReport' : 'Legacy'}
        claveAcceso={verFacturaPdf?.claveAcceso}
        vista={verFacturaPdf?.design ? 'Designer' : 'Viewer'}
        onClose={() => {
          setVerFacturaPdf(undefined)
          dispatch(
            setImprimir({
              imprimir: false,
              key: tabId,
            }),
          )
        }}
      />
    )
  }, [habilitarDisenador, dispatch, tabId, verFacturaPdf])

  const XMLModalMemo = React.useMemo(() => {
    if (modalXML === undefined || modalXML === null) {
      return
    }

    if (!modalXML?.show) {
      return
    }

    return (
      <XMLDisplay
        show={modalXML?.show}
        urlBlobPdf={modalXML?.urlBlobXml}
        urlXml={modalXML?.urlXml}
        fileName={modalXML?.fileName}
        onClose={() => {
          setModalXML({
            ...modalXML,
            show: false,
          })
        }}
      />
    )
  }, [modalXML])

  const OrdenProformaMemo = React.useMemo(() => {
    if (modalOrdenProforma.show) {
      return (
        <ModalProformas
          show={modalOrdenProforma.show}
          onChanged={(value) => {
            handleChangeModalOrdenProforma({
              ...modalOrdenProforma,
              orden: value,
              show: false,
            })
          }}
          onCancel={() => {
            handleChangeModalOrdenProforma({
              ...modalOrdenProforma,
              show: false,
            })
          }}
          orden={modalOrdenProforma?.orden}
        />
      )
    }

    return <></>
  }, [modalOrdenProforma, handleChangeModalOrdenProforma])

  const InvoiceChangeMemo = React.useMemo(() => {
    if (tabs ? tab.tabKey !== tabs.current : false) {
      return
    }

    if (!mostrarIngresoCaja[tabId]) {
      return <></>
    }

    return (
      <InvoiceChange
        show={mostrarIngresoCaja[tabId]}
        setShow={(value: boolean) => {
          handleShowModalInvoiceChange(tabId, value)
        }}
        total={datosEdicion?.totales?.total}
      />
    )
  }, [
    mostrarIngresoCaja,
    tabId,
    tab,
    tabs,
    datosEdicion,
    handleShowModalInvoiceChange,
  ])

  const onVerXML = React.useCallback(
    async (item: FacturaDatosEdicion) => {
      try {
        dispatch(
          changeLoader({
            mensaje: 'Cargando...',
            show: true,
          }),
        )

        const responseXML = await RequestHelper.getAll<any>(
          'ventas',
          'obtenerJson',
          '',
          {
            selloRecibido: item.claveAcceso,
            ext: '.xml',
          },
        )

        const blobForPreview = new Blob([responseXML], {
          type: 'application/xml',
        })
        const blobForDownload = new Blob([responseXML], {
          type: 'application/xml',
        })

        setModalXML({
          show: true,
          urlBlobXml: blobForPreview,
          urlXml: blobForDownload,
          fileName: `${item.claveAcceso}.xml`,
        })

        dispatch(
          changeLoader({
            mensaje: '',
            show: false,
          }),
        )
      } catch (error) {
        dispatch(
          changeLoader({
            mensaje: '',
            show: false,
          }),
        )
        dispatch(
          addToast({
            content: 'Lo siento, algo salió mal al recuperar el archivo XML.',
            type: ToastTypes.Warning,
          }),
        )
        setModalXML({
          show: false,
          urlBlobXml: null,
          urlXml: null,
          fileName: '',
        })
      }
    },
    [dispatch],
  )

  const validarPorcentajeDescuentoFactura = React.useCallback(
    (totalSinDescuento, valorDescuento): boolean => {
      if (local && empresa) {
        const porcentajeDescontado = (100 / totalSinDescuento) * valorDescuento
        const maximoDescuento =
          local.descuento > 0 ? local.descuento : empresa.descuento
        return porcentajeDescontado <= maximoDescuento
      }
      const mensajeError = 'El estado es incorrecto (local, empresa)'
      throw mensajeError
    },
    [local, empresa],
  )

  const cargarVenta = React.useCallback(
    async (numero: number, setInitData: boolean) => {
      if (datosEdicion.imprimir) {
        setVerFacturaPdf({
          claveAcceso: datosEdicion.claveAcceso,
          design: false,
        })
      }
      if (datosEdicion.loading === false) {
        return
      }
      const data = { ...defaultDatosEdicionFactura }
      try {
        if (setInitData)
          dispatch(
            setDatosEdicion({
              data: { ...data },
              key: tabId,
            }),
          )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.find,
          }),
        )
        const venta = await VentasService.getVenta(numero) //VentasService.getVentas(0, "", "", "", "", "", "", undefined, clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.Factura);
        if (venta) {
          const detalles = await VentasService.getDetalleVenta(
            numero,
            TiposComprobantesSri.Factura,
          )
          const cliente = await ClientesService.getCliente(
            venta?.clienteIdentificacion,
          )
          const formasPago = await VentasService.getFormasPago(
            cliente.identificacion,
          )
          const iva = await VentasService.getIva(venta.fecha, null)
          data.iva = iva
          data.establecimiento = venta.establecimiento
          data.puntoEmision = venta.puntoEmision
          data.numerofactura = venta.numero
          data.autorizacionHacienda = venta.autorizacionHacienda
          data.observaciones = venta.observaciones
          data.formasPago = formasPago
          const formaPago = formasPago.find((x) => x.codigo === venta.formaPago)
          if (formaPago) {
            data.formaPago = [formaPago]
          }
          data.cliente = cliente
          const tmpDet2 = VentaHelper.detallesToDetallesVenta(
            detalles,
            empresa?.precision ?? 4,
          )
          data.detalles = tmpDet2
          data.fechaAutorizacion = venta.claveAccesoFecha ?? ''
          data.claveAcceso = venta.claveAcceso ?? ''
          data.fecha = DateUtils.apiDateToPickersDate(venta.fecha)
          data.descuentoGlobal = venta.descuentoGlobal
          data.numero = venta.codigo
          data.loader = {
            mensaje: '',
            show: false,
          }
          data.loading = false

          dispatch(
            setDatosEdicion({
              data: data,
              key: tabId,
            }),
          )

          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...InvoiceButtons,
                Guardar: false,
                Autorizar: venta.claveAccesoFecha === '',
                Enviar: venta.claveAccesoFecha !== '',
                Deshacer: false,
                Descuento: false,
              },
            }),
          )
        }
      } catch (error) {
        data.tieneError = true
        data.mensajeError = error
        data.loading = false
        dispatch(
          setDatosEdicion({
            data: data,
            key: tabId,
          }),
        )
      }
    },
    [
      empresa,
      datosEdicion.imprimir,
      datosEdicion.loading,
      datosEdicion.claveAcceso,
      dispatch,
      tabId,
    ],
  )

  const buttonsModoNuevo = React.useCallback(() => {
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...InvoiceButtons,
          Editar: false,
          Imprimir: false,
          Exportar: false,
          Autorizar: false,
          Enviar: false,
          Guardar: true,
        },
      }),
    )
  }, [dispatch, tabId])

  const modoNuevo = React.useCallback(
    async (puntoVenta: string, template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      sessionStorage.removeItem('autorizacionModulo')

      const data = { ...defaultDatosEdicionFactura }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...InvoiceButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
            [ButtonTypes.generateElectronicDocument]: true,
          },
        }),
      )
      try {
        const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers)

        let autorizacion
        if (!autorizacionVenta) {
          autorizacion = await VentasService.getAutorizacion(
            puntoVenta,
            TiposComprobantesSri.Factura,
          )
          dispatch(setAutorizacionModulo(autorizacion))
        } else {
          autorizacion = autorizacionVenta
        }

        consoleService.log('autorizacion', autorizacion)
        dispatch(setAutorizacionModulo(autorizacion))
        sessionStorage.setItem(
          'autorizacionModulo',
          JSON.stringify(autorizacion),
        )

        const iva = await VentasService.getIva(
          DateUtils.pickersDateToApiDate(fecha),
          autorizacion,
        )
        if (template > 0) {
          const venta = await VentasService.getVenta(template)
          if (venta) {
            const detalles = await VentasService.getDetalleVenta(
              template,
              TiposComprobantesSri.Factura,
            )
            const cliente = await ClientesService.getCliente(
              venta?.clienteIdentificacion,
            )
            const tmpDet2 = VentaHelper.detallesToDetallesVenta(
              detalles,
              empresa?.precision ?? 4,
            )
            data.detalles = tmpDet2
            data.cliente = cliente
            data.observaciones = venta.observaciones
            const formasPago = await VentasService.getFormasPago(
              cliente.identificacion,
            )
            data.formasPago = formasPago
            const fp = formasPago.find((fp) => fp.codigo === venta.formaPago)
            if (fp) {
              data.formaPago = [fp]
            }
            data.desdePlantilla = true
          }
        } else {
          const cliente = await ClientesService.getCliente(
            config.rucConsumidorFinal,
          )
          data.cliente = cliente
          data.detalles = []
          data.observaciones = ''
          const formasPago = await VentasService.getFormasPago(
            config.rucConsumidorFinal,
          )
          data.formasPago = formasPago
          const fp = formasPago.find((fp) => fp.codigo === 6)
          if (fp) {
            data.formaPago = [fp]
          }
          data.desdePlantilla = false
        }

        data.autorizacion = ''
        data.autorizacionVenta = autorizacion
        data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)'
        // almacena la fecha para tomar como parametro para buscar los items con esta fecha
        sessionStorage.setItem('fechaFacturar', JSON.stringify(fecha))

        data.iva = iva
        data.fecha = fecha
        data.establecimiento = autorizacion.establecimiento
        data.puntoEmision = autorizacion.ptoemision
        data.autorizacionHacienda = autorizacion.autorizacion
        data.numerofactura = autorizacion.numActual.toString()
        data.identificadorTransaccion = utilidades.getUUID()

        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
        dispatch(
          addToast({
            content: data.mensajeError,
            type: ToastTypes.Danger,
          }),
        )
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )
    },
    [loading, tabId, empresa, dispatch, autorizacionVenta],
  )

  const modoEdicion = React.useCallback(() => {
    if (cliente == null) {
      return
    }
    if (claveAcceso.length === 37 || claveAcceso.length === 49) {
      dispatch(
        addToast({
          autoHide: true,
          content: 'Una factura eléctronica no puede ser editada.',
          fade: true,
          id: '',
          title: 'Editar factura',
          type: ToastTypes.Info,
        }),
      )
    }
  }, [dispatch, claveAcceso, cliente])

  const onControllerRecurrente = React.useCallback(async () => {
    if (licenceHelper.isLicence() === true) {
      return true
    } else {
      let mensaje: string = ''

      if (inmediato > Number(maxAllowDiasVencidos)) {
        mensaje =
          'Mantienes pagos vencidos, realizalos para reactivar el servicio.'
        dispatch(
          addToast({
            autoHide: true,
            content: mensaje,
            fade: true,
            title: 'Deudas vencidas.',
            type: ToastTypes.Warning,
          }),
        )
        navigate('/past_due_payments')
        return false
      }

      // verificar plan activo
      const contrato = await ComprobantesService.getRecurrente(empresa.uuid)
      if (contrato.codigo && contrato.planActivo === false) {
        dispatch(setLoadSummaryDocs(true))
        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              loader: {
                show: false,
                mensaje: '',
              },
            },
            key: tabId,
          }),
        )
        dispatch(
          addToast({
            autoHide: true,
            content: lh.getMessage(MessagesKeys.GenericSuscriptionEnd, ''),
            fade: true,
            title: 'Renovar Suscripción.',
            type: ToastTypes.Warning,
          }),
        )
        return false
      }

      return true
    }
  }, [
    datosEdicion,
    dispatch,
    empresa,
    navigate,
    tabId,
    maxAllowDiasVencidos,
    inmediato,
  ])

  const guardar = React.useCallback(async () => {
    if (
      usuario == null ||
      empresa == null ||
      local == null ||
      puntoVenta == null
    ) {
      return
    }
    consoleService.log('datosEdicion', datosEdicion)

    if (autorizacionModulo === null || autorizacionModulo === undefined) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          autoHide: true,
          content: 'No se puede encontrar la autorización de este modulo.',
          fade: true,
          title: 'Autorización',
          type: ToastTypes.Warning,
        }),
      )
      return
    } else {
      if (
        autorizacionModulo.idTipoDeNegocio === null ||
        autorizacionModulo.idTipoDeNegocio === undefined
      ) {
        buttonsModoNuevo()
        dispatch(
          addToast({
            autoHide: true,
            content:
              'No se puede encontrar el tipo de negocio de esta empresa, por favor configura la autorización para emitir este documento.',
            fade: true,
            title: 'Tipo de negocio',
            type: ToastTypes.Warning,
          }),
        )
        return
      }
    }

    if (datosEdicion.detalles.length === 0) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          autoHide: true,
          content: 'La factura no tiene detalles',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (datosEdicion.totales.total <= 0) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          autoHide: true,
          content: 'La factura no puede ser por un valor menor que 0',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }
    if (!datosEdicion.cliente) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Debe de seleccionar el cliente',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (
      datosEdicion.formaPago === null ||
      datosEdicion.formaPago.length === 0 ||
      datosEdicion.formaPago[0] == null
    ) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Debe de seleccionar la forma de pago',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (
      !validarPorcentajeDescuentoFactura(
        datosEdicion.totales.subtotal,
        datosEdicion.totales.descuento,
      )
    ) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'El descuento aplicado en la factura sobrepasa el máximo permitido',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Warning,
        }),
      )
      return
    }

    if (empresa.consumidorFinalValor > 0) {
      let controlarIvaMax: boolean = true
      if (
        [
          ETiposNegociosPermitidosMaxCFinal.transporteComercialTaxis,
          ETiposNegociosPermitidosMaxCFinal.transportePublicoIntra,
        ].includes(autorizacionModulo.idTipoDeNegocio)
      ) {
        controlarIvaMax = false
      }
      if (controlarIvaMax) {
        if (
          datosEdicion.totales.total > empresa.consumidorFinalValor &&
          datosEdicion.cliente.identificacion === config.rucConsumidorFinal
        ) {
          buttonsModoNuevo()
          dispatch(
            addToast({
              title: 'Guardar',
              content: `${'La factura excede el monto maximo ($'} ${
                empresa?.consumidorFinalValor ?? 0
              } ${') permitido para consumidor final, por favor ingrese los datos del cliente'}`,
              type: ToastTypes.Warning,
            }),
          )
          return
        }
      }
    }

    if (
      datosEdicion.formaPago[0].codigo === EFormasPago.credito &&
      datosEdicion?.cuotas.length === 0
    ) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          title: 'Guardar',
          content: 'Por favor, detalle la forma de pago!!!',
          type: ToastTypes.Warning,
        }),
      )
      setMostrarIngresoCreditos(true)
      return
    } else if (
      datosEdicion.formaPago[0].codigo === EFormasPago.otros &&
      datosEdicion?.tiposPagos.length === 0
    ) {
      buttonsModoNuevo()
      dispatch(
        addToast({
          title: 'Guardar',
          content: 'Por favor, detalle los metodos de pago!!!',
          type: ToastTypes.Warning,
        }),
      )
      setVariasFormasPago(true)
      return
    }

    let pagos: Array<TFacturaIngresarFormaPago2> = []
    if (datosEdicion.formaPago[0].codigo === EFormasPago.otros) {
      let sumatoriaEnFormas: number = 0
      for (let index = 0; index < datosEdicion?.tiposPagos.length; index++) {
        consoleService.log(
          'datosEdicion?.tiposPagos[index].valor',
          datosEdicion?.tiposPagos[index].valor,
        )

        pagos.push({
          total: parseFloat(datosEdicion?.tiposPagos[index].valor.toFixed(2)),
          medio:
            datosEdicion?.tiposPagos[index].descripcionFormaPago.toString(),
          id_medio: datosEdicion?.tiposPagos[index].codigoFormaPago.toString(),
        })
        sumatoriaEnFormas += parseFloat(
          datosEdicion?.tiposPagos[index].valor.toFixed(2),
        )
      }
      consoleService.log('sumatoriaEnFormas', sumatoriaEnFormas)
      consoleService.log('datosEdicion.totales', datosEdicion.totales)
      if (
        sumatoriaEnFormas !== parseFloat(datosEdicion.totales.total.toFixed(2))
      ) {
        buttonsModoNuevo()
        dispatch(
          addToast({
            title: 'Guardar',
            content:
              'Por favor, verificar los valores en las distintas formas de pago agregadas!!!',
            type: ToastTypes.Warning,
          }),
        )
        return
      }
    } else {
      pagos = [
        {
          total: parseFloat(datosEdicion.totales.total.toFixed(2)),
          medio: datosEdicion.formaPago[0].descripcion,
          id_medio: datosEdicion.formaPago[0].codigo.toString(),
        },
      ]
    }

    try {
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: true,
              mensaje: 'Guardando factura...',
            },
          },
          key: tabId,
        }),
      )

      // verifica deudas
      // cuotasVencidas

      // recurrent control
      if (
        empresa?.codigoPais === ECountry.ElSalvador &&
        getAmbiente(empresa) === EAmbiente.Produccion
      ) {
        const controlRecurrente: boolean = await onControllerRecurrente()
        if (controlRecurrente === false) {
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: undefined,
            }),
          )
          return false
        }
      } else if (empresa?.codigoPais === ECountry.Ecuador) {
        const controlRecurrente: boolean = await onControllerRecurrente()
        if (controlRecurrente === false) {
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: undefined,
            }),
          )
          return false
        }
      }

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.save,
        }),
      )

      consoleService.log('seguimieto identificadorTransaccion ')
      consoleService.log(
        'datosEdicion?.identificadorTransaccion ',
        datosEdicion?.identificadorTransaccion,
      )

      const totalesImuestos: Array<TFacturaIngresarImpuesto> = []

      if (datosEdicion?.totales?.subtotal0) {
        totalesImuestos.push({
          codigo: 2,
          codigo_porcentaje: 0,
          descuento_adicional: 0,
          base_imponible: parseFloat(
            datosEdicion.detalles
              .reduce(
                (prev, next) =>
                  prev +
                  next._subtotal0 +
                  (next._subtotal0 > 0 ? next._descuento : 0),
                0,
              )
              .toFixed(2),
          ),
          valor: 0,
        })
      }

      if (datosEdicion?.totales?.subtotal5) {
        totalesImuestos.push({
          codigo: 2,
          codigo_porcentaje: 5,
          descuento_adicional: 0,
          base_imponible: parseFloat(
            datosEdicion.detalles
              .reduce(
                (prev, next) =>
                  prev +
                  next._subtotal5 +
                  (next._subtotal5 > 0 ? next._descuento : 0),
                0,
              )
              .toFixed(2),
          ),
          valor: parseFloat(
            (
              datosEdicion.detalles.reduce(
                (prev, next) =>
                  prev +
                  next._subtotal5 +
                  (next._subtotal5 > 0 ? next._descuento : 0),
                0,
              ) * 0.05
            ).toFixed(2),
          ),
        })
      }

      if (datosEdicion?.totales?.subtotalIva) {
        const base_imponible = parseFloat(
          datosEdicion.detalles
            .reduce(
              (prev, next) =>
                prev +
                (next.codigoImpuesto === 3
                  ? next._subtotalIva +
                    (next._subtotalIva > 0 ? next._descuento : 0)
                  : 0),
              0,
            )
            .toFixed(2),
        )
        const valor = parseFloat(
          datosEdicion.detalles
            .reduce(
              (prev, next) =>
                prev + (next.codigoImpuesto === 3 ? next._iva : 0),
              0,
            )
            .toFixed(2),
        )
        totalesImuestos.push({
          codigo: 2,
          codigo_porcentaje: 2,
          descuento_adicional: 0,
          base_imponible: base_imponible,
          valor: valor,
        })
      }

      const factura: TFacturaIngresar = {
        identificador:
          datosEdicion?.identificadorTransaccion ?? utilidades.getUUID(),
        tipodoc: '01',
        tipo_emision: '1',
        local: local,
        pventa: puntoVenta,
        fecha_emision: DateUtils.format(fecha, formatoFechasApi),
        emisor: {
          ruc: empresa.ruc,
          razon_social: empresa.nombre,
          nombre_comercial: empresa.comercial,
          contribuyente_especial: '',
          direccion: empresa.direccion,
          obligado_contabilidad: true,
          establecimiento: {
            punto_emision: datosEdicion.puntoEmision,
            codigo: local.codigo.toString(),
            direccion: local.direccion,
          },
        },
        comprador: {
          tipo_identificacion: datosEdicion.cliente?.tipoIdentificacion,
          identificacion: datosEdicion.cliente?.identificacion,
          nombres: datosEdicion.cliente.nombres,
          razon_social: datosEdicion.cliente.nombreComercial ?? '',
          direccion: datosEdicion.cliente.direccion,
          email: datosEdicion.cliente.email ?? '',
          telefono: datosEdicion.cliente.telefono ?? 'S/T',
          ciudad: datosEdicion.cliente.ciudad,
        },
        informacion_adicional: {
          Cliente: datosEdicion.cliente.nombres,
          Enviado_a: datosEdicion.cliente.email ?? '',
          Direccion: datosEdicion.cliente.direccion,
          Telefono: datosEdicion.cliente.telefono ?? 'S/T',
        },
        totales: {
          total_sin_impuestos: parseFloat(
            datosEdicion.detalles
              .reduce(
                (prev, next) => prev + (next._subtotal + next._descuento),
                0,
              )
              .toFixed(2),
          ),
          importe_total: parseFloat(datosEdicion.totales.total.toFixed(2)),
          propina: 0,
          descuento: parseFloat(datosEdicion.totales.descuento.toFixed(2)),
          descuento_adicional: datosEdicion.totales.descuentoGloabal,
          impuestos: totalesImuestos,
          servicio: datosEdicion.totales.servicio ?? 0,
        },
        observaciones: datosEdicion.observaciones,
        moneda: 'USD',
        formaPago: {
          value: datosEdicion.formaPago[0].codigo,
          label: datosEdicion.formaPago[0].descripcion,
        },
        ambiente: empresa.uso_electronica.toString(),
        items: datosEdicion.detalles.map((det) => {
          const detalle: TFacturaIngresarDetalle = {
            codigo_auxiliar: det.codigo.toString(),
            codigo_principal: det.codigoBarras,
            precio_unitario: det._precioUnitario,
            cantidad: det._cantidad,
            precio_total_sin_impuestos: det._subtotal + det._descuento,
            descripcion: det.descripcion,
            descuento_porcentaje: det._porcentajeDescuento,
            descuento_valor: det._descuento,
            detalles_adicionales: {},
            impuestos: [
              {
                codigo: det?.codigoImpuesto ?? 2,
                tarifa: (det.porcentajeiva * 100).toFixed(0),
                codigo_porcentaje: det.codigoImpuesto,
                base_imponible: det._subtotal + det._descuento,
                valor: det._iva,
              },
            ],
          }
          return detalle
        }),
        cuotas: datosEdicion?.cuotas.map((c) => {
          return {
            cuota: c.cuota,
            fechaVencimiento: c.vencimiento,
            saldo: c.saldo,
            interes: 0,
            letra: c.letra,
            saldoCapital: c.saldo,
          }
        }),
        pagos: pagos,
        tiposPagos: datosEdicion?.tiposPagos,
      }

      const resultado = await VentasService.ingresarVenta(factura)
      consoleService.log('resultado ', resultado)

      if (resultado.error === false) {
        if (datosEdicion.formaPago[0]?.codigo === EFormasPago.efectivo) {
          if (empresa?.emitirCambio && empresa?.emitirCambio === 1) {
            handleShowModalInvoiceChange(tabId, true)
          }
        }

        const ventaRegistrada: VentaListado = resultado['auto']

        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              claveAcceso: ventaRegistrada.claveAcceso,
              autorizacion: ventaRegistrada.claveAcceso,
              fechaAutorizacion: ventaRegistrada.fechaAutorizacion ?? '',
              numerofactura: ventaRegistrada.numero,
              fecha: DateUtils.apiDateToPickersDate(ventaRegistrada.fecha),
              loader: {
                show: false,
                mensaje: '',
              },
              imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false,
            },
            key: tabId,
          }),
        )
        dispatch(
          changeEditStatus({
            estado: StatesEdition.save,
            tabKey: tabId,
            info: ventaRegistrada,
            buttons: {
              ...InvoiceButtons,
              Guardar: false,
              Editar: true,
              Deshacer: false,
              Autorizar: ventaRegistrada.estadoElectronico !== 'AUTORIZADO',
              Enviar: ventaRegistrada.estadoElectronico === 'AUTORIZADO',
              Descuento: false,
              Anular: ventaRegistrada.estadoElectronico === 'AUTORIZADO',
            },
          }),
        )
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: resultado.message ?? 'Registro Satisfactorio',
            fade: true,
            title: 'Guardar',
            type: resultado.message.includes('OBSERVACIONES')
              ? ToastTypes.Warning
              : ToastTypes.Success,
          }),
        )
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...InvoiceButtons,
              Editar: false,
              Imprimir: false,
              Exportar: false,
              Autorizar: false,
              Enviar: false,
              Guardar: false,
            },
          }),
        )
      } else {
        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              loader: {
                show: false,
                mensaje: '',
              },
            },
            key: tabId,
          }),
        )
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: resultado.message ?? 'Error al guardar la factura.',
            fade: true,
            title: 'Guardar',
            type: ToastTypes.Danger,
          }),
        )
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        buttonsModoNuevo()
      }
    } catch (error) {
      consoleService.error(error)
      buttonsModoNuevo()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al guardar factura ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )

      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      return
    }
  }, [
    datosEdicion,
    empresa,
    local,
    puntoVenta,
    usuario,
    tabId,
    fecha,
    validarPorcentajeDescuentoFactura,
    dispatch,
    autorizacionModulo,
    buttonsModoNuevo,
    onControllerRecurrente,
    handleShowModalInvoiceChange,
  ])

  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      const tId: any = tabId
      dispatch(closeTab(tId))
      dispatch(clearDatosEdicion(tId))
    } else {
      dispatch(
        changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          buttons: { ...InvoiceButtons },
        }),
      )
      cargarVenta(info.numero, false)
    }
  }, [info, cargarVenta, tabId, dispatch])

  const showEditLoader = React.useCallback(
    (mensaje: string) => {
      dispatch(
        setEditLoader({
          key: tabId,
          info: {
            mensaje: mensaje,
            show: true,
          },
        }),
      )
    },
    [tabId, dispatch],
  )

  const hideEditLoader = React.useCallback(() => {
    dispatch(
      setEditLoader({
        key: tabId,
        info: {
          mensaje: '',
          show: false,
        },
      }),
    )
  }, [tabId, dispatch])

  const sheetOnCellsChanged = React.useCallback(
    async (arrayOfChanges: Array<CellChangedArgs<DetalleVentaRedux>>) => {
      const _detalles = detalles.slice(0)
      for (const change of arrayOfChanges) {
        const detalle = new DetalleVenta() //clase que realiza los calculos para un detalle
        detalle.setDetalle(change.rowData, empresa?.precision ?? 4)

        let fecha = ''
        if (detalle.fecha !== '') {
          fecha = DateUtils.pickersDateToApiDate(detalle.fecha)
        } else {
          fecha = DateUtils.getCurrentDateAsString()
        }

        switch (change.col.name) {
          case 'codigoBarras':
            showEditLoader('Buscando item...')
            // eslint-disable-next-line no-case-declarations

            if (empresa.distinguir === 1 && _detalles.length > 0) {
              const filtrarDetalle = _detalles.findIndex(
                (x) => x.codigoBarras === detalle.codigoBarras,
              )
              if (filtrarDetalle > -1) {
                const item = await VentasService.getItemPorCodigoBarras(
                  detalle.codigoBarras,
                  datosEdicion.cliente?.identificacion ??
                    config.rucConsumidorFinal,
                  fecha,
                )
                detalle.cantidad = _detalles[filtrarDetalle]._cantidad + 1
                detalle.setItem(item, empresa?.precision ?? 4)
                consoleService.log('detalle:', detalle)
                consoleService.log('_detalles:', _detalles)
                _detalles.splice(filtrarDetalle, 1)
                hideEditLoader()
              } else {
                const item = await VentasService.getItemPorCodigoBarras(
                  detalle.codigoBarras,
                  datosEdicion.cliente?.identificacion ??
                    config.rucConsumidorFinal,
                  fecha,
                )
                if (item) {
                  detalle.setItem(item, empresa?.precision ?? 4)
                  hideEditLoader()
                } else {
                  dispatch(
                    addToast({
                      content:
                        'No se encuentra el item con código ' + change.newValue,
                      title: 'Agregar item',
                      type: ToastTypes.Warning,
                      autoHide: 2000,
                    }),
                  )
                  continue
                }
              }
            } else {
              const item = await VentasService.getItemPorCodigoBarras(
                detalle.codigoBarras,
                datosEdicion.cliente?.identificacion ??
                  config.rucConsumidorFinal,
                fecha,
              )
              if (item) {
                detalle.setItem(item, empresa?.precision ?? 4)
                hideEditLoader()
              } else {
                dispatch(
                  addToast({
                    content:
                      'No se encuentra el item con código ' + change.newValue,
                    title: 'Agregar item',
                    type: ToastTypes.Warning,
                    autoHide: 2000,
                  }),
                )
                continue
              }
            }

            break
          case 'cantidad':
            detalle.cantidad = change.newValue
            detalle.calcular()
            break
          case 'descripcion':
            if (typeof change.newValue === 'string') {
              if (detalle.codigo === undefined) {
                dispatch(
                  addToast({
                    content:
                      'No se puede pegar un item, debe usar la búsqueda o la columna código.',
                    title: 'Agregar item',
                    type: ToastTypes.Warning,
                    autoHide: 2000,
                  }),
                )
                continue
              }
              detalle.descripcion = change.newValue
            }
            break
          case '_cantidad':
            detalle.cantidad = parseFloat(change.newValue)
            //detalle.calcular();
            break
          case '_porcentajeDescuento':
            detalle.porcentajeDescuento = parseFloat(change.newValue)
            //detalle.calcular();
            break
          case '_precioUnitario':
            detalle.precio = parseFloat(change.newValue)
            //detalle.calcular();
            break
          case '_precioIva':
            detalle.precioIva = parseFloat(change.newValue)
            //detalle.calcular();
            break
          default:
            break
        }
        if (change.rowIndex === -1) {
          //nueva fila
          _detalles.push(detalle.getDetalle())
        } else {
          _detalles[change.rowIndex] = detalle.getDetalle()
        }
      }
      dispatch(
        setCuotasCredito({
          key: tabId,
          cuotas: [],
        }),
      )
      dispatch(
        setMetodosAgregados({
          key: tabId,
          tiposPagos: [],
        }),
      )
      dispatch(
        updateDetalles({
          detalles: _detalles,
          key: tabId,
        }),
      )
      hideEditLoader()
    },
    [
      datosEdicion,
      detalles,
      empresa,
      dispatch,
      hideEditLoader,
      tabId,
      showEditLoader,
    ],
  )

  const calcularTotales = React.useCallback(
    (detalles: Array<DetalleVentaRedux>, descuentoGlobal: number) => {
      const totalCero: TTotalesFactura = {
        descuento: 0,
        descuentoGloabal: descuentoGlobal,
        ice: 0,
        iva: 0,
        subtotal: 0,
        subtotal0: 0,
        subtotal5: 0,
        subtotalIva: 0,
        total: 0,
        servicio: 0,
      }

      const totales = detalles.reduce((sum, next) => {
        sum.subtotal += next._subtotal
        sum.subtotal0 += next._subtotal0
        sum.subtotal5 += next._subtotal5
        sum.subtotalIva += next._subtotalIva
        sum.iva += next._iva
        sum.descuento += parseFloat(next._descuento.toFixed(2))

        sum.total += next._total

        return sum
      }, totalCero)

      if (autorizacionModulo?.restaurante) {
        totales.servicio = totales.subtotal * 0.1
        totales.total = totales.total + totales.servicio
      }

      if (descuentoGlobal > totales.total) {
        dispatch(
          addToast({
            title: 'Calcular totales',
            content: 'El descuento no puede ser mayor al total',
            type: ToastTypes.Warning,
            autoHide: 2500,
          }),
        )
      }

      if (descuentoGlobal > 0) {
        if (totales.total === 0) {
          return
        }

        const proporciaonalIva =
          ((100 / totales.subtotal) * totales.subtotalIva) / 100
        const proporcionalBaseCero = 1 - proporciaonalIva

        const proporciaonalIva5 =
          ((100 / totales?.subtotal) * totales.subtotal5) / 100

        const proporcionalBaseCero5 = 1 - proporciaonalIva5

        totales.subtotalIva =
          totales.subtotalIva - descuentoGlobal * proporciaonalIva
        totales.iva = totales.iva * 1
        totales.subtotal0 =
          totales.subtotal0 - descuentoGlobal * proporcionalBaseCero
        totales.subtotal5 =
          totales.subtotal5 - descuentoGlobal * proporcionalBaseCero5
        totales.total =
          totales.subtotalIva +
          totales.iva +
          totales.subtotal0 +
          totales.subtotal5

        if (autorizacionModulo?.restaurante) {
          totales.servicio = totales.subtotal * 0.1
          totales.total = totales.total + totales.servicio
        }
      } else if (descuentoGlobal < 0) {
        return
      }

      dispatch(
        updateTotales({
          totales: totales,
          key: tabId,
        }),
      )
    },
    [tabId, dispatch, autorizacionModulo],
  )

  const parseOrdenToVenta = React.useCallback(
    async (orden: OrdenProformaListado) => {
      const parseLocal = (localData: any): Local => {
        return {
          codigo: Number(localData.codigo),
          clase: Number(localData.claseCodigo),
          nombre: localData.nombre,
          siglas: localData.siglas,
          ciudad: localData.ciudadCodigo
            ? {
                codigo: Number(localData.ciudadCodigo),
                descripcion: localData.ciudadNombre,
              }
            : null,
          direccion: localData.direccion,
          telefono: localData.telefono,
          gerente: localData.gerente,
          tipo: localData.tipo
            ? {
                value: Number(localData.tipo),
                label: localData.tipoDescripcion,
              }
            : null,
          pertenece: localData.perteneceCodigo
            ? {
                pertenece: Number(localData.perteneceCodigo),
                perteneceDescripcion: localData.perteneceDescripcion,
              }
            : null,
          kardex: Number(localData.controlaKardex),
          estado: localData.estadoCodigo
            ? {
                codigo: Number(localData.estadoCodigo),
                descripcion: localData.estadoDescripcion,
              }
            : null,
          siglasLocal: localData.siglas,
          siglasBodega: localData.siglasBod,
          web: Number(localData.web),
          uaf: localData.uaf || '',
          descuento: Number(localData.descuento),
          contrato: Number(localData.contrato),
          observaciones: localData.observaciones || '',
          usuarioDokan: localData.usuarioDokan || '',
          passwordDokan: localData.passwordDokan || '',
          uloTipo: localData.tipoRol ? Number(localData.tipoRol) : 0,
          logo: localData.logo || '',
          listaPrecios: Number(localData.listaPrecios),
          establecimiento: '',
        }
      }

      const cliente = await ClientesService.getCliente(
        orden.clienteIdentificacion,
      )

      const local = orden.localCodigo
        ? parseLocal(
            await LocalService.getLocalByCode(String(orden.localCodigo)),
          )
        : null

      const ordenDetalle: VentaDetalleInfo[] =
        orden.ordenDetalle?.map((item) => ({
          codigo: Number(item.itemCodigo),
          codigoInterno: item.codigoInterno,
          codigoBarras: item.itemBarras,
          descripcion: item.itemDescripcion,
          numeroSerie: 0,
          lotes: 0,
          cantidad: item.ordenDetalleCantidad,
          descuento: item.ordenDetalleDescuento,
          impuesto: item.ordenDetalleImpuesto,
          porcentajeIva: item.ordenDetallePorcentaje,
          pvp: item.ordenDetallePrecio,
          imagenes: [],

          descuentoValor: 0,
          pvd: 0,
          valor: 0,
          pvpiva: 0,
          local: 0,
          ice: 0,
        })) ?? []

      const tmpDet2 = VentaHelper.detallesToDetallesVenta(
        ordenDetalle,
        precision ?? 4,
      )

      const venta: FacturaDatosEdicion = {
        ...datosEdicion,
        observaciones: orden.observaciones ?? '',
        cliente: cliente,
        detalles: tmpDet2 ?? [],
        vendedor: orden.vendedor ?? null,
        local: local,
      }
      return venta
    },
    [datosEdicion, precision],
  )

  const cargarAndSaveOrdenProforma = React.useCallback(
    async (orden: OrdenProformaListado) => {
      try {
        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              loader: {
                show: true,
                mensaje: 'Cargando documento...',
              },
            },
            key: tabId,
          }),
        )

        const venta = await parseOrdenToVenta(orden)

        if (venta.cliente?.ciudadCodigo) {
          const ciudadReceptor = await localidadesService.getCiudadByCode(
            venta.cliente.ciudadCodigo,
          )
          if (ciudadReceptor) {
            venta.localidadReceptor = ciudadReceptor
          }
        }

        dispatch(
          setDatosEdicion({
            data: {
              ...datosEdicion,
              ...venta,
              loader: {
                show: false,
                mensaje: '',
              },
            },
            key: tabId,
          }),
        )
      } catch (error) {
        dispatch(
          addToast({
            content: 'Error al cargar la orden',
            type: ToastTypes.Danger,
          }),
        )
      }
    },
    [datosEdicion, dispatch, parseOrdenToVenta, tabId],
  )

  const establecerDescuentoGlobal = React.useCallback(
    (valor) => {
      calcularTotales(detalles, valor)
    },
    [detalles, calcularTotales],
  )

  const agregarDetalleVentaMobile = React.useCallback(
    (detalle: DetalleVenta) => {
      const _detalles = detalles.slice(0)
      _detalles.push(detalle.getDetalle())
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
      setAgregarDetalleMovil(false)
    },
    [detalles, tabId, dispatch],
  )

  const cambiarCliente = React.useCallback(
    async (cliente: Cliente) => {
      const formasPago = await VentasService.getFormasPago(
        cliente.identificacion,
      )
      dispatch(
        updateCliente({
          cliente: cliente,
          formasPago,
          formaPago: [
            formasPago.find(
              (x) => x.codigo === empresa?.formaPagoDefectoVenta,
            ) ?? formasPago[0],
          ],
          key: tabId,
        }),
      )
    },
    [empresa, tabId, dispatch],
  )

  const cambiarClienteConfirm = React.useCallback(
    (
      clienteNuevo: Cliente,
      detalles: Array<DetalleVentaRedux>,
      fecha: string,
    ) => {
      if (cliente?.codigo === clienteNuevo.codigo) {
        dispatch(
          updateClienteOnly({
            cliente: clienteNuevo,
            key: tabId,
          }),
        )
        return
      }

      const clienteLocal = clienteNuevo
      const fnActualizaOnOk = async (clienteNuevo) => {
        showEditLoader('Actualizando precios...')
        for (let index = 0; index < detalles.length; index++) {
          const detalle = detalles[index]
          const _detalle = new DetalleVenta()
          const item = await VentasService.getItemPorCodigoBarras(
            detalle.codigoBarras,
            clienteNuevo.identificacion,
            DateUtils.pickersDateToApiDate(fecha),
          )
          if (item) {
            _detalle.setDetalle(detalle, empresa?.precision ?? 4)
            _detalle.setItem(item, empresa?.precision ?? 4)
            detalles[index] = _detalle.getDetalle()
          } else {
            addToast({
              content: `No se pudo recuperar el item ${detalle.descripcion}, el item no existe`,
              title: 'Cambiar precios',
              type: ToastTypes.Danger,
            })
          }
        }
        const formasPago = await VentasService.getFormasPago(
          clienteNuevo.identificacion,
        )
        dispatch(
          updateCliente({
            cliente: clienteNuevo,
            formasPago,
            formaPago: [
              formasPago.find(
                (x) => x.codigo === empresa?.formaPagoDefectoVenta,
              ) ?? formasPago[0],
            ],
            detalles: detalles,
            key: tabId,
          }),
        )
        addToast({
          content: `Precios actualizados`,
          title: 'Cambiar precios',
          type: ToastTypes.Success,
        })
        hideEditLoader()
      }
      dialogRef.current.show({
        title: 'Cambio de cliente',
        body: `¿Desea actualizar el cliente?, esto afectará a los precios de los productos seleccionados actualmente`,
        actions: [
          Dialog.Action(
            <span>
              <u>A</u>ceptar
            </span>,
            () => fnActualizaOnOk(clienteLocal),
            'btn-success',
            'a',
          ),
          Dialog.Action(
            <span>
              <u>C</u>ancelar
            </span>,
            (dialog) => {
              if (cliente !== null) {
                const tmpCliente = { ...cliente }
                cambiarCliente(tmpCliente)
              }
              dialog.hide()
            },
            'btn-danger',
            'c',
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
      return
    },
    [
      tabId,
      empresa,
      cliente,
      cambiarCliente,
      hideEditLoader,
      showEditLoader,
      dispatch,
    ],
  )

  const cambiarClienteSeleccionado = React.useCallback(
    async (clienteORuc: string | Cliente | null) => {
      clienteORuc = clienteORuc ?? config.rucConsumidorFinal
      if (tab.editStatus === StatesEdition.save) {
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc

        const formasPago = await VentasService.getFormasPago(
          cliente?.identificacion ?? config.rucConsumidorFinal,
        )
        dispatch(
          updateCliente({
            cliente: cliente,
            formasPago: formasPago,
            key: tabId,
            formaPago: [
              formasPago.find(
                (x) => x.codigo === empresa?.formaPagoDefectoVenta,
              ) ?? formasPago[0],
            ],
          }),
        )
        return
      }
      if (detalles.length > 0) {
        if (typeof clienteORuc === 'string') {
          showEditLoader('Recuperando cliente...')
        }
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc
        if (cliente == null) {
          dispatch(
            addToast({
              content: 'No se puede recuperar el cliente ' + clienteORuc,
              type: ToastTypes.Danger,
            }),
          )
          return
        }
        if (detalles.length > 0 && !desdePlantilla) {
          cambiarClienteConfirm(cliente, detalles.slice(0), fecha)
        } else {
          cambiarCliente(cliente)
        }
      } else {
        const cliente =
          typeof clienteORuc === 'string'
            ? await ClientesService.getCliente(clienteORuc)
            : clienteORuc
        const formasPago = await VentasService.getFormasPago(
          cliente?.identificacion ?? config.rucConsumidorFinal,
        )
        if (cliente) {
          dispatch(
            updateCliente({
              cliente: cliente,
              formasPago,
              key: tabId,
              formaPago: [
                formasPago.find(
                  (x) => x.codigo === empresa?.formaPagoDefectoVenta,
                ) ?? formasPago[0],
              ],
            }),
          )
        }
      }
    },
    [
      detalles,
      fecha,
      desdePlantilla,
      cambiarClienteConfirm,
      cambiarCliente,
      tab,
      empresa,
      tabId,
      showEditLoader,
      dispatch,
    ],
  )

  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...')
      await VentasService.reintentarAutorizacion(
        claveAcceso,
        TiposComprobantesSri.Factura,
      )
      dispatch(
        addToast({
          content: 'El comprobante fue enviado al sri para su autorización.',
          type: ToastTypes.Info,
        }),
      )
    } catch (error) {
      if (typeof error == 'string') {
        dispatch(
          addToast({
            content: error,
            type: ToastTypes.Danger,
          }),
        )
      }
      console.log(error)
    }
    hideEditLoader()
  }, [claveAcceso, showEditLoader, hideEditLoader, dispatch])

  const handlePrint = React.useCallback(
    (design: boolean) => {
      setVerFacturaPdf({ claveAcceso: claveAcceso, design: design })
    },
    [claveAcceso],
  )

  const exportarDetalle = React.useCallback(() => {}, [])

  const onModalItemsItemSelected = React.useCallback(
    (item: ItemVenta) => {
      const detalle: DetalleVenta = new DetalleVenta()
      detalle.setItem(item, empresa?.precision ?? 4)
      detalle.cantidad = 1
      dispatch(
        addDetalle({
          key: tabId,
          detalle: detalle.getDetalle(),
        }),
      )
      dispatch(
        setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }),
      )
    },
    [tabId, empresa, dispatch],
  )

  //#region efectos

  const onCreditoButtonClick = React.useCallback(() => {
    if (formaPago.length > 0 && formaPago[0].descripcion !== 'CREDITO') {
      dispatch(
        addToast({
          content:
            'La forma de pago debe de ser credito para usar esta opción!!!',
          type: ToastTypes.Warning,
        }),
      )
      return
    }
    const total = parseFloat(totales.total.toFixed(2))
    if (total <= 0) {
      dispatch(
        addToast({
          content:
            'La factura debe tener valor para poder ingresar las cuotas de credito.',
          type: ToastTypes.Warning,
        }),
      )
      return
    }
    setMostrarIngresoCreditos(true)
  }, [totales, formaPago, dispatch])

  const onMetodoPagoButtonClick = React.useCallback(() => {
    if (formaPago.length > 0 && formaPago[0].codigo !== EFormasPago.otros) {
      dispatch(
        addToast({
          content:
            'La forma de pago debe ser (PAGO COMBINADO) para usar esta opción!!!',
          type: ToastTypes.Warning,
        }),
      )
      return
    }
    const total = parseFloat(totales.total.toFixed(2))
    if (total <= 0) {
      dispatch(
        addToast({
          content:
            'La factura debe tener valor para poder agregar métodos de pago.',
          type: ToastTypes.Warning,
        }),
      )
      return
    }
    setVariasFormasPago(true)
  }, [totales, formaPago, dispatch])

  const onFormaPagoChanged = React.useCallback(
    (newValue) => {
      dispatch(
        setCuotasCredito({
          key: tabId,
          cuotas: [],
        }),
      )
      dispatch(
        setMetodosAgregados({
          key: tabId,
          tiposPagos: [],
        }),
      )
      dispatch(
        setFormaPago({
          formaPago: newValue,
          key: tabId,
        }),
      )
    },
    [tabId, dispatch],
  )

  const onCuotasCreditoChanged = React.useCallback(
    (nuevasCuotas) => {
      dispatch(
        setCuotasCredito({
          key: tabId,
          cuotas: nuevasCuotas,
        }),
      )
      setMostrarIngresoCreditos(false)
      dispatch(
        buttonClick({
          tabKey: tabId,
          button: ButtonTypes.save,
        }),
      )
    },
    [tabId, dispatch],
  )

  const onMetodosPagoChanged = React.useCallback(
    (metodosAgregados) => {
      dispatch(
        setMetodosAgregados({
          key: tabId,
          tiposPagos: metodosAgregados,
        }),
      )
      setVariasFormasPago(false)
      dispatch(
        buttonClick({
          tabKey: tabId,
          button: ButtonTypes.save,
        }),
      )
    },
    [tabId, dispatch],
  )

  const buttonClickAnular = React.useCallback(() => {
    if (info && info.info?.estadoElectronico !== 'AUTORIZADO') {
      dispatch(
        addToast({
          content:
            'La factura no está autorizada, debe autorizarce antes de proceder a la anulación.',
          type: ToastTypes.Warning,
        }),
      )
    }
    setMostrarAnular(true)
  }, [info, dispatch])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          modoEdicion()
          break
        case ButtonTypes.save: {
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          guardar()
          break
        }
        case ButtonTypes.undo:
          deshacer()
          break
        case ButtonTypes.print:
          handlePrint(false)
          break
        case ButtonTypes.print_design:
          handlePrint(true)
          break
        case ButtonTypes.discount:
          dispatch(toogleUsaDescuentoGlobal())
          break
        case ButtonTypes.export:
          exportarDetalle()
          break
        case ButtonTypes.sendMail:
          setShowReenviarMail(true)
          break
        case ButtonTypes.authorizations:
          reenviarAutorizacion()
          break
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? '', 0, true)
          break
        case ButtonTypes.credit:
          onCreditoButtonClick()
          break
        case ButtonTypes.disable:
          buttonClickAnular()
          break
        case ButtonTypes.methodsPay:
          onMetodoPagoButtonClick()
          break
        case ButtonTypes.generateElectronicDocument:
          setModalOrdenProforma({
            ...modalOrdenProforma,
            show: true,
          })
          break
        case ButtonTypes.xml:
          onVerXML(datosEdicion)
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      deshacer,
      dispatch,
      exportarDetalle,
      guardar,
      buttonClickAnular,
      handlePrint,
      modoEdicion,
      modoNuevo,
      tabId,
      puntoVenta,
      reenviarAutorizacion,
      onCreditoButtonClick,
      onMetodoPagoButtonClick,
      modalOrdenProforma,
      onVerXML,
      datosEdicion,
    ],
  )

  //#region  anular
  const onAnular = React.useCallback(async () => {
    if (info.info == null || info === undefined) {
      dispatch(
        addToast({
          content:
            'No se encuentra la información del documento, comunicar a los desarrolladores.',
          type: ToastTypes.Danger,
        }),
      )
      return
    }
    try {
      setMostrarAnular(false)
      showEditLoader('Anulando Comprobante...')
      await VentasService.anularDocumento(info.info)
      dispatch(
        addToast({
          content: 'El documento fue anulado correctamente.',
          type: ToastTypes.Success,
        }),
      )
      dispatch(setTabInfoAsInactive(tabId))
    } catch (error) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al anular ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
    } finally {
      hideEditLoader()
    }
  }, [info, showEditLoader, hideEditLoader, tabId, dispatch])
  const onCancelAnular = React.useCallback(() => {
    setMostrarAnular(false)
  }, [])
  //#endregion

  //#region productos

  const onCrearProductoButtonClick = React.useCallback(() => {
    setCodigoBarrasItemEditar('')
    setMostrarEdicionItems(true)
  }, [])

  const onMasOpcionesButtonClick = React.useCallback(() => {
    dispatch(
      setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }),
    )
    //setMostrarBusquedaItems(true);
  }, [tabId, dispatch])

  //#endregion

  const onObservacionesChanged = React.useCallback(
    (data) => {
      if (data.value !== null) {
        dispatch(
          updateObservaciones({
            key: tabId,
            observaciones: data.value ?? '',
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const onFechaEmisionChanged = React.useCallback(
    async (data) => {
      if (data.value !== null) {
        dispatch(
          updateFechaEmision({
            key: tabId,
            fecha: data.value ?? '',
          }),
        )
        sessionStorage.setItem('fechaFacturar', JSON.stringify(data.value))
        const iva = await VentasService.getIva(
          DateUtils.pickersDateToApiDate(data.value),
          null,
        )
        if (iva) {
          dispatch(
            setIva({
              key: tabId,
              iva: iva ?? 0,
            }),
          )

          // se realiza esto para refrescar el calculo de totales con el nuevo valor del iva recuperado por fecha
          const _detalles = detalles.slice(0)
          dispatch(
            updateDetalles({
              key: tabId,
              detalles: _detalles,
            }),
          )
        }
      }
    },
    [dispatch, tabId, detalles],
  )

  const muiTableCellsChanged = React.useCallback(
    (row, col, newValue) => {
      const _detalles = detalles.slice(0)
      const _detalle = new DetalleVenta()
      _detalle.setDetalle(_detalles[row], empresa?.precision ?? 4)

      switch (col) {
        case 2: //TABLECOL_DESCRIPCION
          _detalle.descripcion = newValue
          break
        case 3: //TABLECOL_CANTIDAD
          _detalle.cantidad = parseFloat(newValue)
          break
        case 4: //TABLECOL_PRECIO
          _detalle.precio = parseFloat(newValue)
          break
        default:
          break
      }
      _detalles[row] = _detalle.getDetalle()
      dispatch(
        updateDetalles({
          key: tabId,
          detalles: _detalles,
        }),
      )
    },
    [detalles, tabId, empresa, dispatch],
  )

  const ocultarBusquedaItems = React.useCallback(() => {
    dispatch(
      setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }),
    )
  }, [tabId, dispatch])

  const isReadonlyDate = React.useCallback(() => {
    if (
      empresa?.editarFechaEmision &&
      Number(empresa?.editarFechaEmision) > 0
    ) {
      return false
    }
    return true
  }, [empresa])

  const isDeleteRow = React.useCallback(
    (indice) => {
      setFilaEliminar(-1)
      const det = detalles.slice()
      det.splice(indice, 1)
      dispatch(
        updateDetalles({
          detalles: det,
          key: tabId,
        }),
      )
    },
    [detalles, dispatch, tabId],
  )

  const enableJsonButton = React.useCallback(() => {
    const enable =
      datosEdicion?.claveAcceso?.length > 0 &&
      datosEdicion?.fechaAutorizacion?.length > 0

    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...currentButtons,
          [ButtonTypes.xml]: enable,
        },
      }),
    )
  }, [currentButtons, datosEdicion, dispatch, tabId])

  //#region efectos

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? '', tab.info?.numero ?? 0)
    } else {
      cargarVenta(info.numero, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles, descuentoGlobal)
    }
  }, [loading, detalles, descuentoGlobal, calcularTotales])

  React.useEffect(() => {
    if (filaEliminar >= 0) {
      // eliminarDetalleConfirm(filaEliminar);
      isDeleteRow(filaEliminar)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filaEliminar])

  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imprimir])

  React.useEffect(() => {
    if (modalOrdenProforma.orden != null) {
      cargarAndSaveOrdenProforma(modalOrdenProforma.orden)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOrdenProforma.orden])

  React.useEffect(() => {
    if (!datosEdicion?.fechaAutorizacion?.includes('NUEVO')) {
      enableJsonButton()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEdicion?.fechaAutorizacion])

  React.useEffect(() => {
    getVerificaPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //#endregion
  //#endregion
  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={datosEdicion.loader.show}
        message={datosEdicion.loader.mensaje}
      >
        {info.info !== null && mostrarAnular && (
          <AnularDocumentoVenta
            onOk={onAnular}
            onCancel={onCancelAnular}
            info={info.info}
          />
        )}
        {showReenviarMail && datosEdicion.cliente != null && (
          <PopupReenvioMail
            showConfirm={showReenviarMail}
            itemReenviarMail={{
              claveAcceso: datosEdicion.claveAcceso,
              cliente: datosEdicion.cliente,
            }}
            closeConfirm={() => setShowReenviarMail(false)}
          />
        )}
        {datosEdicion.tieneError && (
          <VisualizaError
            titulo="Error en ventas"
            mensaje={datosEdicion.mensajeError}
            onOk={() => {
              dispatch(
                setMuestraError({
                  key: props.tabId,
                  tieneError: false,
                  mensajeError: '',
                }),
              )
            }}
          />
        )}
        {tab.editStatus === StatesEdition.save &&
          verFacturaPdf !== undefined &&
          rideMemo}
        {mostrarIngresoCaja[tabId] && InvoiceChangeMemo}
        {modalOrdenProforma?.show && OrdenProformaMemo}
        {modalXML?.show && XMLModalMemo}
        {mostrarIngresoCreditos && (
          <CreditosVenta
            cuotas={cuotas}
            fechaFactura={datosEdicion.fecha}
            totalCobrar={datosEdicion.totales.total}
            onCancel={() => {
              buttonsModoNuevo()
              setMostrarIngresoCreditos(false)
            }}
            onOk={onCuotasCreditoChanged}
          />
        )}
        {variasFormasPago && (
          <VariasFormasPago
            numeroVenta={datosEdicion.numero}
            tiposPagos={tiposPagos}
            fechaFactura={datosEdicion.fecha}
            totalCobrar={datosEdicion.totales.total}
            onCancel={() => setVariasFormasPago(false)}
            onOk={onMetodosPagoChanged}
          />
        )}
        {agregarDetalleMovil && (
          <div>
            <AgregarDetalleVentaMobile
              key="agregarDetalleVentaMovil"
              onOk={agregarDetalleVentaMobile}
              onCancel={() => setAgregarDetalleMovil(false)}
              cliente={datosEdicion.cliente}
              fecha={DateUtils.pickersDateToApiDate(datosEdicion.fecha)}
            />
          </div>
        )}
        <Dialog ref={dialogRef} />
        {mostrarEdicionItems && (
          <ModificaItem
            transaccion={'ventas'}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false)
              setCodigoBarrasItemEditar('')
              if (codigoBarrasItemEditar === '') {
                //nuevo
                const items = await VentasService.buscarItemCodigoBarras(
                  codigoBarras,
                  cliente?.identificacion ?? config.rucConsumidorFinal,
                  DateUtils.pickersDateToApiDate(fecha),
                )
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0])
                }
              }
            }}
          />
        )}
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion="ventas"
            action="none"
            show={mostrarBusquedaItems}
            cliente={
              datosEdicion.cliente !== null
                ? datosEdicion.cliente
                : config.rucConsumidorFinal
            }
            onCancel={ocultarBusquedaItems}
            returnItem={onModalItemsItemSelected}
          />
        )}
        <fieldset
          data-tut="sectionTutComprobante"
          disabled={tab.editStatus === StatesEdition.save}
        >
          <div>
            <RowContainer>
              <CustomCol xs="12" md="7">
                <div data-tut="sectionTutCliente">
                  <RowContainer>
                    <CustomCol xs="12">
                      <Labeled label="Nombre / Razón Social del cliente">
                        <BuscarClienteLookUp
                          disabled={tab.editStatus === StatesEdition.save}
                          selected={cliente}
                          onChanged={(data) => {
                            if (data !== undefined || data !== null) {
                              cambiarClienteSeleccionado(data)
                            }
                          }}
                          allowAdd
                          allowClear
                          allowEdit
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {cliente && (
                    <RowContainer>
                      <CustomCol xs="12">
                        <Labeled label="Dirección">
                          <TextBox value={cliente.direccion} readOnly />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="4">
                        <Labeled label="Teléfono">
                          <TextBox readOnly value={cliente.telefono ?? ''} />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="8">
                        <Labeled label="Correo eléctronico">
                          <TextBox readOnly value={cliente.email ?? ''} />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  )}
                </div>
              </CustomCol>
              <CustomCol xs="12" md="5">
                <div>
                  <RowContainer>
                    <CustomCol xs="12" md="5">
                      <Labeled label="Fecha Factura">
                        <DateBox
                          value={fecha}
                          readOnly={isReadonlyDate()}
                          onValueChanged={(data) => {
                            if (data.event !== undefined) {
                              onFechaEmisionChanged(data)
                            }
                          }}
                          max={DateUtils.getCurrentDateAsString(
                            formatoFechasDatePickers,
                          )}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="7">
                      <Labeled label="Número de factura">
                        <CRow>
                          <CCol xs="3">
                            <TextBox
                              readOnly
                              placeholder="001"
                              value={datosEdicion.establecimiento}
                            />
                          </CCol>
                          <CCol xs="3">
                            <TextBox
                              readOnly
                              placeholder="001"
                              value={datosEdicion.puntoEmision}
                            />
                          </CCol>
                          <CCol xs="6">
                            <TextBox
                              readOnly
                              placeholder="0000000000"
                              value={
                                '0'.repeat(
                                  10 -
                                    datosEdicion.numerofactura.toString()
                                      .length,
                                ) + datosEdicion.numerofactura.toString()
                              }
                            />
                          </CCol>
                        </CRow>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="6" md="3">
                      <Labeled label="# Detalles">
                        <TextBox
                          readOnly
                          value={datosEdicion.detalles.length.toString()}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="6" md="3">
                      <Labeled label="# Articulos">
                        <TextBox
                          readOnly
                          value={detalles
                            .reduce((prev, next) => prev + next._cantidad, 0)
                            .toFixed(2)}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs="12" md="6">
                      <Labeled label="Punto Venta">
                        <TextBox readOnly text={puntoVenta ?? ''} />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {tab.editStatus === StatesEdition.save && ( //Electronica
                    <>
                      <RowContainer>
                        <CustomCol xs="12" xxl="8">
                          {!DeviceHelper.isMobileOnlyDevice() && (
                            <Barcode text={datosEdicion.claveAcceso} />
                          )}
                          {DeviceHelper.isMobileOnlyDevice() && (
                            <Labeled label="Autorización">
                              <small>{datosEdicion.claveAcceso}</small>
                            </Labeled>
                          )}
                        </CustomCol>
                        <CustomCol xs="12" xxl="4">
                          <Labeled label="Fecha Autorización">
                            <CBadge
                              color={
                                datosEdicion.fechaAutorizacion
                                  ? 'info'
                                  : 'danger'
                              }
                            >
                              {datosEdicion.fechaAutorizacion
                                ? datosEdicion.fechaAutorizacion
                                : 'No Autorizado'}
                            </CBadge>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    </>
                  )}
                </div>
              </CustomCol>
            </RowContainer>
            {tab.editStatus !== StatesEdition.save && (
              <RowContainer>
                <CCol>
                  <div className="buttonsContainer mb-1 mt-1">
                    {DeviceHelper.isMobileOnlyDevice() && (
                      <Button
                        text="Agregar Detalle"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={() => {
                          setAgregarDetalleMovil(true)
                        }}
                        className="me-1"
                      />
                    )}
                    <Button
                      style={buttonsProductoStyle}
                      text="Crear Producto / Servicio"
                      icon="file"
                      stylingMode="contained"
                      type="default"
                      onClick={onCrearProductoButtonClick}
                      elementAttr={{
                        id: 'btnCreateItem',
                      }}
                      className="me-1"
                    />
                    <Button
                      style={buttonsProductoStyle}
                      hint="Más opciones"
                      icon="more"
                      stylingMode="contained"
                      type="default"
                      onClick={onMasOpcionesButtonClick}
                      elementAttr={{
                        id: 'btnOptionsItems',
                      }}
                      className="me-1"
                    ></Button>
                  </div>
                </CCol>
              </RowContainer>
            )}
            <div data-tut="detalleVentayTotales">
              {DeviceHelper.isMobileOnlyDevice() && ( //Mobiles
                <RowContainer>
                  <div style={{ paddingTop: '5px' }}>
                    <MUIDataTable
                      data={detalles}
                      columns={getColumnnasTablaDetalleVentas(
                        muiTableCellsChanged,
                        (row) => setFilaEliminar(row),
                      )}
                      options={OptionsTablaDetalleVentas}
                    />
                  </div>
                </RowContainer>
              )}
              {DeviceHelper.isMobileDevice() &&
                !DeviceHelper.isMobileOnlyDevice() && ( //Tablets
                  <RowContainer>
                    <CCol xs="12">
                      <div
                        className="detallesTableContainer"
                        style={{ maxHeight: `${window.innerHeight / 2}px` }}
                      >
                        <CustomSheet
                          id="sheetDetalle"
                          striped
                          blankRows={2}
                          columns={sheetColumns}
                          data={detalles}
                          editable={tab.editStatus !== StatesEdition.save}
                          initializeNewRow={(col, value) => {
                            const detalle: DetalleVenta = new DetalleVenta()
                            if (col.name === 'descripcion') {
                              const item = value as ItemVenta
                              detalle.setItem(item, empresa?.precision ?? 4)
                            } else {
                              detalle[col.name] = value
                            }

                            return detalle.getDetalle()
                          }}
                          onCellChanged={sheetOnCellsChanged}
                          showRowNumber
                          rowCommands={sheetCommands}
                        />
                      </div>
                    </CCol>
                  </RowContainer>
                )}

              {!DeviceHelper.isMobileDevice() && ( //Escritorio
                <RowContainer>
                  <CCol xs="12">
                    <div
                      className="detallesTableContainer overflow-auto"
                      onKeyDown={(e) => {}}
                      style={{
                        width:
                          window.innerWidth > 900
                            ? '100%'
                            : `${window.innerWidth - 20}px`,
                        maxHeight: `${window.innerHeight / 2}px`,
                      }}
                    >
                      <CustomSheet
                        id="sheetDetalle"
                        blankRows={2}
                        striped
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: DetalleVenta = new DetalleVenta()
                          // distinguir
                          if (col.name === 'descripcion') {
                            const item = value as ItemVenta
                            detalle.setItem(item, empresa?.precision ?? 4)
                            dispatch(
                              setCuotasCredito({
                                key: tabId,
                                cuotas: [],
                              }),
                            )
                            dispatch(
                              setMetodosAgregados({
                                key: tabId,
                                tiposPagos: [],
                              }),
                            )
                          } else {
                            detalle[col.name] = value
                          }

                          return detalle.getDetalle()
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}
                      />
                    </div>
                  </CCol>
                </RowContainer>
              )}

              <RowContainer>
                <CustomCol xs="12" md={usaDescuentoGlobal ? '4' : '8'}>
                  <Labeled label="Observaciones">
                    <TextArea
                      value={observaciones}
                      onValueChanged={(data) => {
                        if (data.event !== undefined) {
                          onObservacionesChanged(data)
                        }
                      }}
                    />
                  </Labeled>
                </CustomCol>
                {usaDescuentoGlobal && (
                  <>
                    <CustomCol xs="3" md="1" lg="1">
                      <Labeled label="Desc ($)">
                        <CFormInput
                          name="inputDescuentoXItemValor"
                          type="number"
                          defaultValue={datosEdicion.descuentoGlobal}
                          onBlur={(ev) => {
                            if (ev.currentTarget.value) {
                              establecerDescuentoGlobal(
                                parseFloat(ev.currentTarget.value),
                              )
                            } else {
                              establecerDescuentoGlobal(
                                datosEdicion.descuentoGlobal,
                              )
                            }
                          }}
                        />
                      </Labeled>
                    </CustomCol>
                  </>
                )}
                <CustomCol xs="12" md="3">
                  <Labeled label="Forma Pago">
                    <SelectBox
                      id="selectFormaPago"
                      displayExpr="descripcion"
                      keyExpr="codigo"
                      options={formasPago}
                      selected={
                        formaPago && formaPago.length > 0 ? formaPago[0] : null
                      }
                      placeholder="Forma de pago"
                      multiple={false}
                      onChange={(data) => {
                        if (data !== undefined || data !== null) {
                          onFormaPagoChanged(data)
                        }
                      }}
                      disabled={tab.editStatus === StatesEdition.save}
                      clearButton={true}
                      invalid={datosEdicion.formaPago.length === 0}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {!DeviceHelper.isMobileDevice() && (
                <RowContainer>
                  <div className="totalesContainer">
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal 0%</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {datosEdicion.totales.subtotal0.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal 5%</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {datosEdicion.totales.subtotal5.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>

                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal IVA</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {' '}
                          {datosEdicion.totales.subtotalIva.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Desc. Items</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {' '}
                          {datosEdicion.totales.descuento.toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Subtotal</span>
                      </div>
                      <div className="totalValue">
                        <span>
                          {' '}
                          {(
                            datosEdicion.totales.subtotal -
                            datosEdicion.totales.descuentoGloabal
                          ).toFixed(2)}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>I.C.E</span>
                      </div>
                      <div className="totalValue">
                        <span>{datosEdicion.totales.ice.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>IVA {iva}%</span>
                      </div>
                      <div className="totalValue">
                        <span> {datosEdicion.totales.iva.toFixed(2)} </span>
                      </div>
                    </div>
                    {autorizacionModulo?.restaurante && (
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>{'SERVICIO'}</span>
                        </div>
                        <div className="totalValue">
                          <span>
                            {' '}
                            {datosEdicion.totales.servicio.toFixed(2)}{' '}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="totalContainer">
                      <div className="totalLabel">
                        <span>Total</span>
                      </div>
                      <div className="totalValue">
                        <span> {datosEdicion.totales.total.toFixed(2)} </span>
                      </div>
                    </div>
                  </div>
                </RowContainer>
              )}
              {DeviceHelper.isMobileDevice() && (
                <>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.subtotal.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal 0%</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal 5%</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.subtotal5.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>ICE</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.ice.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Subtotal IVA</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>
                        {datosEdicion.totales.subtotalIva.toFixed(2)}
                      </small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Impuestos</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.iva.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Descuento Items</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.descuento.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs="8">
                      <small>Total</small>
                    </CustomCol>
                    <CustomCol xs="4">
                      <small>{datosEdicion.totales.total.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                </>
              )}
            </div>
          </div>
        </fieldset>
        {DeviceHelper.isMobileDevice() && <div style={{ height: '40px' }} />}
      </BlockUi>
    </div>
  )
}

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null
    },
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    },
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleVentaRedux) =>
      `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`,
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) =>
      `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(
        2,
      )})`,
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._subtotal.toFixed(2),
  },
]

export const defaultDatosEdicionFactura: FacturaDatosEdicion = {
  numero: 0,
  autorizacion: '',
  claveAcceso: '',
  cliente: null,
  codigoGeneracion: '',
  codigoBarrasItemEditar: '',
  descuentoGlobal: 0,
  descuentoPorItemPorcentaje: 0,
  descuentoPorItemValor: 0,
  detalles: [],
  establecimiento: '',
  fecha: '',
  fechaAutorizacion: '',
  formasPagoMinisterio: [],
  formaPago: [],
  identificadorTransaccion: '',
  imprimir: false,
  iva: 0,
  loader: {
    mensaje: 'Cargando...',
    show: true,
  },
  mensajeError: '',
  mostrarBusquedaItems: false,
  mostrarEdicionCliente: false,
  mostrarEdicionItems: false,
  numerofactura: '',
  observaciones: '',
  puntoEmision: '',
  tieneError: false,
  totales: {
    descuento: 0,
    descuentoGloabal: 0,
    ice: 0,
    iva: 0,
    subtotal: 0,
    subtotal0: 0,
    subtotal5: 0,
    subtotalIva: 0,
    total: 0,
    servicio: 0,
  },
  loading: true,
  motivoNotaCredito: '',
  formasPago: [],
  cuotas: [],
  desdePlantilla: false,
  tiposPagos: [], // varias formas de pago seleccionadas
}

const buttonsProductoStyle = { marginLeft: '2px' }

export const InvoiceButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Descuento: true,
  Credito: true,
  Enviar: true,
  Autorizar: true,
  Otros: true,
}

export default React.memo(Venta)
