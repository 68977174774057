import {
  findIconDefinition,
  IconDefinition,
  IconLookup,
} from '@fortawesome/fontawesome-svg-core'
import { RequestHelper } from '../helpers/requestHelper'
import { RequestHelperDotNet } from '../helpers/requestHelperDotNet'
import { utilidades } from '../helpers/utilidades'
import {
  AccionMenu,
  MenuShortCut,
  ModuloMenu,
  OpcionMenu,
} from '../store/types'
import { consoleService } from './console.service'
import { findOnObject } from '../helpers/Helper'

export const menuService = {
  cargarMenu,
  getPermisosUsario,
  cargarAccionesUsuario,
  getIcon,
}

async function cargarMenu(objeto: any): Promise<any> {
  try {
    if (utilidades.canUseNetApi()) {
      const apiData = await RequestHelperDotNet.get<Array<ModuloMenu>>(
        'Permissions',
        '',
        '',
        null,
      )
      return apiData.map((x) => {
        return {
          codigo: x.codigo,
          name: x.nombre,
          url: x.url,
          icon: x.icon,
          urlDocumentacion: null,
          children: convertNetMenuOptionsToChildren(x.opciones),
        }
      })
    } else {
      const data = await RequestHelper.postUrlEncodedAll<any>(
        'menu',
        'listar',
        objeto,
      )
      return data
    }
  } catch (error) {
    return error
  }
}

function convertNetMenuOptionsToChildren(
  options: Array<OpcionMenu>,
): Array<any> {
  const data: Array<any> = []
  for (const option of options) {
    const tmp: any = {
      codigo: option.codigo,
      name: option.nombre,
      url: option.url,
      icon: option.icon,
      urlDocumentacion: null,
    }
    if (option.tieneHijos) {
      tmp.children = convertNetMenuOptionsToChildren(option.opciones ?? [])
    } else {
      tmp.acciones = option.acciones?.map((x, indx) => {
        return {
          name: x.nombre,
          icon: x.icon,
          shortcut: x.shortcut,
          main: x.main ? '1' : '0',
          modal: x.modal ? '1' : '0',
          order: indx,
        }
      })
    }
    data.push(tmp)
  }
  return data
}

function getShortcut(text: string | null): MenuShortCut | null {
  if (text && text.length > 0) {
    try {
      const splited = text.split('+')
      const modifiers: Array<'ctrl' | 'shift'> = []
      let key = 0
      let index = 0
      while (index < splited.length) {
        if (splited[index] === 'ctrl' || splited[index] === 'shift') {
          modifiers.push(splited[index] as any)
        } else {
          key = parseInt(splited[index])
        }
        index++
      }
      const shortCut: MenuShortCut = {
        modificadores: modifiers,
        keyCode: key,
      }
      return shortCut
    } catch (error) {
      consoleService.error(error)
      return null
    }
  } else {
    return null
  }
}

function getIcon(text: string | null): IconDefinition {
  try {
    if (text.length === 0) {
      consoleService.log('text ' + text)
    }
    const icon = (text ?? 'far fa-question-circle').split(' ')

    if (icon.length === 0) {
      consoleService.log('text ' + text)
    }

    //const iconLookup2: IconLookup = { prefix: 'fal', iconName: "check-double" }
    const iconLookup: IconLookup = {
      prefix: icon[0] as any,
      iconName: icon[1].split('-').slice(1).join('-') as any,
    }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup)
    return iconDefinition
  } catch (error) {
    consoleService.log('error al cargar icono ' + error)
    const iconLookup: IconLookup = {
      prefix: 'far',
      iconName: 'question-circle',
    }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup)
    return iconDefinition
  }
}

function ordenarPorCodigo(array) {
  return array.sort((a, b) => {
    return a.codigo - b.codigo
  })
}

function cargarAccionesMenu(acciones: Array<any>): Array<AccionMenu> {
  acciones = ordenarPorCodigo(acciones)
  const acc = acciones.map((a) => {
    const accion: AccionMenu = {
      nombre: a.name,
      icon: a.icon,
      shortcut: getShortcut(a.shortcut),
      main: a.main == '1',
      modal: a.modal == '1',
      actionType: a.name,
    }
    return accion
  })
  return acc
}

function cargarOpcionesMenu(children: Array<any>): Array<OpcionMenu> {
  const opciones = children.map((o) => {
    const opcion: OpcionMenu = {
      codigo: parseInt(o.codigo),
      nombre: o?.name ?? '',
      url: o?.url ?? '',
      icon: o.icon,
      tieneHijos: o.children !== undefined,
      acciones: [],
    }

    if (opcion.tieneHijos) {
      opcion.opciones = cargarOpcionesMenu(o.children)
    } else {
      opcion.acciones = cargarAccionesMenu(o.acciones)
    }
    return opcion
  })
  return opciones
}

async function getPermisosUsario(): Promise<Array<ModuloMenu>> {
  if (utilidades.canUseNetApi()) {
    const apiData = await RequestHelperDotNet.get<Array<ModuloMenu>>(
      'Permissions',
      '',
      '',
      null,
    )
    return apiData
  } else {
    const queryData = {}
    const apiData = await RequestHelper.postUrlEncoded<Array<any>>(
      'menu',
      'listar',
      queryData,
    )
    const menu = apiData.map((m) => {
      let opciones = []
      if (m?.children !== undefined) {
        opciones = cargarOpcionesMenu(m.children as Array<any>)
      }
      const modulo: ModuloMenu = {
        codigo: parseInt(m.codigo),
        nombre: m?.name ?? '',
        url: m?.url ?? '',
        icon: getIcon(m.icon),
        opciones: opciones,
      }
      return modulo
    })
    return menu
  }
}

async function cargarAccionesUsuario(
  codigoModulo: number,
  opciones: Array<number>,
): Promise<Array<AccionMenu>> {
  const menu = await getPermisosUsario()
  const moduloLocal = findOnObject(menu, 'codigo', codigoModulo)
  const modulo = menu.find((x) => x.codigo === codigoModulo)

  if (moduloLocal && moduloLocal.length > 0 && moduloLocal[0].opciones) {
    let opcion = moduloLocal[0].opciones.find((op) => op.codigo === opciones[0])
    if (opcion) {
      for (let index = 1; index < opciones.length; index++) {
        const opcionCodigo = opciones[index]
        if (opcion && opcion.tieneHijos && opcion.opciones) {
          opcion = opcion.opciones?.find((op) => op.codigo === opcionCodigo)
        }
      }
    }
    if (opcion) {
      return opcion?.acciones ?? []
    }
  }

  if (modulo) {
    let opcion = modulo.opciones.find((op) => op.codigo === opciones[0])
    if (opcion) {
      for (let index = 1; index < opciones.length; index++) {
        const opcionCodigo = opciones[index]
        if (opcion && opcion.tieneHijos && opcion.opciones) {
          opcion = opcion.opciones?.find((op) => op.codigo === opcionCodigo)
        }
      }
    }
    if (opcion) {
      return opcion?.acciones ?? []
    } else {
      return []
    }
  } else {
    return []
  }
}
