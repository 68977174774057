import {
  isMobile,
  isTablet,
  isBrowser,
  browserName,
  osName,
  isMobileOnly,
} from 'react-device-detect'

class DeviceHelper {
  /**
   * Verifica si el dispositivo es móvil
   */
  static isMobileDevice(): boolean {
    return isMobile
  }

  /**
   * Verifica si el dispositivo es una tablet
   */
  static isTabletDevice(): boolean {
    return isTablet
  }

  /**
   * Verifica si el dispositivo es un navegador de escritorio
   */
  static isBrowserDevice(): boolean {
    return isBrowser
  }

  /**
   * Devuelve el nombre del navegador
   */
  static getBrowserName(): string {
    return browserName
  }

  /**
   * Devuelve el nombre del sistema operativo
   */
  static getOSName(): string {
    return osName
  }

  /**
   * Verifica si el dispositivo es móvil
   */
  static isMobileOnlyDevice(): boolean {
    return isMobileOnly
  }
}

export default DeviceHelper

//use mode
//import DeviceHelper from './DeviceHelper';
