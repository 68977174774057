import React from 'react'
import { useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Paging,
  Pager,
  ColumnChooser,
  HeaderFilter,
  Export,
} from 'devextreme-react/data-grid'
import { DatosVenta } from '../../../../types/types'
import { getAllowedPageSizes } from '../../../../../../../../../../../helpers/Helper'
import { RootState } from '../../../../../../../../../../../store/store'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
interface ITablaCCFProps extends React.PropsWithChildren {
  data: Array<DatosVenta> | []
  onExport: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200]

const TablaSuj: React.FunctionComponent<ITablaCCFProps> = (props) => {
  const { data, onExport } = props

  const filtros = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.search.filter
  })

  const [showInfo] = React.useState<boolean>(true)
  const [showNavButtons] = React.useState<boolean>(true)
  const [showPageSizeSelector] = React.useState<boolean>(true)
  const [displayMode] = React.useState<string | 'full' | 'compact'>('full')

  const dataGrid = React.useRef<any>()

  const onExportExcell = React.useCallback(
    (e) => {
      const rango = `${filtros?.fechaInicio} - ${filtros?.fechaFinal}`
      const fileName = `anexo_sujetoExcluido-${rango}`
      if (e.format === 'xlsx') {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('SujetoExcluido')
        e.component.exportDataFieldHeaders = false
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              fileName + '.xlsx',
            )
          })
        })
      } else if (e.format === 'pdf') {
        // const doc = new jsPDF();
        // exportDataGridToPdf({
        //   jsPDFDocument: doc,
        //   component: e.component
        // }).then(() => {
        //   doc.save('anexo_consumidor_final.pdf');
        // })
      } else if (e.format === 'csv') {
        onExport({
          title: 'SujetoExcluido',
          format: e.format,
          component: e.component,
          filename: fileName,
        })
      }
    },
    [filtros, onExport],
  )

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr="comprobante"
      focusedRowKey="comprobante"
      dataSource={data}
      showBorders={true}
      onExporting={onExportExcell}
      width={'100%'}
    >
      <Pager
        visible={data.length > 0 ? true : false}
        allowedPageSizes={getAllowedPageSizes(data)}
        displayMode={displayMode}
        showPageSizeSelector={showPageSizeSelector}
        showInfo={showInfo}
        showNavigationButtons={showNavButtons}
      />

      <Export
        enabled={true}
        allowExportSelectedData={false}
        formats={['csv', 'xlsx']}
      />
      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />
      <Column
        dataField="tipoDocProveedor"
        caption="TIPO DE DOCUMENTO" // A
        width="80px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="proveedorIdentificacion"
        caption="NÚMERO DE NIT, DUI U OTRO DOCUMENTO" // B
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="proveedorNombre"
        caption="NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN" // C
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="fechaNegociacion"
        caption="FECHA DE EMISIÓN DEL DOCUMENTO" // D
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="autorizacionHacienda.selloRecibido"
        caption="NÚMERO DE SERIE DEL DOCUMENTO" // E
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="autorizacionHacienda.codigoGeneracion"
        caption="NÚMERO DE DOCUMENTO" // F
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="total"
        caption="MONTO DE LA OPERACIÓN" // G
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="retenidoIva"
        caption="MONTO DE LA RETENCIÓN DEL IVA 13%" // H
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="tipoOperacion"
        caption="TIPO DE OPERACIÓN" // I
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="clasificacion"
        caption="CLASIFICACIÓN " // J
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="sector"
        caption="SECTOR  " // K
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="tipoCostoGasto"
        caption="TIPO DE COSTO/GASTO   " // L
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="numeroAnexo"
        caption="NÚMERO DEL ANEXO" // M
        width="110px"
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>

      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />
    </DataGrid>
  )
}
export default TablaSuj
