import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CAlert, CCard, CCardBody } from '@coreui/react-pro'
import { ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'

import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import { TUnidadMedidaDatosEdicion, TUnidadMedidaData } from '../store/types'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabs.reducer'
import { StatesEdition } from '../../../../../store/enums'
import { setDatosEdicion } from '../store/edit.reducer'
import InputTextDE, {
  InputCheckDE,
} from '../../../../../views/componentes/inputText-DE/inputText-DE'
import { UnidadMedidaService } from '../services/unidadMedida.services'
import { BuscarGrupoLookUp } from '../../../../inventario/components/buscarGrupo/buscarGrupo'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import { useToast } from '../../../../../hooks/useToast'

interface IMProvinciasProps extends React.PropsWithChildren {
  info: DocumentInfo<TUnidadMedidaData>
  tabId: string
  tab: TabState<TUnidadMedidaData>
}

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MantenimientoUnidadMedida: React.FunctionComponent<IMProvinciasProps> = (
  props,
) => {
  const { info, tabId, tab } = props

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const tabs = useSelector(
    (state: RootState) => state.administracion.catalagos.unidadMedida.tabs,
  )
  const dataEdicion = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.unidadMedida.editData[tabId],
  )
  const loading = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.unidadMedida.editData[tabId].loading,
  )

  const dispatch = useDispatch()

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loader, setLoader] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const { setToast } = useToast()

  const parseApiPaisData = React.useCallback(
    async (response: any): Promise<TUnidadMedidaDatosEdicion> => {
      return {
        edition: false,
        codigo: response?.codigo ?? 0,
        nombre: response?.nombre ?? '',
        siglas: response?.siglas ?? '',
        fraccion: response?.fraccion ?? 0,
        grupoCodigo: response?.grupoCodigo ?? 0,
        grupoNombre: response?.grupoNombre ?? '',
        estadoCodigo: response?.estadoCodigo ?? 0,
        estadoNombre: response?.estadoNombre ?? '',
        codigoTributario: response?.codigoTributario ?? '',
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: '',
      }
    },
    [],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultEditionUnidadMedida }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, loading, tabId],
  )

  const modoEdicion = React.useCallback(
    async (reload: boolean = false, dataEdicion: TUnidadMedidaData) => {
      setLoader({ show: true, message: 'Espere, por favor...' })

      const dataCopyEdition = dataEdicion

      setDatosEdicion2(dataCopyEdition)

      try {
        let dataApi: any
        let infoParse: TUnidadMedidaDatosEdicion = {
          edition: false,
          codigo: 0,
          nombre: '',
          siglas: '',
          fraccion: 0,
          grupoCodigo: 0,
          grupoNombre: '',
          estadoCodigo: 0,
          estadoNombre: '',
          codigoTributario: '',
          loader: {
            show: false,
            mensaje: '',
          },
          loading: false,
          tieneError: false,
          mensajeError: '',
        }

        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseApiPaisData(dataEdicion)

        if (reload) {
          dataApi = await UnidadMedidaService.getUnidadMedida(
            dataCopyEdition?.codigo ?? 0,
          )

          infoParse = await parseApiPaisData(dataApi)
        }

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )

        setLoader({ show: false, message: '' })
      } catch (error) {
        setLoader({ show: false, message: JSON.stringify(error) })
      }
    },
    [dispatch, parseApiPaisData, tabId],
  )

  const playLoader = React.useCallback(async () => {
    setLoader({ show: true, message: 'Espere, por favor...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoader({ show: false, message: '' })
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return
    }

    setShowErrorPopup(false)
    setShowErrorMessages([])
    onSetButtonAction(ButtonTypes.save)

    const bodyRequest: any = {
      codigo: dataEdicion?.codigo ?? 0,
      nombre: dataEdicion.nombre,
      siglas: dataEdicion.siglas,
      grupo: dataEdicion.grupoCodigo ?? 0,
      estado: dataEdicion.estadoCodigo,
      estadoNombre: dataEdicion.estadoNombre,
      codigoTributario: dataEdicion.codigoTributario,
      fraccion: dataEdicion.fraccion,
    }

    try {
      const data = await UnidadMedidaService.postUnidadMedida(bodyRequest)

      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning)
        stopLoader()
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EUnidadMedidaButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        return
      }

      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        stopLoader()
        onSetButtonAction(undefined)
      }

      setDatosEdicion2(bodyRequest)
      if (bodyRequest.codigo === 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EUnidadMedidaButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      if (bodyRequest.codigo !== 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EUnidadMedidaButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      dispatch(
        setNameTab({
          key: tabId,
          nombre: bodyRequest?.nombre ?? '',
          codigo: data?.auto ?? 0,
        }),
      )
    } catch (error) {
      onSetButtonAction(undefined)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...EUnidadMedidaButtons,
            Guardar: true,
            Imprimir: true,
          },
        }),
      )
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [
    playLoader,
    onSetButtonAction,
    dataEdicion,
    setToast,
    stopLoader,
    dispatch,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      switch (buttonAction) {
        case ButtonTypes.undo:
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2 },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) {
            handleSubmit()
          }
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, datosEdicion2, tabs, handleSubmit],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(false, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map(function (item, id) {
                  return <li key={id}>{item['message']}</li>
                })}
              </ul>
            </CAlert>
          </PopupContent>
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loader?.show ?? false}
            message={loader?.message ?? ''}
          >
            <fieldset disabled={loader.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />
                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Nombre(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.nombre ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'nombre')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Nombre: Este campo es requerido" />
                                  <StringLengthRule
                                    min="5"
                                    message="- Nombre: Este campo debe tener al menos 5 caracteres"
                                  />
                                  <StringLengthRule
                                    max="50"
                                    message="- Nombre: Este campo no puede tener mas de 50 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Siglas(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.siglas ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'siglas')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Siglas: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Siglas: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="15"
                                    message="- Siglas: Este campo no puede tener mas de 15 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Grupo:'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <BuscarGrupoLookUp
                                selected={dataEdicion?.grupoCodigo ?? 0}
                                onChanged={(e) => {
                                  if (typeof e !== 'number') {
                                    onChangeValue(e, 'grupoCodigo')
                                  }
                                }}
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Tributario(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoTributario ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'codigoTributario')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Tributario: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código Tributario: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- Código Tributario: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Estado:'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputCheckDE
                                value={dataEdicion?.estadoCodigo === 1}
                                onValueChange={(e) => {
                                  const newValue = e ? 1 : 0
                                  onChangeValue(newValue, 'estadoCodigo')
                                }}
                                text={
                                  dataEdicion?.estadoCodigo === 1
                                    ? 'Activo'
                                    : 'Inactivo'
                                }
                              />
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>
                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MantenimientoUnidadMedida)

export const EUnidadMedidaButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}

export const defaultEditionUnidadMedida: TUnidadMedidaDatosEdicion = {
  codigo: 0,
  nombre: '',
  siglas: '',
  fraccion: 0,
  grupoCodigo: 0,
  grupoNombre: '',
  estadoCodigo: 0,
  estadoNombre: '',
  codigoTributario: '',
  edition: false,
  loader: null,
  loading: false,
  tieneError: false,
  mensajeError: '',
}
