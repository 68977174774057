import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CAlert, CCard, CCardBody } from '@coreui/react-pro'
import { ValidationGroup, ValidationSummary, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'

import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import { ProvinciasDatosEdicion, TProvinciasData } from '../store/types'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import {
  clearButtonClick,
  selectTabsProvincias,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabs.reducer'
import { StatesEdition } from '../../../../../store/enums'
import { setDatosEdicion } from '../store/edit.reducer'
import InputTextDE from '../../../../../views/componentes/inputText-DE/inputText-DE'
import { ProvinciasService } from '../services/provincias.services'
import PaisesLookUp from '../../../../componentes/paisesLookUp'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import { useToast } from '../../../../../hooks/useToast'

interface IMProvinciasProps extends React.PropsWithChildren {
  info: DocumentInfo<TProvinciasData>
  tabId: string
  tab: TabState<TProvinciasData>
}

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MantenimientoProvincias: React.FunctionComponent<IMProvinciasProps> = (
  props,
) => {
  const { info, tabId, tab } = props

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()

  const tabs = useSelector(selectTabsProvincias)

  const dataEdicion = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.provincias.editData[tabId],
  )

  const loading = useSelector(
    (state: RootState) =>
      state.administracion.catalagos.provincias.editData[tabId].loading,
  )

  const empresaPais = useSelector(
    (state: RootState) => state.global.session.empresa.codigoPais,
  )

  const dispatch = useDispatch()

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loaderProvincias, setLoaderProvincias] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const { setToast } = useToast()

  const parseApiPaisData = React.useCallback(
    async (response: any): Promise<ProvinciasDatosEdicion> => {
      return {
        edition: false,
        codigo: response?.codigo ?? 0,
        nombre: response?.nombre ?? '',
        paisCodigo: response?.paisCodigo ?? '',
        codigoDep: response?.codigoDep ?? '',
        codigoOrigen: response?.codigoOrigen ?? '',
        inec: response?.inec ?? '',
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: '',
      }
    },
    [],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultEditionPprovincias }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, loading, tabId],
  )

  const modoEdicion = React.useCallback(
    async (reload: boolean = false, dataEdicion: TProvinciasData) => {
      setLoaderProvincias({ show: true, message: 'Cargando...' })

      const dataProvinciaEdition = dataEdicion

      setDatosEdicion2(dataProvinciaEdition)

      try {
        let dataApi: any
        let infoParse: ProvinciasDatosEdicion = {
          edition: false,
          codigo: 0,
          nombre: '',
          paisCodigo: {
            codigo: 0,
            nombre: '',
            uaf: '',
          },
          codigoDep: '',
          codigoOrigen: '',
          inec: '',
          loader: {
            show: false,
            mensaje: '',
          },
          loading: false,
          tieneError: false,
          mensajeError: '',
        }

        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseApiPaisData(dataEdicion)

        if (reload) {
          dataApi = await ProvinciasService.getProvincia(
            dataProvinciaEdition?.codigo ?? 0,
          )

          infoParse = await parseApiPaisData(dataApi)
        }

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )

        setLoaderProvincias({ show: false, message: '' })
      } catch (error) {
        setLoaderProvincias({ show: false, message: JSON.stringify(error) })
      }
    },
    [dispatch, parseApiPaisData, tabId],
  )

  const playLoader = React.useCallback(async () => {
    setLoaderProvincias({ show: true, message: 'Cargando...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoaderProvincias({ show: false, message: '' })
  }, [])

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )

  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return
    }

    setShowErrorPopup(false)
    setShowErrorMessages([])
    onSetButtonAction(ButtonTypes.save)

    const dataProvincia: any = {
      codigo: dataEdicion?.codigo ?? 0,
      nombre: dataEdicion.nombre,
      codigoInec: dataEdicion.inec,
      codigoRDep: dataEdicion.codigoDep,
      codigoOrigen: dataEdicion?.codigoOrigen,
      codigoPais: dataEdicion.paisCodigo.codigo,
    }

    try {
      const data = await ProvinciasService.saveProvincia(dataProvincia)

      stopLoader()

      if (data['error'] === true) {
        setToast(data['message'], ToastTypes.Warning)
        stopLoader()
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EProvinciasButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        return
      }

      if (data['error'] === false) {
        setToast(data['message'], ToastTypes.Success)
        stopLoader()
        onSetButtonAction(undefined)
      }

      setDatosEdicion2(dataProvincia)
      if (dataProvincia.codigo === 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EProvinciasButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      if (dataProvincia.codigo !== 0) {
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...EProvinciasButtons,
              Deshacer: false,
              Guardar: false,
              Imprimir: true,
            },
          }),
        )
      }

      dispatch(
        setNameTab({
          key: tabId,
          nombre: dataProvincia?.nombre ?? '',
          codigo: data?.auto ?? 0,
        }),
      )
    } catch (error) {
      onSetButtonAction(undefined)
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...EProvinciasButtons,
            Guardar: true,
            Imprimir: true,
          },
        }),
      )
      stopLoader()
      setToast(error, ToastTypes.Danger)
    }
  }, [
    playLoader,
    onSetButtonAction,
    dataEdicion,
    setToast,
    stopLoader,
    dispatch,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      switch (buttonAction) {
        case ButtonTypes.undo:
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2 },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) handleSubmit()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, datosEdicion2, tabs, handleSubmit],
  )

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(false, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map(function (item, id) {
                  return <li key={id}>{item['message']}</li>
                })}
              </ul>
            </CAlert>
          </PopupContent>
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loaderProvincias?.show ?? false}
            message={loaderProvincias?.message ?? ''}
          >
            <fieldset disabled={loaderProvincias.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <CCardBody>
                    <ValidationSummary className="mb-2" />
                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Nombre(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.nombre ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'nombre')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Nombre: Este campo es requerido" />
                                  <StringLengthRule
                                    min="3"
                                    message="- Nombre: Este campo debe tener al menos 3 caracteres"
                                  />
                                  <StringLengthRule
                                    max="50"
                                    message="- Nombre: Este campo no puede tener mas de 50 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'INEC(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.inec ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'inec')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- INEC: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- INEC: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- INEC: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código DEP(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoDep ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'codigoDep')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código DEP: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código DEP: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- Código DEP: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Provincia Origen(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.codigoOrigen ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'codigoOrigen')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Código Provincia Origen: Este campo es requerido" />
                                  <StringLengthRule
                                    min="1"
                                    message="- Código Provincia Origen: Este campo debe tener al menos 1 caracteres"
                                  />
                                  <StringLengthRule
                                    max="12"
                                    message="- Código Provincia Origen: Este campo no puede tener mas de 12 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Código Pais(*):'}
                              </div>
                            </CustomCol>
                          </RowContainer>
                          <RowContainer>
                            <CustomCol lg="12" md="12" sm="12">
                              <PaisesLookUp
                                onChanged={(e) => {
                                  onChangeValue(e, 'paisCodigo')
                                }}
                                onChangedOptions={() => {}}
                                selected={
                                  dataEdicion?.paisCodigo
                                    ? dataEdicion?.paisCodigo
                                    : { codigo: empresaPais }
                                }
                              >
                                <Validator>
                                  <RequiredRule
                                    message={
                                      'Código Pais: Este campo es requerido'
                                    }
                                  />
                                </Validator>
                              </PaisesLookUp>
                            </CustomCol>
                          </RowContainer>
                        </div>
                      </CustomCol>
                    </RowContainer>
                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MantenimientoProvincias)

export const EProvinciasButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}

export const defaultEditionPprovincias: ProvinciasDatosEdicion = {
  codigo: 0,
  nombre: '',
  paisCodigo: {
    codigo: 0,
    nombre: '',
    uaf: '',
  },
  codigoDep: '',
  codigoOrigen: '',
  inec: '',
  edition: false,
  loader: null,
  loading: false,
  tieneError: false,
  mensajeError: '',
}
