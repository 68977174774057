import React, { useEffect, useState } from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { DataGrid, RadioGroup } from 'devextreme-react'
import {
  Column,
  Export,
  MasterDetail,
  Pager,
  Paging,
  Button as DatagridButton,
} from 'devextreme-react/data-grid'
import {
  NotificationList,
  NotificationListResult,
  optionsSearch,
  typeNotification,
} from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { notificationsServices } from '../../services/notifications.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  changeLoaderNotifications,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion } from '../../store/editDataReduce'
import {
  fetchNotifications,
  setCleanResult,
  setFilter,
} from '../../store/serachReducer'
import { CBadge } from '@coreui/react-pro'
import { getAllowedPageSizes } from '../../../../../../helpers/Helper'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { isMobile } from 'react-device-detect'
import DetailsNotification from './detailsNotification'
import PopupMensajes from '../../../../../../containers/component/popupMensajes/popupMensajes'
import { TabState } from '../../../../../../store/genericTabTypes'
import ItemsCountIndicator from '../../../../../componentes/indicadorNroRegistros'
import { utilidades } from '../../../../../../helpers/utilidades'

interface ISearchProps extends React.PropsWithChildren {
  tab: TabState<NotificationList>
  tabId: string
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()
  const currentAction = useSelector(
    (state: RootState) =>
      state.administracion.notificaciones.general.currentFunction,
  )
  const resultados = useSelector((state: RootState) => {
    return state.administracion.notificaciones.search.resultados
  })
  const filter = useSelector((state: RootState) => {
    return state.administracion.notificaciones.search.filter
  })
  const typeNotification = useSelector((state: RootState) => {
    return state.administracion.notificaciones.general.typesNotification
  })

  const [selectedNotification, setSelectedTypeCustomer] =
    useState<NotificationListResult>(null)
  const [message, setMessage] = React.useState<
    Array<{ titulo: string; descripcion: string }>
  >([])
  const [showMessage, setShowMessage] = React.useState<boolean>(false)
  const dataGrid = React.useRef<any>()

  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderNotifications({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderNotifications({ show: false, mensaje: '' }))
  }, [dispatch])

  const badgeComponent = (values) => {
    let color = 'danger'
    if (values.value === 1) color = 'success'
    return <CBadge color={color}>{values.value === 1 ? 'SI' : 'NO'}</CBadge>
  }

  const setTypeNotification = React.useCallback(
    (data: any): typeNotification | null => {
      const selectedType = typeNotification.find(
        (type: typeNotification) => data === type.value,
      )
      if (selectedType) return selectedType
      return null
    },
    [typeNotification],
  )

  const onChangedState = React.useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setFilter({
            ...filter,
            descripcion: data.value,
          }),
        )
    },
    [dispatch, filter],
  )

  const onSelectedNotificationChanged = React.useCallback(
    ({ selectedRowsData, currentDeselectedRowKeys }) => {
      if (selectedRowsData && resultados.length > 0) {
        setSelectedTypeCustomer(selectedRowsData[0])
        if (currentDeselectedRowKeys.length === 0)
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: true,
                Eliminar: true,
                Buscar: true,
                Imprimir: false,
                Deshacer: true,
              },
            }),
          )
      }
    },
    [dispatch, resultados, tabId],
  )

  const onCleanResultFilter = React.useCallback(() => {
    dispatch(setCleanResult())
  }, [dispatch])

  const onHandleEdit = React.useCallback(() => {
    const id = utilidades.getUUID()
    dispatch(
      initDatosEdicion({
        key: id,
        data: {
          codigo: selectedNotification.codigo,
          descripcion: selectedNotification.descripcion,
          fechaInicio: DateUtils.apiDateToPickersDate(
            selectedNotification.fechaInicio,
          ),
          fechaFin: DateUtils.apiDateToPickersDate(
            selectedNotification.fechaFin,
          ),
          titulo: selectedNotification.titulo,
          imagen: selectedNotification.imagen,
          permanente: selectedNotification.permanente,
          tipoId: setTypeNotification(selectedNotification.tipoId),
          mostrarPopup: selectedNotification.mostratPopup,
          imagenCodificada: '',
        },
      }),
    )
    dispatch(
      openTab({
        key: id,
        cliente: {
          codigo: selectedNotification.codigo,
          descripcion: selectedNotification.descripcion,
          fechaInicio: selectedNotification.fechaInicio,
          fechaFin: selectedNotification.fechaFin,
          titulo: selectedNotification.titulo,
          imagen: selectedNotification.imagen,
          permanente: selectedNotification.permanente,
          tipoId: setTypeNotification(selectedNotification.tipoId),
          mostrarPopup: selectedNotification.mostratPopup,
          imagenCodificada: '',
        },
      }),
    )
  }, [dispatch, setTypeNotification, selectedNotification])

  const onHandleRemove = React.useCallback(async () => {
    try {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: ButtonTypes.delete,
        }),
      )
      playLoader('Eliminando . . .')
      const deletePerformance = await notificationsServices.deleteNotification(
        selectedNotification.codigo,
      )
      if (!deletePerformance.error) {
        stopLoader()
        onCleanResultFilter()
        const toAny: any = fetchNotifications(filter)
        dispatch(toAny)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Registro eliminado.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Success,
          }),
        )
      } else {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: deletePerformance.message ?? 'Error al eliminar.',
            fade: true,
            title: 'Eliminar',
            type: ToastTypes.Warning,
          }),
        )
      }
    } catch (error) {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )
      stopLoader()
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content:
            'Error al eliminar ' +
            (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Busqueda',
          type: ToastTypes.Danger,
        }),
      )
    }
  }, [
    dispatch,
    filter,
    onCleanResultFilter,
    playLoader,
    selectedNotification,
    stopLoader,
    tabId,
  ])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.delete:
          onHandleRemove()
          break
        case ButtonTypes.edit:
          onHandleEdit()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleRemove, onHandleEdit, tabId],
  )

  const getTypeNotification = React.useCallback(
    (data: any): string => {
      const selectedType = typeNotification.find(
        (type: typeNotification) => data.value === type.value,
      )
      if (selectedType) return selectedType.label
      return 'N/A'
    },
    [typeNotification],
  )

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  return (
    <>
      {showMessage && (
        <PopupMensajes
          show={showMessage}
          messages={message}
          onClose={() => {
            setShowMessage(false)
            setMessage([])
          }}
        />
      )}
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Filtros:">
            <RadioGroup
              name="idSearchOptions"
              dataSource={optionsSearch}
              value={filter.descripcion}
              layout="horizontal"
              displayExpr="label"
              onValueChanged={onChangedState}
              width="100%"
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <ItemsCountIndicator items={resultados} />
      <RowContainer>
        <CustomCol xs="12" md="12">
          <DataGrid
            ref={dataGrid}
            keyExpr="codigo"
            focusedRowKey="codigo"
            id="tabCertificados"
            selection={{ mode: 'single' }}
            dataSource={resultados}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnResizingMode="widget"
            focusedRowEnabled={true}
            onSelectionChanged={onSelectedNotificationChanged}
            loadPanel={{
              enabled: true,
              height: 90,
              indicatorSrc: '',
              shading: false,
              shadingColor: '',
              showIndicator: true,
              showPane: true,
              text: 'Cargando...',
              width: 200,
            }}
          >
            <Paging defaultPageSize={20} />
            <Pager
              visible={resultados.length > 0 ? true : false}
              allowedPageSizes={getAllowedPageSizes(resultados)}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            {!isMobile && (
              <MasterDetail
                enabled={true}
                render={(params) => (
                  <>
                    <DetailsNotification
                      typeNotification={typeNotification}
                      {...params}
                    />{' '}
                  </>
                )}
              />
            )}
            {!isMobile && (
              <Column type="buttons">
                <DatagridButton
                  icon="info"
                  hint="Vista Previa"
                  onClick={async (dataRow) => {
                    setMessage([
                      {
                        titulo: dataRow.row.data.titulo,
                        descripcion: dataRow.row.data.descripcion,
                      },
                    ])
                    setShowMessage(true)
                  }}
                />
              </Column>
            )}
            <Column dataField="titulo" caption="Título" width="10%" />
            <Column dataField="descripcion" caption="Descripcion" width="43%" />
            <Column
              dataType="string"
              dataField="fechaInicio"
              caption="F. Inicio"
              width="10%"
            />
            <Column
              dataType="string"
              dataField="fechaFin"
              caption="F. Fin"
              width="10%"
            />
            <Column
              dataField="tipoId"
              caption="Tipo"
              width="10%"
              cellRender={getTypeNotification}
            />
            <Column dataField="imagen" caption="Imagen" width="10%" />
            <Column
              dataField="permanente"
              caption="Permanente"
              cellRender={badgeComponent}
              width="7%"
            />
            <Column
              dataField="mostratPopup"
              caption="PopUp"
              cellRender={badgeComponent}
              width="5%"
            />
          </DataGrid>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Search
