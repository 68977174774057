import React from 'react'
import { CButton, CModal } from '@coreui/react-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretRight,
  faCaretLeft,
  faDownload,
  faEye,
  faWindowClose,
} from '@fortawesome/pro-light-svg-icons'
import { useDispatch } from 'react-redux'
import { isMobile, isFirefox } from 'react-device-detect'
import ReactDOM from 'react-dom'
import { addToast } from '../../../store/toasterReducer'
import { ToastTypes } from '../../../store/types'
import './XMLDisplay.scss'
import XMLViewer from '../librerias/xml-viewer'

interface IXMLDisplayProps extends React.PropsWithChildren {
  show: boolean
  urlBlobPdf: Blob | null
  urlXml: Blob | null
  fileName: string
  onClose: () => void
}

const customTheme = {
  attributeValueColor: '#000FF',
  tagColor: '#800080',
}

export const XMLDisplay: React.FC<IXMLDisplayProps> = (props) => {
  const { onClose, show, urlBlobPdf } = props

  const dispatch = useDispatch()

  const [numPages, setNumPages] = React.useState(0)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [error, setError] = React.useState(false)
  const [srcFile, setSrcFile] = React.useState<string>('')

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const handleFileLoad = React.useCallback(async () => {
    if (urlBlobPdf) {
      // let blob = new Blob([urlBlobPdf], { type: 'text/xml' });
      // let url = URL.createObjectURL(blob);
      if (isFirefox) {
        const text = await new Response(urlBlobPdf).text()
        const coded = 'data:text/xml;base64,' + btoa(text)
        setSrcFile(coded)
      } else {
        const text = await new Response(urlBlobPdf).text()
        setSrcFile(text)
      }
    }
  }, [urlBlobPdf])

  React.useEffect(() => {
    if (error) {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Error al descargar el archivo  ',
          fade: true,
          title: 'Descargar ',
          type: ToastTypes.Warning,
        }),
      )
      onClose()
    }
  }, [error, dispatch, onClose])

  React.useEffect(() => {
    if (urlBlobPdf) {
      handleFileLoad()
    }
  }, [urlBlobPdf])

  const style = !isMobile
    ? {
        width: `${window.innerWidth > 1000 ? 800 : window.innerWidth - 100}px`,
        height: `${
          window.innerHeight > 1000 ? 800 : window.innerHeight - 100
        }px`,
      }
    : {}
  const element = document.querySelector('#modal')
  if (element) {
    return ReactDOM.createPortal(
      <CModal
        visible={show}
        onClose={onClose}
        className="d-flex align-items-center justify-content-center"
        size="xl"
      >
        <div style={style}>
          <div className="VisorXmlContainer">
            <div className="VisorXmlLayoutContainer">
              <div className="VisorXmlLayoutToolbar">
                <div className="VisorXmlToolbar">
                  <div className="VisorXmlToolbar-left">
                    {pageNumber > 1 && (
                      <CButton
                        variant="ghost"
                        disabled={pageNumber === 1 || error}
                        onClick={previousPage}
                      >
                        <FontAwesomeIcon icon={faCaretLeft} size="2x" />
                      </CButton>
                    )}
                    {numPages > 1 && pageNumber < numPages && (
                      <CButton
                        variant="ghost"
                        disabled={pageNumber >= numPages || error}
                        onClick={nextPage}
                      >
                        <FontAwesomeIcon icon={faCaretRight} size="2x" />
                      </CButton>
                    )}
                  </div>
                  <div className="VisorXmlToolbar-right">
                    {props.urlXml && (
                      <>
                        <CButton
                          disabled={error}
                          variant="ghost"
                          onClick={() => {
                            if (props.urlXml) {
                              const blob = new Blob([props.urlXml], {
                                type: 'text/xml',
                              })
                              const url = URL.createObjectURL(blob)

                              const link = document.createElement('a')

                              link.href = url
                              link.download = `${props.fileName}`
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)

                              //window.open(props.url, '_blank')
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} size="2x" />
                        </CButton>
                        <CButton
                          disabled={error}
                          variant="ghost"
                          onClick={() => {
                            if (props.urlXml) {
                              const blob = new Blob([props.urlXml], {
                                type: 'text/xml',
                              })
                              const url = URL.createObjectURL(blob)
                              window.open(url)
                              URL.revokeObjectURL(url)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} size="2x" />
                        </CButton>
                      </>
                    )}

                    {isMobile && (
                      <CButton
                        disabled={error}
                        variant="ghost"
                        onClick={() => {
                          props.onClose()
                        }}
                      >
                        <FontAwesomeIcon icon={faWindowClose} size="2x" />
                      </CButton>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="VisorXmlLayoutMain"
                style={{
                  overflowY: 'auto',
                  backgroundColor: '#FFF',
                }}
              >
                {srcFile && (
                  <div
                    id="capa23232"
                    style={{
                      width: '100%',
                      height: 'auto',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                    className="d-flex align-items-start justify-content-start"
                  >
                    {!isFirefox && (
                      <XMLViewer
                        xml={srcFile}
                        theme={customTheme}
                        collapsible
                        // overflowBreak
                      />
                    )}
                    {isFirefox && (
                      <iframe
                        src={srcFile}
                        frameBorder="0"
                        allowFullScreen
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CModal>,
      element,
    )
  }

  return <></>
}
