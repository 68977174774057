// Library
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Components and Types
import { ProvinciasListado } from './types'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import { StatesEdition, TabTypes } from '../../../../../store/enums'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { CustomButtons, CustomDictionary } from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import { EProvinciasButtons } from '../mantenimiento/mantenimiento'
import { tabIsVisible } from '../../../../../hooks/useNavigationTabControls'
import { ModuleButtonsImport } from '../../../../clientes/pages/clientes/components/importar'

const initialState: TabsState<ProvinciasListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
      },
    },
  },
}

const existeTab = (tabs: CustomDictionary<TabState<any>>, codigo: number) => {
  for (const key in tabs) {
    const tab = tabs[key]
    if (tab.id === codigo) return key
  }
}

const getNewTabKeyOnClose = (state: TabsState<any>, keyToClose: string) => {
  let newCurrent = ''
  for (const key in state.tabs) {
    if (newCurrent === '') {
      newCurrent = key
    }
    if (keyToClose === key) {
      return newCurrent
    }
  }
  return 'BUSQUEDA'
}

const tabsConfPaisesSlice = createSlice({
  name: 'ProvinciasTabs',
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string>) {
      const newCurrent = getNewTabKeyOnClose(state, action.payload)
      state.current = newCurrent
      delete state.tabs[action.payload]
    },
    openTab(
      state,
      action: PayloadAction<{
        key: string
        item?: ProvinciasListado
        templateMode?: boolean
      }>,
    ) {
      if (action.payload.item === undefined || action.payload.templateMode) {
        const tab: TabState<ProvinciasListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo:
              action.payload.templateMode && action.payload.item
                ? action.payload.item.codigo
                : 0,
          },
          buttons: EProvinciasButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = existeTab(state.tabs, action.payload.item.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<ProvinciasListado> = {
            tabKey: action.payload.key,
            id: action.payload.item.codigo,
            tittle: `${action.payload.item.nombre}-${action.payload.item.codigo}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.item.codigo,
              info: action.payload.item,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: EProvinciasButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string
        estado: StatesEdition
        info?: ProvinciasListado
        buttons: CustomButtons
      }>,
    ) {
      const { estado, tabKey, info, buttons } = action.payload
      state.tabs[tabKey].editStatus = estado
      state.tabs[tabKey].currentExecutingAction = undefined
      state.tabs[tabKey].buttons = buttons
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      const info = state.tabs[action.payload].info
      // if (info !== undefined) {
      //   if (info.info) {
      //     info.info.estado = 'ANULADO'
      //     info.info.estadoElectronico = 'ANULADO'
      //   }
      //   state.tabs[action.payload].info = info
      // }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    setNameTab(
      state,
      action: PayloadAction<{ key: string; codigo: number; nombre: string }>,
    ) {
      state.tabs[
        action.payload.key
      ].tittle = `${action.payload.codigo}-${action.payload.nombre}`
      state.tabs[action.payload.key].info.codigo = action.payload.codigo
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    openTabImport(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      if (action.payload.type === TabTypes.import) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<ProvinciasListado> = {
            id: -1,
            tittle: 'Importar',
            type: TabTypes.import,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsImport,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
  },
})

export const selectTabsProvincias = (state: RootState) => {
  return state.administracion.catalagos.provincias.tabs
}

export const {
  closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setTabInfoAsInactive,
  setButtons,
  setNameTab,
  setCurrentExecutingAction,
  openTabImport,
} = tabsConfPaisesSlice.actions
export const tabsReducer = tabsConfPaisesSlice.reducer
