import DateBox from 'devextreme-react/date-box'
import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box'
import React from 'react'
import {
  LoaderInfo,
  TipoComprobante,
  TiposComprobantesSri,
  ToastTypes,
} from '../../../../store/types'
import CustomCol from '../../../../views/componentes/colContainer'
import Labeled from '../../../../views/componentes/labeledInput/labeledInput'
import CustomModalDevx from '../../../../views/componentes/modal/Modal'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import { BuscarProveedorLookUp } from '../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import { ProveedorBusqueda } from '../../../proveedores/store/types'
import { SelectBuscarTiposComprobantes } from '../../../shared/components/buscarTiposComporbantes/SelectTiposComprobantes'
import {
  Validator,
  RequiredRule,
  PatternRule,
  RangeRule,
  AsyncRule,
} from 'devextreme-react/validator'
import { useDispatch, useSelector } from 'react-redux'
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper'
import NumberBox from 'devextreme-react/number-box'
import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils'
import { TiposComprobantesService } from '../../../shared/services/TiposComprobantes.service'
import { addToast } from '../../../../store/toasterReducer'
import { ProveedoresService } from '../../../proveedores/services/proveedores.service'
import LoadPanel from 'devextreme-react/load-panel'
import { XmlFacturacionElectronicaHelper } from '../../../../helpers/xmlFileHelper'
import {
  CompraFullInfo,
  CompraIngresar,
  FormaPagoModuloCompras,
} from '../../types/types'
import { RootState } from '../../../../store/store'
import ValidationGroup from 'devextreme-react/validation-group'
import { CompraService } from '../../services/compra.service'
import SelectBox from '../../../../views/componentes/selectBox/selectBox'
import { VentasService } from '../../../ventas/services/ventas.service'
import { utilidades } from '../../../../helpers/utilidades'
import ValidationSummary from 'devextreme-react/validation-summary'
import { isMobileOnly } from 'react-device-detect'
import { ECountry } from '../../../../store/enum/countries'
import { consoleService } from '../../../../services/console.service'
import { JsonDteElectronicHelper } from '../../../../helpers/sv/jsonFileHelper'

interface IRegistrarCompraProps extends React.PropsWithChildren {
  onOk: (codigo: number) => void
  onCancel: () => void
  compra: CompraFullInfo | null
}

export type SustentosTributarios = {
  codigo: string
  tipo: string
}

export const RegistrarCompra: React.FC<IRegistrarCompraProps> = (props) => {
  const { onCancel, onOk, compra } = props
  const dispatch = useDispatch()

  const validationGroupRef = React.useRef<any>()

  const localState = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const empresaState = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const sesion = useSelector((state: RootState) => {
    return state.global.session
  })
  const pventa = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const precision = useSelector((state: RootState) => {
    return state.global.session?.empresa?.precision
  })

  const [proveedor, setProveedor] = React.useState<ProveedorBusqueda | null>(
    null,
  )
  const [tipoComprobante, setTipoComprobante] =
    React.useState<TipoComprobante | null>(null)
  const [fecha, setFecha] = React.useState<Date>(new Date())
  const [numero, setNumero] = React.useState<string>('')
  const [codigo, setCodigo] = React.useState<number>(0)
  const [autorizacion, setAutorizacion] = React.useState<string>('')
  const [subtotal0, setSubtotal0] = React.useState<number>(0)
  const [subtotalIva, setSubtotalIva] = React.useState<number>(0)
  const [iva, setIva] = React.useState<number>(0)
  const [subtotal5, setSubtotal5] = React.useState<number>(0)
  const [ivaConstruccion, setIvaConstruccion] = React.useState<number>(0)
  const [formasPago, setFormasPago] = React.useState<
    Array<FormaPagoModuloCompras>
  >([])
  const [formaPago, setFormaPago] = React.useState<FormaPagoModuloCompras>()
  const [loader, setLoader] = React.useState<LoaderInfo>({
    show: false,
    mensaje: '',
  })
  const inputFile = React.useRef<any>(null)
  const [esXmlImportado, setEsXmlImportado] = React.useState<boolean>(false)
  const [crearProveedor, setCrearProveedor] = React.useState<boolean>(false)
  const [show, setShow] = React.useState<boolean>(false)
  const [porcentajeIva, setPorcentajeIva] = React.useState(12)
  const [autorizacionCorrecta, setAutorizacionCorrecta] = React.useState(false)
  const [sustentosTributarios, setSustentosTributarios] = React.useState<
    Array<SustentosTributarios> | []
  >([])
  const [sustentoTributario, setSustentoTributario] =
    React.useState<SustentosTributarios | null>(null)
  const [edition, setEdition] = React.useState<boolean>(false)
  const [dataEdition, setDataEdition] = React.useState<CompraFullInfo | null>(
    null,
  )

  const validateProveedorOnClaveAccesoElectronica = React.useCallback(() => {
    const rucProveedorSegunAutorizacion = autorizacion.substr(10, 10)
    if (proveedor == null) {
      return Promise.resolve()
    }
    const rucProveedorSeleccionado = proveedor.identificacion.substr(0, 10)
    if (rucProveedorSegunAutorizacion !== rucProveedorSeleccionado) {
      return Promise.reject(
        `El ruc de proveedor segun la clave de acceso es ${autorizacion.substr(
          10,
          13,
        )}001, ud ha seleccionado un proveedor con ruc ${
          proveedor.identificacion
        }, corrija.`,
      )
    }
    return Promise.resolve()
  }, [proveedor, autorizacion])

  const onProveedorChanged = React.useCallback(
    (newValue: ProveedorBusqueda | null) => {
      setProveedor(newValue)
    },
    [],
  )

  const onTipoComprobanteChanged = React.useCallback(
    (newValue: TipoComprobante) => {
      setTipoComprobante(newValue)
    },
    [],
  )

  const onFechaChanged = React.useCallback(async (value) => {
    setFecha(value)

    const iva = await VentasService.getIva(
      DateUtils.dateBoxToApiDate(value),
      null,
    )
    setPorcentajeIva(iva)
  }, [])

  const onNumeroChanged = React.useCallback((value) => {
    setNumero(value)
  }, [])

  const onSustentoTributario = React.useCallback((value) => {
    setSustentoTributario(value)
  }, [])

  const onFormaPago = React.useCallback((value) => {
    setFormaPago(value)
  }, [])

  const handleFileUpload = React.useCallback(
    async (e) => {
      const { files } = e.target
      if (files && files.length) {
        try {
          const current = inputFile.current
          if (current != null && current?.instance) {
            current.instance.reset()
          }
          if (sesion?.empresa?.codigoPais === ECountry.ElSalvador) {
            setLoader({
              show: true,
              mensaje: 'Recuperando Información del archivo .json...',
            })

            const text = await JsonDteElectronicHelper.openFile(files[0])
            console.log('text', JSON.parse(text))

            const docRelated = await JsonDteElectronicHelper.readJsonFile(
              JSON.parse(text),
            )
            console.log('docRelated', docRelated)

            if (docRelated === false || docRelated === '') {
              dispatch(
                addToast({
                  content:
                    'No se pudo recuperar la información del archivo seleccionado, Dte no permitido o contiene el formato incorrecto ',
                  type: ToastTypes.Warning,
                }),
              )
              setLoader({ show: false, mensaje: '' })
              return false
            }

            setAutorizacion(docRelated.numeroAutorizacion)
            const proveedor = await ProveedoresService.getProveedor(
              docRelated.receptorIdentificacion,
            )
            if (proveedor) {
              setProveedor(proveedor)
            }
            const dpTiposComprobantes = await TiposComprobantesService.getAll()

            const tipoComprobante = dpTiposComprobantes.find(
              (x) => x.codigo === docRelated.tipoDte,
            )
            if (tipoComprobante !== undefined) {
              setTipoComprobante(tipoComprobante)
            }
            if (docRelated?.fecEmi) {
              const fechaEmision = DateUtils.format(
                docRelated?.fecEmi,
                formatoFechasApi,
              )
              if (fechaEmision === 'Invalid Date') {
                const dateInvalid =
                  'Formato de fecha incorrecto, clave de acceso inválida'
                throw dateInvalid
              }
              setFecha(DateUtils.strDateToDate(fechaEmision, formatoFechasApi))
            }

            if (docRelated?.formasPago && docRelated?.formasPago.length === 1) {
              const fp = await CompraService.getFormasPago()
              consoleService.log('fp', fp)
              if (fp) {
                const fpago = fp.find(
                  (x) => x.SRICodigo === docRelated?.formasPago[0].codigo,
                )
                setFormaPago(fpago)
              }
            }
            if (docRelated?.numeroControl) {
              const partes = docRelated?.numeroControl.split('-')
              if (partes.length >= 3) {
                const resultado = partes.slice(2).join('-')
                setNumero(
                  JsonDteElectronicHelper.extractNumber(resultado) ?? '',
                )
              }
            }
          } else {
            setLoader({
              show: true,
              mensaje: 'Recuperando Información del xml...',
            })
            const text = await XmlFacturacionElectronicaHelper.openFile(
              files[0],
            )

            if (!text) {
              dispatch(
                addToast({
                  content:
                    'No se pudo recuperar la información del archivo seleccionado.',
                  type: ToastTypes.Warning,
                }),
              )
              setLoader({ show: false, mensaje: '' })
              return
            }
            const infoXml = await XmlFacturacionElectronicaHelper.proccesXml(
              text,
            )
            console.log('infoXml:', infoXml)
            if (infoXml === undefined) {
              dispatch(
                addToast({
                  content:
                    'El Xml no puede ser procesado, contenido incorrecto.',
                  type: ToastTypes.Danger,
                }),
              )
              setLoader({ show: false, mensaje: '' })
              return
            }

            const fp = await CompraService.getFormasPago()
            setFormasPago(fp)

            const tiposComprobantes = await TiposComprobantesService.getAll()
            const tipoComprobante = tiposComprobantes.find(
              (x) => x.codigo === infoXml.tipoComprobante,
            )
            if (tipoComprobante === undefined) {
              dispatch(
                addToast({
                  content:
                    'El xml es incorrecto, no se puede identificar el tipo de comprobante con código ' +
                    infoXml.tipoComprobante,
                  type: ToastTypes.Warning,
                }),
              )
              setAutorizacion('')
              setLoader({ show: false, mensaje: '' })
              return
            }
            if (infoXml.tipoComprobante !== TiposComprobantesSri.Factura) {
              dispatch(
                addToast({
                  content:
                    'Importar el tipo de comprobante ' +
                    infoXml.tipoComprobante +
                    ' no está soportado.',
                  type: ToastTypes.Warning,
                }),
              )
              setLoader({ show: false, mensaje: '' })
              return
            }

            if (infoXml.comprobante === undefined) {
              dispatch(
                addToast({
                  content:
                    'El xml es incorrecto, no se puede recuperar informacion del comprobante',
                  type: ToastTypes.Warning,
                }),
              )
              setLoader({ show: false, mensaje: '' })
              return
            }
            const factura = infoXml.comprobante
            if (
              factura.infoFactura.identificacionComprador.substr(0, 10) !==
              (sesion?.empresa.ruc ?? '').substr(0, 10)
            ) {
              dispatch(
                addToast({
                  content:
                    'La factura no pertenece a la empresa actual, está emitida a ' +
                    factura.infoFactura.razonSocialComprador,
                  type: ToastTypes.Warning,
                  autoHide: 5000,
                }),
              )
              setLoader({ show: false, mensaje: '' })
              return
            }

            const iva = await VentasService.getIva(
              factura.infoFactura.fechaEmision,
              null,
            )
            consoleService.log('iva: ', iva)
            setPorcentajeIva(iva)

            setTipoComprobante(tipoComprobante)
            setAutorizacion(infoXml.numeroAutorizacion)
            setFecha(
              DateUtils.strDateToDate(
                factura.infoFactura.fechaEmision,
                formatoFechasApi,
              ),
            )
            setNumero(
              `${factura.infoTributaria.estab}-${factura.infoTributaria.ptoEmi}-${factura.infoTributaria.secuencial}`,
            )
            const impuesto0 = factura.infoFactura.totalConImpuestos.find(
              (x) => x.codigoPorcentaje === 0,
            )
            const impuesto12 = factura.infoFactura.totalConImpuestos.find(
              (x) => x.codigoPorcentaje !== 0 && x.codigoPorcentaje !== 5,
            )
            const impuesto5 = factura.infoFactura.totalConImpuestos.find(
              (x) => x.codigoPorcentaje === 5,
            )

            setSubtotal0(impuesto0 !== undefined ? impuesto0.baseImponible : 0)
            setSubtotalIva(
              impuesto12 !== undefined ? impuesto12.baseImponible : 0,
            )
            setSubtotal5(impuesto5 !== undefined ? impuesto5.baseImponible : 0)
            setIva(impuesto12 !== undefined ? impuesto12.valor : 0)
            setIvaConstruccion(impuesto5 !== undefined ? impuesto5.valor : 0)
            const proveedor = await ProveedoresService.getProveedor(
              factura.infoTributaria.ruc,
            )
            if (proveedor) {
              setProveedor(proveedor)
            } else {
              setCrearProveedor(true)
            }
            setEsXmlImportado(true)
            setAutorizacionCorrecta(true)
            console.log(infoXml)
          }
        } catch (error) {
          console.error(error)
        }
      }
      setLoader({ show: false, mensaje: '' })
    },
    [dispatch, sesion],
  )

  const onProcessDataAntorization = React.useCallback(
    async (stringValue) => {
      if (stringValue.length === 49) {
        //eléctronica
        setLoader({ show: true, mensaje: 'Recuperando Información...' })
        try {
          const rucProveedor = stringValue.substr(10, 13)
          const proveedor = await ProveedoresService.getProveedor(rucProveedor)
          if (proveedor) {
            setProveedor(proveedor)
          }

          const strFecha = `${stringValue.substr(0, 2)}/${stringValue.substr(
            2,
            2,
          )}/${stringValue.substr(4, 4)}`
          const tipoComprobanteCodigo = stringValue.substr(8, 2)
          const tiposComprobantes = await TiposComprobantesService.getAll()
          const fechaAsDate = DateUtils.strDateToDate(
            strFecha,
            formatoFechasApi,
          )
          const dateValue = fechaAsDate.toString()
          if (dateValue === 'Invalid Date') {
            const dateInvalid =
              'Formato de fecha incorrecto, clave de acceso inválida'
            throw dateInvalid
          }
          setFecha(fechaAsDate)

          const tipoComprobante = tiposComprobantes.find(
            (x) => x.codigo === tipoComprobanteCodigo,
          )
          if (tipoComprobante !== undefined) {
            setTipoComprobante(tipoComprobante)
          } else {
            dispatch(
              addToast({
                content:
                  'La Autorización es incorrecta, no se puede identificar el tipo de comprobante con código ' +
                  tipoComprobanteCodigo,
                type: ToastTypes.Warning,
              }),
            )
            setAutorizacion('')
            return
          }

          const establecimiento = stringValue.substr(24, 3)
          const puntoEmision = stringValue.substr(27, 3)
          const numeroComprobante = stringValue.substr(30, 9)
          setNumero(`${establecimiento}-${puntoEmision}-${numeroComprobante}`)
          setAutorizacionCorrecta(true)
        } catch (error) {
          dispatch(
            addToast({
              content: 'La autorización no tiene un formato correcto',
              type: ToastTypes.Warning,
            }),
          )
          setAutorizacionCorrecta(false)
        }
      } else {
        setAutorizacionCorrecta(true)
      }
      setLoader({ show: false, mensaje: '' })
    },
    [dispatch],
  )

  const onAutorizacionChanged = React.useCallback(
    async (value) => {
      const stringValue = (value ?? '').trim() as string
      setAutorizacion(stringValue)
      onProcessDataAntorization(stringValue)
    },
    [onProcessDataAntorization],
  )

  const onSubtotal0Changed = React.useCallback((value) => {
    setSubtotal0(value)
  }, [])

  const onSubtotal5Changed = React.useCallback(
    (value) => {
      setSubtotal5(value)
      setIvaConstruccion(
        parseFloat((value * (5 / 100.0)).toFixed(precision ?? 2)),
      )
    },
    [precision],
  )

  const onSubtotalIvaChanged = React.useCallback(
    (value) => {
      setSubtotalIva(value)
      setIva(
        parseFloat((value * (porcentajeIva / 100.0)).toFixed(precision ?? 2)),
      )
    },
    [porcentajeIva, precision],
  )

  const onIvaChanged = React.useCallback(
    (value) => {
      const valorIva = parseFloat(value.toFixed(precision ?? 2))
      const subtotalIva = (valorIva * 100) / porcentajeIva
      setIva(valorIva)
      setSubtotalIva(parseFloat(subtotalIva.toFixed(precision ?? 2)))
    },
    [porcentajeIva, precision],
  )

  const onIvaConstruccionChanged = React.useCallback(
    (value) => {
      const valorIva = parseFloat(value.toFixed(precision ?? 2))
      const subtotalIva = (valorIva * 100) / 5
      setIvaConstruccion(valorIva)
      setSubtotal5(parseFloat(subtotalIva.toFixed(precision ?? 2)))
    },
    [precision],
  )

  const validate = React.useCallback(async () => {
    let resolved = false
    let status = false
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(50)
      }
      resolve(resolved)
    })
    const validationResult = validationGroupRef.current.instance.validate()
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true);
      return false
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async (resolve) => {
        await resolve
        status = resolve.isValid
        resolved = true
      })
      await p
      return status
    }
    return true
  }, [])

  const onGuardarCompra = React.useCallback(async () => {
    const validationResult = await validate() //validationGroupRef.current.instance.validate();
    if (!validationResult) {
      //setTieneErroresValidacion(true);
      return
    }
    try {
      setLoader({ show: true, mensaje: 'Guardando Compra...' })
      const numeroFactura = numero.split('-')
      const fullProveedorInfo = await ProveedoresService.getProveedorByCode(
        proveedor?.codigo ?? 0,
      )
      const compra: CompraIngresar = {
        sustentoTributario: sustentoTributario?.codigo ?? '00',
        local: sesion?.local.codigo ?? 0,
        codigo: codigo,
        fechaEmision: DateUtils.dateToString(fecha, formatoFechasApi),
        establecimiento: numeroFactura[0],
        puntoEmision: numeroFactura[1],
        numero: numeroFactura[2],
        host: pventa ?? '',
        autorizacion: autorizacion,
        items: [],
        moneda: 'USD',
        proveedor: {
          identificacion: proveedor?.identificacion ?? '',
          nombreComercial: proveedor?.razonComercial ?? '',
          razonSocial: proveedor?.razonComercial ?? '',
          ciudad: fullProveedorInfo.ciudadNombre ?? '',
          contribuyenteEspecial: '',
          direccion: fullProveedorInfo.direccion ?? '',
          email: fullProveedorInfo.email ?? '',
          obligadoContabilidad: true,
          telefono: fullProveedorInfo.telefono ?? '',
        },
        pagos: [
          {
            medio: formaPago?.nombre ?? '',
            total: parseFloat(
              (subtotal0 + subtotalIva + iva).toFixed(precision ?? 2),
            ),
            id_medio: formaPago?.codigo.toString() ?? '',
          },
        ],
        totales: {
          descuentoAdicional: 0,
          importeTotal: parseFloat(
            (
              subtotal0 +
              subtotalIva +
              subtotal5 +
              iva +
              ivaConstruccion
            ).toFixed(precision ?? 2),
          ),
          propina: 0,
          totalSinImpuestos: parseFloat(
            (subtotal0 + subtotalIva + subtotal5).toFixed(precision ?? 2),
          ),
          impuestos: [
            {
              codigo: 2,
              codigo_porcentaje: 0,
              descuento_adicional: 0,
              baseImponible: subtotal0,
              valor: 0,
            },
            {
              codigo: 2,
              codigo_porcentaje: 2,
              descuento_adicional: 0,
              baseImponible: subtotalIva,
              valor: parseFloat(iva.toFixed(precision ?? 2)),
            },
            {
              codigo: 5,
              codigo_porcentaje: 5,
              descuento_adicional: 0,
              baseImponible: subtotal5,
              valor: parseFloat(ivaConstruccion.toFixed(precision ?? 2)),
            },
          ],
        },
        cuotas: [],
        tipoComprobante: tipoComprobante?.codigo ?? '01',
        paisCodigo: empresaState?.codigoPais ?? 1,
      }
      if (empresaState?.codigoPais === ECountry.ElSalvador) {
        const numeroFacturaDte = numero.split('-')

        compra.establecimiento = numero?.substring(0, 4) ?? ''
        compra.puntoEmision = numero?.substring(4, 8) ?? ''
        compra.numero = numeroFacturaDte?.[1] ?? ''
      }
      const resultado = await CompraService.ingresar(compra)
      onOk(parseInt(resultado))
      dispatch(
        addToast({
          content: 'Compra registrada correctamente',
          type: ToastTypes.Success,
        }),
      )
    } catch (error) {
      dispatch(
        addToast({
          content: error,
          type: ToastTypes.Info,
        }),
      )
    }
    setLoader({ show: false, mensaje: '' })
  }, [
    validate,
    precision,
    sustentoTributario,
    codigo,
    autorizacion,
    fecha,
    formaPago,
    iva,
    numero,
    proveedor,
    pventa,
    sesion,
    subtotal0,
    subtotalIva,
    tipoComprobante,
    empresaState,
    onOk,
    dispatch,
    subtotal5,
    ivaConstruccion,
  ])

  const onAbrirXml = React.useCallback(() => {
    consoleService.log('onAbrirXml')
    const current = inputFile.current
    if (current != null) {
      current.click()
      //current.addEventListener('change',(e)=>console.log(e) )
      current.onchange = handleFileUpload
    }
  }, [handleFileUpload])

  const onInitIva = React.useCallback(async () => {
    const iva = await VentasService.getIva(
      DateUtils.format(new Date(), formatoFechasApi),
      null,
    )
    setPorcentajeIva(iva)
  }, [])

  const cargarFormasPago = React.useCallback(async () => {
    consoleService.log('cargarFormasPagoEIva')
    const fp = await CompraService.getFormasPago()
    setFormasPago(fp)
  }, [])

  const cargarSustentoTributario = React.useCallback(async () => {
    const sustentosTributarios: any =
      await CompraService.getSustentosTributarios()
    if (sustentosTributarios.length > 0) {
      const sustentos: Array<SustentosTributarios> = []
      for (const itemSustento of sustentosTributarios) {
        const itSustento = {
          codigo: itemSustento.codigo,
          tipo: itemSustento.tipo,
        }
        sustentos.push(itSustento)
      }
      setSustentosTributarios(sustentos)
      setSustentoTributario(sustentos[0])
    }
  }, [])

  const resetFormularioCompra = React.useCallback(() => {
    setProveedor(null)
    setTipoComprobante(null)
    setFecha(new Date())
    setNumero('')
    setSubtotal0(0)
    setSubtotal5(0)
    setSubtotalIva(0)
    setIva(0)
    setIvaConstruccion(0)
  }, [])

  const verificaAutorizacionValida = React.useCallback((data) => {
    return new Promise((resolve, reject) => {
      if (data?.value.length === 49 || data?.value.length === 10) {
        resolve(true)
      } else {
        reject('Formato inválido')
      }
    })
  }, [])

  const cargarCompra = React.useCallback(
    async (compraData: CompraFullInfo) => {
      console.log('cargarCompra', compraData)
      setDataEdition(compraData)
      setEdition(true)
      setLoader({ show: true, mensaje: 'Cargando Compra...' })
      try {
        setCodigo(compraData.codigo)
        setAutorizacion(compraData.autorizacion)
        const num: string =
          empresaState?.codigoPais === ECountry.ElSalvador
            ? `${compraData.establecimiento}${compraData.puntoEmision}-${compraData.numero}`
            : `${compraData.establecimiento}-${compraData.puntoEmision}-${compraData.numero}`

        setNumero(num)
        setSubtotal0(compraData.subtotal0)
        setSubtotalIva(compraData.subtotal12)
        setIva(compraData.IVA)

        const fechaAsDate = DateUtils.strDateToDate(
          compraData.fecha,
          formatoFechasApi,
        )
        setFecha(fechaAsDate)

        const tiposComprobantes = await TiposComprobantesService.getAll()

        const tipoComprobante = tiposComprobantes.find(
          (x) => x.codigo === compraData.tipoDocumento,
        )
        if (tipoComprobante !== undefined) {
          setTipoComprobante(tipoComprobante)
        } else {
          dispatch(
            addToast({
              content:
                'La Autorización es incorrecta, no se puede identificar el tipo de comprobante con código ' +
                compraData.tipoDocumento,
              type: ToastTypes.Warning,
            }),
          )
          setAutorizacion('')
          return
        }

        const rucProveedor = compraData.proveedorIdentificacion
        const proveedor = await ProveedoresService.getProveedor(rucProveedor)
        if (proveedor) {
          setProveedor(proveedor)
        }

        const fp = await CompraService.getFormasPago()
        if (fp) {
          const fpago = fp.find((x) => x.codigo === compraData.formaPagoCodigo)
          setFormaPago(fpago)
        }

        const sustentosTributarios: any =
          await CompraService.getSustentosTributarios()
        if (sustentosTributarios) {
          const sustento = sustentosTributarios.find(
            (x) => x.codigo === compraData.sustentoCodigo,
          )
          if (sustento) {
            setSustentoTributario(sustento)
          }
        }

        setShow(true)
      } catch (error) {
        dispatch(
          addToast({
            content: error,
            type: ToastTypes.Info,
          }),
        )
      }

      setLoader({ show: false, mensaje: '' })
    },
    [dispatch, empresaState],
  )

  const inputIdentificacionCedula = React.useMemo(() => {
    if (localState?.ciudad?.paisCodigo === ECountry.ElSalvador) {
      return (
        <>
          <input
            id="inputAbrirXmlCompra"
            style={{ display: 'none' }}
            accept={
              empresaState?.codigoPais === ECountry.ElSalvador
                ? '.json'
                : '.xml'
            }
            ref={inputFile}
            type="file"
          />
          <TextBox
            value={autorizacion}
            onValueChange={onAutorizacionChanged}
            readOnly={esXmlImportado}
            onEnterKey={() => {
              if (autorizacion.length > 0) {
                onProcessDataAntorization(autorizacion)
              }
            }}
          />
        </>
      )
    } else {
      return (
        <>
          <input
            id="inputAbrirXmlCompra"
            style={{ display: 'none' }}
            accept={
              empresaState?.codigoPais === ECountry.ElSalvador
                ? '.json'
                : '.xml'
            }
            ref={inputFile}
            type="file"
          />
          <TextBox
            value={autorizacion}
            onValueChange={onAutorizacionChanged}
            readOnly={esXmlImportado}
            onEnterKey={() => {
              if (autorizacion.length > 0) {
                onProcessDataAntorization(autorizacion)
              }
            }}
          >
            <TextBoxButton
              name="cargarCompra"
              location="after"
              options={{
                disabled: autorizacion.length !== 49,
                icon: 'search',
                type: 'default',
                onClick: () => onProcessDataAntorization(autorizacion),
              }}
            />
            <Validator>
              <RequiredRule
                message={lh.getMessage(
                  MessagesKeys.GlobalCampoRequerido,
                  'Autorización',
                )}
              ></RequiredRule>
              {edition === false && (
                <PatternRule
                  message={lh.getMessage(
                    MessagesKeys.GlobalFormatoCampoIncorrecto,
                    'Autorización',
                  )}
                  pattern="^[0-9]{10}$|^[0-9]{49}$"
                />
              )}
              {edition === false && (
                <AsyncRule
                  message={lh.getMessage(
                    MessagesKeys.GlobalFormatoCampoIncorrecto,
                    'Autorización',
                  )}
                  validationCallback={(data) =>
                    verificaAutorizacionValida(data)
                  }
                  reevaluate
                />
              )}
            </Validator>
          </TextBox>
        </>
      )
    }
  }, [
    empresaState,
    autorizacion,
    edition,
    esXmlImportado,
    localState,
    onAutorizacionChanged,
    onProcessDataAntorization,
    verificaAutorizacionValida,
  ])

  React.useEffect(() => {
    cargarSustentoTributario()
    onInitIva()
    cargarFormasPago()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (compra !== null && dataEdition === null) {
      cargarCompra(compra)
    } else setShow(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CustomModalDevx
      id="registrarDocumentoCompra"
      size="lg"
      show={show}
      title={`${
        edition === false ? 'Registrar' : 'Actualizar'
      } ${'documento de compra'}`}
      toolbarItems={[
        {
          options: {
            text: 'Aceptar',
            stylingMode: 'contained',
            type: 'default',
            icon: 'check',
            onClick: onGuardarCompra,
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'after',
        },
        {
          options: {
            text: 'Cancelar',
            stylingMode: 'contained',
            type: 'danger',
            icon: 'remove',
            onClick: onCancel,
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'after',
        },
        {
          options: {
            text:
              empresaState?.codigoPais === ECountry.ElSalvador
                ? 'Abrir Json'
                : 'Abrir Xml',
            stylingMode: 'contained',
            type: 'default',
            icon: 'upload',
            onClick: onAbrirXml,
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'before',
        },
      ]}
      onClose={onCancel}
    >
      <div key="RegistrarCompra" id="RegistrarCompra">
        <ValidationGroup
          id={'validationGroupRegistrarCompra'}
          ref={validationGroupRef}
        >
          {!isMobileOnly && (
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          )}
          <RowContainer>
            <CustomCol xs="12" lg="6" xxl="4">
              <Labeled label="Autorización">
                {inputIdentificacionCedula}
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="12" lg="8">
              <Labeled label="Proveedor">
                <BuscarProveedorLookUp
                  selected={proveedor}
                  onChanged={onProveedorChanged}
                  disabled={esXmlImportado && !crearProveedor}
                  allowAdd
                  allowClear
                  allowEdit
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Proveedor',
                      )}
                    ></RequiredRule>
                    {empresaState?.codigoPais === ECountry.Ecuador &&
                      autorizacion.length === 49 &&
                      edition === false && (
                        <AsyncRule
                          reevaluate
                          validationCallback={
                            validateProveedorOnClaveAccesoElectronica
                          }
                          message="El proveedor seleccionado tiene un ruc diferente al esperado."
                        />
                      )}
                  </Validator>
                </BuscarProveedorLookUp>
              </Labeled>
            </CustomCol>
            <CustomCol xs="12" lg="4">
              <Labeled label="Tipo Documento">
                <SelectBuscarTiposComprobantes
                  selected={tipoComprobante}
                  onChanged={onTipoComprobanteChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Tipo Documento',
                      )}
                    ></RequiredRule>
                  </Validator>
                </SelectBuscarTiposComprobantes>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="6" lg="4" xxl="2">
              <Labeled label="Fecha">
                <DateBox
                  applyValueMode="useButtons"
                  value={fecha}
                  onValueChange={onFechaChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Fecha',
                      )}
                    ></RequiredRule>
                  </Validator>
                </DateBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="6" lg="4" xxl="2">
              <Labeled label="Número">
                <TextBox
                  name="Numero"
                  value={numero}
                  onValueChange={onNumeroChanged}
                  useMaskedValue={true}
                  readOnly={esXmlImportado}
                  placeholder={
                    empresaState?.codigoPais === ECountry.Ecuador
                      ? '000-000-000000000'
                      : '0000-0000-00000000000'
                  }
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Número',
                      )}
                    ></RequiredRule>
                    {empresaState?.codigoPais === ECountry.Ecuador && (
                      <PatternRule
                        message={lh.getMessage(
                          MessagesKeys.RetencionNroIncorrecto,
                          '',
                        )}
                        pattern={'^[0-9]{3}-[0-9]{3}-[0-9]{1,10}$'}
                      />
                    )}
                    {empresaState?.codigoPais === ECountry.ElSalvador && (
                      <PatternRule
                        message={lh.getMessage(
                          MessagesKeys.RetencionNroIncorrecto,
                          '',
                        )}
                        pattern={'^[0-9]{4}-[0-9]{4}-[0-9]{11}$'}
                      />
                    )}
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="6" lg="4" xxl="2">
              <Labeled label="Forma pago">
                <SelectBox
                  id="formaPagoretencionVenta"
                  placeholder="Forma de Pago"
                  options={formasPago}
                  displayExpr="nombre"
                  keyExpr="codigo"
                  selected={formaPago ?? null}
                  onChange={onFormaPago}
                  disabled={false}
                  clearButton={true}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.RetencionFormaPagoRequerida,
                      )}
                    />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="6" lg="4" xxl="6">
              <Labeled label="Sustento Tributario">
                <SelectBox
                  id="sustentosTributarios"
                  placeholder=""
                  options={sustentosTributarios}
                  displayExpr="tipo"
                  keyExpr="codigo"
                  selected={sustentoTributario ?? null}
                  onChange={onSustentoTributario}
                  clearButton={true}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GenericSustentoTributarioRequerida,
                      )}
                    />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol xs="3" lg="2">
              <Labeled label="Subt. 0%">
                <NumberBox
                  value={subtotal0}
                  onValueChange={onSubtotal0Changed}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Subt. 0%',
                      )}
                    ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>

            <CustomCol xs="3" lg="2">
              <Labeled label="Subt. 5%">
                <NumberBox
                  value={subtotal5}
                  onValueChange={onSubtotal5Changed}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Subt. 5%',
                      )}
                    ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>

            <CustomCol xs="3" lg="2">
              <Labeled label="Subt. IVA">
                <NumberBox
                  value={subtotalIva}
                  onValueChange={onSubtotalIvaChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'Subt. IVA',
                      )}
                    ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="3" lg="2">
              <Labeled label="Subt.">
                <NumberBox
                  value={parseFloat(
                    (subtotal0 + subtotalIva).toFixed(precision ?? 2),
                  )}
                  readOnly
                />
              </Labeled>
            </CustomCol>
            <CustomCol xs="3" lg="2">
              <Labeled label={'IVA' + porcentajeIva + ' %'}>
                <NumberBox
                  value={iva}
                  onValueChange={onIvaChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'IVA',
                      )}
                    ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="3" lg="2">
              <Labeled label="IVA Construcción">
                <NumberBox
                  value={ivaConstruccion}
                  onValueChange={onIvaConstruccionChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule
                      message={lh.getMessage(
                        MessagesKeys.GlobalCampoRequerido,
                        'IVA Construcción',
                      )}
                    ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol xs="3" lg="2">
              <Labeled label="Total">
                <NumberBox
                  value={parseFloat(
                    (
                      subtotal0 +
                      subtotalIva +
                      subtotal5 +
                      iva +
                      ivaConstruccion
                    ).toFixed(precision ?? 2),
                  )}
                  readOnly
                >
                  <Validator>
                    <RangeRule
                      min={0.01}
                      message="El total de la factura debe de ser mayor que 0"
                    ></RangeRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          {isMobileOnly && (
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          )}
        </ValidationGroup>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={'center'}
          visible={loader.show}
          showIndicator={true}
          shading={true}
          showPane={true}
          message={loader.mensaje}
        />
      </div>
    </CustomModalDevx>
  )
}
