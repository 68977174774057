import { DateUtils } from '../../../../../helpers/dateUtils'
import { ItemVenta } from '../../../types/types'
import { DetalleValidationResult } from './types'

export class DetalleVentaRedux {
  codigo: number = 0
  codigoInterno: string = ''
  codigoBarras: string = ''
  descripcion: string = ''
  tieneIva: boolean = false
  porcentajeiva: number = 0
  codigoImpuesto: number = 0
  imagenes: Array<string> = []
  fecha: string = ''
  _iva: number = 0
  _subtotal: number = 0
  _subtotal0: number = 0
  _exenta?: number = 0
  _noSujeto?: number = 0
  _gravada?: number = 0
  _subtotal5: number = 0
  _subtotalIva: number = 0
  _total: number = 0
  _cantidad: number = 0
  _precioUnitario: number = 0
  _precioIva: number = 0
  _porcentajeDescuento: number = 0
  _descuento: number = 0
  constructor() {}
}

export class DetalleVenta {
  private _iva: number
  private _subtotal: number
  private _subtotal0: number
  private _exenta: number
  private _noSujeto: number
  private _gravada: number
  private _subtotal5: number
  private _subtotalIva: number
  private _total: number
  private _cantidad: number = 0
  private _precioUnitario: number = 0
  private _precioIva: number = 0
  private _porcentajeDescuento: number = 0
  private _descuento: number = 0
  private _presicionDecimal: number = 4
  private _codigoImpuesto: number = 0

  codigo: number
  codigoInterno: string
  codigoBarras: string
  descripcion: string
  tieneIva: boolean
  porcentajeiva: number
  imagenes: Array<string>
  fecha: string

  get codigoImpuesto(): number {
    return this._codigoImpuesto
  }

  //#region cantidad
  get cantidad(): number {
    return this._cantidad
  }

  set cantidad(newCantidad: number) {
    if (newCantidad < 0) {
      throw new Error('la cantidad no puede ser menor a cero')
    }
    this._cantidad = newCantidad
    if (isNaN(newCantidad)) {
      this._cantidad = 0
    }
    this.calcular()
  }
  //#endregion

  //#region precio
  get precio(): number {
    return this._precioUnitario
  }

  set precio(newPrecio: number) {
    if (newPrecio < 0) {
      throw new Error('el precio no puede ser menor a cero')
    }
    this._precioUnitario = newPrecio
    if (isNaN(newPrecio)) {
      this._precioUnitario = 0
    }
    this._precioUnitario = this.redondear(this._precioUnitario)
    this.calcular()
  }
  //#endregion

  get descuento(): number {
    return this._descuento
  }

  get porcentajeDescuento(): number {
    return this._porcentajeDescuento
  }

  set porcentajeDescuento(newPorcentaje: number) {
    this._porcentajeDescuento = newPorcentaje
    if (isNaN(newPorcentaje)) {
      this._porcentajeDescuento = 0
    }
    this.calcular()
  }

  get subtotal(): number {
    return this._subtotal
  }

  get subtotal0(): number {
    return this._subtotal0
  }

  get exenta(): number {
    return this._exenta
  }

  get noSujeto(): number {
    return this._noSujeto
  }

  get gravada(): number {
    return this._gravada
  }

  get subtotal5(): number {
    return this._subtotal5
  }

  get subtotalIVA(): number {
    return this._subtotalIva
  }

  get iva(): number {
    return this._iva
  }

  get total(): number {
    return this._total
  }

  get precioIva(): number {
    return this._precioIva
  }

  set precioIva(newPrecioIva: number) {
    this._precioIva = newPrecioIva
    if (isNaN(newPrecioIva)) {
      this._precioIva = 0
      newPrecioIva = 0
    }
    this._precioUnitario = this.redondear(
      this.tieneIva ? newPrecioIva / (this.porcentajeiva + 1) : newPrecioIva,
      //this.tieneIva ? newPrecioIva / ((this.porcentajeiva + 100) / 100) : newPrecioIva
    )
    this.calcular()
  }

  constructor() {
    this.fecha = DateUtils.getCurrentDateAsString()
    this.codigo = 0
    this.codigoInterno = ''
    this.codigoBarras = ''
    this.descripcion = ''
    this.tieneIva = false
    this._iva = 0
    this._subtotal0 = 0
    this._exenta = 0
    this._noSujeto = 0
    this._gravada = 0
    this._subtotal5 = 0
    this._subtotal = 0
    this._total = 0
    this._subtotalIva = 0
    this._codigoImpuesto = 0
    this.porcentajeiva = 0
    this.imagenes = []
  }

  public redondear(valor: number): number {
    return parseFloat(valor.toFixed(this._presicionDecimal))
  }

  public calcular() {
    const subtotalAntesDescuento = this.redondear(
      this.cantidad * this._precioUnitario,
    )
    const descuento = this.redondear(
      (subtotalAntesDescuento * this.porcentajeDescuento) / 100.0,
    )
    const subtotalNeto = this.redondear(subtotalAntesDescuento - descuento)
    this._subtotal0 = this.tieneIva ? 0 : subtotalNeto
    if (this._codigoImpuesto === 5) {
      this._subtotal5 = this.tieneIva ? subtotalNeto : 0
      this._iva = this.redondear(this._subtotal5 * Number(this.porcentajeiva))
    }
    if (this._codigoImpuesto === 3) {
      this._subtotalIva = this.tieneIva ? subtotalNeto : 0
      this._iva = this.redondear(this._subtotalIva * Number(this.porcentajeiva))
    }
    this._descuento = descuento
    this._subtotal = this.redondear(
      this._subtotal0 + this._subtotalIva + this._subtotal5,
    )
    this._total = this.subtotal + this._iva
    this._precioIva = this.redondear(
      this.precio * (this.tieneIva ? 1 + Number(this.porcentajeiva) : 1),
    )
  }

  public validate(): DetalleValidationResult {
    return { resultado: true }
  }

  public setDetalle(data: DetalleVentaRedux, presicionDecimal: number) {
    this.codigo = data.codigo
    this.codigoInterno = data.codigoInterno
    this.codigoBarras = String(data.codigoBarras)
    this.descripcion = data.descripcion
    this.tieneIva = data.tieneIva
    this.porcentajeiva = data.porcentajeiva
    this.imagenes = data.imagenes
    this.fecha = data.fecha
    this._iva = data._iva
    this._subtotal = data._subtotal
    this._subtotal0 = data._subtotal0
    this._noSujeto = data._noSujeto
    this._exenta = data._exenta
    this._gravada = data._gravada
    this._subtotal5 = data._subtotal5
    this._subtotalIva = data._subtotalIva
    this._total = data._total
    this._cantidad = data._cantidad
    this._precioUnitario = data._precioUnitario
    this._precioIva = data._precioIva
    this._porcentajeDescuento = data._porcentajeDescuento
    this._descuento = data._descuento
    this._presicionDecimal = presicionDecimal
    this._codigoImpuesto = data.codigoImpuesto

    if (this._total <= 0) {
      this.calcular()
    }
  }

  public getDetalle(): DetalleVentaRedux {
    const detalle: DetalleVentaRedux = {
      codigo: this.codigo,
      codigoInterno: this.codigoInterno,
      codigoBarras: String(this.codigoBarras),
      descripcion: this.descripcion,
      tieneIva: this.tieneIva,
      porcentajeiva: this.porcentajeiva,
      imagenes: this.imagenes,
      fecha: this.fecha,
      _iva: this.iva,
      _subtotal: this.subtotal,
      _subtotal0: this.subtotal0,
      _exenta: this.exenta,
      _noSujeto: this.noSujeto,
      _gravada: this.gravada,
      _subtotal5: this.subtotal5,
      _subtotalIva: this.subtotalIVA,
      _total: this.total,
      _cantidad: this.cantidad,
      _precioUnitario: this.precio,
      _precioIva: this.precioIva,
      _descuento: this.descuento,
      _porcentajeDescuento: this.porcentajeDescuento,
      codigoImpuesto: this.codigoImpuesto,
    }
    return detalle
  }

  public setData(
    codigo: number,
    codigoInterno: string,
    codigoBarras: string,
    descripcion: string,
    porcentajeiva,
  ) {
    this.codigo = codigo
    this.codigoInterno = codigoInterno
    this.codigoBarras = String(codigoBarras)
    this.descripcion = descripcion
    this.tieneIva = porcentajeiva > 0
    this._iva = 0
    this._subtotal0 = 0
    this._subtotal5 = 0
    this._subtotal = 0
    this._total = 0
    this._subtotalIva = 0
    this.porcentajeiva = porcentajeiva / 100
    this.imagenes = []
  }

  public setItem(item: ItemVenta, presicionDecimal: number) {
    this.codigo = item.codigo
    this.codigoInterno = item.codigoInterno
    this.codigoBarras = String(item.codigoBarras)
    this.descripcion = item.descripcion
    this.tieneIva = item.porcentajeIva > 0
    this.porcentajeiva = item.porcentajeIva / 100
    this.precio = item.pvp
    this.cantidad = this.cantidad === 0 ? 1 : this.cantidad
    this._presicionDecimal = presicionDecimal
    this._codigoImpuesto = item.codigoImpuesto
    this.calcular()
  }
}
