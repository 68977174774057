import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor'
import {
  DateBox,
  RadioGroup,
  Switch,
  TextBox,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { AsyncRule, RequiredRule } from 'devextreme-react/data-grid'
import { NotificationList, SaveNotification } from '../../types/types'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  openTab,
  setButtons,
  setCurrentExecutingAction,
  setNameTabe,
} from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { notificationsServices } from '../../services/notifications.services'
import { RootState } from '../../../../../../store/store'
import { ToastTypes } from '../../../../../../store/types'
import {
  changeLoaderNotifications,
  setCurrentFunction,
} from '../../store/generalReducer'
import { initDatosEdicion, setDatosEdicion } from '../../store/editDataReduce'
import { StatesEdition } from '../../../../../ventas/types/enums'
import Dialog from '../../../../../../views/componentes/librerias/bootstrap-dialog'
import { defaultDataNotification } from '../..'
import FIleInput from '../../../../../componentes/fileInput'
import { restaFechas } from '../../../../../../helpers/Helper'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../helpers/dateUtils'
import { CButton, CRow } from '@coreui/react-pro'
import PopupMensajes from '../../../../../../containers/component/popupMensajes/popupMensajes'
import { TabState } from '../../../../../../store/genericTabTypes'
import {
  useConvertToBase64,
  useSetToast,
} from '../../../../../../hooks/useGlobalHooks'
import { utilidades } from '../../../../../../helpers/utilidades'

export const sizeValues = [
  '8pt',
  '10pt',
  '12pt',
  '14pt',
  '18pt',
  '24pt',
  '36pt',
]
export const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
]
export const headerValues = [false, 1, 2, 3, 4, 5]

interface INewProps extends PropsWithChildren {
  tab: TabState<NotificationList>
  tabId: string
}

const Nuevo: FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const setToastMessage = useSetToast()

  const currentAction = useSelector(
    (state: RootState) =>
      state.administracion.notificaciones.general.currentFunction,
  )
  const notificationState = useSelector((state: RootState) => {
    return state.administracion.notificaciones.editData[tabId]
  })
  const tabs = useSelector((state: RootState) => {
    return state.administracion.notificaciones.tabs
  })
  const typeNotification = useSelector((state: RootState) => {
    return state.administracion.notificaciones.general.typesNotification
  })

  const [confirmarNuevo, setconfirmarNuevo] = useState<boolean>(false)
  const validationGroupRef = useRef<any>()
  const dialogRef = useRef<any>(null)
  const [imageSoruce, setImageSource] = useState<string>('')
  const [showImage, setShowImage] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [enablePreview, setEnablePreview] = useState<boolean>(false)

  const convertToBase64 = useConvertToBase64()

  const playLoader = useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderNotifications({ show: true, mensaje: message }))
    },
    [dispatch],
  )

  const stopLoader = useCallback(() => {
    dispatch(changeLoaderNotifications({ show: false, mensaje: '' }))
  }, [dispatch])

  const onChangedTitle = useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              titulo: data.value,
            },
          }),
        )
    },
    [dispatch, notificationState, tabId],
  )

  const onChangedDescription = useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              descripcion: data.value,
            },
          }),
        )
    },
    [dispatch, tabId, notificationState],
  )

  const onValuedIdType = useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              tipoId: data.value,
            },
          }),
        )
    },
    [dispatch, tabId, notificationState],
  )

  const validateStartDate = useCallback((date: any) => {
    const dateError: number = Number(
      restaFechas(
        DateUtils.dateToString(new Date(), formatoFechasApi),
        DateUtils.pickersDateToApiDate(date.value),
      ),
    )
    if (dateError < 0)
      return Promise.reject(
        'Fecha de Inicio: No puede ser menor a la fecha de actual',
      )
    else return Promise.resolve()
  }, [])

  const validateFinishDate = useCallback(
    (date: any) => {
      const dateError = Number(
        restaFechas(
          DateUtils.pickersDateToApiDate(notificationState.fechaInicio),
          DateUtils.pickersDateToApiDate(date.value),
        ),
      )
      if (dateError < 0)
        return Promise.reject(
          'Fecha de Fin: No puede ser menor a la fecha inicial',
        )
      else return Promise.resolve()
    },
    [notificationState],
  )

  const onChangedStartDate = useCallback(
    (date) => {
      if (date.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              fechaInicio: date.value,
            },
          }),
        )
    },
    [dispatch, tabId, notificationState],
  )

  const onChangedEndDate = useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              fechaFin: data.value,
            },
          }),
        )
    },
    [dispatch, tabId, notificationState],
  )

  const setImageNotification = useCallback(() => {
    if (notificationState.imagen && notificationState.imagen.length > 0) {
      // eslint-disable-next-line no-undef
      const pathImage = `${process.env['REACT_APP_PATH_FOTOS_NOTIFICACIONES']}${notificationState.imagen}`
      setImageSource(pathImage)
      setShowImage(true)
    }
  }, [notificationState])

  const onChangedImage = useCallback(
    async (eventImage) => {
      if (eventImage === null || eventImage === undefined) {
        setImageSource('')
        setShowImage(false)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              imagen: '',
              imagenCodificada: '',
            },
          }),
        )
      } else if (
        eventImage &&
        eventImage.value &&
        eventImage.value.length > 0
      ) {
        let fileCoded: any = ''
        await convertToBase64(eventImage.value[0])
          .then((data) => {
            fileCoded = data
          })
          .catch((data) => {
            fileCoded = data
          })
        setImageSource(fileCoded)
        setShowImage(true)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              imagen: eventImage.value[0].name,
              imagenCodificada: fileCoded,
            },
          }),
        )
      }
    },
    [convertToBase64, dispatch, notificationState, tabId],
  )

  const onChangedPermanent = useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              permanente: data.value === true ? 1 : 0,
            },
          }),
        )
    },
    [dispatch, tabId, notificationState],
  )

  const onChangedShowPopup = useCallback(
    (data) => {
      if (data.event)
        dispatch(
          setDatosEdicion({
            key: tabId,
            notification: {
              ...notificationState,
              mostrarPopup: data.value === true ? 1 : 0,
            },
          }),
        )
    },
    [dispatch, tabId, notificationState],
  )

  const onConfirmarNuevo = useCallback(() => {
    dialogRef.current.show({
      title: 'Ácatha',
      body: `¿Desea crear un nuevo registro?`,
      actions: [
        Dialog.Action(
          <span>
            <u>P</u>estaña Actual
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'p',
        ),
        Dialog.Action(
          <span>
            <u>N</u>ueva Pestaña
          </span>,
          (dialog) => {
            dialog.hide()
            const id = utilidades.getUUID()
            const dataInitialDefault = {
              ...defaultDataNotification,
              tipoId: typeNotification[0],
            }
            dispatch(
              initDatosEdicion({
                key: id,
                data: dataInitialDefault,
              }),
            )
            dispatch(openTab({ key: id }))
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>C</u>ancelar
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    setconfirmarNuevo(false)
    return
  }, [dispatch, typeNotification])

  useEffect(() => {
    if (confirmarNuevo) {
      onConfirmarNuevo()
    }
  }, [confirmarNuevo, onConfirmarNuevo])

  const onHandleSave = useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false)
      setToastMessage(
        'Guardar Notificación',
        'Existen Errores en los datos, por favor verifíquelos.',
        ToastTypes.Warning,
      )
    else {
      try {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: ButtonTypes.save,
          }),
        )
        playLoader('Guardando . . .')
        let contentMail: string = notificationState?.descripcion ?? ''
        if (notificationState?.descripcion.includes('imagenDefault')) {
          contentMail = notificationState?.descripcion.replace(
            'imagenDefault',
            notificationState?.imagen ?? '',
          )
        }
        const notification: SaveNotification = {
          infoRegistro: {
            codigo: notificationState.codigo,
            descripcion: contentMail,
            fechaInicio: DateUtils.pickersDateToApiDate(
              notificationState.fechaInicio,
            ),
            fechaFin: DateUtils.pickersDateToApiDate(
              notificationState.fechaFin,
            ),
            titulo: notificationState.titulo,
            imagen:
              notificationState.imagen === '' ? null : notificationState.imagen,
            permanente: notificationState.permanente,
            tipoId: notificationState.tipoId.value,
            mostratPopup: notificationState.mostrarPopup,
            imagenCodificada:
              notificationState.imagen === ''
                ? null
                : notificationState.imagenCodificada,
          },
        }
        const newNotification = await notificationsServices.saveNotification(
          notification,
        )
        stopLoader()
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        if (!newNotification.error) {
          dispatch(
            setNameTabe({
              key: tabId,
              codigo:
                tab.editStatus === StatesEdition.save
                  ? tab.info.info.codigo
                  : newNotification.auto,
              nombre: notificationState.titulo,
            }),
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                Nuevo: true,
                Guardar: false,
                Editar: false,
                Eliminar: false,
                Buscar: true,
                Imprimir: false,
                Deshacer: false,
              },
            }),
          )
          setEnablePreview(true)
        }
        setToastMessage(
          'Guardar Notificación',
          newNotification.message,
          newNotification.error ? ToastTypes.Warning : ToastTypes.Success,
        )
      } catch (error) {
        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
        stopLoader()
        setToastMessage('Guardar Notificación', error, ToastTypes.Danger)
      }
    }
  }, [
    setToastMessage,
    dispatch,
    tabId,
    playLoader,
    notificationState,
    stopLoader,
    tab,
  ])

  const onHandleUndo = useCallback(() => {
    if (tab.editStatus === StatesEdition.save) {
      dispatch(
        setDatosEdicion({
          key: tabId,
          notification: {
            ...notificationState,
            descripcion: tab.info.info.descripcion,
            fechaInicio: DateUtils.apiDateToPickersDate(
              tab.info.info.fechaInicio,
            ),
            fechaFin: DateUtils.apiDateToPickersDate(tab.info.info.fechaFin),
            titulo: tab.info.info.titulo,
            imagen: tab.info.info.imagen,
            permanente: tab.info.info.permanente,
            tipoId: tab.info.info.tipoId,
            mostrarPopup: tab.info.info.mostrarPopup,
          },
        }),
      )
    } else {
      dispatch(
        setDatosEdicion({
          key: tabId,
          notification: {
            ...notificationState,
            descripcion: defaultDataNotification.descripcion,
            fechaInicio: defaultDataNotification.fechaInicio,
            fechaFin: defaultDataNotification.fechaFin,
            titulo: defaultDataNotification.titulo,
            imagen: defaultDataNotification.imagen,
            permanente: defaultDataNotification.permanente,
            tipoId: typeNotification[0],
            mostrarPopup: defaultDataNotification.mostrarPopup,
          },
        }),
      )
    }
  }, [tab, dispatch, tabId, notificationState, typeNotification])

  const handleButtonClick = useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.save:
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.new:
          if (tabs.current === tabId) setconfirmarNuevo(true)
          break
        case ButtonTypes.undo:
          if (tabs.current === tabId) onHandleUndo()
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onHandleSave, onHandleUndo, tabId, tabs],
  )

  const showPreview = useCallback(() => {
    if (
      notificationState.descripcion.length > 0 &&
      notificationState.mostrarPopup === 1
    )
      setShowMessage(true)
    else
      setToastMessage(
        'Vista Previa Notificación',
        'Debe ingresar la descripción y activar la opcion Mostrar Popup para poder visualizar la vista previa.',
        ToastTypes.Warning,
      )
  }, [notificationState, setToastMessage])

  useEffect(() => {
    if (currentAction !== '') handleButtonClick(currentAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction])

  useEffect(() => {
    if (tab.editStatus === StatesEdition.save) {
      setImageNotification()
      setEnablePreview(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showMessage && (
        <PopupMensajes
          show={showMessage}
          messages={[
            {
              titulo: notificationState.titulo,
              descripcion: notificationState.descripcion,
            },
          ]}
          onClose={() => {
            setShowMessage(false)
          }}
        />
      )}
      <Dialog ref={dialogRef} />

      <ValidationGroup
        key={'valitadationTypeCustomer'}
        id={'valitadationTypeCustomer'}
        ref={validationGroupRef}
      >
        <RowContainer>
          <ValidationSummary
            id="summaryValidateTypeCustomer"
            style={{ marginTop: '12px' }}
          />
          <CustomCol xs="12" md="2">
            <CRow>
              <Labeled label="Título:">
                <TextBox
                  value={notificationState.titulo}
                  onValueChanged={onChangedTitle}
                  maxLength={50}
                />
              </Labeled>
            </CRow>
            <CRow>
              <Labeled label="Fecha Inicio:">
                <DateBox
                  value={notificationState.fechaInicio}
                  onValueChanged={onChangedStartDate}
                >
                  <Validator>
                    <RequiredRule
                      message={'Fecha de Inicio: Debe seleccionar una fecha'}
                    />
                    <AsyncRule
                      message={
                        'Fecha de Inicio: No puede ser menor a la fecha de actual.'
                      }
                      validationCallback={validateStartDate}
                    />
                  </Validator>
                </DateBox>
              </Labeled>
            </CRow>
            <CRow>
              <Labeled label="Fecha Fin:">
                <DateBox
                  value={notificationState.fechaFin}
                  onValueChanged={onChangedEndDate}
                >
                  <Validator>
                    <RequiredRule
                      message={'Fecha de Fin: Debe seleccionar una fecha.'}
                    />
                    <AsyncRule
                      message={
                        'Fecha de Fin: No puede ser menor a la fecha inicial.'
                      }
                      validationCallback={validateFinishDate}
                    />
                  </Validator>
                </DateBox>
              </Labeled>
            </CRow>
          </CustomCol>
          <CustomCol xs="12" md="10">
            <Labeled label="Descripción:">
              <HtmlEditor
                height="225px"
                defaultValue={
                  tab.editStatus === StatesEdition.save
                    ? notificationState.descripcion
                    : notificationState.defaultContent
                }
                valueType={'html'}
                onValueChanged={onChangedDescription}
              >
                <Toolbar multiline={true}>
                  <Item name="undo" />
                  <Item name="redo" />
                  <Item name="separator" />
                  <Item name="size" acceptedValues={sizeValues} />
                  <Item name="font" acceptedValues={fontValues} />
                  <Item name="separator" />
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="strike" />
                  <Item name="underline" />
                  <Item name="separator" />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="separator" />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="separator" />
                  <Item name="header" acceptedValues={headerValues} />
                  <Item name="separator" />
                  <Item name="color" />
                  <Item name="background" />
                  <Item name="separator" />
                  <Item name="link" />
                  <Item name="image" />
                  <Item name="separator" />
                  <Item name="clear" />
                  <Item name="codeBlock" />
                  <Item name="blockquote" />
                  <Item name="separator" />
                  <Item name="insertTable" />
                  <Item name="deleteTable" />
                  <Item name="insertRowAbove" />
                  <Item name="insertRowBelow" />
                  <Item name="deleteRow" />
                  <Item name="insertColumnLeft" />
                  <Item name="insertColumnRight" />
                  <Item name="deleteColumn" />
                </Toolbar>
                <Validator>
                  <RequiredRule
                    message={'Descripción: Este campo es requerido.'}
                  />
                </Validator>
              </HtmlEditor>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="5">
            <Labeled label="Imagen:">
              <FIleInput
                accept={'.jpg'}
                allowedFileExtensions={['.jpg', '.jpeg']}
                onChanged={onChangedImage}
                showDownloadDemo={false}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="3">
            <Labeled label="Tipo de Notificación:">
              <RadioGroup
                name="idIDTypeOptions"
                dataSource={typeNotification}
                value={notificationState.tipoId}
                layout="vertical"
                displayExpr="label"
                onValueChanged={onValuedIdType}
                width="100%"
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Permanente:">
              <Switch
                value={notificationState.permanente === 1 ? true : false}
                onValueChanged={onChangedPermanent}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs="12" md="2">
            <Labeled label="Mostrar Popup:">
              <Switch
                value={notificationState.mostrarPopup === 1 ? true : false}
                onValueChanged={onChangedShowPopup}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="12" md="10">
            {showImage && (
              <>
                <img
                  key={utilidades.getUUID()}
                  id={utilidades.getUUID()}
                  src={imageSoruce}
                  className="avathar"
                  style={{
                    marginTop: '12px',
                    maxWidth: '250px',
                    maxHeight: '250px',
                    objectFit: 'cover',
                  }}
                  alt="imagen_notificacion"
                />
                <p style={{ marginTop: '6px' }}>
                  Link imagen:
                  {/*eslint-disable-next-line no-undef */}
                  {` ${process.env['REACT_APP_PATH_FOTOS_NOTIFICACIONES']}${notificationState.imagen}`}
                </p>
              </>
            )}
          </CustomCol>
          <CustomCol xs="12" md="2">
            <CButton
              id="btnConfirmar"
              color="secondary"
              className="m-1"
              size="sm"
              disabled={!enablePreview}
              onClick={showPreview}
            >
              {'Vista Previa'}
            </CButton>
          </CustomCol>
        </RowContainer>
      </ValidationGroup>
    </>
  )
}

export default Nuevo
