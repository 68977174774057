import { DonacionDetalleInfo } from '../../sv/donacion/types/types'
import { ItemVenta } from '../../../types/types'
import { DetalleDonacion, DetalleDonacionRedux } from './detalleDonacion'
import { DateUtils } from '../../../../../helpers/dateUtils'

export const DonacionHelperDetallSV = {
  detallesToDetallesDonacion,
  itemVentaToDonacionDetalle,
}

function detallesToDetallesDonacion(
  detalles: Array<DonacionDetalleInfo>,
  presicionDecimal: number,
): Array<DetalleDonacionRedux> {
  return detalles.map((det) => {
    const detalle: DetalleDonacionRedux = {
      codigo: det.codigo,
      codigoInterno: det.codigoInterno,
      codigoBarras: det.codigoBarras,
      codigoImpuesto: det.impuesto,
      descripcion: det.descripcion,
      porcentajeiva: det.porcentajeIva / 100,
      _cantidad: det.cantidad,
      _precioUnitario: det.pvp,
      tieneIva: det.porcentajeIva > 0,
      imagenes: det.imagenes,
      _descuento: det.descuentoValor,
      _porcentajeDescuento: det.descuento,
      _iva: 0,
      _precioIva: det.pvpiva,
      _subtotal: 0,
      _subtotal0: 0,
      _subtotal5: 0,
      _subtotalIva: 0,
      _total: 0,
      _noSujeto: 0,
      _exenta: 0,
      _gravada: 0,
      _depreciacion: 0,
      fecha: DateUtils.getCurrentDateAsString(),
    }
    const _detalle: DetalleDonacion = new DetalleDonacion()
    _detalle.setDetalle(detalle, presicionDecimal)
    return _detalle.getDetalle()
  })
}

function itemVentaToDonacionDetalle(
  item: ItemVenta,
  index: number,
): DonacionDetalleInfo {
  return {
    codigo: index,
    codigoInterno: item.codigo.toString(),
    codigoBarras: item.codigoBarras,
    descripcion: item.descripcion,
    numeroSerie: item.numeroSerie,
    lotes: 0,
    cantidad: 1,
    descuento: 0,
    descuentoValor: 0,
    pvd: item.pvd,
    pvp: item.pvp,
    valor: item.pvpiva,
    impuesto: item.codigoImpuesto,
    porcentajeIva: item.porcentajeIva,
    pvpiva: item.pvpiva,
    local: 0,
    ice: item.tieneIce ? 1 : 0,
    imagenes: [],
  }
}
