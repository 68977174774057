import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addToast } from '../store/toasterReducer';
import { ToastTypes } from '../store/types';

const useCopyToClipboard = () => {
  const dispatch = useDispatch();

  const handleCopy = useCallback((event) => {
    const copyText = event.target.textContent;
    if (copyText) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          dispatch(
            addToast({
              content: 'Copiado',
              type: ToastTypes.Success,
              autoHide: 1000,
            }),
          );
        })
        .catch((err) => {
          console.error('Error al copiar el texto: ', err);
        });
    } else {
      console.error('No hay texto para copiar!');
    }
  }, [dispatch]);

  return handleCopy;
};

export default useCopyToClipboard;
