import React from 'react'
import { DocumentInfo, TabState } from '../../../../types/generics'
import {
  CustomButtons,
  TiposComprobantesSLV,
  ToastTypes,
} from '../../../../../../store/types'
import {
  DonacionDatosEdicion,
  DonacionDetalleInfo,
  DonacionesListado,
  TDocument,
  TDonacionIngresar,
  TDonacionIngresarDetalle,
} from '../../../../pages/sv/donacion/types/types'
import { isMobile, isMobileOnly } from 'react-device-detect'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { StatesEdition } from '../../../../../../store/enums'
import { ValidationGroup } from 'devextreme-react/validation-group'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import ValidationSummary from 'devextreme-react/validation-summary'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import BuscarClienteLookUp from '../../../busquedaCliente/busquedaCliente'
import TextArea from 'devextreme-react/text-area'
import TextBox from 'devextreme-react/text-box'
import { VentasService } from '../../../../services/ventas.service'
import {
  setDatosEdicion,
  setFormaPago,
  setMuestraError,
  updateFechaEmision,
  updateObservaciones,
  updateSecuencial,
  updateVendedor,
} from '../../../../pages/sv/donacion/store/editDataReducer'
import useCopyToClipboard from '../../../../../../hooks/copyTag'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
} from '../../../../pages/sv/donacion/store/tabsReducer'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  DateUtils,
  formatoFechasApi,
  formatoFechasDatePickers,
} from '../../../../../../helpers/dateUtils'
import DateBox from 'devextreme-react/date-box'
import { autorizacionModuloDtestore } from '../../../../../../store/zustand/autorizacionModuloDte.store'
import Barcode from '../../../../../../views/componentes/barcode/barcode'
import BadgeComponent from '../sharedComponent/badgeAutorizado'
import QrDocumento from '../sharedComponent/qrDocumento'
import BuscarVendedorLookMemoUp from '../../../../../shared/components/buscarVendedorLookMemoUp/buscarVendedorLookMemoUp'
import { tabsStore } from '../../../../../../store/zustand/tabs.store'
import Button from 'devextreme-react/button'
import DataGrid, {
  Button as DatagridButton,
  Column,
  Editing,
  Export,
  Lookup,
  Paging,
  RequiredRule as GridRequiredRule,
} from 'devextreme-react/data-grid'
import SelectBox from '../../../../../../views/componentes/selectBox/selectBox'
import {
  DetalleDonacion,
  DetalleDonacionRedux,
} from '../../../../pages/ventas/types/detalleDonacion'
import { DonacionHelperDetallSV } from '../../../../pages/ventas/types/donacionHelperDetallSV'
import config from '../../../../../../config/config'
import { tipoDonacionService } from '../../../../../componentes/tipoDonacionLookUp/service/tipodonacion.service'
import { utilidades } from '../../../../../../helpers/utilidades'
import DropDownButton from 'devextreme-react/drop-down-button'
import AsyncSelectBox from '../../../../../../views/componentes/asyncSelectBox/asyncSelectBox'
import { Highlighter } from 'react-bootstrap-typeahead'
import { ItemVenta } from '../../../../types/types'
import { GeneralVentaHelper } from '../../../../pages/ventas/helpers/ventaHelper'
import { EFormasPago } from '../../../../../../store/enum/formasPago'
import { TFacturaIngresarFormaPago2 } from '../../../../pages/ventas/types/types'
import { ETipoItemImpuesto } from '../../../../../../services/itemEnum/enumSV'
import VisualizaError from '../../../../pages/shared/visualizaError/visualizaError'
import {
  AlertCUI,
  BadgeCUI,
} from '../../../../../../views/componentes/coreuiComp/coreuiComponent'
import { setAutorizacionModuloDonacion } from '../../../../pages/sv/donacion/store/configuracionesDonacionReducer'
import './donaciones.scss'
import { consoleService } from '../../../../../../services/console.service'
import { SLV_VentasService } from '../../../../services/elSalvador/slv-ventas.service'
interface IDonacionProps extends React.PropsWithChildren {
  info: DocumentInfo<DonacionesListado>
  tabId: string
  tab: TabState<DonacionesListado>
  tipoComprobante: TiposComprobantesSLV
}
type Item = any

const decimalPrecision: number = config.decimalPrecision

const Donacion: React.FunctionComponent<IDonacionProps> = (props) => {
  const { tabId, info, tab, tipoComprobante } = props

  const validationGroupRef = React.useRef<any>()

  const dispatch = useDispatch()
  const onHandleCopy = useCopyToClipboard()

  const [tipoDocumento] = React.useState<TiposComprobantesSLV>(tipoComprobante)

  const { empresa, usuario, local } = useSelector((state: RootState) => {
    return state.global.session
  })
  const puntoVenta = useSelector((state: RootState) => {
    return state.global.puntoVenta
  })
  const datosEdicion = useSelector((state: RootState) => {
    return state.ventas.ventasSV.donaciones.editData[props.tabId]
  })

  const {
    loading,
    fecha,
    cliente,
    detalles,
    estadoElectronico,
    loader,
    autorizacionVenta,
  } = useSelector((state: RootState) => {
    return state.ventas.ventasSV.donaciones.editData[props.tabId]
  })

  const precision =
    useSelector((state: RootState) => {
      return state.global.session?.empresa?.precision
    }) ?? decimalPrecision
  const session = useSelector((state: RootState) => {
    return state.global.session
  })

  console.log('Detalles en render:', detalles)

  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('')
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false)
  const [changes, setChanges] = React.useState([])
  const [editRowKey, setEditRowKey] = React.useState('')

  const generaDetalle = React.useCallback(
    (item: Item, alertColor: 'danger' | 'info', tabId: string) => {
      if (Array.isArray(item) && item.length > 0) {
        return (
          <AlertCUI color={alertColor} id={`alert-${tabId}`}>
            <ul className="errorList" id={`${tabId}-List`}>
              {item.map((subItem, id) => (
                <li key={`${id}-${tabId}`}>{subItem}</li>
              ))}
            </ul>
          </AlertCUI>
        )
      } else if (!Array.isArray(item)) {
        return <>{JSON.stringify(item)}</>
      } else {
        return <></>
      }
    },
    [],
  )

  const generaDetalleErrores = React.useCallback(
    (item: Item) => generaDetalle(item, 'danger', tabId),
    [tabId, generaDetalle],
  )

  //#region  setMuestraError

  const onSetMuestraError = React.useCallback(() => {
    dispatch(
      setMuestraError({
        key: props.tabId,
        tieneError: false,
        mensajeError: '',
      }),
    )
  }, [dispatch, props])

  //#endregion setMuestraError

  const setToast = React.useCallback(
    (message: string, type: ToastTypes) => {
      dispatch(
        addToast({
          content: message,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const currentExecutingAction = React.useCallback(
    (action: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: props.tabId,
          buttonType: action,
        }),
      )
    },
    [dispatch, props],
  )

  const isEditable = React.useCallback(() => {
    if (tab.editStatus === StatesEdition.save) {
      return datosEdicion?.fechaAutorizacion !== ''
    } else {
      return false
    }
  }, [datosEdicion, tab])

  const onCrearProductoButtonClick = React.useCallback(() => {
    setCodigoBarrasItemEditar('')
    setMostrarEdicionItems(true)
  }, [])

  const onMasOpcionesButtonClick = React.useCallback(() => {
    // dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }))
    //setMostrarBusquedaItems(true);
    // setMostrarBusquedaItems(true)
  }, [])

  const buscarClienteOnChanged = React.useCallback(
    async (value) => {
      if (value !== undefined || value !== null) {
        if (value?.codigo) {
          const formasPago = await VentasService.getFormasPago(
            value.identificacion,
          )

          const data: any = { ...datosEdicion }
          if (value) {
            data.cliente = { ...value }
          }
          if (formasPago.length > 0) {
            data.formasPago = formasPago ?? []
            data.formaPago = formasPago[0] ? [formasPago[0]] : undefined
          }

          dispatch(
            setDatosEdicion({
              data: data,
              key: tabId,
            }),
          )
        }
      }
    },
    [dispatch, tabId, datosEdicion],
  )

  const modoNuevo = React.useCallback(
    async (
      puntoVenta: string,
      templateDocument: number,
      limpiar: boolean = false,
    ) => {
      if (loading === false && !limpiar) {
        return
      }

      const data = { ...defaultDatosEdicionDonacion }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...InvoiceButtons,
            Editar: false,
            Imprimir: false,
            Exportar: false,
            Autorizar: false,
            Enviar: false,
            Guardar: true,
            [ButtonTypes.generateElectronicDocument]: true,
          },
        }),
      )

      try {
        const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers)
        const hora = DateUtils.getCurrentTime()
        const autorizacion = await VentasService.getAutorizacion(
          puntoVenta,
          tipoDocumento,
        )
        dispatch(setAutorizacionModuloDonacion(autorizacion))

        sessionStorage.setItem(
          `autorizacionModulo-${empresa?.codigo}-${tipoComprobante}`,
          JSON.stringify(autorizacion),
        )
        autorizacionModuloDtestore
          .getState()
          .setAutorizacion(
            `autorizacionModulo-${empresa?.codigo}-${tipoComprobante}`,
          )

        const formasPagoMinisterio =
          await VentasService.getFormasPagoMinisterio()
        data.formasPagoMinisterio = formasPagoMinisterio ?? []
        data.autorizacionVenta = autorizacion
        data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)'

        data.fecha = fecha
        data.hora = hora
        data.establecimiento = autorizacion.establecimiento
        data.puntoEmision = autorizacion.ptoemision
        data.numerofactura = autorizacion.numActual.toString()
        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
        console.log('initdetall', initdetall)

        const detalles: Array<DonacionDetalleInfo> = [initdetall]
        const tmpDet1 = DonacionHelperDetallSV.detallesToDetallesDonacion(
          detalles,
          precision,
        )

        // Incrementa el valor del `codigo` al clonar
        const tmpDet2 = tmpDet1.map((detalle, index) => ({
          ...detalle,
          codigo:
            detalle.codigo !== undefined
              ? detalle.codigo + index + 1
              : index + 1, // Asigna o incrementa el `codigo`
        }))

        data.detalles = [...tmpDet1, ...tmpDet2]
        console.log('data.detalles', data.detalles)

        const tipoDonacionDp = await tipoDonacionService.getTiposDonacion(
          'Elija un estado',
        )
        console.log('tipoDonacionDp', tipoDonacionDp)
        if (tipoDonacionDp?.auto) {
          data.tiposDonacion = tipoDonacionDp?.auto ?? []
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
        setToast(data.mensajeError, ToastTypes.Danger)
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )

      // dispatch(
      //   setDatosEdicionBackup({
      //     data: data,
      //     key: tabId,
      //   }),
      // )
    },
    [
      loading,
      dispatch,
      tabId,
      tipoDocumento,
      empresa,
      tipoComprobante,
      precision,
      setToast,
    ],
  )

  const onFechaEmisionChanged = React.useCallback(
    async (data) => {
      if (data.value !== null) {
        dispatch(
          updateFechaEmision({
            key: tabId,
            fecha: data.value ?? '',
          }),
        )

        if (data.value) {
          // verificar si la fecha es anterior a la fecha actual
          // si es anterior entonces debe cargar la api de autorizacion

          const currentDate = DateUtils.getCurrentDateAsString()

          const parseFecha = (fecha: string): Date => {
            const [dia, mes, año] = fecha.split('/').map(Number)
            return new Date(año, mes - 1, dia) // Mes comienza desde 0
          }

          const date1 = parseFecha(data.value.replaceAll('-', '/'))
          const date2 = parseFecha(currentDate.replaceAll('-', '/'))

          if (date1 > date2) {
            const partes = data.value.split('-')
            const periodoBuscar = partes[0]

            const newAutorizacion = await VentasService.getAutorizacion(
              puntoVenta,
              tipoDocumento,
              Number(periodoBuscar),
            )
            if (newAutorizacion) {
              sessionStorage.setItem(
                `autorizacionModulo-${empresa?.codigo}-${tipoComprobante}`,
                JSON.stringify(newAutorizacion),
              )
              autorizacionModuloDtestore
                .getState()
                .setAutorizacion(
                  `autorizacionModulo-${empresa?.codigo}-${tipoComprobante}`,
                )
              dispatch(
                updateSecuencial({
                  key: tabId,
                  secuencial: {
                    numerofactura: newAutorizacion?.numActual.toString(),
                    establecimiento: newAutorizacion?.establecimiento,
                    puntoEmision: newAutorizacion?.ptoemision,
                    autorizacionVenta: newAutorizacion,
                    periodo: periodoBuscar,
                  },
                }),
              )
            }
          }
        }
      }
    },
    [tabId, dispatch, empresa, puntoVenta, tipoComprobante, tipoDocumento],
  )

  const onVendedorChanged = React.useCallback(
    (data: any) => {
      if (data) {
        dispatch(
          updateVendedor({
            key: tabId,
            vendedor: data,
          }),
        )
      }
    },
    [tabId, dispatch],
  )

  const onInitNewRow = React.useCallback(({ data }) => {
    data.valor = 0
  }, [])

  const onEditorPreparing = React.useCallback((e: any) => {}, [])

  const onSaving = React.useCallback(
    (e) => {
      e.cancel = true
      const change = e.changes[0]

      if (!change) return

      const _detalles = [...detalles]

      if (change.type === 'insert') {
        if (change.data) {
          const detalleDonacion = new DetalleDonacion()
          detalleDonacion.setDetalle(
            {
              ...change.data,
              _cantidad: change.data._cantidad || 0,
              _precioUnitario: change.data._precioUnitario || 0,
              _depreciacion: change.data._depreciacion || 0,
            },
            precision,
          )

          _detalles.push(detalleDonacion.getDetalle())
        }
      } else if (change.type === 'update') {
        const index = _detalles.findIndex((item) => item.codigo === change.key)
        if (index !== -1) {
          const detalleDonacion = new DetalleDonacion()
          detalleDonacion.setDetalle(
            {
              ..._detalles[index],
              ...change.data,
            },
            precision,
          )

          _detalles[index] = detalleDonacion.getDetalle()
        }
      } else if (change.type === 'remove') {
        const filteredData = _detalles.filter(
          (item) => item.codigo !== change.key,
        )
        _detalles.splice(0, _detalles.length, ...filteredData)
      }

      const nuevoTotal = _detalles.reduce(
        (sum, detail) => sum + (detail._total || 0),
        0,
      )

      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...datosEdicion,
            detalles: _detalles,
            valorTotal: nuevoTotal,
          },
        }),
      )

      e.promise = Promise.resolve()
      setChanges([])
    },
    [datosEdicion, detalles, dispatch, tabId, precision],
  )

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes)
  }, [])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey)
  }, [])

  const updateDetalles = React.useCallback(
    (detalleModificado: DetalleDonacionRedux) => {
      const nuevosDetalles = detalles.map((detalle) =>
        detalle.codigo === detalleModificado.codigo
          ? detalleModificado
          : detalle,
      )

      const nuevoTotal = nuevosDetalles.reduce(
        (sum, detail) => sum + (detail._total || 0),
        0,
      )

      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...datosEdicion,
            detalles: nuevosDetalles,
            valorTotal: nuevoTotal,
          },
        }),
      )
    },
    [datosEdicion, detalles, dispatch, tabId],
  )

  const onPrecioSinIvaChanged = React.useCallback(
    (
      newData: DetalleDonacionRedux,
      value: number,
      currentData: DetalleDonacionRedux,
    ) => {
      if (value !== undefined && value !== null) {
        const detalleDonacion = new DetalleDonacion()
        detalleDonacion.setDetalle(currentData, precision)
        detalleDonacion.precio = value
        updateDetalles(detalleDonacion.getDetalle())
      }
    },
    [precision, updateDetalles],
  )

  const onDepreciacionChanged = React.useCallback(
    (
      newData: DetalleDonacionRedux,
      value: number,
      currentData: DetalleDonacionRedux,
    ) => {
      if (value !== undefined && value !== null) {
        const detalleDonacion = new DetalleDonacion()
        detalleDonacion.setDetalle(currentData, precision)
        detalleDonacion.depreciacion = value
        updateDetalles(detalleDonacion.getDetalle())
      }
    },
    [precision, updateDetalles],
  )

  const onCantidadChanged = React.useCallback(
    (
      newData: DetalleDonacionRedux,
      value: number,
      currentData: DetalleDonacionRedux,
    ) => {
      if (value !== undefined && value !== null) {
        const detalleDonacion = new DetalleDonacion()
        detalleDonacion.setDetalle(currentData, precision)
        detalleDonacion.cantidad = value
        updateDetalles(detalleDonacion.getDetalle())
      }
    },
    [precision, updateDetalles],
  )

  const onDescripcionChanged = React.useCallback(
    (
      newData: DetalleDonacionRedux,
      value: ItemVenta,
      currentRowData: DetalleDonacionRedux,
    ) => {
      if (typeof value === 'string') {
        const detallesActualizados = detalles.map((detalle) => {
          if (detalle.codigo === currentRowData.codigo) {
            return {
              ...detalle,
              descripcion: value,
            }
          }
          return detalle
        })

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: {
              ...datosEdicion,
              detalles: detallesActualizados,
            },
          }),
        )

        setEditRowKey('')
        setChanges([])
        return
      }

      const detallesConContenido = detalles.filter((d) => d.descripcion)
      const ultimoCodigo = detallesConContenido.length

      const nuevoDonacionDetalle = new DetalleDonacion()
      nuevoDonacionDetalle.setItem(value, precision)
      nuevoDonacionDetalle.codigo = ultimoCodigo + 1

      const detallesNuevos = [
        ...detallesConContenido,
        nuevoDonacionDetalle.getDetalle(),
      ]

      const filasVacias = [new DetalleDonacion(), new DetalleDonacion()].map(
        (detalle, index) => {
          detalle.codigo = detallesNuevos.length + index + 1
          return detalle.getDetalle()
        },
      )

      const detallesFinales = [...detallesNuevos, ...filasVacias]

      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...datosEdicion,
            detalles: detallesFinales,
            valorTotal: detallesNuevos.reduce(
              (sum, detail) => sum + (detail._total || 0),
              0,
            ),
          },
        }),
      )

      setEditRowKey('')
      setChanges([])
    },
    [datosEdicion, detalles, dispatch, precision, tabId],
  )

  const onCodigoBarrasChanged = React.useCallback(
    async (newData, value, currentRowData) => {
      if (value) {
        try {
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: {
                ...datosEdicion,
                loader: {
                  mensaje: 'Buscando item...',
                  show: true,
                },
              },
            }),
          )

          const fecha = DateUtils.getCurrentDateAsString('dd/MM/yyyy')
          const items = await VentasService.buscarItem(
            '',
            value,
            '9999999999999',
            fecha,
          )

          dispatch(
            setDatosEdicion({
              key: tabId,
              data: {
                ...datosEdicion,
                loader: {
                  mensaje: '',
                  show: false,
                },
              },
            }),
          )

          const itemExacto = items.find((item) => item.codigoBarras === value)
          console.log('item', itemExacto)
          if (itemExacto) {
            onDescripcionChanged(newData, itemExacto, currentRowData)
          } else {
            setToast(
              `No existe un item con el código ${value}`,
              ToastTypes.Warning,
            )
            newData.codigoBarras = ''
          }

          setEditRowKey('')
          setChanges([])
        } catch (error) {
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: {
                ...datosEdicion,
                loader: {
                  mensaje: '',
                  show: false,
                },
              },
            }),
          )

          setToast('Error al buscar el item', ToastTypes.Danger)
          newData.codigoBarras = ''

          setEditRowKey('')
          setChanges([])
        }
      }
    },
    [dispatch, tabId, datosEdicion, onDescripcionChanged, setToast],
  )

  const onObservacionesChanged = React.useCallback(
    (data) => {
      dispatch(
        updateObservaciones({
          key: tabId,
          observaciones: data ?? '',
        }),
      )
    },
    [tabId, dispatch],
  )

  const onFormaPagoChanged = React.useCallback(
    (newValue) => {
      dispatch(
        setFormaPago({
          formaPago: newValue,
          key: tabId,
        }),
      )
    },
    [tabId, dispatch],
  )

  const onHandleErrors = React.useCallback(
    (errorF, message: string = '') => {
      const errores = [...errorF]

      const dataError =
        Array.isArray(errores) === true ? errores : JSON.stringify(errores)

      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
            tieneError: true,
            mensajeError: dataError ?? '',
          },
          key: tabId,
        }),
      )

      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: undefined,
        }),
      )

      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: message ?? 'Error al guardar la factura.',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger,
        }),
      )
    },
    [datosEdicion, dispatch, tabId],
  )

  const onValidateForm = React.useCallback(
    (dataForm) => {
      const sms = []
      console.log('onValidateForm')
      console.log('dataForm', dataForm)
      try {
        // requiere total > 0
        if (dataForm.totales.total < 0) {
          sms.push('La factura no puede tener un valor inferior o igual a 0.')
        }
        // requiere forma pago

        if (
          dataForm?.formaPago === undefined ||
          dataForm?.formaPago === null ||
          dataForm?.formaPago.length === 0 ||
          dataForm?.formaPago[0] == null
        ) {
          sms.push('Es necesario seleccionar la forma de pago.')
        } else {
          if (
            dataForm.formaPago[0].codigo === EFormasPago.credito &&
            dataForm.totales.total <= 0
          ) {
            sms.push(
              'No se puede utilizar esta forma de pago para un documento con valor 0!!!',
            )
          } else if (
            dataForm.formaPago[0].codigo === EFormasPago.otros &&
            dataForm.totales.total <= 0
          ) {
            sms.push(
              'No se puede utilizar esta forma de pago para un documento con valor 0!!!',
            )
          }

          if (
            dataForm.formaPago[0].codigo === EFormasPago.credito &&
            dataForm.cuotas.length === 0
          ) {
            sms.push('Por favor, detalle la forma de pago !!!')
          } else if (
            dataForm.formaPago[0].codigo === EFormasPago.otros &&
            dataForm.tiposPagos.length === 0
          ) {
            sms.push('Por favor, detalle los metodos de pago!!!')
          }

          if (dataForm.formaPago[0].codigo === EFormasPago.otros) {
            let sumatoriaEnFormas: number = 0
            for (let index = 0; index < dataForm.tiposPagos.length; index++) {
              sumatoriaEnFormas += parseFloat(
                dataForm.tiposPagos[index].valor.toFixed(2),
              )
            }
            if (
              sumatoriaEnFormas !==
              parseFloat(dataForm.totales.total.toFixed(2))
            ) {
              sms.push(
                'Por favor, verificar los valores en las distintas formas de pago agregadas!!!',
              )
            }
          }
          if (
            GeneralVentaHelper.obtenerFormaPagoSRICodigo(
              dataForm.formaPago[0].codigo,
              dataForm.formasPagoMinisterio,
            ) === null
          ) {
            sms.push(
              'La forma de pago no está asociada con los métodos de pago establecidos por el Ministerio de Hacienda.!!!',
            )
          }
        }
        // requiere detalle
        if (dataForm.detalles.length === 0) {
          sms.push('La factura no tiene detalles')
        } else {
          if (dataForm.detalles.some((d) => d.codigo !== '')) {
            if (
              dataForm.detalles.some(
                (d) => !d.descripcion || d.descripcion.trim() === '',
              )
            ) {
              sms.push(
                'La factura no puede tener una descripción vacía en uno de sus ítems del detalle',
              )
            }
          }
        }
        // requiere donante

        if (!dataForm.cliente) {
          sms.push('Debe seleccionar el donante')
        }

        if (autorizacionVenta === null || autorizacionVenta === undefined) {
          sms.push('No se encuentra la autorización de este documento')
        } else {
          if (tipoDocumento !== TiposComprobantesSLV.FacturaExcluidoSujeto) {
            if (session?.empresa.consumidorFinalValor > 0) {
              if (
                dataForm.totales.total >
                  session?.empresa.consumidorFinalValor &&
                dataForm.cliente.identificacion === config.rucConsumidorFinal
              ) {
                sms.push(
                  `${'La factura excede el monto maximo ($'} ${
                    session?.empresa?.consumidorFinalValor ?? 0
                  } ${') permitido para consumidor final, por favor ingrese los datos del cliente'}`,
                )
              }
            }
          }
        }
        return sms
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return sms
      }
    },
    [autorizacionVenta, session, tipoDocumento],
  )

  const parseIngresarDocumento = React.useCallback((): TDocument => {
    const documento: TDocument = {
      messages: [],
      documento: null,
    }

    const resValidate = onValidateForm(datosEdicion)
    if (resValidate.length) {
      documento.messages = resValidate
      return documento
    }

    let pagos: Array<TFacturaIngresarFormaPago2> = []
    if (datosEdicion.formaPago[0].codigo === EFormasPago.otros) {
      for (let index = 0; index < datosEdicion.tiposPagos.length; index++) {
        pagos.push({
          total: parseFloat(datosEdicion.tiposPagos[index].valor.toFixed(2)),
          medio: datosEdicion.tiposPagos[index].descripcionFormaPago.toString(),
          id_medio: datosEdicion.tiposPagos[index].codigoFormaPago.toString(),
        })
      }
    } else {
      pagos = [
        {
          total: parseFloat(datosEdicion.totales.total.toFixed(2)),
          medio: datosEdicion.formaPago[0].descripcion,
          id_medio: datosEdicion.formaPago[0].codigo.toString(),
        },
      ]
    }

    const calcularImpuesto = (
      detalles: any[],
      tipoImpuesto: number,
      baseKey: string,
      valorKey?: string,
    ): {
      descuento_adicional: number
      base_imponible: number
      valor: number
    } => {
      const detallesFiltrados = detalles.filter(
        (detalle) => detalle.codigoImpuesto === tipoImpuesto,
      )

      const descuentoAdicional = parseFloat(
        detallesFiltrados
          .reduce((prev, next) => prev + next._descuento, 0)
          .toFixed(2),
      )

      const baseImponible = parseFloat(
        detallesFiltrados
          .reduce(
            (prev, next) =>
              prev + next[baseKey] + (next[baseKey] > 0 ? next._descuento : 0),
            0,
          )
          .toFixed(2),
      )

      const valor = valorKey
        ? parseFloat(
            detallesFiltrados
              .reduce((prev, next) => prev + next[valorKey], 0)
              .toFixed(2),
          )
        : 0

      return {
        descuento_adicional: descuentoAdicional,
        base_imponible: baseImponible,
        valor: valor,
      }
    }

    const impuestos = [
      {
        codigo: 1,
        codigo_porcentaje: ETipoItemImpuesto.exento.toString(),
        ...calcularImpuesto(
          datosEdicion.detalles,
          ETipoItemImpuesto.exento,
          '_exenta',
        ),
      },
      {
        codigo: 2,
        codigo_porcentaje: ETipoItemImpuesto.gravado.toString(),
        ...calcularImpuesto(
          datosEdicion.detalles,
          ETipoItemImpuesto.gravado,
          '_gravada',
          '_iva',
        ),
      },
      {
        codigo: 3,
        codigo_porcentaje: ETipoItemImpuesto.noSujeto.toString(),
        ...calcularImpuesto(
          datosEdicion.detalles,
          ETipoItemImpuesto.noSujeto,
          '_noSujeto',
        ),
      },
    ]

    const factura: TDonacionIngresar = {
      identificador: datosEdicion.identificadorTransaccion,
      tipodoc: tipoDocumento,
      local: {
        codigo: datosEdicion?.local?.codigo,
        nombre: datosEdicion?.local?.nombre,
        ciudadCodigo: datosEdicion?.local?.ciudad?.codigo,
        ciudadNombre: datosEdicion?.local?.ciudad?.descripcion,
        clase: String(datosEdicion?.local?.clase),
        descuento: datosEdicion?.local?.descuento,
        direccion: datosEdicion?.local?.direccion,
        telefono: datosEdicion?.local?.telefono,
        tipoRol: '',
        controlaKardex: false,
        establecimiento: datosEdicion?.local?.establecimiento,
        siglas: datosEdicion?.local?.siglas,
        uaf: datosEdicion?.local?.uaf,
        tipo: 0,
        estado: datosEdicion?.local?.estado?.codigo,
      },
      pventa: puntoVenta,
      fecha_emision: DateUtils.format(fecha, formatoFechasApi),
      emisor: {
        ruc: empresa.ruc,
        razon_social: empresa.nombre,
        nombre_comercial: empresa.comercial,
        contribuyente_especial: '',
        direccion: empresa.direccion,
        obligado_contabilidad: true,
        establecimiento: {
          punto_emision: datosEdicion.puntoEmision,
          codigo: local.codigo.toString(),
          direccion: local.direccion,
        },
      },
      comprador: {
        tipo_identificacion: datosEdicion.cliente?.tipoIdentificacion,
        identificacion: datosEdicion.cliente?.identificacion,
        nombres: datosEdicion.cliente.nombres,
        razon_social: datosEdicion.cliente.nombreComercial ?? '',
        direccion: datosEdicion.cliente.direccion,
        email: datosEdicion.cliente.email ?? '',
        telefono: datosEdicion.cliente.telefono ?? 'S/T',
        ciudad: datosEdicion.cliente.ciudad,
      },
      informacion_adicional: {
        Cliente: datosEdicion.cliente.nombres,
        Enviado_a: datosEdicion.cliente.email ?? '',
        Direccion: datosEdicion.cliente.direccion,
        Telefono: datosEdicion.cliente.telefono ?? 'S/T',
      },
      totales: {
        importe_total: parseFloat(datosEdicion.totales.total.toFixed(2)),
        subtotal: parseFloat(datosEdicion?.totales?.subtotal.toFixed(2)),
        subtotal0: parseFloat(datosEdicion?.totales?.subtotal0.toFixed(2)),
      },
      observaciones: datosEdicion.observaciones,
      moneda: 'USD',
      formaPago: {
        value: datosEdicion.formaPago[0].codigo,
        label: datosEdicion.formaPago[0].descripcion,
      },
      ambiente: empresa.uso_electronica.toString(),
      items: datosEdicion.detalles.map((det) => {
        const detalle: TDonacionIngresarDetalle = {
          codigo_auxiliar: det.codigo.toString(),
          codigo_principal: det.codigoBarras,
          precio_unitario: parseFloat(String(det._precioUnitario)),
          cantidad: det._cantidad,
          precio_total_sin_impuestos: parseFloat(
            det._subtotal.toFixed(2) + det._descuento.toFixed(2),
          ),
          descripcion: det.descripcion,
          descuento_porcentaje: det._porcentajeDescuento,
          descuento_valor: det._descuento,
          impuestos: [
            {
              codigo: det.tieneIva ? 3 : 2,
              tarifa: (det.porcentajeiva * 100).toFixed(0),
              codigo_porcentaje: det.codigoImpuesto,
              base_imponible: parseFloat(
                det._subtotal.toFixed(2) + det._descuento.toFixed(2),
              ),
              valor: parseFloat(det._iva.toFixed(2)),
            },
          ],
        }
        return detalle
      }),
      cuotas: datosEdicion.cuotas.map((c) => {
        return {
          cuota: c.cuota,
          fechaVencimiento: c.vencimiento,
          saldo: c.saldo,
          interes: 0,
          letra: c.letra,
          saldoCapital: c.saldo,
        }
      }),
      pagos: pagos,
      tiposPagos: datosEdicion.tiposPagos,
      vendedor: {
        codigo: datosEdicion?.vendedor?.usuarioCodigo ?? null,
      },
      periodo: datosEdicion?.periodo ?? null,
    }
    documento.documento = factura
    return documento
  }, [
    onValidateForm,
    datosEdicion,
    tipoDocumento,
    puntoVenta,
    fecha,
    empresa,
    local,
  ])

  const onRegister = React.useCallback(async (documentoF) => {
    try {
      const res = await SLV_VentasService.ingresarDonacion(documentoF)
      return res
    } catch (err) {
      consoleService.log('register-catch -> ', err)
      return err
    }
  }, [])

  const handleSubmit = React.useCallback(async () => {
    if (
      usuario == null ||
      empresa == null ||
      local == null ||
      puntoVenta == null
    ) {
      return
    }

    dispatch(
      setDatosEdicion({
        key: tabId,
        data: {
          ...datosEdicion,
          loader: {
            mensaje: 'Guardando documento...',
            show: true,
          },
        },
      }),
    )

    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save,
      }),
    )

    let errorF: any = []
    // let errorFSV: any = []
    let documentoF: TDonacionIngresar = null
    // let documentoFSV = null

    if (
      datosEdicion?.autorizacionVenta === null ||
      datosEdicion?.autorizacionVenta === undefined
    ) {
      dispatch(
        addToast({
          content: 'No se puede encontrar la autorización de este documento ',
          type: ToastTypes.Danger,
        }),
      )
      return false
    }

    const ingresarFactura = await parseIngresarDocumento()

    if (ingresarFactura?.messages && ingresarFactura.messages.length > 0) {
      errorF = ingresarFactura?.messages
    } else {
      documentoF = ingresarFactura?.documento
    }

    console.log('errorF', errorF)
    if (errorF.length > 0) {
      onHandleErrors(errorF, 'Revise las advertencias para poder continuar.')
      return false
    }
    try {
      const payload = {
        emisor: {
          ruc: empresa?.ruc || '',
        },
        vendedor: {
          codigo: datosEdicion.vendedor?.vendedorCodigo || '',
        },
        comprador: {
          identificacion: datosEdicion.cliente?.identificacion || '',
          actualizar: false,
          razonSocial: datosEdicion.cliente?.nombreComercial || '',
          nombreComercial: datosEdicion.cliente?.nombreComercial || '',
          direccion: datosEdicion.cliente?.direccion || '',
          telefono: datosEdicion.cliente?.telefono || '',
          email: datosEdicion.cliente?.email || '',
          ciudad: datosEdicion.cliente?.ciudad || '',
        },
        local: {
          codigo: local?.codigo,
        },
        periodo: datosEdicion.periodo?.toString() || '',
        establecimiento: datosEdicion.establecimiento,
        puntoEmision: datosEdicion.puntoEmision,
        numero: datosEdicion.numerofactura,
        autorizacion: datosEdicion.autorizacionVenta?.codigoAutorizacion || '',
        tipodoc: tipoDocumento,
        identificador: '',
        fecha_emision: DateUtils.pickersDateToApiDate(datosEdicion.fecha),
        totales: {
          subtotal: datosEdicion.valorTotal,
          subtotal0: datosEdicion.valorTotal,
          importe_total: datosEdicion.valorTotal,
        },
        observaciones: datosEdicion.observaciones,
        pventa: puntoVenta || '',
        items: datosEdicion.detalles
          .filter((d) => d.descripcion)
          .map((item) => ({
            codigo_principal: item.codigoInterno,
            descripcion: item.descripcion,
            impuestos: [
              {
                tarifa: 0,
              },
            ],
            cantidad: item._cantidad,
            precio_unitario: item._precioUnitario,
            descuento: 0,
            precio_total_sin_impuestos: item._total,
          })),
        pagos: [
          {
            medio: datosEdicion.formaPago[0]?.descripcion || '',
            propiedades: {
              banco: '',
              numero: '',
            },
          },
        ],
      }

      const resultadoIngresarDte = await onRegister(documentoF)

      console.log('resultadoIngresarDte', resultadoIngresarDte)

      currentExecutingAction(undefined)
    } catch (error) {
      currentExecutingAction(undefined)
      console.error('Error al guardar la donación:', error)
      setToast(
        error.message || 'Error al guardar la donación',
        ToastTypes.Danger,
      )
    }
  }, [
    usuario,
    empresa,
    local,
    puntoVenta,
    setToast,
    currentExecutingAction,
    dispatch,
    tabId,
    datosEdicion,
    parseIngresarDocumento,
    tipoDocumento,
    onHandleErrors,
  ])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.edit:
          break
        case ButtonTypes.save:
          handleSubmit()
          break
        case ButtonTypes.undo:
          break
        case ButtonTypes.print:
          break
        case ButtonTypes.print_design:
          break
        case ButtonTypes.discount:
          break
        case ButtonTypes.export:
          break
        case ButtonTypes.sendMail:
          break
        case ButtonTypes.authorizations:
          break
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? '', 0, true)
          break
        case ButtonTypes.credit:
          break
        case ButtonTypes.disable:
          break
        case ButtonTypes.methodsPay:
          break
        case ButtonTypes.json:
          break
        case ButtonTypes.generateElectronicDocument:
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, handleSubmit, modoNuevo, puntoVenta],
  )

  const onAbrir = React.useCallback(
    (venta: DonacionesListado) => {
      const id = utilidades.getUUID()
    },
    [dispatch],
  )

  const onItemClick = React.useCallback((e) => {
    console.log('onItemClick', e)
  }, [])

  const onButtonClick = React.useCallback(
    (e: any, props: any) => {
      console.log('onButtonClick', e)
    },
    [setEditRowKey],
  )

  React.useEffect(() => {
    const estRegistrado = tabsStore.getState().tabs[tabId]
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? '', tab.info?.numero ?? 0)

      if (!estRegistrado) {
        tabsStore.getState().setTab(tabId, 'new')
      }
    } else {
      // cargarVenta(info.numero, true)
      // if (!estRegistrado) {
      //   tabsStore.getState().setTab(tabId, 'edit')
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  return (
    <div
      style={!isMobile ? { padding: '10px', overflowX: 'hidden' } : {}}
      className="mb-4"
    >
      <BlockUi
        tag="div"
        loader={isMobile ? <></> : LoadingIndicator}
        blocking={loader.show}
        message={loader.mensaje}
      >
        {datosEdicion.tieneError && (
          <VisualizaError
            titulo="Error en documento"
            mensaje={datosEdicion.mensajeError}
            onOk={() => {
              onSetMuestraError()
            }}
            content={generaDetalleErrores(datosEdicion.mensajeError)}
          />
        )}

        <fieldset
          data-tut="sectionTutComprobante"
          disabled={tab.editStatus === StatesEdition.save}
        >
          <div>
            <ValidationGroup
              id={`valGroupDonacion${props.tabId}`}
              ref={validationGroupRef}
            >
              {!isMobileOnly && (
                <RowContainer className="mt-2 mb-2">
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              )}

              <RowContainer>
                <CustomCol xs="12" md="7">
                  <div>
                    <RowContainer>
                      <CustomCol xs="12">
                        <Labeled label="Donante / Razón Social del donante">
                          <BuscarClienteLookUp
                            disabled={isEditable()}
                            selected={cliente}
                            onChanged={buscarClienteOnChanged}
                            allowEdit
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {cliente && (
                      <RowContainer>
                        <CustomCol xs="12">
                          <Labeled label="Dirección">
                            <TextBox value={cliente?.direccion} readOnly />
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="4">
                          <Labeled label="Teléfono">
                            <TextBox readOnly value={cliente?.telefono ?? ''} />
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="8">
                          <Labeled label="Correo eléctronico">
                            <TextBox readOnly value={cliente?.email ?? ''} />
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    )}
                  </div>
                </CustomCol>
                <CustomCol xs="12" md="5">
                  <div>
                    <RowContainer>
                      <CustomCol xs="12" className="d-flex justify-content-end">
                        <strong> {'DONACION'} </strong>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="12" md="5">
                        <Labeled label="Fecha Donación">
                          <DateBox
                            value={fecha}
                            readOnly={isEditable()}
                            onValueChanged={(data) => {
                              if (data.event !== undefined) {
                                onFechaEmisionChanged(data)
                              }
                            }}
                            max={DateUtils.getCurrentDateAsString(
                              formatoFechasDatePickers,
                            )}
                            min={DateUtils.subDay(new Date(), 30)}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" md="7">
                        <Labeled label="Número de Donación">
                          <RowContainer>
                            <CustomCol xs="3">
                              <TextBox
                                readOnly
                                placeholder="001"
                                value={datosEdicion.establecimiento}
                              />
                            </CustomCol>
                            <CustomCol xs="3">
                              <TextBox
                                readOnly
                                placeholder="001"
                                value={datosEdicion.puntoEmision}
                              />
                            </CustomCol>
                            <CustomCol xs="6">
                              <TextBox
                                readOnly
                                placeholder="0000000000"
                                value={
                                  '0'.repeat(
                                    10 -
                                      datosEdicion.numerofactura.toString()
                                        .length,
                                  ) + datosEdicion.numerofactura.toString()
                                }
                              />
                            </CustomCol>
                          </RowContainer>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs="6" md="3">
                        <Labeled label="# Detalles">
                          <TextBox
                            readOnly
                            value={datosEdicion.detalles.length.toString()}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="6" md="3">
                        <Labeled label="# Articulos">
                          <TextBox
                            readOnly
                            value={detalles
                              .reduce((prev, next) => prev + next._cantidad, 0)
                              .toFixed(2)}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" md="6">
                        <Labeled label="Punto Venta">
                          <TextBox readOnly text={puntoVenta ?? ''} />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" md="12">
                        <Labeled label="Vendedor">
                          <BuscarVendedorLookMemoUp
                            vendedor={datosEdicion?.vendedor ?? null}
                            tab={tab}
                            cambiarVendedorSeleccionado={onVendedorChanged}
                            disabled={
                              !datosEdicion?.fechaAutorizacion?.includes(
                                'NUEVO',
                              )
                            }
                            allowAdd
                            allowClear={tab.editStatus !== StatesEdition.save}
                            allowEdit={!loading && !isEditable()}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {tab.editStatus === StatesEdition.save && ( //Electronica
                      <>
                        <RowContainer>
                          <CustomCol xs="12" xxl="8">
                            {!isMobileOnly && (
                              <Barcode text={datosEdicion.claveAcceso} />
                            )}
                            {isMobileOnly && (
                              <Labeled label="Autorización">
                                <small>{datosEdicion.claveAcceso}</small>
                              </Labeled>
                            )}
                          </CustomCol>
                          <CustomCol xs="12" xxl="6">
                            <Labeled label="Fecha Autorización">
                              <BadgeComponent
                                fechaAutorizacion={
                                  datosEdicion?.fechaAutorizacion
                                }
                                estadoElectronico={estadoElectronico}
                              />
                              {datosEdicion?.autorizacionHacienda &&
                                datosEdicion?.autorizacionHacienda
                                  ?.selloRecibido && (
                                  <QrDocumento
                                    autorizacionDocumento={
                                      datosEdicion.autorizacionHacienda
                                    }
                                    label="VER QR"
                                    showEnlace
                                  />
                                )}
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                        <br />
                      </>
                    )}
                  </div>
                </CustomCol>
              </RowContainer>

              {tab.editStatus === StatesEdition.save && (
                <>
                  <RowContainer>
                    <CustomCol xs="6" xxl="6">
                      <Labeled label="Cod. Generación">
                        <span
                          onClick={onHandleCopy}
                          id="labelCodGeneracion"
                          style={{ cursor: 'pointer' }}
                        >
                          {datosEdicion.codigoGeneracion
                            ? datosEdicion.codigoGeneracion.toUpperCase()
                            : ''}
                        </span>
                      </Labeled>
                    </CustomCol>
                    {datosEdicion?.autorizacionHacienda?.selloRecibido && (
                      <CustomCol xs="6" xxl="4">
                        <Labeled label="Sello Recibido ">
                          <BadgeCUI
                            color={
                              datosEdicion.fechaAutorizacion
                                ? 'success'
                                : 'danger'
                            }
                          >
                            <span
                              onClick={onHandleCopy}
                              id="labelCodGeneracion"
                              style={{ cursor: 'pointer' }}
                            >
                              {datosEdicion.autorizacionHacienda?.selloRecibido}
                            </span>
                          </BadgeCUI>
                        </Labeled>
                      </CustomCol>
                    )}
                  </RowContainer>
                  <br />
                </>
              )}

              {tab.editStatus !== StatesEdition.save && (
                <RowContainer>
                  <CustomCol>
                    <div className="buttonsContainer mb-1 mt-1">
                      {isMobileOnly && (
                        <Button
                          text="Agregar Detalle"
                          icon="add"
                          stylingMode="contained"
                          type="default"
                          onClick={() => {
                            // setAgregarDetalleMovil(true)
                          }}
                          className="me-1"
                        />
                      )}
                      <Button
                        style={buttonsProductoStyle}
                        text="Crear Producto / Servicio"
                        icon="file"
                        stylingMode="contained"
                        type="default"
                        onClick={onCrearProductoButtonClick}
                        elementAttr={{
                          id: 'btnCreateItem',
                        }}
                        className="me-1"
                      />
                      <Button
                        style={buttonsProductoStyle}
                        hint="Más opciones"
                        icon="more"
                        stylingMode="contained"
                        type="default"
                        onClick={onMasOpcionesButtonClick}
                        elementAttr={{
                          id: 'btnOptionsItems',
                        }}
                        className="me-1"
                      ></Button>
                    </div>
                  </CustomCol>
                </RowContainer>
              )}

              <div data-tut="detalleVentayTotales">
                <RowContainer className="mp-1 mb-1">
                  <CustomCol>
                    <DataGrid
                      className="gridContainerCustom"
                      focusedRowEnabled={true}
                      keyExpr="codigo"
                      repaintChangesOnly
                      dataSource={detalles}
                      showBorders={true}
                      onInitNewRow={onInitNewRow}
                      onEditorPreparing={onEditorPreparing}
                      onSaving={onSaving}
                      width={'100%'}
                    >
                      <Editing
                        mode={'cell'}
                        refreshMode={'repaint'}
                        allowUpdating={true}
                        allowAdding={false}
                        useIcons={true}
                        allowDeleting={true}
                        changes={changes}
                        onChangesChange={onChangesChange}
                        editRowKey={editRowKey}
                        onEditRowKeyChange={onEditRowKeyChange}
                        selectTextOnEditStart={false}
                      />
                      <Column
                        dataField="codigo"
                        caption="#"
                        width="20px"
                        allowEditing={false}
                      />

                      <Column type="buttons">
                        <DatagridButton
                          render={(props) => {
                            return (
                              <DropDownButton
                                splitButton={false}
                                useSelectMode={false}
                                text=""
                                icon="plus"
                                dropDownOptions={buttonDropDownOptions}
                                items={commands}
                                id={'commandsDetall' + utilidades.getUUID()}
                                displayExpr="text"
                                className="ms-1 me-1"
                                onButtonClick={(e) => onButtonClick(e, props)}
                                onItemClick={onItemClick}
                              />
                            )
                          }}
                        />
                      </Column>
                      <Column
                        dataField="tipo"
                        caption="Tipo"
                        width="10%"
                        setCellValue={(rowData, value) => {
                          rowData.concepto = null
                          rowData.tipo = value
                        }}
                        allowEditing={true}
                      >
                        <Lookup
                          dataSource={datosEdicion?.tiposDonacion ?? []}
                          displayExpr="descripcion"
                          valueExpr="id"
                        />

                        {/* <AsyncRule validationCallback={validateTipoRetencion} /> */}
                      </Column>
                      <Column
                        dataField="codigoBarras"
                        caption="Código"
                        minWidth="10%"
                        width="15%"
                        setCellValue={onCodigoBarrasChanged}
                        name="codigoBarras"
                        dataType="string"
                        allowEditing={(options) => {
                          return !options.data.codigoBarras
                        }}
                      >
                        <GridRequiredRule />
                      </Column>
                      <Column
                        dataField="descripcion"
                        caption="Descripcion"
                        minWidth="10%"
                        width="25%"
                        editCellRender={(cellInfo) => {
                          if (cellInfo.data.descripcion) {
                            return (
                              <TextBox
                                defaultValue={cellInfo.value}
                                onValueChanged={(e) => {
                                  cellInfo.setValue(e.value)
                                }}
                              />
                            )
                          }

                          return <ItemBuscadorEditor data={cellInfo} />
                        }}
                        setCellValue={onDescripcionChanged}
                        name="descripcion"
                        dataType="string"
                        allowEditing={true}
                      >
                        <GridRequiredRule />
                      </Column>

                      <Column
                        dataField="_cantidad"
                        caption="Cantidad"
                        width="10%"
                        dataType="number"
                        allowEditing={true}
                        setCellValue={onCantidadChanged}
                      />
                      <Column
                        dataField="_depreciacion"
                        caption="Depreciacion"
                        minWidth="10%"
                        width="10%"
                        setCellValue={onDepreciacionChanged}
                        name="depreciacion"
                        dataType="number"
                        format="#,##0.00"
                        allowEditing={true}
                      >
                        <GridRequiredRule />
                      </Column>
                      <Column
                        dataField="_precioUnitario"
                        caption="P. Unit."
                        width="10%"
                        alignment="right"
                        dataType="number"
                        format="#,##0.00"
                        setCellValue={onPrecioSinIvaChanged}
                        allowEditing={true}
                      >
                        <GridRequiredRule />
                      </Column>
                      <Column
                        dataField="_total"
                        caption="Total"
                        alignment="right"
                        width="10%"
                        dataType="number"
                        format="#,##0.00"
                        allowEditing={false}
                      >
                        <GridRequiredRule />
                      </Column>

                      <Export enabled={true} allowExportSelectedData={true} />
                      <Paging enabled={false} />
                    </DataGrid>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Observaciones">
                      <TextArea
                        value={datosEdicion?.observaciones}
                        onValueChange={(data) => {
                          onObservacionesChanged(data)
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="3">
                    <Labeled label="Forma Pago">
                      <SelectBox
                        id="selectFormaPago"
                        displayExpr="descripcion"
                        keyExpr="codigo"
                        options={datosEdicion?.formasPago}
                        selected={
                          datosEdicion?.formaPago &&
                          datosEdicion?.formaPago.length > 0
                            ? datosEdicion?.formaPago[0]
                            : null
                        }
                        placeholder="Forma de pago"
                        multiple={false}
                        onChange={(data) => {
                          if (data !== undefined || data !== null) {
                            onFormaPagoChanged(data)
                          }
                        }}
                        disabled={isEditable()}
                        clearButton={true}
                        invalid={datosEdicion.formaPago.length === 0}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                {!isMobile && (
                  <RowContainer>
                    <div className="totalesContainer">
                      <div className="totalContainer">
                        <div className="totalLabel">
                          <span>{'Valor Total'}</span>
                        </div>
                        <div className="totalValue">
                          <span>{datosEdicion.valorTotal.toFixed(2)} </span>
                        </div>
                      </div>
                    </div>
                  </RowContainer>
                )}
              </div>
            </ValidationGroup>
          </div>
        </fieldset>
      </BlockUi>
    </div>
  )
}

export const defaultDatosEdicionDonacion: DonacionDatosEdicion = {
  codigo: 0,
  totales: {
    total: 0,
  },
  detalles: [],
  cliente: null,
  loader: {
    mensaje: 'Cargando...',
    show: true,
  },
  tieneError: false,
  mensajeError: '',
  imprimir: false,
  fecha: '',
  observaciones: '',
  fechaAutorizacion: '',
  codigoGeneracion: '',
  hora: '',
  loading: true,
  establecimiento: '',
  puntoEmision: '',
  numerofactura: '',
  periodo: null,
  vendedor: null,
  claveAcceso: '',
  formaPago: [],
  formasPago: [],
  valorTotal: 0,
  tiposDonacion: [],
  autorizacionVenta: null,
  tiposPagos: [],
  local: null,
  cuotas: [],
  identificadorTransaccion: null,
  formasPagoMinisterio: [],
}

export const InvoiceButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Descuento: true,
  Enviar: true,
  Autorizar: true,
}

const buttonsProductoStyle = { marginLeft: '2px' }

const initdetall: DonacionDetalleInfo = {
  codigo: 0,
  codigoInterno: '',
  codigoBarras: '',
  descripcion: '',
  numeroSerie: 0,
  lotes: 0,
  cantidad: 0,
  descuento: 0,
  descuentoValor: 0,
  pvd: 0,
  pvp: 0,
  valor: 0,
  impuesto: 0,
  porcentajeIva: 0,
  pvpiva: 0,
  local: 0,
  ice: 0,
  imagenes: [],
}

const commands = [
  {
    id: 'Eliminar',
    text: 'Eliminar',
    icon: 'trash',
  },
  {
    id: 'Editar',
    text: 'Editar',
    icon: 'edit',
  },
]

const buttonDropDownOptions = {
  width: 100,
  color: '#39f',
  backgroudColor: 'white',
  fontSize: 14,
}

const ItemBuscadorEditor = React.memo((props: any) => {
  const { data } = props
  const { setValue } = data

  const onSearch = React.useCallback(async (text: string) => {
    const fecha = DateUtils.getCurrentDateAsString('dd/MM/yyyy')
    return await VentasService.buscarItem(text, '', '9999999999999', fecha)
  }, [])

  const onItemSelect = React.useCallback(
    (items: any) => {
      if (!items?.[0]) return

      const selectedItem = items[0]
      console.log('onItemSelect: ', selectedItem)
      setValue(selectedItem)
    },
    [setValue],
  )

  return (
    <AsyncSelectBox
      id="itemBuscador"
      selected={[]}
      minSearchlength={3}
      onSearch={onSearch}
      labelKey="descripcion"
      placeholder={''}
      onChange={onItemSelect}
      renderMenuItemChildren={(option: any, { text }) => (
        <div>
          <Highlighter search={text}>{option.descripcion}</Highlighter>
          <div>
            <small>Código: {option.codigoBarras}</small>
            <small>Precio: {option.pvp}</small>
          </div>
        </div>
      )}
    />
  )
})

export default React.memo(Donacion)
