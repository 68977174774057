// BadgeComponent.tsx

import { CBadge } from '@coreui/react-pro'
import React from 'react'
import { EEstadoElectronicoDoc } from '../../../../../../store/enums'

interface BadgeComponentProps {
  fechaAutorizacion: string
  estadoElectronico: string
}

const BadgeComponent: React.FC<BadgeComponentProps> = ({
  fechaAutorizacion,
  estadoElectronico,
}) => {
  const getConfig = React.useCallback(
    (
      status: string,
      fechaAutorizacion: string,
    ): { color: string; texto: string } => {
      let config = {
        color: 'danger',
        texto: 'NO AUTORIZADO', // Config por defecto si no se cumple ninguna condición
      }

      if (fechaAutorizacion === '') {
        config = {
          color: 'danger',
          texto: 'NO AUTORIZADO',
        }
      } else if (fechaAutorizacion !== '') {
        switch (status) {
          case EEstadoElectronicoDoc.SINAUTORIZAR:
            config = {
              color: 'danger',
              texto: 'NO AUTORIZADO',
            }
            break
          case EEstadoElectronicoDoc.AUTORIZADO:
            config = {
              color: 'info',
              texto: fechaAutorizacion,
            }
            break
          case EEstadoElectronicoDoc.ANULADO:
            config = {
              color: 'primary',
              texto: 'ANULADO',
            }
            break
          case 'PAGADO':
            config = {
              color: 'info',
              texto: fechaAutorizacion,
            }
            break
          case undefined:
            if (fechaAutorizacion === 'NO AUTORIZADO (NUEVO)') {
              config = {
                color: 'info',
                texto: fechaAutorizacion,
              }
            } else {
              config = {
                color: 'info',
                texto: fechaAutorizacion,
              }
            }
            break
          default:
            config = {
              color: 'danger',
              texto: fechaAutorizacion,
            }
        }
      }

      return config
    },
    [],
  )

  return (
    <CBadge color={getConfig(estadoElectronico, fechaAutorizacion).color}>
      {getConfig(estadoElectronico, fechaAutorizacion).texto}
    </CBadge>
  )
}

export default BadgeComponent
