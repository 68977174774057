import React from 'react'
import Draggable from 'devextreme-react/draggable'
import { SpeedDialAction } from 'devextreme-react/speed-dial-action'
import { VistasBusqueda } from '../../../store/enums'
import { FETCH_STATUS } from '../../../store/types'

interface DraggableActionsProps {
  vistaActual: VistasBusqueda
  estadoBusqueda: FETCH_STATUS
  resultados: any[]
  setVistaActual: (vista: VistasBusqueda) => void
  draggingGroupName: string
}

const DraggableActions: React.FC<DraggableActionsProps> = ({
  vistaActual,
  estadoBusqueda,
  resultados,
  setVistaActual,
  draggingGroupName,
}) => {
  const handleDragStart = (data: any) => {
    console.log('Drag Start:', data)
  }

  const handleDragEnd = (data: any) => {
    console.log('Drag End:', data)
  }

  return (
    <Draggable
      id="list"
      data="dropArea"
      group={draggingGroupName}
      onDragStart={handleDragStart}
    >
      <Draggable
        group={draggingGroupName}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SpeedDialAction
          icon="filter"
          label="Filtros"
          visible={
            vistaActual !== VistasBusqueda.Filtros &&
            estadoBusqueda !== FETCH_STATUS.LOADING
          }
          index={1}
          onClick={() => setVistaActual(VistasBusqueda.Filtros)}
        />
        <SpeedDialAction
          icon="search"
          label="Busqueda"
          visible={
            resultados.length > 0 &&
            vistaActual !== VistasBusqueda.ResultadosBusqueda
          }
          index={2}
          onClick={() => setVistaActual(VistasBusqueda.ResultadosBusqueda)}
        />
      </Draggable>
    </Draggable>
  )
}

export default DraggableActions
