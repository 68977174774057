import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../store/store'
import { FETCH_STATUS } from '../../../../../store/types'
import {
  TTipoDonacionListado,
  TFiltroBusquedaTipoDonacionesState,
} from './types'
import { TipoDonacionService } from '../services/tipoDonaciones.services'
import { TConfigSearchState } from '../../../configuracion/store/types'

const initialState: TConfigSearchState<TTipoDonacionListado> = {
  filter: {
    descripcion: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  selectedItem: null,
  error: false,
}

export const fetchPlanes = createAsyncThunk<
  Array<TTipoDonacionListado>,
  TFiltroBusquedaTipoDonacionesState
>('admin/botones', async (filtro) => {
  try {
    return await TipoDonacionService.getCollection(filtro)
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchConfSlice = createSlice({
  name: 'TipoDonacionesSearch',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(
      state,
      action: PayloadAction<TFiltroBusquedaTipoDonacionesState>,
    ) {
      state.filter = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
    },
    setResultados(
      state,
      action: PayloadAction<Array<TTipoDonacionListado> | []>,
    ) {
      state.resultados = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlanes.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
        state.error = false
      }
    })
    builder.addCase(
      fetchPlanes.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.resultados = payload.auto
        state.status = FETCH_STATUS.SUCCESS
        state.error = payload.error
        state.mensaje = payload.message
      },
    )
    builder.addCase(
      fetchPlanes.rejected,
      (state, { payload }: { payload: any }) => {
        state.resultados = []
        state.status = FETCH_STATUS.FAILED
        state.error = payload.error
        state.mensaje = payload.message
      },
    )
  },
})

export const selectFiltroBusquedaTipoDonaciones = (state: RootState) => {
  return state.administracion.catalagos.tipoDonaciones.search.filter
}

export const selectResultadosBusquedaTipoDonaciones = (state: RootState) => {
  return state.administracion.catalagos.tipoDonaciones.search.resultados
}

export const selectEstadoBusquedaTipoDonaciones = (state: RootState) => {
  return state.administracion.catalagos.tipoDonaciones.search.status
}

export const { setResultados, resetState, setCleanResult, changeFilter } =
  searchConfSlice.actions
export const searchReducer = searchConfSlice.reducer
