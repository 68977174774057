import { combineReducers } from '@reduxjs/toolkit'
import { modalAnticiposReducer } from '../../componentes/modalAnticipos/store/modalAnticiposReducer'
import { SharedState } from '../types/types'
import { providersReducer } from './providerReducers'
import { autorizacionesReducers } from './autorizacionesReducers'

export const sharedReducer = combineReducers<SharedState>({
  modalAnticipos: modalAnticiposReducer,
  providers: providersReducer,
  autorizacionesModulos: autorizacionesReducers,
})
