import { DateUtils } from '../../../../../helpers/dateUtils'
import { ETipoItemImpuesto } from '../../../../../services/itemEnum/enumSV'
import { ItemVenta } from '../../../types/types'
import { DetalleValidationResult } from './types'

export class DetalleNotaRemisionRedux {
  codigo: number = 0
  codigoInterno: string = ''
  codigoBarras: string = ''
  codigoImpuesto: number = 0
  descripcion: string = ''
  tieneIva: boolean = false
  porcentajeiva: number = 0
  imagenes: Array<string> = []
  fecha: string = ''
  _iva: number = 0
  _subtotal: number = 0
  _subtotal0: number = 0
  _subtotal5: number = 0
  _subtotalIva: number = 0
  _total: number = 0
  _cantidad: number = 0
  _precioUnitario: number = 0
  _precioIva: number = 0
  _porcentajeDescuento: number = 0
  _descuento: number = 0
  _noSujeto?: number = 0
  _gravada?: number = 0
  _exenta?: number = 0
  constructor() {}
}

export class DetalleNotaRemision {
  private _iva: number
  private _subtotal: number
  private _subtotal0: number
  private _exenta: number
  private _noSujeto: number
  private _gravada: number
  private _subtotal5: number
  private _subtotalIva: number
  private _total: number
  private _cantidad: number = 0
  private _precioUnitario: number = 0
  private _precioIva: number = 0
  private _porcentajeDescuento: number = 0
  private _descuento: number = 0
  private _presicionDecimal: number = 4
  private _codigoImpuesto: number = 0

  codigo: number
  codigoInterno: string
  codigoBarras: string
  descripcion: string
  tieneIva: boolean
  porcentajeiva: number
  imagenes: Array<string>
  fecha: string

  get exenta(): number {
    return this._exenta
  }

  get noSujeto(): number {
    return this._noSujeto
  }

  get gravada(): number {
    return this._gravada
  }

  get codigoImpuesto(): number {
    return this._codigoImpuesto
  }

  //#region cantidad
  get cantidad(): number {
    return this._cantidad
  }

  set cantidad(newCantidad: number) {
    if (newCantidad < 0) {
      throw new Error('la cantidad no puede ser menor a cero')
    }
    this._cantidad = newCantidad
    if (isNaN(newCantidad)) {
      this._cantidad = 0
    }
    this.calcular()
  }
  //#endregion

  //#region precio
  get precio(): number {
    return this._precioUnitario
  }

  set precio(newPrecio: number) {
    if (newPrecio < 0) {
      throw new Error('el precio no puede ser menor a cero')
    }
    this._precioUnitario = newPrecio
    if (isNaN(newPrecio)) {
      this._precioUnitario = 0
    }
    this.calcular()
  }
  //#endregion

  get descuento(): number {
    return this._descuento
  }

  get porcentajeDescuento(): number {
    return this._porcentajeDescuento
  }

  set porcentajeDescuento(newPorcentaje: number) {
    this._porcentajeDescuento = newPorcentaje
    if (isNaN(newPorcentaje)) {
      this._porcentajeDescuento = 0
    }
    this.calcular()
  }

  get subtotal(): number {
    return this._subtotal
  }

  get subtotal0(): number {
    return this._subtotal0
  }

  get subtotal5(): number {
    return this._subtotal5
  }

  get subtotalIVA(): number {
    return this._subtotalIva
  }

  get iva(): number {
    return this._iva
  }

  get total(): number {
    return this._total
  }

  get precioIva(): number {
    return this._precioIva
  }

  set precioIva(newPrecioIva: number) {
    this._precioIva = newPrecioIva
    if (isNaN(newPrecioIva)) {
      this._precioIva = 0
      newPrecioIva = 0
    }
    ;(this._precioUnitario = this.tieneIva
      ? newPrecioIva / (this.porcentajeiva + 1)
      : newPrecioIva),
      this.calcular()
  }

  constructor() {
    this.fecha = DateUtils.getCurrentDateAsString()
    this.codigo = 0
    this.codigoInterno = ''
    this.codigoBarras = ''
    this.descripcion = ''
    this.tieneIva = false
    this._iva = 0
    this._subtotal0 = 0
    this._exenta = 0
    this._noSujeto = 0
    this._gravada = 0
    this._subtotal5 = 0
    this._subtotal = 0
    this._total = 0
    this._subtotalIva = 0
    this._codigoImpuesto = 0
    this.porcentajeiva = 0
    this.imagenes = []
  }

  public redondear(valor: number): number {
    return parseFloat(valor.toFixed(this._presicionDecimal))
  }

  public calcular(columna: string = '') {
    const keys = Object.keys(this)

    for (const key of keys) {
      if (
        key !== '_total' &&
        key !== '_subtotal' &&
        key !== 'codigoBarras' &&
        key !== '_codigoBarras' &&
        key !== 'descripcion' &&
        key !== '_precioUnitario'
      ) {
        const value = Number(this[key])
        if (!isNaN(value)) {
          this[key] = value
        }
      }
    }

    const cantidad = this.cantidad

    // Calcular el subtotal antes del descuento
    const subtotalAntesDescuento = cantidad * this._precioUnitario

    // Calcular el descuento
    const descuento =
      this.porcentajeDescuento > 0
        ? (subtotalAntesDescuento * this.porcentajeDescuento) / 100.0
        : 0

    // Calcular el subtotal neto después del descuento
    const subtotalNeto = subtotalAntesDescuento - descuento

    // Calcular el IVA

    let ivaTotal = 0

    if (this.codigoImpuesto === ETipoItemImpuesto.gravado) {
      this._gravada = this._precioUnitario * cantidad - descuento
      const subtotalGravado = this._precioUnitario * cantidad - descuento
      ivaTotal = subtotalGravado * this.porcentajeiva
      this._subtotalIva = subtotalNeto
      this._iva = ivaTotal
    } else if (this.codigoImpuesto === ETipoItemImpuesto.exento) {
      this._exenta = subtotalNeto
      this._iva = 0
    } else if (this.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      this._noSujeto = subtotalNeto
      this._iva = 0
    }

    // Actualizar los valores en el item
    this._descuento = this.redondear(descuento)
    ;(this._subtotal = this._gravada + this._exenta + this._noSujeto),
      (this._total = this.redondear(subtotalNeto))

    if (columna == '_precioUnitario') {
      const precioIva =
        this._precioUnitario * (this.tieneIva ? 1 + this.porcentajeiva : 1)
      this._precioIva = this.redondear(precioIva)
    }
  }

  public validate(): DetalleValidationResult {
    return { resultado: true }
  }

  public setDetalle(data: DetalleNotaRemisionRedux, presicionDecimal: number) {
    this.codigo = data.codigo
    this.codigoInterno = data.codigoInterno
    this.codigoBarras = data.codigoBarras
    this.descripcion = data.descripcion
    this.tieneIva = data.tieneIva
    this.porcentajeiva = data.porcentajeiva
    this.imagenes = data.imagenes
    this.fecha = data.fecha
    this._iva = data._iva
    this._subtotal = data._subtotal
    this._subtotal0 = data._subtotal0
    this._noSujeto = data._noSujeto
    this._gravada = data._gravada
    this._exenta = data._exenta
    this._subtotalIva = data._subtotalIva
    this._total = data._total
    this._cantidad = data._cantidad
    this._precioUnitario = data._precioUnitario
    this._precioIva = data._precioIva
    this._porcentajeDescuento = data._porcentajeDescuento
    this._descuento = data._descuento
    this._presicionDecimal = presicionDecimal
    this._codigoImpuesto = data.codigoImpuesto
    if (this._total <= 0) {
      this.calcular()
    }
  }

  public getDetalle(): DetalleNotaRemisionRedux {
    this.calcular()
    const detalle: DetalleNotaRemisionRedux = {
      codigo: this.codigo,
      codigoInterno: this.codigoInterno,
      codigoBarras: this.codigoBarras,
      descripcion: this.descripcion,
      tieneIva: this.tieneIva,
      porcentajeiva: this.porcentajeiva,
      imagenes: this.imagenes,
      fecha: this.fecha,
      _iva: this.iva,
      _subtotal: this.subtotal,
      _subtotal0: this.subtotal0,
      _exenta: this.exenta,
      _noSujeto: this.noSujeto,
      _gravada: this.gravada,
      _subtotal5: this.subtotal5,
      _subtotalIva: this.subtotalIVA,
      _total: this.total,
      _cantidad: this.cantidad,
      _precioUnitario: this.precio,
      _precioIva: this.precioIva,
      _descuento: this.descuento,
      _porcentajeDescuento: this.porcentajeDescuento,
      codigoImpuesto: this.codigoImpuesto,
    }
    return detalle
  }

  public setData(
    codigo: number,
    codigoInterno: string,
    codigoBarras: string,
    descripcion: string,
    porcentajeiva,
  ) {
    this.codigo = codigo
    this.codigoInterno = codigoInterno
    this.codigoBarras = codigoBarras
    this.descripcion = descripcion
    this.tieneIva = porcentajeiva > 0
    this._iva = 0
    this._subtotal0 = 0
    this._subtotal = 0
    this._total = 0
    this._subtotalIva = 0
    this.porcentajeiva = porcentajeiva / 100
    this.imagenes = []
  }

  public setItem(item: ItemVenta, presicionDecimal: number) {
    this.codigo = item.codigo
    this.codigoInterno = item.codigoInterno
    this.codigoBarras = item.codigoBarras
    this.descripcion = item.descripcion
    this.tieneIva = item.porcentajeIva > 0
    this.porcentajeiva = item.porcentajeIva / 100
    this.precio = item.pvp
    this.precioIva = item.pvpiva
    this.cantidad = this.cantidad === 0 ? 1 : this.cantidad
    this._presicionDecimal = presicionDecimal
    this._codigoImpuesto = item.codigoImpuesto
    this.calcular()
  }
}
