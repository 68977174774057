import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../store/toasterReducer'
import { ToastTypes } from '../store/types'

export const useToast = () => {
  const dispatch = useDispatch()

  const setToast = useCallback(
    (texto: string, type: ToastTypes, title: string = 'Acatha') => {
      const time = texto.length < 50 ? 5000 : texto.length > 50 ? 9000 : 3000

      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: title,
          content: texto,
          fade: true,
          type,
        }),
      )
    },
    [dispatch],
  )

  return { setToast }
}
